import React, {useContext, useEffect, useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import Config from "../Config";
import axios from "axios";
import yellow_glitter from "../assets/images/yellow star.png";
import pink_glitter from "../assets/images/star.svg";
import green_glitter from "../assets/images/greenstar.png";
import purple_glitter from "../assets/images/glitter.png";
import {DistanceMatrixService} from "@react-google-maps/api";
import BookingAddress from "../component/Booking/BookingAddress";
import Footer from "../component/LandingPage/Footer";
import {AiFillQuestionCircle, AiOutlineArrowLeft} from "react-icons/ai";
import ProvideRequirementsModal from "../component/Booking/ProvideRequirementsModal";
import {Modal} from "@material-ui/core";
import {toast} from "react-hot-toast";
import ReSubscriptionOrder from "../component/Booking/ReSubscriptionOrder";
import {Context} from "../Contexts/Context";
import {BsChevronRight} from "react-icons/bs";
import {numberWithCommas} from "../component/Utils";
import TransactionDetail from "../component/OrderSubscriptionDetail/TransactionDetail";

const OrderDetail = () => {
    const history = useHistory()
    const location = useLocation()

    const {setFaqNum, handleOpenFaqModal} = useContext(Context);

    const [openModal, setOpenModal] = useState(false)

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const [bookedItem, setBookedItem] = useState(null)

    const [distance, setDistance] = useState(1)

    const currency = useSelector(state => state.accounts.currency)

    const cartId = location.pathname.split('/')[2]
    useEffect(() => {
        const config = Config()
        axios.get(`https://cgapp.clykk.com/cart/api/v2/cart-management/cart/${cartId}/book`, config)
            .then(response => {
                setBookedItem(response.data)
            })
            .catch(error => {
                console.log(error.message)
            })
    }, [])

    const [slide, setSlide] = useState(2)

    const [changeSubscriptionData, setChangeSubscriptionData] = useState({
        subID: "",
        serviceTime: "",
        serviceDate: "",
    })

    const [allowedTime, setAllowedTime] = useState([])

    const get_date_format = () => {
        const date = new Date()
        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()
        if (`${month}`.length > 2) {
            return `${year}-${month}-${day}`
        } else {
            return `${year}-0${month}-${day}`
        }
    }

    const change_in_schedule = (value, key) => {
        if (key === 'serviceDate') {
            const current_date = Date.parse(get_date_format())
            const date = Date.parse(value)
            if (current_date > date) {
                toast.error('You can not set a date before the current date', {duration: 3000})
                return
            }
        }
        setChangeSubscriptionData({...changeSubscriptionData, [key]: value})
    }

    const calculateServiceTime = (openTime, closeTime, ServiceDuration) => {
        const openTimeInt = parseInt(openTime.substring(0, 2))
        const closeTimeInt = parseInt(closeTime.substring(0, 2)) + 12
        const openTimeSecInt = parseInt(openTime.substring(3, 5))
        const closeTimeSecInt = parseInt(closeTime.substring(3, 5))

        let times = []
        for (let i = openTimeInt; i <= closeTimeInt; i = i + 1) {
            if (i === openTimeInt && openTimeSecInt !== 0) {
                times.push(`${i}:30`)
            } else if (i === closeTimeInt && closeTimeSecInt === 0) {
                times.push(`${i}:00`)
            } else {
                times.push(`${i}:00`)
                times.push(`${i}:30`)
            }
        }
        // }
        setAllowedTime(times)
    }

    useEffect(() => {
        if (bookedItem !== null) {
            const config = Config()
            axios.get(`https://cgapp.clykk.com/routing/api/v2/ext/route-setting/routes?cityID=27&ruleID=${bookedItem.subscriptions[0].package.serviceRuleID}&subcategoryID=${bookedItem.subscriptions[0].package.subcategoryID}&date=${changeSubscriptionData.serviceDate}`, config)
                .then(resp => {
                    const data = resp.data
                    const openTime = data.ServiceTime.substring(11, 15)
                    const closeTime = data.LastServiceTime.substring(11, 15)

                    if (changeSubscriptionData.serviceDate === data.ServiceTime.substring(0, 10)) {
                        calculateServiceTime(openTime, closeTime, parseInt(data.ServiceDuration))
                    } else {
                        toast.error('No service available on this date. Please another date')
                        setAllowedTime([])
                    }
                })
                .catch(err => console.log(err.message))
        }
    }, [changeSubscriptionData.serviceDate])

    const changeSubscription = () => {
        const config = Config()
        axios.put(`https://cgapp.clykk.com/cart/api/v2/cart-management/subscription/${changeSubscriptionData.subID}/schedule`, changeSubscriptionData, config)
            .then(response => {
                axios.get(`https://cgapp.clykk.com/cart/api/v2/cart-management/cart/${cartId}/book`, config)
                    .then(response => {
                        setBookedItem(response.data)
                        setSlide(2)
                    })
                    .catch(error => {
                        console.log(error.message)
                    })
            })
            .catch(error => console.log(error.message))
    }

    const [selectedTransaction, setSelectedTransaction] = useState(null)
    const [transactionDetailModal, setTransactionDetailModal] = useState(false)

    const openTransactionDetailModal = () => {
        setTransactionDetailModal(true)
    }

    const closeTransactionDetailModal = () => {
        setTransactionDetailModal(false)
    }

    return (
        <div style={{position: 'relative'}}>
            <>
                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 12, top: '5%', left: '70%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 12, top: '10%', left: '10%'}}/>
                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 10, top: '12%', left: '60%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 10, top: '31%', left: '20%'}}/>


                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 12, top: '32%', left: '65%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 10, top: '40%', left: '55%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 10, top: '50%', left: '70%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '60%', left: '82%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '70%', left: '12%'}}/>

                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 12, top: '75%', left: '65%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 12, top: '80%', left: '49%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '84%', left: '15%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '97%', left: '87%'}}/>
            </>
            <div className="container mt-3 mb-3 ">
                <div style={{display: 'flex'}}>
                    <AiOutlineArrowLeft onClick={() => {
                        history.goBack()
                    }} style={{margin: "auto 10px auto 0", cursor: 'pointer'}}/>
                    <p className={'booking-heading'}>Booking Details
                        <span style={{fontSize: 12, marginLeft: 10}}>{cartId}</span>
                    </p>
                    <AiFillQuestionCircle
                        className={'faq-icon-selection-location booking-faq'}
                        onClick={() => {
                            setFaqNum(38)
                            handleOpenFaqModal()
                        }}/>
                </div>
            </div>
            {bookedItem !== null &&
                <>
                    <div className={'container'}>
                        {
                            bookedItem.subscriptions[0].package.serviceRuleID !== 1 &&
                            <DistanceMatrixService
                                options={{
                                    destinations: [{
                                        lng: bookedItem.subscriptions[0].serviceAddress.longitude,
                                        lat: bookedItem.subscriptions[0].serviceAddress.latitude
                                    }],
                                    origins: [{
                                        lng: bookedItem.subscriptions[0].deliveryAddress.longitude,
                                        lat: bookedItem.subscriptions[0].deliveryAddress.latitude
                                    }],
                                    travelMode: "DRIVING",
                                }}
                                callback={(res) => {
                                    try {
                                        const distance = res.rows[0].elements[0].distance.value
                                        if (Math.round(distance / 1000) > 0) {
                                            setDistance(Math.round(distance / 1000))
                                        } else {
                                            setDistance(1)
                                        }
                                    } catch {
                                        setDistance(1)
                                    }
                                }}
                            />
                        }

                        <img src={bookedItem.subscriptions[0].package.packageImage}
                             className={'w-100 booking-main-img'}/>


                        <div className="row mt-4 mb-5">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2">

                                {bookedItem.subscriptions[0].package.serviceRuleID === 1 &&
                                    <BookingAddress
                                        cartItem={bookedItem} setCartItem={setBookedItem}
                                        addressHeading={'Service Address'}
                                        addressType={'service'} detail={true}/>
                                }
                                {bookedItem.subscriptions[0].package.serviceRuleID === 2 &&
                                    <>
                                        <BookingAddress
                                            cartItem={bookedItem} setCartItem={setBookedItem}
                                            addressHeading={'Your Address'}
                                            addressType={'service'} detail={true}/>
                                        <BookingAddress
                                            cartItem={bookedItem} setCartItem={setBookedItem}
                                            addressHeading={'Drop Location'}
                                            addressType={'delivery'} detail={true}/>
                                    </>
                                }
                                {bookedItem.subscriptions[0].package.serviceRuleID === 3 &&
                                    <>
                                        <BookingAddress
                                            cartItem={bookedItem} setCartItem={setBookedItem}
                                            addressHeading={'Customer Address'}
                                            addressType={'service'} detail={true}/>
                                    </>
                                }
                                {bookedItem.subscriptions[0].package.serviceRuleID === 4 &&
                                    <>
                                        <BookingAddress
                                            cartItem={bookedItem} setCartItem={setBookedItem}
                                            addressHeading={'Pickup Address'}
                                            addressType={'service'} detail={true}/>
                                        <BookingAddress
                                            cartItem={bookedItem} setCartItem={setBookedItem}
                                            addressHeading={'Delivery Address'}
                                            addressType={'delivery'} detail={true}/>
                                    </>
                                }

                                <div style={{width: '100%'}}>

                                    <div style={{display: 'flex', marginBottom: 10}}>
                                        <p className={'booking-packing-heading'}>Package</p>
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        <div style={{margin: 'auto 10px auto 0'}}>
                                            <p className={'booking-package-name'}>{bookedItem.subscriptions[0].package.packageName}
                                                {bookedItem.subscriptions[0].package.serviceRuleID !== 1 &&
                                                    <span>
                                                 [ 0 - {distance} km]
                                        </span>
                                                }
                                            </p>
                                        </div>
                                        <div style={{margin: 'auto 0 auto auto'}}>
                                            {bookedItem.subscriptions[0].package.serviceRuleID !== 1 ?
                                                <>
                                                    <p className={'booking-base-price'}>
                                                        <del>
                                                            {currency}{numberWithCommas(parseFloat(bookedItem.subscriptions[0].package.basePrice + bookedItem.subscriptions[0].package.sellingPrice * distance).toFixed(2))}
                                                        </del>
                                                    </p>
                                                    <p className={'booking-selling-price'}>
                                                        {currency}
                                                        {numberWithCommas(parseFloat((bookedItem.subscriptions[0].package.basePrice) / 2 + bookedItem.subscriptions[0].package.sellingPrice * distance).toFixed(2))}
                                                    </p>
                                                </>
                                                :
                                                <>
                                                    <p className={'booking-base-price'}>
                                                        <del>{currency}{numberWithCommas(parseFloat(bookedItem.subscriptions[0].package.basePrice).toFixed(2))}</del>
                                                    </p>
                                                    <p className={'booking-selling-price'}>{currency}{numberWithCommas(parseFloat(bookedItem.subscriptions[0].package.sellingPrice).toFixed(2))}</p>
                                                </>
                                            }
                                        </div>
                                    </div>


                                    <p className={'package-add-on-text'}>Package Add-On's</p>
                                    {bookedItem.subscriptions[0].hasOwnProperty('addOns') &&
                                        bookedItem.subscriptions[0].addOns.map((item) => {
                                            return (
                                                <div style={{display: 'flex'}} className={'my-4'}>
                                                    <img src={item.packageImage} className={'package-add-img'}/>
                                                    <div className={'package-add-on-services-text-container'}>
                                                        <p className={'package-add-on-service'}>{item.packageName}</p>
                                                    </div>
                                                    <div className={'booking-price-container'}>
                                                        <p className={'package-add-on-price-1'}>
                                                            <del>{currency}{numberWithCommas(parseFloat(item.basePrice).toFixed(2))}</del>
                                                        </p>
                                                        <p className={'package-add-on-price-2'}>{currency}{numberWithCommas(parseFloat(item.sellingPrice).toFixed(2))}</p>
                                                    </div>
                                                    <div
                                                        className={'package-add-on-quantity-container'}>
                                                        <div className={'quantity-controller-container'}
                                                             style={{cursor: 'auto'}}
                                                            // onClick={() => {
                                                            //     increase_decrease_add_ons(item, '-')
                                                            // }}
                                                        >
                                                            <p className={'quantity-controller'}>-</p>
                                                        </div>
                                                        <p className={'quantity'}
                                                           style={{margin: 'auto'}}>{item.itemCount}</p>
                                                        <div className={'quantity-controller-container'}
                                                             style={{cursor: 'auto'}}
                                                            // onClick={() => {
                                                            //     increase_decrease_add_ons(item, '+')
                                                            // }}
                                                        >
                                                            <p className={'quantity-controller'}>+</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                {slide === 1 &&
                                    <ReSubscriptionOrder
                                        changeSubscriptionData={changeSubscriptionData}
                                        allowedTime={allowedTime}
                                        change_in_schedule={change_in_schedule}
                                        changeSubscription={changeSubscription}
                                    />
                                }
                                {slide === 2 &&
                                    <div style={{width: '100%'}}>
                                        <div style={{display: 'flex'}}>
                                            <p
                                                style={{fontSize: 14, fontWeight: 600, margin: "auto auto auto 0"}}>
                                                Service Date - Time
                                                {bookedItem.reschedCount === 0 &&
                                                    '(One Free Re-schedule Avaliable)'
                                                }
                                            </p>
                                        </div>

                                        <div style={{padding: 10, backgroundColor: "#F6F7F9", marginBottom: 10}}>
                                            {bookedItem.subscriptions.map((subscription) => {
                                                return (
                                                    <>
                                                        <p style={{
                                                            fontSize: 12,
                                                            fontWeight: 600,
                                                            color: "#424B5A"
                                                        }}>{subscription.cartID} - {subscription.id}</p>
                                                        <div className="row g-0 mb-2">
                                                            <div
                                                                className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
                                                                <p className={'schedule-info-text'}>Date</p>
                                                                <p className={'schedule-info-detail'}>{subscription.serviceDate}</p>
                                                            </div>
                                                            <div
                                                                className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2">
                                                                <p className={'schedule-info-text'}>Time</p>
                                                                <p className={'schedule-info-detail'}>{subscription.serviceTime}</p>
                                                            </div>
                                                            <div
                                                                className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
                                                                <p className={'schedule-info-text'}>Subscription</p>
                                                                <p className={'schedule-info-detail'}>
                                                                    {subscription.subscriptionFrequency}
                                                                </p>
                                                            </div>
                                                            <div
                                                                className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
                                                                <p className={'schedule-info-text'}>Price</p>
                                                                <p className={'schedule-info-detail'}>
                                                                    {currency}{numberWithCommas(parseFloat(subscription.package.sellingPrice).toFixed(2))}
                                                                </p>
                                                            </div>
                                                            <div
                                                                className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-1 col-1">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    width: '100%',
                                                                    height: '100%'
                                                                }}>
                                                                    <BsChevronRight
                                                                        className={'order-detail-right-arrow'}
                                                                        onClick={() => {
                                                                            history.push(`/service-execution/${subscription.id}/`)
                                                                        }}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                        <p style={{fontSize: 14, fontWeight: 600}}>Transaction</p>
                                        <div style={{padding: 10, backgroundColor: "#F6F7F9", marginBottom: 10}}>
                                            {bookedItem.subscriptions.map((subscription) => {
                                                return subscription.transaction.map((transaction) => {
                                                    return (
                                                        <div className="row g-0 mb-2" onClick={() => {
                                                            setSelectedTransaction(transaction)
                                                            openTransactionDetailModal()
                                                        }}>
                                                            <div
                                                                className="col-xl-4 col-lg-4 col-md-4 col-sm-3 col-xs-3 col-3">
                                                                <p className={'schedule-info-text'}>Transaction ID</p>
                                                                <p className={'schedule-info-detail'}>{transaction.transactionID}</p>
                                                            </div>
                                                            <div
                                                                className="col-xl-2 col-lg-2 col-md-2 col-sm-3 col-xs-3 col-3">
                                                                <p className={'schedule-info-text'}>Paid Amount</p>
                                                                <p className={'schedule-info-detail'}>{currency}{numberWithCommas(parseFloat(transaction.payableAmount).toFixed(2))}</p>
                                                            </div>
                                                            <div
                                                                className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2">
                                                                <p className={'schedule-info-text'}>Date</p>
                                                                <p className={'schedule-info-detail'}>
                                                                    {transaction.createdAt.substring(0, 10)}
                                                                </p>
                                                            </div>
                                                            <div
                                                                className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
                                                                <p className={'schedule-info-text'}>Description</p>
                                                                <p className={'schedule-info-detail'}
                                                                   style={{textTransform: "capitalize"}}
                                                                >
                                                                    {transaction.description}
                                                                </p>
                                                            </div>
                                                            <div
                                                                className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-1 col-1"
                                                                style={{display: 'flex'}}>
                                                                <BsChevronRight
                                                                    className={'cart-right-arrow'}/>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            })}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <Modal
                        open={openModal}
                        onClose={handleCloseModal}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        <ProvideRequirementsModal packageImage={bookedItem.package.packageImage}/>
                    </Modal>

                    <Modal
                        open={transactionDetailModal}
                        onClose={closeTransactionDetailModal}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        <TransactionDetail
                            subcategoryID={bookedItem.subscriptions[0].package.subcategoryID}
                            sellingPrice={bookedItem.subscriptions[0].package.serviceRuleID !== 1 ? parseFloat(bookedItem.subscriptions[0].package.basePrice) / 2 + bookedItem.subscriptions[0].package.sellingPrice * distance : parseFloat(bookedItem.subscriptions[0].package.sellingPrice)}
                            cartID={bookedItem.subscriptions[0].cartID} selectedTransaction={selectedTransaction}
                            closeTransactionDetailModal={closeTransactionDetailModal}/>

                    </Modal>

                </>
            }

            <Footer/>
        </div>
    );
}

export default OrderDetail;