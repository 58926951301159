import cart_img from '../assets/icons/cart.svg'
import bell from '../assets/icons/bell.svg'
import logo from '../assets/images/logo.svg'
import {GiHamburgerMenu} from "react-icons/gi";
import {useHistory} from "react-router-dom";
import CategoryImg1 from "../assets/images/category-img.png";
import CategoryImg2 from "../assets/images/category-img1.png";
import CategoryImg3 from "../assets/images/category-img3.png"
import "swiper/css";
import "swiper/css/pagination";
import {useState, useContext, useEffect} from 'react';
import CategoryDropDown from "./CategoryDropdown/CategoryDropDown";
import {Context} from "../Contexts/Context";
import {useSelector} from "react-redux";
import {useDispatch} from "react-redux";
import {Logout} from "../actions/accounts";
import axios from "axios";

const Navbar = ({setShowSidebar, handleOpenModal, customer = true}) => {
    const history = useHistory()
    const {login, setValue} = useContext(Context)

    let isAuthenticated = useSelector(state => state.accounts.isAuthenticated)

    let cart = useSelector(state => state.accounts.cart)

    const alertCount = useSelector(state => state.accounts.alertCount)

    // let alerts = useSelector(state => state.accounts.alerts)

    let user = useSelector(state => state.accounts.data)

    const dispatch = useDispatch();

    return (
        <>
            <div className={'navbar'}>
                <img src={logo} className={'logo'} onClick={() => {
                    history.push('/')
                }}/>
                <div className={'nav-item nav-item-lg'}>
                    <p onClick={() => {
                        history.push('')
                    }}>Home</p>
                </div>
                <div className={'nav-item nav-item-lg'}>
                    <a style={{textDecoration: 'None', color: "#5a6167"}} href={'https://blog.clykk.com/'} target={'_blank'}>Blog</a>
                </div>
                <div className={'nav-item nav-item-lg'}>
                    <p onClick={() => {
                        history.push('/offers/')
                    }}>Offers</p>
                </div>
                <div className={'nav-item nav-item-lg'}>
                    <p onClick={() => {
                        history.push('/about-us/')
                    }}>About us</p>
                </div>
                <div className={'nav-item nav-item-lg'}>
                    <p onClick={() => {
                        if (isAuthenticated) {
                            history.push('/my-orders/')
                        } else {
                            handleOpenModal(1)
                        }
                    }}>Orders</p>
                </div>
                <div className={'nav-item-diff nav-item-lg'}>
                    <p>|</p>
                </div>
                {customer &&
                    <div className={'nav-item nav-item-lg'}>
                        <p onClick={() => {
                            history.push('/service-provider/')
                        }}>Service Providers</p>
                    </div>
                }
                {!customer &&
                    <div className={'nav-item nav-item-lg'}>
                        <p onClick={() => {
                            history.push('')
                        }}>Customer</p>
                    </div>
                }
                <div className={'nav-item-diff'}>
                    <div style={{position: 'relative'}}
                         onClick={() => {
                             if (isAuthenticated) {
                                 history.push('/alert/')
                             } else {
                                 handleOpenModal(1)
                             }
                         }}>
                        <img src={bell} alt={'bell'}/>

                        {alertCount > 0 &&
                            <div style={{
                                position: 'absolute',
                                backgroundColor: '#6CD7BB',
                                padding: 3,
                                width: 18,
                                height: 18,
                                borderRadius: '50%',
                                top: -10,
                                right: -10
                            }}>
                                <div style={{width: '100%', height: '100%', display: 'flex'}}>
                                    <p style={{
                                        fontSize: 10,
                                        fontWeight: 700,
                                        color: 'white',
                                        textAlign: 'center',
                                        margin: 'auto'
                                    }}>
                                        {alertCount}
                                    </p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className={'nav-item-diff'}>
                    <div style={{position: 'relative'}}
                         onClick={() => {
                             if (isAuthenticated) {
                                 history.push('/cart/')
                             } else {
                                 handleOpenModal(1)
                             }
                         }}>
                        <img src={cart_img} alt={'cart'}
                        />
                        {cart.length > 0 &&
                            <div style={{
                                position: 'absolute',
                                backgroundColor: '#6CD7BB',
                                padding: 3,
                                width: 18,
                                height: 18,
                                borderRadius: '50%',
                                top: -10,
                                right: -10
                            }}>
                                <div style={{width: '100%', height: '100%', display: 'flex'}}>
                                    <p style={{
                                        fontSize: 10,
                                        fontWeight: 700,
                                        color: 'white',
                                        textAlign: 'center',
                                        margin: 'auto'
                                    }}>
                                        {cart.length}
                                    </p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {isAuthenticated ?
                    <div className={'nav-item nav-item-lg'}>
                        <button
                            onClick={() => {
                                history.push('/account/')
                            }}
                            style={{
                                // margin: '0 0 20px 0',
                                fontSize: 13,
                                fontWeight: 600,
                                color: '#d86a9d',
                                borderRadius: 5,
                                border: '2px solid #d86a9d',
                                backgroundColor: "white"
                            }}
                            className={'btn'}>My Account
                        </button>
                        <button
                            onClick={() => {
                                dispatch(Logout())

                            history.push('/')
                            }}
                            style={{
                                // margin: '0 0 20px 0',
                                fontSize: 13,
                                fontWeight: 600,
                                color: '#FFC19A',
                                borderRadius: 5,
                                border: '2px solid #FFC19A',
                                marginLeft: 5,
                                backgroundColor: "white"
                            }}
                            className={'btn'}>Logout
                        </button>
                    </div>
                    :
                    <div className={'nav-item nav-item-lg'}>
                        <button
                            onClick={() => {
                                setValue(0)
                                handleOpenModal(1)
                            }}
                            className={'btn nav-btn login-btn'}>Login
                        </button>
                        <button
                            onClick={() => {
                                setValue(1)
                                handleOpenModal(1)
                            }}
                            style={{
                                // margin: '0 0 20px 0',
                                fontSize: 13,
                                width: 100,
                                fontWeight: 600,
                                color: '#FFC19A',
                                borderRadius: 5,
                                border: '2px solid #FFC19A',
                                backgroundColor: "white",
                                marginLeft: 5
                            }}
                            className={'btn'}>SignUp
                        </button>
                    </div>
                }

                <div className={'nav-item nav-item-sm'}>
                    <GiHamburgerMenu fontSize={20} onClick={() => {
                        setShowSidebar(true)
                    }}/>
                </div>

            </div>


        </>
    );
}

export default Navbar;