import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {Pagination} from "swiper";
import {useHistory} from "react-router-dom";
import clsx from "clsx";
import {useSelector} from "react-redux";
import {AiFillQuestionCircle} from "react-icons/ai";
import React, {useContext, useEffect, useState} from "react";
import Config from "../../../Config";
import axios from "axios";
import {Context} from "../../../Contexts/Context";
import {numberWithCommas} from "../../Utils";

const Offers = ({
                    offers,
                    get_slide,
                    offers_text,
                    type,
                    openSubCatPage,
                    setCatOpenModal,
                    handleCategoryClick,
                    allowHandleClick = true,
                    offerActions = true,
                    offersLoading = false, showFaq = false
                }) => {
    const history = useHistory()
    const currency = useSelector(state => state.accounts.currency)
    const location = useSelector(state => state.accounts.location)

    const onOfferClicked = (offer) => {
        if (offerActions) {
            if (offer.packageID !== 0) {
                history.push(`/package-detail/${offer.subcategoryID}/${offer.packageID}/`)
            } else if (offer.subcategoryID !== 0 && allowHandleClick) {
                openSubCatPage(offer.categoryID, offer.subcategoryID)
                // history.push(`/sub-category-detail/${offer.categoryID}/${offer.subcategoryID}/`)
            } else if (offer.categoryID !== 0 && allowHandleClick) {
                handleCategoryClick(location.city, offer.categoryID)
                setCatOpenModal(true)
            }
        }
    }

    const [loadingSlides, setLoadingSlides] = useState([
        1, 2
    ])

    const {setFaqNum, handleOpenFaqModal} = useContext(Context);

    return (
        <div className={'container my-2'}>
            <div style={{display: 'flex', width: '100%'}}>
                <p style={{margin: 'auto 0', fontWeight: 600, fontSize: 15, color: '#424B5A'}}>
                    {offers_text}
                </p>
                {showFaq &&
                    <AiFillQuestionCircle
                        className={'faq-icon-selection-location'}
                        style={{margin: 'auto 0 auto auto'}}
                        onClick={() => {
                            setFaqNum(11)
                            handleOpenFaqModal()
                        }}/>
                }
            </div>
            {offersLoading ?
                <Swiper
                    slidesPerView={"auto"}
                    autoplay={{delay: 2000}}
                    spaceBetween={30}
                    pagination={{
                        clickable: false,
                    }}
                    modules={[Pagination]}
                    className={clsx("mySwiper my-3 swiper-sm")}
                >
                    {loadingSlides.map((slide) => {
                        return (
                            <SwiperSlide>
                                <div style={{cursor: "pointer"}}>
                                    <div style={{position: 'relative', backgroundColor: "#F6F7F9", display: 'flex'}}>
                                        <div className="spinner-grow text-secondary lazy-loading" role="status"
                                             style={{margin: 'auto', width: '3rem', height: '3rem'}}>
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
                :
                <Swiper
                    slidesPerView={"auto"}
                    autoplay={{delay: 2000}}
                    spaceBetween={30}
                    pagination={{
                        clickable: false,
                    }}
                    modules={[Pagination]}
                    className={clsx("mySwiper my-3 swiper-sm")}
                >
                    {offers.map((slide) => {
                        return (
                            get_slide(slide, type) &&
                            <SwiperSlide>
                                <div style={{cursor: "pointer"}}
                                     onClick={() => {
                                         onOfferClicked(slide)
                                     }}
                                >
                                    <div style={{position: 'relative'}}>
                                        <div style={{
                                            padding: "15px 5px",
                                            textAlign: 'left',
                                            zIndex: 10,
                                            position: 'absolute',
                                            height: "100%",
                                            width: "100%",
                                            display: 'flex',
                                            flexDirection: "column"
                                        }}>
                                            <div style={{display: 'flex'}}>
                                                <div style={{marginLeft: "10px"}}>
                                                    {slide.discountType === 'value' ?
                                                        <p style={{
                                                            fontSize: 10,
                                                            fontWeight: 600,
                                                            color: 'white',
                                                            textAlign: 'center',
                                                            letterSpacing: '0.3px'
                                                        }}>
                                                            UP TO <br/>
                                                            {currency}{numberWithCommas(slide.maxDiscount)}
                                                            <br/>
                                                            OFF
                                                        </p>
                                                        :
                                                        <p style={{
                                                            fontSize: 10,
                                                            fontWeight: 600,
                                                            color: 'white',
                                                            textAlign: 'center',
                                                            letterSpacing: '0.3px'
                                                        }}>
                                                            UP TO <br/>
                                                            {numberWithCommas(slide.maxDiscount)}%
                                                            <br/>
                                                            OFF
                                                        </p>
                                                    }
                                                </div>
                                                <div style={{
                                                    marginLeft: 15
                                                }}>
                                                    <p style={{
                                                        fontSize: 12,
                                                        fontWeight: 600,
                                                        color: '#87494d'
                                                    }}>{slide.title}</p>
                                                    <p style={{
                                                        fontSize: 12,
                                                        fontWeight: 400,
                                                        color: 'black'
                                                    }}>{slide.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <img style={{position: 'absolute', zIndex: 4}} src={slide.image}
                                             alt={'offer-img'}/>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            }
        </div>
    );
}

export default Offers;