import {Box, InputLabel, MenuItem, Select} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CountDownTimer from "../CountDownTimer";
import {getAuth, RecaptchaVerifier, signInWithPhoneNumber} from "firebase/auth";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {login, set_first_user_location, signup} from "../../actions/accounts";
import {toast} from "react-hot-toast";
import {initializeApp} from "firebase/app";
import {useHistory} from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'

const AuthBody = ({loader, value, setValue, isLogin, handleCloseModal}) => {

    const history = useHistory()

    function CircularProgressWithLabel(props) {
        return (
            <Box position="relative" display="inline-flex" style={{margin: '0 auto'}}>
                <div style={{position: 'relative'}}>
                    <CircularProgress style={{color: '#6CD7BB'}} variant="determinate" value={props.progress}
                                      size={80}/>
                </div>
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    {/*<p style={{fontSize: 16, fontWeight: 600}}>{props.text}</p>*/}
                    <CountDownTimer time={props.time} setTime={props.setTime}
                                    setProgress={props.setProgress} setOtpSent={props.setOtpSent}
                    />
                </Box>
            </Box>
        );
    }

    const firebaseConfig = {
        apiKey: "AIzaSyCq7z36nZYY2m1E4qPf6AzaP_XgI-A5C_8",
        authDomain: "clykk-356df.firebaseapp.com",
        databaseURL: "https://clykk-356df.firebaseio.com",
        projectId: "clykk-356df",
        storageBucket: "clykk-356df.appspot.com",
        messagingSenderId: "11379513871",
        appId: "1:11379513871:web:e6d15083b499d10ade617a",
        measurementId: "G-9SVF06531T"
    };

    initializeApp(firebaseConfig);

    const formRef = useRef()
    const recaptchaWrapperRef = useRef()

    const [time, setTime] = useState([0, 0, 45]);
    const [progress, setProgress] = useState(100)

    const [confirmation, setConfirmation] = useState(null);
    const [otpSent, setOtpSent] = useState(false)

    const [loginData, setLoginData] = useState({
        mobile: "",
        countryCode: "+1"
    })

    const dispatch = useDispatch();

    const address = useSelector(state => state.accounts.address)

    const sendCode = (e) => {
        if (!loginData) return;

        if (!checkBox) {
            toast.error('Please accept the Terms of Use and Privacy Policy.', {duration: 2000})
            return
        }

        // if (isLogin) {
        //     dispatch(login(loginData, setValue, value, setOtpSent))
        // } else {
        //     dispatch(signup(loginData, setValue, value, setOtpSent))
        // }
        //
        // setTimeout(() => {
        //     dispatch(set_first_user_location())
        // }, 1500)
        //
        // return;

        loader = true

        const authenticate = getAuth()

        if (recaptchaWrapperRef) {
            recaptchaWrapperRef.current.innerHTML = `<div id="recaptcha__container"></div>`
        }

        const recaptcha = new RecaptchaVerifier(
            "recaptcha__container",
            {
                size: "invisible"
            }, authenticate
        );

        const number = `+${loginData.countryCode}${loginData.mobile}`

        signInWithPhoneNumber(authenticate, number, recaptcha)
            .then(confirmationResult => {
                setTime([0, 0, 45])
                setConfirmation(confirmationResult)
                setOtpSent(true)
            })
            .catch(err => {
                console.log(err)
                if (err.code === "auth/invalid-phone-number") {
                    toast.error('Error: Invalid Phone Number')
                }
                if (err.code === 'auth/too-many-requests') {
                    toast.error('Too Many Requests from this number. Please try again later')
                }
            })
    };

    useEffect(() => {
        loader = false
    }, [otpSent])


    const [code, setCode] = useState('')
    const handleCodeChange = (e) => {
        setCode(e.target.value)
    }
    const confirmCode = () => {
        confirmation
            .confirm(code)
            .then(user => {
                    if (isLogin) {
                        dispatch(login(loginData, setValue, value, setOtpSent))
                    } else {
                        dispatch(signup(loginData, setValue, value, setOtpSent))
                    }
                    setTimeout(() => {
                        dispatch(set_first_user_location())
                    }, 1500)
                }
            )
            .catch(err => {
                console.log(err)
                if (err.code === "auth/invalid-verification-code") {
                    toast.error('Error: Wrong Code')
                } else if (err.code === 'auth/too-many-requests') {
                    toast.error('Too Many Requests try again later')
                } else if (err.code === 'auth/code-expired') {
                    toast.error('Code has expired, please try again')
                } else {
                    toast.error(err.code)
                }
                setOtpSent(false)
            })
    };

    const [checkBox, setCheckBox] = useState(false)

    return (
        <div className={'register-modal-container'}
             style={{width: '100%', display: 'flex'}}>
            <div style={{margin: 'auto'}}>
                <div className={'register-modal-content-container'}>
                    {!otpSent ?
                        <>
                            <p className={'enter-contact-text'}>Enter your Contact Number below</p>

                            <PhoneInput
                                enableSearch={true}
                                country="in"
                                inputProps={{
                                    name: 'phone',
                                    required: true,
                                    autoFocus: true
                                }}
                                onChange={(value, countryData) => {
                                    console.log(countryData?.dialCode, value?.slice(countryData?.dialCode?.length))
                                    setLoginData({
                                        countryCode: countryData?.dialCode,
                                        mobile: value?.slice(countryData?.dialCode?.length)
                                    })
                                }}
                            />

                            <p className={'reg-modal-helper'}>We will send you a One time Password (OTP) SMS.
                                Standard carrier rates may apply</p>

                            <button
                                style={{
                                    backgroundColor: '#6CD7BB',
                                    color: 'white',
                                    fontSize: 14,
                                    fontWeight: 500
                                }}
                                className={'p-2 btn form-control'}
                                onClick={sendCode}
                            >
                                Send Code
                            </button>

                            <div className={'checkbox-container'}>
                                <label className="check-container"
                                    // style={{margin: 'auto 0'}}
                                >
                                    <input type="checkbox" value={checkBox}
                                           onClick={() => {
                                               setCheckBox(!checkBox)
                                           }}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                                <p className={'reg-modal-helper-checkbox'}
                                   style={{margin: '0 0 10px 0'}}
                                >By clicking, I accept
                                    <span style={{color: '#6CD7BB', cursor: "pointer"}}
                                          onClick={() => {
                                              history.push('/terms/')
                                              handleCloseModal()
                                          }}> Terms of Use
                                    </span>
                                    and
                                    <span style={{color: '#6CD7BB', cursor: "pointer"}}
                                          onClick={() => {
                                              history.push('/privacy-policy/')
                                              handleCloseModal()
                                          }}> Privacy Policy</span>
                                </p>
                            </div>

                            <div ref={recaptchaWrapperRef}>
                                <div id="recaptcha__container"></div>
                            </div>
                        </>
                        :
                        <>
                            <p style={{fontSize: 12, fontWeight: 600}}>We have sent an OTP SMS to your
                                contact
                                number. Kindly enter the SMS Code sent</p>
                            <input style={{fontSize: 12, padding: 10}} className={'form-control my-3'}
                                   onChange={handleCodeChange}
                                   placeholder={'Enter SMS Code'}/>
                            <button className={'btn'} onClick={confirmCode}
                                    style={{backgroundColor: '#6CD7BB', fontSize: 12, color: 'white'}}>Verify
                            </button>
                            <div style={{width: '100%', display: 'flex'}}>
                                <CircularProgressWithLabel time={time} setTime={setTime}
                                                           progress={progress} setProgress={setProgress}
                                                           setOtpSent={setOtpSent}/>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

export default AuthBody;