import React from 'react';
import {GiMagnifyingGlass} from "react-icons/gi";
import {ImPriceTags} from "react-icons/im";
import {BsHourglassSplit, BsWallet2, BsShare} from "react-icons/bs";
import StoreBtn from "../Hero/StoreBtn";
import ApplyStore from "../../assets/images/apply-stone-btn.png";
import GoogleStore from "../../assets/images/google-play-btn.png";
import {FiTarget} from "react-icons/fi";
import {useSelector} from "react-redux";

function JoinUsBannerProfessional(props) {

    const userType = useSelector(state => state.accounts.userType)
    return (
        <div style={{width: "100%", backgroundColor: '#e3f8fa'}}>
            <div className={'container p-4 my-2'} style={{textAlign: 'left'}}>
                <div className={'join-us-heading-container'}>
                    <p className={'join-us-heading'}>Join us now!</p>
                </div>
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 my-3"
                         style={{display: 'flex'}}>
                        <div className={'join-us-left'}>
                            <p className={'join-us-meta-text'}>Check out what the Service Providers have to say.</p>
                            <div className={'join-us-item-container'}>
                                <FiTarget
                                    className={'join-us-icon'}/>
                                <p className={'join-us-item-text'}>Confirmed Orders and NOT a Lead</p>
                            </div>

                            <div className={'join-us-item-container'}>
                                <BsWallet2
                                    className={'join-us-icon'}/>
                                <p className={'join-us-item-text'}>Payment 24 to 48 hours after completion of Service Order</p>
                            </div>

                            <div className={'join-us-item-container'}>
                                <BsShare
                                    className={'join-us-icon'}/>
                                <p className={'join-us-item-text'}>Grow your business with Provider & Sub-Provider Model</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 my-3" style={{display: 'flex'}}>
                        <div className="row join-btn-container g-0">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 my-3"
                                 style={{display: 'flex'}}>
                                <div className={'download-btn-left'}>
                                    <StoreBtn img={ApplyStore} text={userType} store={'App Store'}
                                              url={userType === 'Customer' ? 'https://apps.apple.com/us/app/clykk/id1542592627?platform=iphone' : 'https://apps.apple.com/us/app/clykk-service-provider/id1550083789'}/>
                                </div>
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 my-1"
                                 style={{display: 'flex'}}>
                                <div className={'download-btn-right'}>
                                    <StoreBtn img={GoogleStore} text={userType} store={'Google Play'}
                                              url={userType === 'Customer' ? 'https://play.google.com/store/apps/details?id=com.clykk' : 'https://play.google.com/store/apps/details?id=com.clykksp'}/>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default JoinUsBannerProfessional;