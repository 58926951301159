import clsx from "clsx";
import React from "react";
import {useDispatch} from "react-redux";
import {Logout} from "../../actions/accounts";
import {useHistory} from "react-router-dom";

const AccountsSidebar = ({accountSideBar, onClickAccountSideBar, setMobileSideBarOpen}) => {
    const dispatch = useDispatch()
    const history = useHistory()

    return (
        <>
            <div className="account-sidebar mb-3">
                {accountSideBar.slice(0, 6).map((item) => {
                    return (
                        <div
                            onClick={() => {
                                onClickAccountSideBar(item.text)
                                setMobileSideBarOpen(false)
                            }}
                            className={clsx('account-sidebar-container', item.active ? 'account-sidebar-item-active' : 'account-sidebar-item-inactive')}>
                            {item.icon}
                            <p className={'account-sidebar-item account-sidebar-item-text '}>{item.text}</p>
                        </div>
                    )
                })}
            </div>

            <div className="account-sidebar">
                {accountSideBar.slice(6, 10).map((item) => {
                    return (
                        <div
                            onClick={() => {
                                item.text === 'Orders' ?
                                    history.push('/my-orders/')
                                    :
                                    item.text === 'To Do' ?
                                        history.push('/cart/2')
                                        :
                                        item.text === 'Deal' ?
                                            history.push('/offers/')
                                            :
                                            item.text === 'Support' ?
                                                history.push('/contact-us/')
                                                :
                                                onClickAccountSideBar(item.text)
                            }}
                            className={clsx('account-sidebar-container', item.active ? 'account-sidebar-item-active' : 'account-sidebar-item-inactive')}>
                            {item.icon}
                            <p className={'account-sidebar-item account-sidebar-item-text '}>{item.text}</p>
                        </div>
                    )
                })}
                <div style={{padding: '10px 30px 20px'}}>
                    <button
                        style={{padding: 10, fontSize: 16, backgroundColor: '#6CD7BB', color: 'white'}}
                        className={'btn form-control'}
                        onClick={() => {
                            dispatch(Logout())
                            history.push('/')
                        }}
                    >Sign Out
                    </button>
                </div>
            </div>
        </>
    );
}

export default AccountsSidebar;