import upload_photo from "../../assets/images/Upload Photo.png";
import {AiOutlineRight} from "react-icons/ai";
import {Switch} from "@material-ui/core";
import React, {useContext, useEffect} from "react";
import {useState} from "react";
import {update_user_profile} from "../../actions/accounts";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import Config from "../../Config";
import {Context} from "../../Contexts/Context";

const UserInformationTab = ({user_data}) => {

    const [userData, setUserData] = useState(null)

    const dispatch = useDispatch()

    useEffect(() => {
        setUserData(user_data)
    }, [user_data])

    const [enableEdit, setEnableEdit] = useState(false)

    const onTextChange = (e) => {
        setUserData({...userData, [e.target.name]: e.target.value})
    }

    const updateProfile = () => {
        dispatch(update_user_profile(userData))
        setEnableEdit(!enableEdit)
    }

    const account_settings = useSelector(state => state.accounts.account_settings)

    const {handleOpenBlockerModal, setBlockerType} = useContext(Context);

    const handleImageChange = (e) => {
        if (account_settings.media) {
            if (e.target.files && e.target.files[0]) {
                let img = e.target.files[0];

                let formData = new FormData();
                formData.append('imageFile', img);

                const config = Config()
                const delete_url = userData.picture.split("/")[userData.picture.split("/").length - 1]
                axios.delete(`https://cgapp.clykk.com/s3/api/s3manager/user-management/images/${delete_url}`, {}, config)
                    .then(response => {

                    })
                    .catch(error => console.log(error.message))

                axios.post('https://cgapp.clykk.com/s3/api/s3manager/user-management/images', formData, config)
                    .then(response => {
                        setUserData({...userData, picture: response.data.url})
                    })
                    .catch(error => console.log(error.message))
            }
        } else {
            setBlockerType('media')
            handleOpenBlockerModal()
        }
    };

    return (
        <>
            {userData &&
                <>
                    <div style={{display: 'flex', width: '100%'}}>
                        {enableEdit ?
                            <button
                                onClick={updateProfile}
                                className={'btn user-info-btn'}>
                                Save
                            </button>
                            :
                            <button onClick={() => {
                                setEnableEdit(!enableEdit)
                            }}
                                    className={'btn user-info-btn'}>
                                Edit
                            </button>
                        }
                    </div>
                    <div className={'my-3'} style={{display: 'flex'}}>
                        <label htmlFor="read-file">
                            <div style={{position: 'relative', cursor: enableEdit && 'pointer'}}>
                                <img src={upload_photo} style={{right: 0, position: 'absolute', width: 30}}/>
                                {userData.picture !== 'test.img' && userData.picture !== "" ?
                                    <img src={userData.picture} className={'user-info-avatar'}/>
                                    :
                                    // <img src={avatar} style={{width: 120}}/>
                                    <div className={'user-info-avatar'} style={{display: 'flex'}}>
                                        <p
                                            style={{
                                                margin: 'auto',
                                                fontSize: 12,
                                                fontWeight: 700,
                                                color: 'white'
                                            }}
                                        >Upload Photo</p>
                                    </div>
                                }
                            </div>
                        </label>
                        {enableEdit &&
                            <input type="file"
                                   onChange={handleImageChange}
                                   onClick={(event) => {
                                       event.target.value = null
                                   }} className="read-file" name="read-file" id="read-file"
                                   style={{display: "none"}}
                            />
                        }

                        <div style={{margin: 'auto 0 20px 20px'}}>
                            <p style={{
                                fontSize: 15,
                                fontWeight: 700
                            }}>{userData.firstName} {userData.lastName}</p>
                            <p style={{fontSize: 12}}>{userData.email}</p>
                            <p style={{fontSize: 12}}>{userData.mobile}</p>
                        </div>
                    </div>

                    <div className={'my-3'}>
                        <p style={{fontSize: 16, fontWeight: 600}}>Edit Account Details</p>
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 my-1">
                                <input style={{fontSize: 14, padding: 10}}
                                       placeholder={'First Name'}
                                       disabled={!enableEdit}
                                       name={'firstName'}
                                       value={userData.firstName}
                                       onChange={onTextChange}
                                       className={'form-control'}/>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 my-1">
                                <input style={{fontSize: 14, padding: 10}}
                                       placeholder={'Last Name'}
                                       disabled={!enableEdit}
                                       name={'lastName'}
                                       onChange={onTextChange}
                                       value={userData.lastName}
                                       className={'form-control'}/>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 my-1">
                                <input
                                    style={{fontSize: 14, padding: 10}}
                                    placeholder={'Email Address'}
                                    disabled={!enableEdit}
                                    name={'email'}
                                    onChange={onTextChange}
                                    value={userData.email}
                                    className={'form-control'}/>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 my-1">
                                <input
                                    style={{fontSize: 14, padding: 10}}
                                    placeholder={'Mobile Number'}
                                    disabled={!enableEdit}
                                    name={'mobile'}
                                    onChange={onTextChange}
                                    value={userData.mobile}
                                    className={'form-control'}/>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default UserInformationTab;