import React, {useEffect, useState} from 'react';
import {GrFormClose} from "react-icons/gr";
import {AiFillCalendar} from "react-icons/ai";

import {atcb_init} from "add-to-calendar-button";
import 'add-to-calendar-button/assets/css/atcb.css';

import GoogleCalender from '../../assets/images/google-calender.png';
import AppleCalender from '../../assets/images/iCal.jpg';
import OutlookCalender from '../../assets/images/outlook.png';

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

function ReminderModal({handleCloseReminderModal}) {
    const [modalStyle] = useState(getModalStyle);
    const event = {
        title: 'Sample Event',
        description: 'This is the sample event provided as an example only',
        location: 'Portland, OR',
        startTime: '2016-09-16T20:15:00-04:00',
        endTime: '2016-09-16T21:45:00-04:00'
    }

    useEffect(atcb_init, []);

    return (
        <div style={modalStyle} className={'share-modal'}>

            <div style={{display: 'flex', marginBottom: 10}}>
                <p style={{fontSize: 14, fontWeight: 600}}>Set a reminder</p>
                <AiFillCalendar style={{fontSize: 14, margin: "auto 0 auto 10px"}}/>
                <GrFormClose onClick={handleCloseReminderModal} style={{marginLeft: "auto", cursor: 'pointer'}}/>
            </div>





            {/*<div style={{display: 'flex', width: '100%'}}>*/}
            {/*    <div className={'calender-item'}>*/}
            {/*        <p className={'calender-text'}>*/}
            {/*            Google Calender*/}
            {/*        </p>*/}
            {/*        <img src={GoogleCalender} className={'calender-logo'}/>*/}
            {/*    </div>*/}
            {/*    <div className={'calender-item'}>*/}
            {/*        <p className={'calender-text'}>*/}
            {/*            Apple Calender*/}
            {/*        </p>*/}
            {/*        <img src={AppleCalender} className={'calender-logo'}/>*/}
            {/*    </div>*/}
            {/*    <div className={'calender-item'}>*/}
            {/*        <p className={'calender-text'}>*/}
            {/*            Outlook Calender*/}
            {/*        </p>*/}
            {/*        <img src={OutlookCalender} className={'calender-logo'}/>*/}
            {/*    </div>*/}
            {/*</div>*/}

        </div>
    );
}

export default ReminderModal;