import React from "react";
import AuthBody from "./authBody";
import Logo from "../../assets/images/logo.svg";

const RegisterModal = ({value, setValue, isLogin}) => {
    let loader = false

    return (
        <div className={'row g-0 reg-modal-grid'}>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className={'reg-modal-img-container'}>
                    <img src={Logo} className={'register-modal-img'}/>
                    <p className={'register-model-meta-text'}>Your Services are just a CLYKK Away <sup
                        style={{fontSize: 8}}>TM</sup></p>
                </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <AuthBody loader={loader} value={value} setValue={setValue} isLogin={isLogin}/>
            </div>
        </div>
    );
}

export default RegisterModal;