import AppBar from "../component/PackageDetails/AppBar";
import React, {useContext, useEffect, useState} from "react";
import SwipeableViews from "react-swipeable-views";
import {Box, Modal, useTheme} from "@material-ui/core";
import PropTypes from "prop-types";
import PackageDetailTabs from "../component/PackageDetails/tabs/PackageDetailTabs";
import Footer from "../component/LandingPage/Footer";
import PackageRequirementsTabs from "../component/PackageDetails/tabs/PackageRequirementsTabs";
import PackageCheckListTabs from "../component/PackageDetails/tabs/PackageCheckListTabs";
import PackageAddOnsTabs from "../component/PackageDetails/tabs/PackageAddOnsTabs";
import axios from "axios";
import Config from "../Config";
import {useHistory, useLocation} from "react-router-dom";
import {load_user_cart} from "../actions/accounts";
import {useDispatch, useSelector} from "react-redux";
import {Context} from "../Contexts/Context";
import PackageDetailHero from "../component/PackageDetailHero";
import yellow_glitter from "../assets/images/yellow star.png";
import pink_glitter from "../assets/images/star.svg";
import purple_glitter from "../assets/images/glitter.png";
import green_glitter from "../assets/images/greenstar.png";
import ShareModal from "../component/PackageDetails/ShareModal";
import AddAlert from "../AddAlert";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            style={{backgroundColor: "white"}}
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <p>{children}</p>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


const PackageDetails = () => {
    const theme = useTheme();
    const location = useLocation()
    const dispatch = useDispatch()
    const history = useHistory()

    const subCatID = location.pathname.split('/')[2];
    const packageID = location.pathname.split('/')[3];

    const {handleOpenModal} = useContext(Context);
    const isAuthenticated = useSelector(state => state.accounts.isAuthenticated)
    const user = useSelector(state => state.accounts.data)
    const currency = useSelector(state => state.accounts.currency)
    const curr_location = useSelector(state => state.accounts.location)

    const [data, setData] = useState(null)
    const [loadedPackage, setLoadedPackage] = useState(null)
    const [value, setValue] = useState(0);

    const [liked, setLiked] = useState(false)

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const add_to_cart = (status) => {
        const config = Config()
        const get_date = (date) => {
            return date.length > 1 ? date : '0' + date
        }
        const date = new Date().toLocaleString().split(',')[0].split('/')

        const body = {
            "status": status,
            "qna": [...data.details, ...data.requirements, ...data.checklist],
            "serviceAddress": curr_location,
            "deliveryAddress": curr_location,
            "schedule": {
                "serviceTime": "08:30",
                "startDate": date[2] + '-' + get_date(date[0]) + '-' + get_date(date[1]),
                "endDate": date[2] + '-' + get_date(date[0]) + '-' + get_date(date[1]),
                "subscriptionFrequency": "once",
                "weekdays": []
            },
            "items": {
                "package": {
                    id: loadedPackage.package.id,
                    packageName: loadedPackage.package.packageName,
                    shortDescription: loadedPackage.package.shortDescription,
                    packageImage: loadedPackage.package.packageImage,
                    basePrice: loadedPackage.package.basePrice,
                    sellingPrice: loadedPackage.package.sellingPrice,
                    serviceRuleID: loadedPackage.package.subCategoryServiceRuleID,
                    subcategoryID: parseInt(subCatID)
                },
                "addOns": data.addOns.map(item => ({
                    packageName: item.addonName,
                    shortDescription: item.addonDescription,
                    packageimage: item.addonImage,
                    basePrice: item.basePrice,
                    sellingPrice: item.sellingPrice,
                    itemCount: item.itemCount
                }))
            }
        }

        axios.post(`https://cgapp.clykk.com/cart/api/v2/cart-management/cart`, body, config)
            .then(response => {
                dispatch(load_user_cart())

                AddAlert(dispatch, user.userId, body['items']['package'], status, "Pending", response.data.cartID)

                if (status === 'cart')
                    history.push('/cart/')
                else
                    history.push('/cart/2')
            })
            .catch(e => console.log(e.message))
    }

    useEffect(() => {
        if (isAuthenticated) {
            const config = Config()
            axios.get(`https://cgapp.clykk.com/like/api/v2/packageLikeOrNot/${packageID}`, config)
                .then(response => {
                    if (response.data.m === 'success') {
                        setLiked(response.data['rs']['likes'] === 1)
                    }
                })
                .catch(e => console.log(e.message))
        }
    }, [isAuthenticated])

    useEffect(() => {
        const config = Config()

        let tabData = {
            details: [], requirements: [], checklist: [], addOns: []
        }

        axios.get(`https://cgapp.clykk.com/catalog/api/v2/catalog-manager/package/${packageID}/qna`, config)
            .then((response) => {
                response.data['qna'].map((item) => {
                    if (item['questionType'] === 'detail')
                        tabData.details.push(item)
                    else if (item['questionType'] === 'requirements')
                        tabData.requirements.push(item)
                    else if (item['questionType'] === 'checklist')
                        tabData.checklist.push(item)
                })
            })

        axios.get(`https://cgapp.clykk.com/catalog/api/v2/catalog-manager/package/${packageID}/addOn`, config)
            .then((response) => {
                response.data.map((item) => {
                    tabData.addOns.push({...item, itemCount: 0})
                })
            })

        setData(tabData)

        axios.get(`https://cgapp.clykk.com/catalog/api/v2/catalog-manager/sub-categories/${subCatID}/package/${packageID}`, config)
            .then((response) => {
                setTimeout(() => {
                    setLoadedPackage(response.data)
                }, 500)
            })

        axios.post(`https://cgapp.clykk.com/like/api/v2/user/packageViewed/${packageID}`,
            {},
            config)
            .then(response => {
                console.log(response.data)
            })

    }, [])

    const [openShareModal, setShareModalOpen] = useState(false);
    const handleShareCloseModal = () => {
        setShareModalOpen(false)
    }
    const handleShareOpenModal = () => {
        if (!isAuthenticated) {
            handleOpenModal()
        } else {
            setShareModalOpen(true)
        }
    }


    return (
        <div style={{position: 'relative'}}>
            <>
                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '1%', left: '98%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 15, top: '10%', left: '10%'}}/>
                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '16%', left: '60%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '30%', left: '2%'}}/>


                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 12, top: '32%', left: '90%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '40%', left: '55%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '50%', left: '10%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 12, top: '65%', left: '5%'}}/>

                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 12, top: '70%', left: '89%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '80%', left: '51%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '97%', left: '5%'}}/>

            </>
            {loadedPackage &&
                <>
                    <PackageDetailHero packageID={packageID} loadedPackage={loadedPackage.package} currency={currency}
                                       add_to_cart={add_to_cart} handleOpenModal={handleOpenModal} liked={liked}
                                       setLiked={setLiked} handleShareOpenModal={handleShareOpenModal}/>
                    <AppBar value={value} setValue={setValue} handleChangeIndex={handleChangeIndex}/>
                    <div className="container">
                        <SwipeableViews
                            index={value}
                            onChangeIndex={handleChangeIndex}
                        >
                            <TabPanel value={value} index={0} dir={theme.direction}>
                                <PackageDetailTabs data={data.details} add_update_cart={add_to_cart}
                                                   handleOpenModal={handleOpenModal}
                                                   loadedPackage={loadedPackage.package}
                                                   currency={currency}/>
                            </TabPanel>
                            <TabPanel value={value} index={1} dir={theme.direction}>
                                <PackageRequirementsTabs data={data} setData={setData} add_update_cart={add_to_cart}
                                                         setValue={setValue} handleOpenModal={handleOpenModal}
                                                         loadedPackage={loadedPackage.package} currency={currency}/>
                            </TabPanel>
                            <TabPanel value={value} index={2} dir={theme.direction}>
                                <PackageCheckListTabs data={data.checklist} add_update_cart={add_to_cart}
                                                      handleOpenModal={handleOpenModal}
                                                      loadedPackage={loadedPackage.package} currency={currency}/>
                            </TabPanel>
                            <TabPanel value={value} index={3} dir={theme.direction}>
                                <PackageAddOnsTabs data={data.addOns} setData={setData} add_update_cart={add_to_cart}
                                                   handleOpenModal={handleOpenModal}
                                                   loadedPackage={loadedPackage.package}
                                                   currency={currency}/>
                            </TabPanel>
                        </SwipeableViews>
                    </div>
                </>
            }

            {openShareModal &&
                <Modal
                    open={openShareModal}
                    onClose={handleShareCloseModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <ShareModal shareData={{
                        packageName: loadedPackage.package.packageName,
                        shortDescription: loadedPackage.package.shortDescription,
                        url: `https://clykk.com/package-detail/${subCatID}/${loadedPackage.package.id}`,
                        id: loadedPackage.package.id,
                        subcategoryID: subCatID
                    }} handleShareCloseModal={handleShareCloseModal}
                    />
                </Modal>
            }

            <Footer/>
        </div>
    );
}

export default PackageDetails;
