import letsgetstarted from "../../assets/images/lets get starte.png";
import React from "react";

const ConsumerProcessOne = ({my_swiper, value, setValue}) => {
    return (
        <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>
            <div className='modal-top-nav'
            >
                <p style={{margin: 'auto auto auto 0', fontSize: 14, fontWeight: 700, color: '#424B5A'}}>Lets start</p>
            </div>
            <hr className={'hr-line'} />

            <p className={'consumer-process-modal-text'}
            >Glad you are Here! Lets get to know more about your
                preferences!</p>
            <p className={'preference-meta-text'}
            >The choices and interest will enable us to tailor
                specific deals and offers for you</p>
            <img className={'consumer-process-modal-img'} src={letsgetstarted}/>
            <button style={{}}
                    onClick={() => {
                        my_swiper.slideNext()
                    }}
                    className={'p-2 btn form-control consumer-process-btn'}>Start
            </button>
        </div>
    );
}

export default ConsumerProcessOne;