import {useContext, useEffect, useState} from 'react';
import {useCallback} from "react";
import {useDropzone} from 'react-dropzone'
import {AiFillQuestionCircle, AiOutlineClose} from "react-icons/ai";
import Config from "../../Config";
import axios from "axios";
import {toast} from "react-hot-toast";
import {Modal} from "@material-ui/core";
import {Context} from "../../Contexts/Context";
import {useSelector} from "react-redux";

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

function ProvideRequirementsModal({cartItem, setCartItem, handleCloseModal, booked = false, edit = false}) {
    const [modalStyle] = useState(getModalStyle);
    const account_settings = useSelector(state => state.accounts.account_settings)

    const {setFaqNum, handleOpenFaqModal} = useContext(Context);
    const [changedFields, setChangedFields] = useState({})

    const {handleOpenBlockerModal, setBlockerType} = useContext(Context);

    const [openWarningModal, setOpenWarningModal] = useState(false)
    const handleOpenWarningModal = () => {
        setOpenWarningModal(true)
    }
    const handleCloseWarningModal = () => {
        setOpenWarningModal(false)
    }

    const body = (
        <div style={modalStyle} className={'leave-page-paper'}>
            <div style={{display: 'flex'}}>
                <AiOutlineClose style={{marginLeft: "auto", cursor: "pointer", fontSize: 12}}
                                onClick={handleCloseWarningModal}/>
            </div>
            <p style={{color: 'red', fontSize: 14, fontWeight: 600}}>You have changes that have not been saved. Please
                save before proceeding</p>
            <div style={{width: '100%', display: 'flex', margin: "10px 0"}}>
                <button className={'btn leave-btn'}
                        onClick={handleCloseWarningModal}
                >Cancel
                </button>
                <button className={'btn stay-btn'}
                        onClick={handleCloseWarningModal}
                >Ok
                </button>
            </div>
        </div>
    )

    const closeModal = () => {
        let allow = true
        for (const key in changedFields) {
            if (changedFields.hasOwnProperty(key)) {
                allow = false
                break;
            }
        }

        if (allow) {
            handleCloseModal()
        } else {
            handleOpenWarningModal()
        }
    }

    const customerAnswer = (id, images = false) => {
        const config = Config()
        cartItem.qnA.map(question => {
            question.answers.map(answer => {
                if (answer.id === id) {
                    let url = ''
                    if (!booked) {
                        url = `https://cgapp.clykk.com/cart/api/v2/cart-management/cart/${cartItem.cartID}/qna/${id}`
                    } else {
                        url = `https://cgapp.clykk.com/cart/api/v2/cart-management/subscription/${cartItem.cartID}/qna/${id}`
                    }

                    axios.put(url,
                        JSON.stringify(answer.customerAnswer),
                        config)
                        .then(response => {
                            let changed = {}
                            for (const key in changedFields) {
                                if (key != id) {
                                    changed[key] = changedFields[key]
                                }
                            }
                            setChangedFields(changed)
                            if (!images)
                                toast.success("Answer Submitted successfully.", {duration: 1000})
                            else
                                toast.success("Images Submitted successfully.", {duration: 1000})
                        })
                        .catch(error => {
                            console.log(error.message)
                            toast.error("Error please try again.", {duration: 1000})
                        })
                }
            })
        })
    }

    const onAnswerChange = (e, id) => {
        setCartItem({
            ...cartItem,
            qnA: cartItem.qnA.map(question => {
                question.answers.map(answer => {
                    if (answer.id === id) {
                        if (!changedFields.hasOwnProperty(id)) {
                            setChangedFields({...changedFields, [id]: {original: answer.customerAnswer, changed: true}})
                        }
                        if (e.target.value === '') {
                            answer.customerAnswer = null
                        } else {
                            answer.customerAnswer = [{answer: e.target.value}]
                        }
                    }
                })
                return question
            })
        })
    }

    const [images, setImages] = useState([])
    const [newImages, setNewImages] = useState([])
    useEffect(() => {
        setImages([...images, ...newImages])

        const imageUrls = [...images, ...newImages]

        if (imageUrls.length > 0) {
            setCartItem({
                ...cartItem,
                qnA: cartItem.qnA.map(question => {
                    if (question.questionType === 'requirements') {
                        question.answers.map(answer => {
                            if (answer.answerType === 'upload') {
                                if (!changedFields.hasOwnProperty(answer.id)) {
                                    setChangedFields({
                                        ...changedFields,
                                        [answer.id]: {original: answer.customerAnswer, changed: true}
                                    })
                                }
                                answer.customerAnswer = imageUrls
                            }
                            return answer
                        })
                    }
                    return question
                })
            })
        }
    }, [newImages])

    const remove_image = (index) => {
        const config = Config()
        setCartItem({
            ...cartItem,
            qnA: cartItem.qnA.map(question => {
                if (question.questionType === 'requirements') {
                    question.answers.map(answer => {
                        if (answer.answerType === 'upload') {
                            answer.customerAnswer = [...images.slice(0, index), ...images.slice(index + 1, images.length)]
                            const delete_url = images[index].answer.split('/')[images[index].answer.split('/').length - 1]

                            axios.delete(`https://cgapp.clykk.com/s3/api/s3manager/order-execution/images/${delete_url}`, {}, config)
                                .then(response => console.log(response))
                                .catch(error => console.log(error.message))

                            setImages([...images.slice(0, index), ...images.slice(index + 1, images.length)])

                            let url = ''
                            if (!booked) {
                                url = `https://cgapp.clykk.com/cart/api/v2/cart-management/cart/${cartItem.cartID}/qna/${answer.id}`
                            } else {
                                url = `https://cgapp.clykk.com/cart/api/v2/cart-management/subscription/${cartItem.cartID}/qna/${answer.id}`
                            }

                            axios.put(url,
                                JSON.stringify(answer.customerAnswer),
                                config)
                                .then(response => {

                                })
                                .catch(error => {
                                    console.log(error.message)
                                    toast.error("Error please try again.", {duration: 1000})
                                })
                        }
                        return answer
                    })
                }
                return question
            })
        })
    }

    useEffect(() => {
        cartItem.qnA.map(question => {
            if (question.questionType === 'requirements') {
                question.answers.map(answer => {
                    if (answer.answerType === 'upload') {
                        setImages(answer.customerAnswer !== null ? answer.customerAnswer : [])
                    }
                })
            }
        })
    }, [])

    const onDrop = useCallback((acceptedFiles) => {
        if (account_settings.media) {
            acceptedFiles.forEach((file) => {
                const config = Config()
                let form_data = new FormData();
                form_data.append('imageFile', file);
                axios.post('https://cgapp.clykk.com/s3/api/s3manager/order-execution/images', form_data, config)
                    .then(response => {
                            setNewImages([...newImages, {answer: response.data.url}])
                        }
                    )
            })
        } else {
            handleCloseModal()
            setBlockerType('media')
            handleOpenBlockerModal()
        }
    }, [])

    const {getRootProps, getInputProps} = useDropzone({onDrop})

    return (
        <>
            <div style={modalStyle} className={'package-requirement-modal'}>
                <div style={{display: 'flex', marginBottom: 5}}>
                    <AiOutlineClose style={{cursor: "pointer", marginLeft: 'auto'}}
                                    onClick={closeModal}/>
                </div>
                <div className={'package-requirement-modal-body'} style={{position: 'relative'}}>
                    {cartItem.hasOwnProperty('items') ?
                        <img src={cartItem.items.package.packageImage} className={'w-100'}/>
                        :
                        <img src={cartItem.package.packageImage} className={'w-100'}/>
                    }

                    <div style={{display: 'flex', marginTop: 5}}>
                        <p className={'package-requirements-heading'}>Package Requirements</p>
                        <AiFillQuestionCircle
                            className={'faq-icon-selection-location package-requirements-modal'}
                            onClick={() => {
                                setFaqNum(27)
                                handleOpenFaqModal()
                            }}/>
                    </div>

                    <p style={{fontSize: 12, color: '#98999B', margin: '5px 0 10px 0'}}>The Service provider would like
                        more
                        information</p>

                    {cartItem.qnA.map((qna, index) => {
                        return (
                            qna.questionType === 'requirements' &&
                            <>
                                <p style={{fontSize: 12, fontWeight: 600, margin: '10px 0'}}>{qna.questionName}</p>
                                <div className={'package-requirement-container'}>
                                    {qna.answers.map((point) => {
                                        return (
                                            point.answerType === 'text' ?
                                                <>
                                                    <p style={{
                                                        fontSize: 12,
                                                        color: '#98999B',
                                                        marginBottom: 10
                                                    }}>{point.answer}</p>
                                                    <input className={'form-control'} style={{fontSize: 12}}
                                                           disabled={edit}
                                                           value={point.customerAnswer === null ? "" : point.customerAnswer[0].answer}
                                                           onChange={(e) => {
                                                               onAnswerChange(e, point.id)
                                                           }}
                                                           placeholder={'Enter Your Answer'}/>
                                                    <div style={{display: 'flex'}}>
                                                        <button
                                                            onClick={() => {
                                                                customerAnswer(point.id)
                                                            }}
                                                            style={{
                                                                backgroundColor: point.id in changedFields ? 'orange' : '#6CD7BB',
                                                                color: 'white',
                                                                fontSize: 12,
                                                                margin: "10px 0 0 auto"
                                                            }}
                                                            className={'btn'}>Update
                                                        </button>
                                                    </div>
                                                </>
                                                :
                                                point.answerType === "upload" &&
                                                <>
                                                    <p style={{
                                                        fontSize: 12,
                                                        color: '#98999B',
                                                        marginBottom: 10
                                                    }}>{point.answer}</p>

                                                    <div className="row" style={{margin: "30px 0"}}>
                                                        <div {...getRootProps()}
                                                             style={{
                                                                 margin: "0 auto",
                                                                 padding: "0",
                                                                 backgroundColor: "white",
                                                                 height: 200,
                                                                 border: "1px solid #50515e",
                                                                 display: "flex"
                                                             }}>
                                                            <input
                                                                disabled={edit} {...getInputProps()} />
                                                            <p style={{
                                                                margin: "auto",
                                                                fontSize: 14,
                                                                fontWeight: 600,
                                                                color: "#424B5A"
                                                            }}>Upload Images</p>
                                                        </div>
                                                    </div>

                                                    <div className={'selected-images-container'}>
                                                        {images.map((img, index) => {
                                                            return (
                                                                <div style={{position: 'relative', margin: 10}}>
                                                                    <AiOutlineClose style={{
                                                                        cursor: "pointer",
                                                                        top: 5,
                                                                        right: 0,
                                                                        position: 'absolute',
                                                                        zIndex: 10000,
                                                                        color: 'white'
                                                                    }}
                                                                                    onClick={() => {
                                                                                        remove_image(index)
                                                                                    }}/>
                                                                    <img className={'selected-image'}
                                                                         src={img.answer}
                                                                    />
                                                                </div>
                                                            )
                                                        })}
                                                    </div>

                                                    <div style={{display: 'flex'}}>
                                                        <button
                                                            onClick={() => {
                                                                customerAnswer(point.id, true)
                                                            }}
                                                            style={{
                                                                backgroundColor: point.id in changedFields ? 'orange' : '#6CD7BB',
                                                                color: 'white',
                                                                fontSize: 12,
                                                                margin: "10px 0 0 auto"
                                                            }}
                                                            className={'btn'}>Upload
                                                        </button>
                                                    </div>
                                                </>
                                        )
                                    })}
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>

            <Modal
                open={openWarningModal}
                onClose={handleCloseWarningModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>

        </>
    );
}

export default ProvideRequirementsModal;