import Offers from "../component/LandingPage/Offers/offers";
import Categories from "../component/LandingPage/Categories";
import JoinUsBanner from "../component/LandingPage/JoinUsBanner";
import Faq from "../component/LandingPage/FAQ";
import SocialsBanner from "../component/LandingPage/SocialsBanner";
import Footer from "../component/LandingPage/Footer";
import React, {useContext, useEffect, useState} from "react";
import Config from "../Config";
import axios from "axios";
import {useSelector} from "react-redux";
import purple_glitter from "../assets/images/glitter.png";
import yellow_glitter from "../assets/images/yellow star.png";
import green_glitter from "../assets/images/greenstar.png";
import pink_glitter from "../assets/images/star.svg";
import {Context} from "../Contexts/Context";
import {useHistory} from "react-router-dom";
import LikedService from "../component/LandingPage/LikedService";
import WatchedServices from "../component/LandingPage/WatchedServices";
import {getMessaging, getToken, onMessage} from "firebase/messaging";
import {initializeApp} from "firebase/app";
import {onMessageListener, requestForToken} from "../firebaseMessaging";
import {Helmet} from "react-helmet-async";

// BCA_bflkEALRPctLPAMPZTSWeOspPjmUj3P4mLnxz7d1GYGrpYaia0ctfh43xqIH6EeBIbSBuV6Y8iHlPeRx-hI

const LandingPage = () => {


    useEffect(() => {
        console.log('token =>', requestForToken())
    }, [])

    //     if (!fcm_token) {
    //         requestForToken()
    //             .then(r => {
    //                 console.log("token: ", r);
    //                 dispatch(setFCMToken(r));
    //             })
    //             .catch(error => {
    //                 console.log("error while receiver fcm token from firebase: ", error)
    //             });
    //     } else {
    //         console.log("fcm token: ", fcm_token);
    //     }
    // }, [])

    onMessageListener()
        .then(value => console.log("notification msg: ", value))
        .catch(err => console.log("error: ", err))

    const [offers, setOffers] = useState([])
    const [offersLoading, setOffersLoading] = useState(true)

    const [categoriesLoading, setCategoriesLoading] = useState(true)

    const data = useSelector(state => state.accounts.data)
    const location = useSelector(state => state.accounts.location)
    const isAuthenticated = useSelector(state => state.accounts.isAuthenticated)

    const history = useHistory()

    const {
        openCatModal,
        setCatOpenModal,
        setSelectCat,
        setSubCategory,
        categories, setCategories, filterCategories, setFilterCategories
    } = useContext(Context);

    useEffect(() => {
        const config = Config()
        let cityId = 27
        if (location.city === 'Hyderabad') {
            cityId = 31
        } else if (location.city === 'Pune') {
            cityId = 32
        } else if (location.city === 'Chennai') {
            cityId = 33
        } else if (location.city === 'Ahmedabad') {
            cityId = 34
        } else if (location.city === 'Coimbatore') {
            cityId = 35
        } else if (location.city === 'Mumbai') {
            cityId = 36
        } else if (location.city === 'Nagpur') {
            cityId = 37
        } else if (location.city === 'Visakhapatnam') {
            cityId = 38
        }

        axios.get(`https://cgapp.clykk.com/discount/api/v2/discount-management/city/${cityId}?default=true`, config)
            .then((response) => {
                setOffers(response.data ? response.data : [])
                setOffersLoading(false)
            })
    }, [location])

    // const [filteredCategories, setFilteredCategories] = useState([])

    const handleCategoryClick = (city, category_id) => {
        setSelectCat(categories.filter(item => item.category_id === category_id)[0])
        const config = Config()
        axios.get(`https://cgapp.clykk.com/catalog/api/v2/catalog-manager/by-location/${city}/categories/${category_id}`, config)
            .then(response => {
                setSubCategory(response.data ? response.data : [])
            })
            .catch(err => console.log(err.message))
    }

    useEffect(() => {
        const config = Config()
        axios.get(`https://cgapp.clykk.com/catalog/api/v2/catalog-manager/by-location/${location.city}`, config)
            .then(response => {
                setCategories(response.data ? response.data : [])
                setFilterCategories(response.data ? response.data : [])
                setCategoriesLoading(false)
            })
            .catch(err => console.log('This one failed', err.message))
    }, [location])

    const openSubCatPage = (category_id, subcategoryID) => {
        const cat_name = categories.filter(item => item.category_id === category_id)[0]
        history.push(`/sub-category-detail/${cat_name.category_name}/${category_id}/${subcategoryID}/`)
    }

    const get_offers_slide = (slide, type) => {
        const types = type.split(',')
        let yes = false
        for (let i = 0; i < types.length; i++) {
            if (slide.discount === types[i]) {
                yes = true
                break
            }
        }
        if (!yes) {
            return false
        }
        if (!isAuthenticated) {
            // && Date.parse(slide.expireAt) >= Date.parse(get_date_format())
            return (slide.status === 'approved') && (slide.userID === 0)
        } else {
            return (slide.status === 'approved') && (slide.userID === 0 || slide.userID === data.userId)
        }
    }

    const {openFaqModal} = useContext(Context);

    return (
        <div style={{position: 'relative'}}>
            <Helmet>
                <title>CLYKK</title>
                <meta
                    name={'description'}
                    content={'Get your Professional Home Services from Cleaning - Pest Control - Pickup & Drop - Salon Services - Math & Science Tutor - Elder Care.'}
                />
                <link rel="canonical" href="/"/>
            </Helmet>

            <>
                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '0.5%', left: '70%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '8%', left: '87%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 10, top: '12%', left: '10%'}}/>


                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '15%', left: '5%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '20%', left: '55%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 15, top: '26%', left: '82%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 15, top: '35%', left: '85%'}}/>


                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 17, top: '39.5%', left: '63%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 20, top: '50%', left: '5%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 15, top: '53%', left: '95%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '65%', left: '90%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 15, top: '70%', left: '52%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 17, top: '80%', left: '69%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '90%', left: '87%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '95%', left: '5%'}}/>

            </>
            <Offers offers={offers} get_slide={get_offers_slide} offers_text={'Offers for You'}
                    type={'offer,coupon'} setCatOpenModal={setCatOpenModal}
                    openSubCatPage={openSubCatPage}
                    handleCategoryClick={handleCategoryClick}
                    offersLoading={offersLoading}
                    showFaq={true}
            />

            <Categories openCatModal={openCatModal} setCatOpenModal={setCatOpenModal}
                        handleCategoryClick={handleCategoryClick}
                        categories={filterCategories}
                        categoriesLoading={categoriesLoading}
            />

            <Offers offers={offers} get_slide={get_offers_slide} offers_text={'Deals for You'}
                    type={'deal'} setCatOpenModal={setCatOpenModal}
                    openSubCatPage={openSubCatPage}
                    handleCategoryClick={handleCategoryClick}
                    offersLoading={offersLoading}/>

            <JoinUsBanner/>
            <WatchedServices/>
            {isAuthenticated &&
                <LikedService/>
            }
            {!openFaqModal &&
                <Faq faqNum={11} page={false} home={true}/>
            }
            <SocialsBanner/>
            <Footer/>
        </div>
    );
}

export default LandingPage;
