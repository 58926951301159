import Footer from "../component/LandingPage/Footer";
import React from "react";

const ContactUsPage = () => {

    return (
        <>
            <div className={'contact-us-paralax'}>
                <div className={'container'} style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                    <div style={{textAlign: "center", margin: "auto", color: "white"}}>
                        <p className={'contact-us-banner-heading'}>Contact Us</p>
                        <p className={'contact-us-banner-text'}>Experienced and Friendly</p>
                    </div>
                </div>
            </div>

            <div className="container my-4" style={{textAlign: "left", fontSize: 14}}>
                <h3 style={{fontWeight: 400, lineHeight: 1.3, color: "#333"}}>Who we are?</h3>

                <p style={{fontWeight: 400, fontSize: 14, margin: "10px 0", color: '#5a6167', lineHeight: 1.8}}>A New
                    Start-Up out of Bangalore is offering something unique in the home services space. It is
                    constituted Of The People (Local Service Providers), By The People (CLYKK Services Marketplace), and
                    For the People (Local Consumers). The concept is to provide one-time or recurring home services to
                    the consumers, so they don’t have to do the work themselves. The consumer will CLYKK through our
                    Mobile APP to select the Services they would like to get done. We at CLYKK will deploy the Service
                    Providers to do their work. Our goal is to make life easier for the Consumer and to also provide
                    additional business to qualified Services Providers in your area.</p>

                <p style={{
                    fontWeight: 700,
                    fontSize: 14,
                    margin: "10px 0",
                    color: '#000080',
                    lineHeight: 1.8,
                    fontStyle: "italic"
                }}>
                    We are in the process of signing up Services Providers in the Bangalore area. Our Marketplace
                    Offering to the Consumers will be starting with Individuals and Companies who
                    can provide one-time or recurring Services to Consumers – like  Cleaning, Pest Control, Elder Care, Pick up &amp; Delivery, Tutor, Salon and many more…</p>

                <p style={{fontWeight: 400, fontSize: 14, margin: "10px 0", color: '#5a6167', lineHeight: 1.8}}>We would
                    love to tell you more about the Services we are launching. Reach us on <span
                        style={{color: "#0000ff"}}>
                        <strong>
                            <a style={{color: "#0000ff"}}
                               href="https://m.me/CLYKKServices"
                               target="_blank" rel="noopener noreferrer">
                                CLYKKServices Messenger
                            </a>
                        </strong>
                    </span> and
                    one of our Partner Managers will reach out to you.</p>

            </div>

            <div className="container my-4">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                        <h3 style={{color: "#333", lineHeight: '1.3em', fontWeight: 300, fontSize: 32}}>CLYKKServices On
                            Messenger</h3>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                        <a href="https://m.me/CLYKKServices" target="_blank" rel="noopener noreferrer">
                            <button className={'btn contact-us-btn'}>
                                CLYKK SERVICES
                            </button>
                        </a>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    );
}

export default ContactUsPage;
