import {useSelector} from "react-redux";
import {numberWithCommas} from "../Utils";

function PackageAddOnSection({subscription, increase_decrease_add_ons}) {
    const currency = useSelector(state => state.accounts.currency)
    return (
        <>
            <p className={'package-add-on-text'}>Package Add-On's</p>
            {subscription.hasOwnProperty('addOns') &&
            subscription.addOns.map((item) => {
                return (
                    <div style={{display: 'flex'}} className={'my-4'}>
                        <img src={item.packageImage} className={'package-add-img'}/>
                        <div className={'package-add-on-services-text-container'}>
                            <p className={'package-add-on-service'}>{item.packageName}</p>
                        </div>
                        <div className={'booking-price-container'}>
                            <p className={'package-add-on-price-1'}>
                                <del>{currency}{numberWithCommas(parseFloat(item.basePrice).toFixed(2))}</del>
                            </p>
                            <p className={'package-add-on-price-2'}>{currency}{numberWithCommas(parseFloat(item.sellingPrice).toFixed(2))}</p>
                        </div>
                        <div
                            className={'package-add-on-quantity-container'}>
                            <div className={'quantity-controller-container'}
                                 onClick={() => {
                                     increase_decrease_add_ons(item, '-')
                                 }}
                            >
                                <p className={'quantity-controller'}>-</p>
                            </div>
                            <p className={'quantity'}
                               style={{margin: 'auto'}}>{item.itemCount}</p>
                            <div className={'quantity-controller-container'}
                                 onClick={() => {
                                     increase_decrease_add_ons(item, '+')
                                 }}
                            >
                                <p className={'quantity-controller'}>+</p>
                            </div>
                        </div>
                    </div>
                )
            })}
        </>
    );
}

export default PackageAddOnSection;