export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILED = 'SIGNUP_FAILED';

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILED = "LOGOUT_FAILED";

export const USER_ADDRESS_LOADED_SUCCESS = "USER_ADDRESS_LOADED_SUCCESS";
export const USER_ADDRESS_LOADED_FAILED = "USER_ADDRESS_LOADED_FAILED";

export const USER_ADDRESS_SAVED_SUCCESS = "USER_ADDRESS_SAVED_SUCCESS";
export const USER_ADDRESS_SAVED_FAILED = "USER_ADDRESS_SAVED_FAILED";

export const USER_ADDRESS_UPDATED_SUCCESS = "USER_ADDRESS_UPDATED_SUCCESS";
export const USER_ADDRESS_UPDATED_FAILED = "USER_ADDRESS_UPDATED_FAILED";

export const USER_ADDRESS_DELETE_SUCCESS = "USER_ADDRESS_DELETE_SUCCESS";
export const USER_ADDRESS_DELETE_FAILED = "USER_ADDRESS_DELETE_FAILED";

export const USER_ADDRESS_UPDATED_LOCALLY_SUCCESS = "USER_ADDRESS_UPDATED_LOCALLY_SUCCESS";
export const USER_ADDRESS_UPDATED_LOCALLY_FAILED = "USER_ADDRESS_UPDATED_LOCALLY_FAILED";

export const USER_PREFERENCES_LOADED_SUCCESS = "USER_PREFERENCES_LOADED_SUCCESS";
export const USER_PREFERENCES_LOADED_FAILED = "USER_PREFERENCES_LOADED_FAILED";

export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAILED = "REFRESH_TOKEN_FAILED";

export const USER_PROFILE_LOADED_SUCCESS = "USER_PROFILE_LOADED_SUCCESS";
export const USER_PROFILE_LOADED_FAILED = "USER_PROFILE_LOADED_FAILED";

export const USER_PROFILE_UPDATED_SUCCESS = "USER_PROFILE_UPDATED_SUCCESS";
export const USER_PROFILE_UPDATED_FAILED = "USER_PROFILE_UPDATED_FAILED";

export const USER_PREFERENCES_UPDATED_SUCCESS = "USER_PREFERENCES_UPDATED_SUCCESS";
export const USER_PREFERENCES_UPDATED_FAILED = "USER_PREFERENCES_UPDATED_FAILED";

export const USER_CART_LOADED_SUCCESS = "USER_CART_LOADED_SUCCESS";
export const USER_CART_LOADED_FAILED = "USER_CART_LOADED_FAILED";

export const USER_FIRST_ADDRESS_SET_SUCCESS = "USER_FIRST_ADDRESS_SET_SUCCESS";
export const USER_FIRST_ADDRESS_SET_FAILED = "USER_FIRST_ADDRESS_SET_FAILED";

export const DEFAULT_USER_LOADED_SUCCESS = "DEFAULT_USER_LOADED_SUCCESS";
export const DEFAULT_USER_LOADED_FAILED = "DEFAULT_USER_LOADED_FAILED";

export const UPDATE_CURRENCY_SUCCESS = "UPDATE_CURRENCY_SUCCESS";
export const UPDATE_CURRENCY_FAILED = "UPDATE_CURRENCY_FAILED";

export const CHANGE_USER_TYPE_SUCCESS = "CHANGE_USER_TYPE_SUCCESS";
export const CHANGE_USER_TYPE_FAILED = "CHANGE_USER_TYPE_FAILED";

export const LOAD_ALERT_SUCCESS = "LOAD_ALERT_SUCCESS";
export const LOAD_ALERT_FAILED = "LOAD_ALERT_FAILED";

export const SAVE_USER_APP_SETTINGS_SUCCESS = "SAVE_USER_APP_SETTINGS_SUCCESS";
export const SAVE_USER_APP_SETTINGS_FAILED = "SAVE_USER_APP_SETTINGS_FAILED";
