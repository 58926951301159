import {MdAddToPhotos} from "react-icons/md";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {AiFillQuestionCircle} from "react-icons/ai";
import React, {useContext} from "react";
import {Context} from "../../../Contexts/Context";
import {numberWithCommas} from "../../Utils";

const PackageCheckListTabs = ({data, add_update_cart, handleOpenModal, loadedPackage, currency, update = false}) => {
    const history = useHistory()

    let isAuthenticated = useSelector(state => state.accounts.isAuthenticated)

    const {setFaqNum, handleOpenFaqModal} = useContext(Context);
    return (
        <div className={'container'}>
            <div className={'row page-detail-container'}>
                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12">
                    <div style={{display: 'flex'}}>
                        <p className={'page-name'}>Package Checklist</p>
                        <div className={'update-cart-btn-sm'}>
                            {update ?
                                <button
                                    onClick={add_update_cart}
                                    style={{
                                        backgroundColor: '#6CD7BB',
                                        color: 'white',
                                        fontSize: 12
                                    }}
                                    className={'btn'}>Update Cart</button>
                                :
                                <button
                                    onClick={() => {
                                        if (!isAuthenticated) {
                                            handleOpenModal()
                                        } else {
                                            add_update_cart('cart')
                                        }
                                    }}
                                    style={{
                                        backgroundColor: '#6CD7BB',
                                        color: 'white',
                                        fontSize: 12
                                    }}
                                    className={'btn'}>Add to Cart</button>
                            }
                        </div>
                        <AiFillQuestionCircle
                            className={'question-faq-icon-package'}
                            onClick={() => {
                                setFaqNum(19)
                                handleOpenFaqModal()
                            }}
                            style={{color: '#ce5e97'}}/>
                    </div>
                    {data.map((item) => {
                        return (
                            <div className={'my-4'}>
                                <p className={'page-sub-heading'}>{item.questionName}</p>
                                <div className={'page-list'} style={{paddingLeft: 15}}>
                                    <ul>
                                        {item.answers.map((point) => {
                                            return (
                                                <li className={'package-points'}>{point.answer}</li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12">
                    <div style={{width: '100%', display: 'flex'}}>
                        <div style={{marginLeft: 'auto', textAlign: 'right'}}>
                            <p className={'red-price body-price'} style={{fontSize: 15}}>
                                <del>{currency}{numberWithCommas(loadedPackage.basePrice.toFixed(2))}</del>
                            </p>
                            <p className={'black-price body-price'}
                               style={{fontSize: 15}}>{currency}{numberWithCommas(loadedPackage.sellingPrice.toFixed(2))}</p>
                            <div className={'update-cart-btn-bg'}>
                                {update ?
                                    <button
                                        onClick={add_update_cart}
                                        style={{
                                            backgroundColor: '#6CD7BB',
                                            color: 'white',
                                            fontSize: 14
                                        }}
                                        className={'btn'}>Update Cart</button>
                                    :
                                    <button
                                        onClick={() => {
                                            if (!isAuthenticated) {
                                                handleOpenModal()
                                            } else {
                                                add_update_cart('cart')
                                            }
                                        }}
                                        style={{
                                            backgroundColor: '#6CD7BB',
                                            color: 'white',
                                            fontSize: 14
                                        }}
                                        className={'btn'}>Add to Cart</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PackageCheckListTabs;