import Faq from "../component/LandingPage/FAQ";
import Footer from "../component/LandingPage/Footer";
import {useState} from "react";
import ContactUsHero from "../component/ContactUs/ContactUsHero";

const FaqPage = () => {

    const [faqNum, setFaqNum] = useState(8);

    return (
        <>
            <ContactUsHero faqNum={faqNum} setFaqNum={setFaqNum}/>

            <Faq faqNum={faqNum} page={false} home={true}/>

            <Footer/>
        </>
    );
}

export default FaqPage;