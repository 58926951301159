import {
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGOUT_SUCCESS,
    LOGOUT_FAILED,
    SIGNUP_FAILED,
    SIGNUP_SUCCESS,
    USER_ADDRESS_LOADED_SUCCESS,
    USER_PREFERENCES_LOADED_SUCCESS,
    REFRESH_TOKEN_SUCCESS,
    REFRESH_TOKEN_FAILED,
    USER_PROFILE_LOADED_SUCCESS,
    USER_PROFILE_LOADED_FAILED,
    USER_PROFILE_UPDATED_SUCCESS,
    USER_PROFILE_UPDATED_FAILED,
    USER_PREFERENCES_UPDATED_SUCCESS,
    USER_PREFERENCES_UPDATED_FAILED,
    USER_ADDRESS_SAVED_SUCCESS,
    USER_ADDRESS_SAVED_FAILED,
    USER_ADDRESS_UPDATED_SUCCESS,
    USER_ADDRESS_UPDATED_FAILED,
    USER_ADDRESS_DELETE_SUCCESS,
    USER_ADDRESS_DELETE_FAILED,
    USER_ADDRESS_UPDATED_LOCALLY_SUCCESS,
    USER_ADDRESS_UPDATED_LOCALLY_FAILED,
    USER_CART_LOADED_SUCCESS,
    USER_CART_LOADED_FAILED,
    USER_FIRST_ADDRESS_SET_SUCCESS,
    USER_FIRST_ADDRESS_SET_FAILED,
    DEFAULT_USER_LOADED_SUCCESS,
    DEFAULT_USER_LOADED_FAILED, UPDATE_CURRENCY_SUCCESS,
    CHANGE_USER_TYPE_SUCCESS,
    CHANGE_USER_TYPE_FAILED,
    LOAD_ALERT_SUCCESS,
    LOAD_ALERT_FAILED,
    SAVE_USER_APP_SETTINGS_SUCCESS,
    SAVE_USER_APP_SETTINGS_FAILED
} from "../actions/types";

const initialAccountState = {
    access_token: null,
    refresh_token: null,
    isAuthenticated: false,
    userType: 'Customer',
    data: {},
    account_settings: {
        "sms": false,
        "media": false,
        "calendar": false,
        "contacts": false,
        "calls": false,
        "location": false
    },
    address: [],
    preferences: {},
    location: {
        "addressType": "Home",
        "line1": "Bengaluru",
        "line2": "Bengaluru",
        "line3": "Bengaluru",
        "pincode": "560076",
        "city": "Bengaluru",
        "state": "Karnataka",
        "country": "India",
        "latitude": 12.9716,
        "longitude": 77.5946,
        "landmark": "Bengaluru",
        "locationText": "Bengaluru",
        "defaultServiceAddress": false,
        "defaultBillingAddress": false
    },
    cart: [],
    alertCount: 0,
    currency: '₹',
}

export default function (state = initialAccountState, action) {
    const {type, payload} = action;

    switch (type) {
        //   ACCOUNTS
        case SIGNUP_SUCCESS:
        case LOGIN_SUCCESS:
            localStorage.setItem('access_token', payload.access_token)
            localStorage.setItem('refresh_token', payload.refresh_token)
            localStorage.setItem('isAuthenticated', true)
            return {
                ...state,
                access_token: payload.access_token,
                refresh_token: payload.refresh_token,
                isAuthenticated: true,
                data: payload.data
            }
        case DEFAULT_USER_LOADED_SUCCESS:
            localStorage.setItem('access_token', payload.access_token)
            localStorage.setItem('refresh_token', payload.refresh_token)
            localStorage.setItem('isAuthenticated', false)
            return {
                ...state,
                access_token: payload.access_token,
                refresh_token: payload.refresh_token,
                location: {
                    "addressType": "",
                    "line1": "Bengaluru",
                    "line2": "Bengaluru",
                    "line3": "Bengaluru",
                    "pincode": "560076",
                    "city": "Bengaluru",
                    "state": "Karnataka",
                    "country": "India",
                    "latitude": 12.9716,
                    "longitude": 77.5946,
                    "landmark": "Bengaluru",
                    "locationText": "Bengaluru",
                    "defaultServiceAddress": false,
                    "defaultBillingAddress": false
                }
            }
        case SAVE_USER_APP_SETTINGS_SUCCESS:
            return {
                ...state,
                account_settings: payload
            }
        case USER_ADDRESS_UPDATED_SUCCESS:
        case USER_ADDRESS_SAVED_SUCCESS:
        case USER_ADDRESS_UPDATED_LOCALLY_SUCCESS:
            return {
                ...state,
                location: payload.data
            }
        case LOAD_ALERT_SUCCESS:
            return {
                ...state,
                alertCount: payload.count
            }
        case USER_FIRST_ADDRESS_SET_SUCCESS:
            const locations = state.address
            if (locations.length > 0) {
                return {
                    ...state,
                    location: locations[0]
                }
            } else {
                return {
                    ...state,
                }
            }
        case UPDATE_CURRENCY_SUCCESS:
            const country = state.location.country
            if (country === 'India') {
                return {
                    ...state,
                    currency: '₹'
                }
            } else {
                return {
                    ...state,
                    currency: '$'
                }
            }
        case USER_PROFILE_UPDATED_SUCCESS:
        case USER_PROFILE_LOADED_SUCCESS:
            return {
                ...state,
                data: payload.data
            }
        case REFRESH_TOKEN_SUCCESS:
            localStorage.setItem('access_token', payload.access_token)
            localStorage.setItem('refresh_token', payload.refresh_token)
            return {
                ...state,
                access_token: payload.access_token,
                refresh_token: payload.refresh_token
            }
        case USER_ADDRESS_LOADED_SUCCESS:
            return {
                ...state,
                address: payload.data
            }
        case USER_PREFERENCES_LOADED_SUCCESS:
            return {
                ...state,
                preferences: payload.data
            }

        //    CART
        case USER_CART_LOADED_SUCCESS:
            return {
                ...state,
                cart: payload ? payload : [],
            }
        case CHANGE_USER_TYPE_SUCCESS:
            return {
                ...state,
                userType: payload
            }

        case REFRESH_TOKEN_FAILED:
        case LOGOUT_SUCCESS:
            localStorage.removeItem('access_token')
            localStorage.removeItem('refresh_token')
            localStorage.setItem('isAuthenticated', false)
            return {
                access_token: null,
                refresh_token: null,
                isAuthenticated: false,
                userType: 'Customer',
                data: {},
                account_settings: {
                    "sms": false,
                    "media": false,
                    "calendar": false,
                    "contacts": false,
                    "calls": false,
                    "location": false
                },
                address: [],
                preferences: {},
                location: {
                    "addressType": "Home",
                    "line1": "Bengaluru",
                    "line2": "Bengaluru",
                    "line3": "Bengaluru",
                    "pincode": "560076",
                    "city": "Bengaluru",
                    "state": "Karnataka",
                    "country": "India",
                    "latitude": 12.9716,
                    "longitude": 77.5946,
                    "landmark": "Bengaluru",
                    "locationText": "Bengaluru",
                    "defaultServiceAddress": false,
                    "defaultBillingAddress": false
                },
                cart: [],
                alertCount: 0,
                currency: '₹'
            }

        //    FAILED CASES
        case SAVE_USER_APP_SETTINGS_FAILED:
        case USER_FIRST_ADDRESS_SET_FAILED:
        case USER_CART_LOADED_FAILED:
        case USER_ADDRESS_DELETE_SUCCESS:
        case USER_ADDRESS_UPDATED_LOCALLY_FAILED:
        case USER_ADDRESS_DELETE_FAILED:
        case USER_ADDRESS_UPDATED_FAILED:
        case USER_ADDRESS_SAVED_FAILED:
        case USER_PREFERENCES_UPDATED_SUCCESS:
        case USER_PREFERENCES_UPDATED_FAILED:
        case USER_PROFILE_UPDATED_FAILED:
        case USER_PROFILE_LOADED_FAILED:
        case DEFAULT_USER_LOADED_FAILED:
        case CHANGE_USER_TYPE_FAILED:
        case LOGIN_FAILED:
        case SIGNUP_FAILED:
        case LOGOUT_FAILED:
        case LOAD_ALERT_FAILED:
            return {
                ...state
            }
        default:
            return state
    }
}
