import axios from "axios";

function Config() {
    const config = {
        header: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Accept': 'application/json'
        }
    }
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`
    axios.defaults.headers.common['Accept'] = 'application/json'
    return config
}

export default Config;