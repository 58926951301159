import React from 'react';
import Footer from "../component/LandingPage/Footer";
import {AiFillQuestionCircle} from "react-icons/ai";
import {useHistory} from "react-router-dom";
import Faq from "../component/LandingPage/FAQ";

function TermsPage() {
    return (
        <>
            <div style={{
                backgroundColor: "#F6F6F6",
                display: 'flex',
                flexDirection: 'column',
                margin: "30px 0"
            }}>
                <div style={{
                    padding: "40px 0"
                }}>
                    <div className={'container'} style={{display: 'flex'}}>
                        <p style={{
                            color: "#424B5A",
                            fontSize: 36,
                            fontWeight: 400,
                            flexDirection: 'auto 0',
                            letterSpacing: 1
                        }}>Terms</p>
                    </div>
                </div>
            </div>

            <Faq faqNum={21} page={false} home={true} terms={true}/>

            <Footer/>
        </>
    );
}

export default TermsPage;