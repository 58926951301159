import {BsBookmark, BsBookmarkFill, BsShare} from "react-icons/bs";
import {AiFillHeart, AiOutlineHeart} from "react-icons/ai";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import axios from "axios";
import Config from "../Config";
import {numberWithCommas} from "./Utils";


const PackageDetailHero = ({
                               packageID,
                               loadedPackage,
                               currency,
                               add_to_cart,
                               handleOpenModal,
                               liked,
                               setLiked,
                               handleShareOpenModal
                           }) => {
    let isAuthenticated = useSelector(state => state.accounts.isAuthenticated)

    const likePackage = () => {
        if (isAuthenticated) {
            const config = Config()
            axios.post(`https://cgapp.clykk.com/like/api/v2/packageLike`,
                {"packageId": parseInt(packageID), "likes": liked ? 0 : 1}
                , config)
                .then(response => {
                    setLiked(response.data['rs']['likes'] === 1)
                })
                .catch(err => {
                    console.log(err.message)
                })
        } else {
            handleOpenModal()
        }
    }

    return (
        <>
            <div className={'package-detail-hero-lg'}>
                <div className="container">
                    <div style={{display: 'flex'}}>
                        <p className={'hero-heading-package-detail'}>{loadedPackage.packageName}</p>
                        <button className={'btn my-1 package-detail-hero-btn'}
                                onClick={handleShareOpenModal}>
                            <BsShare/> <span className={'package-detail-hero-text'}>Share</span>
                        </button>
                        <button className={'btn my-1 package-detail-hero-btn'}
                                onClick={() => {
                                    if (!isAuthenticated) {
                                        handleOpenModal()
                                    } else {
                                        add_to_cart('todo')
                                    }
                                }}
                        >
                            <BsBookmark/> <span className={'package-detail-hero-text'}>To-Do</span>
                        </button>
                        <button className={'btn my-1 package-detail-hero-btn'}
                                onClick={likePackage}
                        >
                            {liked ? <AiFillHeart style={{color: 'red'}}/> : <AiOutlineHeart/>} <span
                            className={'package-detail-hero-text'}>{liked ? "Liked" : "Like"}</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className={'package-detail-hero-sm'}>
                <div className="container">
                    <p className={'hero-heading-package-detail'}>{loadedPackage.packageName}</p>
                </div>

                <img src={loadedPackage.packageImage} style={{width: '100%'}}/>
                <div className="container">
                    <div style={{display: 'flex', margin: '10px 0'}}>
                        <div style={{margin: "auto 0"}}>
                            <p className={'red-price header-price'} style={{marginRight: 10}}>
                                <del>{currency}{numberWithCommas(parseFloat(loadedPackage.basePrice).toFixed(2))}</del>
                            </p>
                            <p className={'black-price header-price'}>{currency}{numberWithCommas(parseFloat(loadedPackage.sellingPrice).toFixed(2))}</p>
                        </div>
                        <div style={{margin: 'auto 0 auto auto', display: 'flex'}}>
                            <button className={'btn my-1 package-detail-hero-btn'} onClick={handleShareOpenModal}>
                                <BsShare/> <span className={'package-detail-hero-text'}>Share</span>
                            </button>
                            <button className={'btn my-1 package-detail-hero-btn'}
                                    onClick={() => {
                                        if (!isAuthenticated) {
                                            handleOpenModal()
                                        } else {
                                            add_to_cart('todo')
                                        }
                                    }}
                            >
                                <BsBookmark/> <span className={'package-detail-hero-text'}>To-Do</span>
                            </button>
                            <button className={'btn my-1 package-detail-hero-btn'}
                                    onClick={likePackage}
                            >
                                {liked ? <AiFillHeart style={{color: 'red'}}/> : <AiOutlineHeart/>} <span
                                className={'package-detail-hero-text'}>{liked ? "Liked" : "Like"}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PackageDetailHero;