import {IoIosArrowForward} from "react-icons/io";
import {useHistory, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {BsChevronRight} from "react-icons/bs";
import {AiFillQuestionCircle} from "react-icons/ai";
import React, {useContext} from "react";
import {Context} from "../../Contexts/Context";
import {numberWithCommas} from "../Utils";

const TopRateServices = ({services, subCatID}) => {
    const history = useHistory()

    const currency = useSelector(state => state.accounts.currency);

    const {setFaqNum, handleOpenFaqModal} = useContext(Context);

    return (
        <div className={'container my-3'}>
            <div style={{display: 'flex', width: '100%'}}>
                <p style={{margin: 'auto 0', fontWeight: 600, fontSize: 15, color: '#424B5A'}}>Services for you</p>
                <AiFillQuestionCircle
                    className={'faq-icon-selection-location'}
                    style={{margin: 'auto 0 auto auto'}}
                    onClick={() => {
                        setFaqNum(15)
                        handleOpenFaqModal()
                    }}/>
            </div>
            <div className="row">
                {services.map((service) => {
                    return (
                        service.enabled &&
                        <div className={'col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 my-2'}
                        >
                            <div onClick={() => {
                                history.push(`/package-detail/${subCatID}/${service.id}`)
                            }}
                                 style={{
                                     boxShadow: '0 0.5px #d0d7da',
                                     cursor: 'pointer',
                                     padding: "10px",
                                     backgroundColor: '#f6f7f9',
                                     display: 'flex',
                                     borderRadius: 10,
                                     height: '100%'
                                 }}>
                                <div className={'service-img-container'}
                                     style={{backgroundImage: `url(${service.packageImage})`}}
                                ></div>
                                {/*<img src={service.packageImage} className={'service-img'}/>*/}
                                <div className={'mx-2'} style={{display: 'flex', flexDirection: 'column'}}>
                                    <p className={'service-name'}>
                                        {service.packageName}
                                    </p>
                                    <div style={{display: 'flex', marginTop: 'auto'}}>
                                        <p className={'service-price-1'}>{currency}
                                            <del>{numberWithCommas(parseFloat(service.basePrice).toFixed(2))}</del>
                                        </p>
                                        <p className={'service-price-2'}>{currency}{numberWithCommas(parseFloat(service.sellingPrice).toFixed(2))}</p>
                                    </div>
                                </div>
                                <div style={{
                                    height: '100%',
                                    margin: 'auto 0 auto auto',
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '100%',
                                    }}>
                                        {service.offer &&
                                            <button className={'btn service-offer-btn'}>{service.offer}</button>
                                        }
                                        <BsChevronRight
                                            style={{margin: "auto 0 auto auto", fontSize: 12, strokeWidth: 2}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default TopRateServices;