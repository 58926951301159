import pin from '../assets/images/map-location pin.png'
import {AiFillQuestionCircle, AiOutlineArrowLeft} from "react-icons/ai";
import React, {useContext, useEffect, useState} from "react";
import {BsChevronRight} from "react-icons/bs";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import faq from '../assets/images/faq-img.png'
import Footer from "../component/LandingPage/Footer";
import {useHistory, useLocation} from "react-router-dom";
import {TiTick} from "react-icons/ti";
import {useDispatch, useSelector} from "react-redux";
import {Modal} from "@material-ui/core";
import ProvideRequirementsModal from "../component/Booking/ProvideRequirementsModal";
import axios from "axios";
import {load_user_cart} from "../actions/accounts";
import Config from "../Config";
import yellow_glitter from "../assets/images/yellow star.png";
import pink_glitter from "../assets/images/star.svg";
import purple_glitter from "../assets/images/glitter.png";
import green_glitter from "../assets/images/greenstar.png";
import clsx from "clsx";
import {Context} from "../Contexts/Context";
import AddAlert from "../AddAlert";
import ProvideChecklistModal from "../component/OrderSubscriptionDetail/ProvideChecklistModal";
import {numberWithCommas} from "../component/Utils";
import {toast} from "react-hot-toast";

const ServiceExecutionPage = () => {
    const history = useHistory()
    const [order, setOrder] = useState(null)
    const location = useLocation()
    const {setFaqNum, handleOpenFaqModal} = useContext(Context);

    const scheduleId = location.pathname.split('/')[2]

    const [activeStep, setActiveStep] = useState(2);

    useEffect(() => {
        const config = Config()
        axios.get(`https://cgapp.clykk.com/cart/api/v2/cart-management/subscription/${scheduleId}/schedule`, config)
            .then(response => {
                if (response.data.status === "Scheduled") {
                    setActiveStep(2)
                } else if (response.data.status === "ReScheduled") {
                    setActiveStep(2)
                } else if (response.data.status === "Routed") {
                    setActiveStep(2)
                } else if (response.data.status === "ProviderAssigned") {
                    setActiveStep(2)
                } else if (response.data.status === "ProviderOnTheWay") {
                    setActiveStep(4)
                } else if (response.data.status === "ProviderArrived") {
                    setActiveStep(4)
                } else if (response.data.status === "ProviderOTPSent") {
                    setActiveStep(4)
                } else if (response.data.status === "ProviderOTPConfirmed") {
                    setActiveStep(4)
                } else if (response.data.status === "InProgress") {
                    setActiveStep(6)
                } else if (response.data.status === "AdditionalCharges") {
                    setActiveStep(6)
                } else if (response.data.status === "Completed") {

                    setActiveStep(7)
                }

                setOrder(response.data)
            })
            .catch(error => {
                console.log(error.message)
            })
    }, [])

    const dispatch = useDispatch()

    const user = useSelector(state => state.accounts.data)

    const currency = useSelector(state => state.accounts.currency)

    const reOrder = () => {
        const config = Config()
        const get_date = (date) => {
            return date.length > 1 ? date : '0' + date
        }
        const date = new Date().toLocaleString().split(',')[0].split('/')
        const body = {
            "qna": order.qnA,
            "serviceAddress": order.serviceAddress,
            "deliveryAddress": order.deliveryAddress,
            "schedule": {
                "serviceTime": "08:30",
                "startDate": date[2] + '-' + get_date(date[0]) + '-' + get_date(date[1]),
                "endDate": date[2] + '-' + get_date(date[0]) + '-' + get_date(date[1]),
                "subscriptionFrequency": "once",
                "weekdays": []
            },
            "items": {
                "package": order.package,
                "addOns": order.addOns.map(item => ({
                    packageName: item.packageName,
                    shortDescription: item.shortDescription,
                    packageimage: item.packageImage,
                    basePrice: item.basePrice,
                    sellingPrice: item.sellingPrice,
                    itemCount: 0
                }))
            }
        }
        axios.post(`https://cgapp.clykk.com/cart/api/v2/cart-management/cart`, body, config)
            .then(response => {
                dispatch(load_user_cart())
                AddAlert(dispatch, user.userId, body['items']['package'], 'Cart', 'ReorderConfirmation', response.data.cartID)
                history.push('/cart/')
            })
            .catch(e => console.log(e.message))
    }

    const steps = [
        {text: 'Order Details', link: order === null ? '' : `/order-detail/${order.cartID}/`},
        {text: 'Provide Requirements', link: ''},
        {text: 'Service Provider On the way', link: `/service-track/${scheduleId}/`},
        {text: 'Review the Delivery', link: ''},
        {text: 'Additional Charge', link: `/additional-charge/${scheduleId}/`},
        {text: 'Rate the Service Provider', link: `/service-rating/${scheduleId}/`},
        {text: 'Order Completed', link: ''}
    ]

    const [openModal, setOpenModal] = useState(false)
    const handleOpenModal = () => {
        setOpenModal(true)
    }
    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const [openCheckListModal, setCheckListModal] = useState(false)
    const handleCheckListOpenModal = () => {
        setCheckListModal(true)
    }
    const handleCheckListCloseModal = () => {
        setCheckListModal(false)
    }

    const [requirementsComplete, setRequirementsComplete] = useState(false)
    useEffect(() => {
        if (order !== null) {
            let complete = true

            order.qnA.map((question) => {
                if (question.questionType === "requirements") {
                    question.answers.map((answer) => {
                        if (answer.answerType === 'text' && answer.customerAnswer === null) {
                            complete = false
                        }
                    })
                }
            })
            if (complete) {
                if (activeStep < 2)
                    setActiveStep(2)
            }
            setRequirementsComplete(complete)
        }
    }, [order])

    const returnOrderStatus = (status) => {
        if (status === "Scheduled") {
            return "Your Order is now Scheduled!"
        } else if (status === "ReScheduled") {
            return "Your Order is now ReScheduled!"
        } else if (status === "Routed") {
            return "We are finding a Service Provider!"
        } else if (status === "ProviderAssigned") {
            return "A Provider has been Assigned!"
        } else if (status === "ProviderOnTheWay") {
            return "Your Provider is On the Way!"
        } else if (status === "ProviderArrived") {
            return "Your Provider has Arrived!"
        } else if (status === "ProviderOTPSent") {
            return "Provider OTP has been Generated!"
        } else if (status === "ProviderOTPConfirmed") {
            return "Provider has Confirmed OTP!"
        } else if (status === "InProgress") {
            return "Provider has Started his Service!"
        } else if (status === "AdditionalCharges") {
            return "Customer to Pay Additional Charges!"
        } else if (status === "Completed") {
            return "Order is now Completed!"
        }
    }

    const [serviceProviderData, setServiceProviderData] = useState(null)

    useEffect(() => {
        const config = Config()
        axios.get(`https://clapp.clykk.com/routing_management/public/index.php/api/getorderdetails?sub_id=${scheduleId}`, config)
            .then(response => {
                if (response.data.status) {
                    setServiceProviderData(response.data.data[0])
                }
            })
            .catch(e => console.log(e.message))
    }, [])

    useEffect(() => {
        console.log("serviceProviderData =>", serviceProviderData)
    }, [serviceProviderData])

    return (
        <div style={{position: 'relative'}}>
            <>
                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '5%', left: '70%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '10%', left: '10%'}}/>
                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 17, top: '15%', left: '60%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '25%', left: '90%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '30%', left: '20%'}}/>


                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '32%', left: '65%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 10, top: '40%', left: '55%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 17, top: '50%', left: '70%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '60%', left: '82%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '70%', left: '12%'}}/>

                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 17, top: '75%', left: '63%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '80%', left: '51%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 20, top: '85%', left: '87%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '90%', left: '52%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 17, top: '95%', left: '69%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '97%', left: '87%'}}/>
            </>
            <div className={'container my-4'}>
                {/*<img src={hero_background} className={'w-100'}/>*/}
                <div style={{display: 'flex'}}>
                    <AiOutlineArrowLeft onClick={() => {
                        history.push('/my-orders/')
                    }} style={{margin: "auto 10px auto 0", cursor: 'pointer'}}/>

                    <p style={{fontSize: 17, fontWeight: 650, margin: '20px 0'}}>Booking Execution</p>
                    <AiFillQuestionCircle
                        className={'faq-icon-selection-location'}
                        style={{margin: 'auto 0 auto auto'}}
                        onClick={() => {
                            setFaqNum(36)
                            handleOpenFaqModal()
                        }}/>
                </div>
                <div className={'my-3'}>
                    {order &&
                        <>
                            <div style={{
                                textAlign: 'center',
                                padding: '15px',
                                backgroundColor: '#6CD7BB',
                                color: 'white',
                                fontSize: 12,
                                fontWeight: 500,
                                borderRadius: '5px 5px 0 0'
                            }}>
                                <p><span><TiTick/></span>{returnOrderStatus(order.status)}</p>
                            </div>

                            <div className={'p-3'} style={{backgroundColor: '#F6F7F9'}}>

                                <div style={{width: '100%'}} onClick={() => {
                                    history.push(`/order-subscription-detail/${scheduleId}/`)
                                }}>
                                    <div style={{display: 'flex'}}>
                                        <div style={{width: '100%'}}>
                                            <div style={{display: 'flex'}}>
                                                <div className={'package-image-container'}
                                                     style={{backgroundImage: `url(${order.package.packageImage})`}}>
                                                </div>
                                                <p className={'cart-package-name'}>{order.package.packageName}</p>
                                                <p className={'cart-chat-id'}
                                                   style={{margin: "auto 0 auto auto"}}>{order.cartID} - {order.id}</p>
                                            </div>
                                            <div style={{display: 'flex', marginTop: 12}}>
                                                <img src={pin} style={{width: 10, height: 12}}/>
                                                <p style={{
                                                    fontSize: 11,
                                                    fontWeight: 400,
                                                    color: '#A1A2A4',
                                                    marginLeft: 5
                                                }}>{order.serviceAddress.line2}</p>
                                            </div>
                                            <div style={{display: 'flex', marginTop: 10, padding: '0 '}}>
                                                <div style={{margin: 'auto auto auto 0'}}>
                                                    <p className={'cart-detail-heading'}>Date</p>
                                                    <p className={'cart-detail-meta-text'}>{order.serviceDate}</p>
                                                </div>
                                                <div style={{margin: 'auto'}}>
                                                    <p className={'cart-detail-heading'}>Time</p>
                                                    <p className={'cart-detail-meta-text'}>{order.serviceTime}</p>
                                                </div>
                                                <div style={{margin: 'auto 0 auto auto'}}>
                                                    <p className={'cart-detail-heading'}>Subscription</p>
                                                    <p className={'cart-detail-meta-text'}>{order.subscriptionFrequency}</p>
                                                </div>
                                                <div style={{margin: 'auto 0 auto auto'}}>
                                                    <p className={'cart-detail-heading'}>Price</p>
                                                    <p className={'cart-detail-meta-text'}>{currency} {numberWithCommas(order.transaction[0].payableAmount.toFixed(2))}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{display: 'flex'}}>
                                            <BsChevronRight style={{margin: "auto 10px"}}/>
                                        </div>
                                    </div>
                                </div>


                                <div className={'stepper my-5'}>
                                    <Stepper activeStep={activeStep} orientation="vertical">
                                        {steps.map((item, index) => {
                                            return (
                                                <Step key={index}>
                                                    <StepLabel onClick={() => {
                                                        if (item.text === "Provide Requirements") {
                                                            handleOpenModal()
                                                        } else if (item.text === "Review the Delivery") {
                                                            handleCheckListOpenModal()
                                                        } else if (item.text === "Service Provider On the way") {
                                                            if (serviceProviderData !== null) {
                                                                history.push(item.link)
                                                            } else {
                                                                toast.error("Service Provider not available yet", {duration: 1000})
                                                            }
                                                        } else if (item.link !== '') {
                                                            history.push(item.link)
                                                        }
                                                    }}>{item.text}
                                                        {item.text === 'Service Provider On the way' &&
                                                            <span style={{fontSize: 10}}> (Track ETA)</span>
                                                        }
                                                    </StepLabel>
                                                    {item.text === 'Provide Requirements' &&
                                                        <button
                                                            onClick={handleOpenModal}
                                                            style={{marginLeft: 'auto'}}
                                                            className={clsx('btn btn-provide-requirement', requirementsComplete ? 'complete-status' : 'incomplete-status')}>
                                                            {requirementsComplete ? "Complete" : "Incomplete"}
                                                        </button>
                                                    }
                                                    {item.text === 'Additional Charge' && order.additionalMaterials.paid_status &&
                                                        <button className={'btn'}
                                                                style={{
                                                                    marginLeft: 'auto',
                                                                    color: '#424b5a',
                                                                    backgroundColor: 'white',
                                                                    border: '2px solid #424b5a',
                                                                    fontSize: 10,
                                                                    fontWeight: 700,
                                                                    width: 80,
                                                                    padding: 7
                                                                }}>{order.additionalMaterials.paid_status}</button>
                                                    }
                                                </Step>
                                            );
                                        })}
                                    </Stepper>
                                </div>

                            </div>
                        </>
                    }
                </div>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <button className={'reorders-btn btn'}
                            onClick={reOrder}>
                        Reorder Same Service
                    </button>
                </div>

                <div style={{display: 'flex', textAlign: 'center', margin: '30px 0'}}>
                    <div style={{margin: 'auto'}}>
                        <img src={faq} style={{width: 40}}/>
                        <p style={{fontSize: 12, fontWeight: 600}}>Have some question?</p>
                        <p style={{fontSize: 12, fontWeight: 600}}>Reach our Answers</p>
                        <button className={'btn mt-2 w-100'} onClick={() => {
                            history.push('/faq')
                        }}
                                style={{color: 'white', backgroundColor: '#6CD7BB', fontSize: 13}}>FAQs
                        </button>
                    </div>
                    <div style={{margin: 'auto', textAlign: 'center'}}>
                        <img src={faq} style={{width: 40}}/>

                        {/*<MdSupportAgent style={{fontSize: 30}}/>*/}
                        <p style={{fontSize: 12, fontWeight: 600}}>Having issues with your</p>
                        <p style={{fontSize: 12, fontWeight: 600}}>Order? Contact Us</p>
                        <button className={'btn mt-2 w-100'} onClick={() => {
                            history.push('/contact-us')
                        }}
                                style={{color: 'white', backgroundColor: '#6CD7BB', fontSize: 13}}>Support
                        </button>
                    </div>
                </div>
            </div>

            {order &&
                <Modal
                    open={openModal}
                    onClose={() => {
                    }}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <ProvideRequirementsModal cartItem={order} setCartItem={setOrder}
                                              handleCloseModal={handleCloseModal}
                                              booked={true} edit={order.status === 'Completed'}
                    />
                </Modal>
            }

            {order &&
                <Modal
                    open={openCheckListModal}
                    onClose={() => {
                    }}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <ProvideChecklistModal cartItem={order} handleCloseModal={handleCheckListCloseModal}/>
                </Modal>
            }

            <Footer/>

        </div>
    );
}

export default ServiceExecutionPage;