import axios from 'axios';
import {
    LOGIN_SUCCESS,
    LOGIN_FAILED,

    LOGOUT_SUCCESS,
    LOGOUT_FAILED,

    SIGNUP_SUCCESS,
    SIGNUP_FAILED,

    USER_ADDRESS_LOADED_SUCCESS,
    USER_ADDRESS_LOADED_FAILED,

    USER_ADDRESS_SAVED_SUCCESS,
    USER_ADDRESS_SAVED_FAILED,

    USER_ADDRESS_UPDATED_SUCCESS,
    USER_ADDRESS_UPDATED_FAILED,

    USER_ADDRESS_DELETE_SUCCESS,
    USER_ADDRESS_DELETE_FAILED,

    USER_PREFERENCES_LOADED_SUCCESS,
    USER_PREFERENCES_LOADED_FAILED,

    REFRESH_TOKEN_SUCCESS,
    REFRESH_TOKEN_FAILED,

    USER_PROFILE_LOADED_SUCCESS,
    USER_PROFILE_LOADED_FAILED,

    USER_PROFILE_UPDATED_SUCCESS,
    USER_PROFILE_UPDATED_FAILED,

    USER_PREFERENCES_UPDATED_SUCCESS,
    USER_PREFERENCES_UPDATED_FAILED,

    USER_ADDRESS_UPDATED_LOCALLY_SUCCESS,
    USER_ADDRESS_UPDATED_LOCALLY_FAILED,

    USER_CART_LOADED_SUCCESS,
    USER_CART_LOADED_FAILED,

    USER_FIRST_ADDRESS_SET_SUCCESS,
    USER_FIRST_ADDRESS_SET_FAILED,

    DEFAULT_USER_LOADED_SUCCESS,
    DEFAULT_USER_LOADED_FAILED,

    UPDATE_CURRENCY_SUCCESS,
    UPDATE_CURRENCY_FAILED,

    CHANGE_USER_TYPE_SUCCESS,
    CHANGE_USER_TYPE_FAILED,

    LOAD_ALERT_SUCCESS,
    LOAD_ALERT_FAILED,
    SAVE_USER_APP_SETTINGS_SUCCESS,
    SAVE_USER_APP_SETTINGS_FAILED
} from "./types";
import Config from "../Config";
import localStorage from "redux-persist/es/storage";
import {toast} from "react-hot-toast";
import RefreshConfig from "../RefreshConfig";
import CheckAuthenticated from "../checkAuthenticated";

export const load_default_account = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = {
        mobile: "7676230500",
        countryCode: "91"
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/clykk/ext/um/v1/sign-in`, body, config);

        dispatch({
            type: DEFAULT_USER_LOADED_SUCCESS,
            payload: res.data
        })

    } catch (ex) {
        if (ex && ex.toString) {
            console.log(ex.toString());
        }
        if (ex.response && ex.response.data) {
            console.log(ex.response.data);
        }
        dispatch({
            type: DEFAULT_USER_LOADED_FAILED
        })
    }
}

export const refresh_access_token = () => async dispatch => {
    if (localStorage.getItem('refresh_token')) {
        const refresh_config = RefreshConfig()
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/clykk/ext/um/v1/refresh-token`, {}, refresh_config)
            dispatch({
                type: REFRESH_TOKEN_SUCCESS,
                payload: response.data
            })
        } catch (e) {
            dispatch({
                type: REFRESH_TOKEN_FAILED
            })
        }
    }
}

export const load_user_profile = () => dispatch => {
    if (CheckAuthenticated()) {
        const config = Config()
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/clykk/ext/um/v1/get-profile`, config)
                .then(response => {
                    dispatch({
                        type: USER_PROFILE_LOADED_SUCCESS,
                        payload: response.data
                    })

                    dispatch(load_user_data(response.data.data.userId))
                })
        } catch (err) {
            console.log(err)
            dispatch({
                type: USER_PROFILE_LOADED_FAILED
            })
        }
    }
}

export const update_user_profile = (data) => dispatch => {
    if (CheckAuthenticated()) {
        const config = Config()
        try {
            axios.post(`${process.env.REACT_APP_API_URL}/clykk/ext/um/v1/update-profile`, data, config)
                .then(response => {
                    dispatch({
                        type: USER_PROFILE_UPDATED_SUCCESS,
                        payload: response.data
                    })
                })
        } catch (err) {
            console.log(err)
            dispatch({
                type: USER_PROFILE_UPDATED_FAILED
            })
        }
    }
}

export const update_user_preferences = (data) => dispatch => {
    if (CheckAuthenticated()) {
        const config = Config()
        try {
            axios.put(`${process.env.REACT_APP_API_URL}/clykk/ext/um/v1/user/preferences`, data, config)
                .then(response => {
                    dispatch({
                        type: USER_PREFERENCES_UPDATED_SUCCESS,
                        payload: response.data
                    })
                    dispatch(load_user_preferences())
                })
        } catch (err) {
            console.log(err)
            dispatch({
                type: USER_PREFERENCES_UPDATED_FAILED
            })
        }
    }
}

export const load_user_alert = (userId) => async dispatch => {
    if (CheckAuthenticated()) {
        const config = Config()
        try {
            const response = await axios.get(`https://clapp.clykk.com/customer_alerts/public/index.php/api/getalertscount?user_id=${userId}`, config)

            dispatch({
                type: LOAD_ALERT_SUCCESS,
                payload: response.data
            })
        } catch (err) {
            dispatch({
                type: LOAD_ALERT_FAILED
            })
        }
    }
}

export const load_user_cart = () => async dispatch => {
    if (CheckAuthenticated()) {
        const config = Config()
        try {
            const response = await axios.get('https://cgapp.clykk.com/cart/api/v2/cart-management/cart', config)

            dispatch({
                type: USER_CART_LOADED_SUCCESS,
                payload: response.data
            })
        } catch (err) {

            dispatch({
                type: USER_CART_LOADED_FAILED
            })
        }
    }
}

export const add_to_cart = () => async dispatch => {
    if (CheckAuthenticated()) {
        const config = Config()
        const body = {}
        try {
            const response = await axios.post('https://cgapp.clykk.com/cart/api/v2/cart-management/cart', body, config)
            dispatch({
                type: USER_CART_LOADED_SUCCESS,
                payload: response.data
            })
        } catch (err) {
            console.log('cart err.message ==>', err.message)
            dispatch({
                type: USER_CART_LOADED_FAILED
            })
        }
    }
}

export const load_user_data = (userId) => dispatch => {
    if (CheckAuthenticated()) {
        dispatch(load_user_preferences())
        dispatch(load_user_address())
        dispatch(load_user_cart())
        dispatch(load_user_alert(userId))
    }
}


export const load_user_preferences = () => async dispatch => {
    if (CheckAuthenticated()) {
        const config = Config()
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/clykk/ext/um/v1/user/preferences`, config)
            dispatch({
                type: USER_PREFERENCES_LOADED_SUCCESS,
                payload: response.data
            })
        } catch (err) {
            dispatch({
                type: USER_ADDRESS_LOADED_FAILED
            })
        }
    }
}

export const load_user_address = () => async dispatch => {
    if (CheckAuthenticated()) {
        const config = Config()
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/clykk/ext/um/v1/get-address`, config)
            dispatch({
                type: USER_ADDRESS_LOADED_SUCCESS,
                payload: response.data
            })
        } catch (err) {
            console.log(err)
            dispatch({
                type: USER_PREFERENCES_LOADED_FAILED
            })
        }
    }
}

export const update_user_address_locally = (data) => async dispatch => {
    try {
        dispatch({
            type: USER_ADDRESS_UPDATED_LOCALLY_SUCCESS,
            payload: {data: data}
        })
        dispatch(update_currency())
        // toast.success('New Address Set', {duration: 1500})
    } catch (e) {
        dispatch({
            type: USER_ADDRESS_UPDATED_LOCALLY_FAILED
        })
    }
}

export const delete_user_address = (deleteId) => async dispatch => {
    if (CheckAuthenticated()) {

        const config = Config()

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/clykk/ext/um/v1/delete-address?addressId=` + deleteId, config)
            dispatch({
                type: USER_ADDRESS_DELETE_SUCCESS,
            })
            toast.success('Address Deleted', {duration: 1500})
            dispatch(load_user_address())
        } catch (e) {
            dispatch({
                type: USER_ADDRESS_DELETE_FAILED
            })
        }
    }
}

export const update_user_address_server = (body, updateId) => async dispatch => {
    if (CheckAuthenticated()) {

        const config = Config()

        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/clykk/ext/um/v1/save-address?addressId=` +
                updateId, body, config)
            dispatch({
                type: USER_ADDRESS_UPDATED_SUCCESS,
                payload: response.data
            })
            toast.success('Address Updated', {duration: 3000})
            dispatch(update_currency())
            // dispatch(update_user_address_locally(response.data.data))
            dispatch(load_user_address())
        } catch (e) {
            dispatch({
                type: USER_PREFERENCES_UPDATED_FAILED
            })
        }
    }
}


export const set_first_user_location = () => async dispatch => {
    if (CheckAuthenticated()) {
        try {
            dispatch({
                type: USER_FIRST_ADDRESS_SET_SUCCESS,
            })
            dispatch(update_currency())
        } catch (err) {
            dispatch({
                type: USER_FIRST_ADDRESS_SET_FAILED
            })
        }
    }
}

export const save_user_address = (body) => async dispatch => {
    if (CheckAuthenticated()) {
        const config = Config()

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/clykk/ext/um/v1/save-address`, body, config)

            dispatch({
                type: USER_ADDRESS_SAVED_SUCCESS,
                payload: response.data
            })
            toast.success('Address Saved', {duration: 1500})
            dispatch(update_currency())
            // dispatch(update_user_address_locally(response.data.data))
            dispatch(load_user_address())
        } catch (err) {
            console.log(err.message)
            dispatch({
                type: USER_ADDRESS_SAVED_FAILED
            })
        }
    }
}

export const signup = (loginData, setValue, value, setOtpSent) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    const body = {
        mobile: loginData.mobile,
        countryCode: '+' + loginData.countryCode
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/clykk/ext/um/v1/sign-up`, body, config);
        dispatch({
            type: SIGNUP_SUCCESS,
            payload: res.data
        });

        dispatch(load_user_data(res.data.data.userId))

        setTimeout(() => {
            setValue(value + 1)
        }, 2000)

    } catch (err) {
        console.log(err.message)
        setOtpSent(false)
        toast.error("Expected Error occured Please try again.", {duration: 1500})
        dispatch({
            type: SIGNUP_FAILED
        })
    }
}

export const update_currency = () => async dispatch => {
    try {
        dispatch({
            type: UPDATE_CURRENCY_SUCCESS,
        })
    } catch (e) {
        dispatch({
            type: UPDATE_CURRENCY_FAILED,
        })
    }
}

export const login = (loginData, setValue, value, setOtpSent) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = {
        mobile: loginData.mobile,
        countryCode: '+' + loginData.countryCode
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/clykk/ext/um/v1/sign-in`, body, config);

        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        })

        axios.get('https://cgapp.clykk.com/um/clykk/ext/um/v1/settings', config)
            .then((response) => {
                dispatch({
                    type: SAVE_USER_APP_SETTINGS_SUCCESS,
                    payload: response.data.data
                })
            })
            .catch((error) => {
                console.log(error.response.data.error)
                if (error.response.data.error === 'record not found') {
                    axios.post('https://cgapp.clykk.com/um/clykk/ext/um/v1/settings',
                        {
                            "sms": true,
                            "media": false,
                            "calendar": false,
                            "contacts": false,
                            "calls": false,
                            "location": true
                        }, config)
                        .then((response) => {
                            dispatch({
                                type: SAVE_USER_APP_SETTINGS_SUCCESS,
                                payload: {
                                    "sms": true,
                                    "media": false,
                                    "calendar": false,
                                    "contacts": false,
                                    "calls": false,
                                    "location": true
                                }
                            })
                        }).catch((error) => {
                        console.log(error.message)
                    })
                }
            })

        dispatch(load_user_data(res.data.data.userId))

        setTimeout(() => {
            setValue(value + 2)
        }, 1000)

    } catch (err) {
        console.log(err.message)
        toast.error("Record not found", {duration: 1500})
        setOtpSent(false)
        dispatch({
            type: LOGIN_FAILED
        })
    }
}

export const update_account_settings = (settings) => async dispatch => {
    const config = Config()

    axios.patch('https://cgapp.clykk.com/um/clykk/ext/um/v1/settings', settings, config)
        .then(response => {
            dispatch({
                type: SAVE_USER_APP_SETTINGS_SUCCESS,
                payload: response.data.data
            })
        })
        .catch(error => {
            console.log(error.message)
        })
}

export const refresh_token = () => async dispatch => {
    const config = {
        header: {
            'Authorization': `Bearer ${localStorage.getItem('refresh_token')}`,
        }
    }
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/clykk/ext/um/v1/refresh-token`, config)
        dispatch({
            type: REFRESH_TOKEN_SUCCESS,
            payload: response.data
        })
    } catch (err) {
        console.log(err)
        dispatch({
            type: REFRESH_TOKEN_FAILED
        })
    }
}

export const Logout = () => dispatch => {
    try {
        dispatch({
            type: LOGOUT_SUCCESS
        })
    } catch (e) {
        dispatch({
            type: LOGOUT_FAILED
        })
    }
}

export const changeUserType = (type) => dispatch => {
    try {
        dispatch({
            type: CHANGE_USER_TYPE_SUCCESS,
            payload: type
        })
    } catch (e) {
        dispatch({
            type: CHANGE_USER_TYPE_FAILED
        })
    }
}

const raise_error = (err) => {
    let error = ''
    console.log(err)
    Object.keys(err.response.data).forEach((key, index) => {
        if (typeof err.response.data === 'object')
            error += err.response.data[key]
        else
            err.response.data[key].map(item => error += item)
    })
}
