import {useContext, useEffect, useState} from 'react';
import Footer from "../component/LandingPage/Footer";
import yellow_glitter from "../assets/images/yellow star.png";
import purple_glitter from "../assets/images/glitter.png";
import green_glitter from "../assets/images/greenstar.png";
import pink_glitter from "../assets/images/star.svg";
import JoinUsBannerProfessional from "../component/Professional/JoinUsBannerProfessional";
import Faq from "../component/LandingPage/FAQ";
import Config from "../Config";
import axios from "axios";
import {useSelector} from "react-redux";
import Categories from "../component/LandingPage/Categories";
import SocialsBanner from "../component/LandingPage/SocialsBanner";
import {Context} from "../Contexts/Context";
import {useHistory} from "react-router-dom";

function RegisterAsAProfessional({}) {
    const [offers, setOffers] = useState([])
    const data = useSelector(state => state.accounts.data)
    const location = useSelector(state => state.accounts.location)
    const isAuthenticated = useSelector(state => state.accounts.isAuthenticated)

    const faq = [
        {
            header: 'What does service include?',
            points: ['Buffing of the entire house floor', 'Deep cleaning of kitchen & all bathrooms', 'Dry vacuuming of all fabrics incl. sofa, carpet and mattress', 'Hard to reach incl. Fans, exhausts, cobweb, removal. etc.']
        },
        {
            header: 'What are the benefits of Deep Cleaning',
            points: ['Buffing of the entire floor for strain removal', 'Removal of embedded dust, food particles', 'Thorough cleaning of all corners']
        },
        {
            header: 'What Equipment Do We Use?',
            points: ['Disinfectent Chemicals', 'Floor disc machine']
        },
    ]

    useEffect(() => {
        const config = Config()
        let cityId = 27
        if (location.city === 'Hyderabad') {
            cityId = 31
        } else if (location.city === 'Pune') {
            cityId = 32
        } else if (location.city === 'Chennai') {
            cityId = 33
        } else if (location.city === 'Ahmedabad') {
            cityId = 34
        } else if (location.city === 'Coimbatore') {
            cityId = 35
        } else if (location.city === 'Mumbai') {
            cityId = 36
        } else if (location.city === 'Nagpur') {
            cityId = 37
        } else if (location.city === 'Visakhapatnam') {
            cityId = 38
        }

        axios.get(`https://cgapp.clykk.com/discount/api/v2/discount-management/city/${cityId}?default=true`, config)
            .then((response) => {
                setOffers(response.data ? response.data : [])
            })
    }, [location])

    const get_offers_slide = (slide, type) => {
        const types = type.split(',')
        let yes = false
        for (let i = 0; i < types.length; i++) {
            if (slide.discount === types[i]) {
                yes = true
                break
            }
        }
        if (!yes) {
            return false
        }
        if (!isAuthenticated) {
            // && Date.parse(slide.expireAt) >= Date.parse(get_date_format())
            return (slide.status === 'approved') && (slide.userID === 0)
        } else {
            return (slide.status === 'approved') && (slide.userID === 0 || slide.userID === data.userId)
        }
    }

    const small = true

    const {
        openCatModal,
        setCatOpenModal,
        // handleCategoryClick,
        setSelectCat,
        setSubCategory,
        categories,
        setCategories,
        setCategoriesT
    } = useContext(Context);

    useEffect(() => {
        const config = Config()
        axios.get(`https://cgapp.clykk.com/catalog/api/v2/catalog-manager/by-location/${location.city}`, config)
            .then(response => {
                setFilteredCategories(response.data ? response.data : [])
            })
            .catch(err => console.log('This one failed', err.message))
    }, [location])

    const [filteredCategories, setFilteredCategories] = useState([])

    const handleCategoryClick = (city, category_id) => {
        setSelectCat(filteredCategories.filter(item => item.category_id === category_id)[0])
        const config = Config()
        axios.get(`https://cgapp.clykk.com/catalog/api/v2/catalog-manager/by-location/${city}/categories/${category_id}`, config)
            .then(response => {
                setSubCategory(response.data ? response.data : [])
            })
            .catch(err => console.log(err.message))
    }
    const history = useHistory()
    const openSubCatPage = (category_id, subcategoryID) => {
        const cat_name = filteredCategories.filter(item => item.category_id === category_id)[0]
        history.push(`/sub-category-detail/${cat_name.category_name}/${category_id}/${subcategoryID}/`)
    }

    return (
        <div style={{position: 'relative'}}>
            <>
                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '0.5%', left: '70%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '8%', left: '87%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 10, top: '12%', left: '10%'}}/>


                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '15%', left: '5%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '20%', left: '55%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 15, top: '26%', left: '82%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 15, top: '35%', left: '85%'}}/>


                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 17, top: '39.5%', left: '63%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 20, top: '50%', left: '5%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 15, top: '53%', left: '95%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '65%', left: '90%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 15, top: '70%', left: '52%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 17, top: '80%', left: '69%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '90%', left: '87%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '95%', left: '5%'}}/>

            </>

            <Categories search={false} openCatModal={openCatModal} setCatOpenModal={setCatOpenModal}
                        handleCategoryClick={handleCategoryClick}
                        categories={categories} setCategories={setCategories}
                        filteredCategories={filteredCategories} setFilteredCategories={setFilteredCategories}
            />

            <JoinUsBannerProfessional/>

            <Faq faqNum={11} page={false} home={true}/>

            <SocialsBanner customer={false}/>

            <Footer/>
        </div>
    );
}

export default RegisterAsAProfessional;