import {AiFillQuestionCircle, AiFillStar, AiOutlineLeft, AiOutlineSearch, AiOutlineStar} from "react-icons/ai";
import clsx from "clsx";
import pin from "../../../assets/images/map-location pin.png";
import {BsThreeDotsVertical} from "react-icons/bs";
import {
    delete_user_address,
    set_first_user_location,
    update_user_address_locally,
    update_user_address_server
} from "../../../actions/accounts";
import React, {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {BiCurrentLocation} from "react-icons/bi";
import {useHistory} from "react-router-dom";
import {Context} from "../../../Contexts/Context";

const SavedLocations = ({
                            my_swiper,
                            setDefaultScreen,
                            location,
                            setUpdateId,
                            handleCloseModal,

                            set_edit_data,
                            setAddress,

                            regModal
                        }) => {

    const dispatch = useDispatch()

    const {setFaqNum, handleOpenFaqModal} = useContext(Context);

    const [defaultServiceAddress, setDefaultServiceAddress] = useState(null)

    const current_location = useSelector(state => state.accounts.location)
    let savedLocations = useSelector(state => state.accounts.address ? state.accounts.address : [])

    const [saved_locations, setSavedLocations] = useState(savedLocations)

    useEffect(() => {
        setSavedLocations(savedLocations)
    }, [savedLocations])

    const get_address = (address) => {
        if (address.substring().length < 30) {
            return address
        } else {
            return address.substring(0, 30) + '...'
        }
    }

    const set_default_service_address = (location) => {
        const body = {...location, defaultServiceAddress: true}
        dispatch(update_user_address_server(body, location.id))
    }

    const [filterText, setFilterText] = useState('')

    const filter_search = (e) => {
        setFilterText(e.target.value)
        if (e.target.value === '') {
            setSavedLocations(savedLocations)
        } else {
            let filteredAddress = []
            saved_locations.map((location) => {
                let pushed = false
                Object.keys(location).forEach(function (key) {
                    if (location[key].toString().toLowerCase().includes(e.target.value.toLowerCase()) && !pushed) {
                        filteredAddress.push(location)
                        pushed = true
                    }
                })
            })
            setSavedLocations(filteredAddress)
        }
    }

    const delete_location = (id) => {
        if (current_location.id === id) {
            dispatch(set_first_user_location())
        }
        dispatch(delete_user_address(id))
        setUpdateId(null)
    }

    return (
        <div style={{width: '100%'}}>
            {regModal &&
                <>
                    <div style={{textAlign: 'left'}}>
                        <p style={{fontSize: 12, fontWeight: 700, cursor: 'pointer'}}
                           onClick={() => {
                               setUpdateId(null)
                               my_swiper.slidePrev()
                               // setScreen(0)
                           }}>
                            <AiOutlineLeft
                                style={{margin: '0 15px', fontSize: 14, fontWeight: 700}}/>
                            Select Location
                        </p>
                    </div>
                    <hr className={'hr-line'}/>
                </>
            }

            <div className={'location-search'}>
                <div className={'location-search-container'}>
                    <p className={'filter-location-text'}
                    >Filter Location</p>
                    <div style={{display: 'flex'}}>
                        <AiOutlineSearch className={'location-search-icon'}/>
                        <input
                            style={{backgroundColor: !regModal && 'white'}}
                            value={filterText}
                            onChange={filter_search}
                            className={'form-control location-search-input'}
                            placeholder={'Filter'}
                        />
                    </div>
                </div>
                <p
                    style={{margin: 'auto', fontSize: 12, fontWeight: 400, cursor: 'pointer', color: '#5A6167'}}
                    onClick={() => {
                        setFilterText('')
                        setSavedLocations(savedLocations)
                    }}>Clear</p>
            </div>

            <div className={'choose-map-location'}
                 onClick={() => {
                     setDefaultScreen(0)
                 }}
            >
                <div className={'choose-map-location-container'}>
                    <BiCurrentLocation className={'choose-map-location-icon'}/>
                    <div style={{textAlign: 'left'}}>
                        <p className={'choose-map-location-text'}>Choose on Map</p>
                        <p className={'choose-map-location-support-text'}>Using GPS</p>
                    </div>
                </div>
            </div>

            <div className={'map-modal-container'}>
                <div style={{margin: '10px 0', textAlign: 'left'}}>
                    <div style={{display: 'flex'}}>
                        <p className={'saved-location-modal-subheading'}>Saved Locations</p>
                        <AiFillQuestionCircle
                            onClick={() => {
                                setFaqNum(13)
                                handleOpenFaqModal()
                            }}
                            className={'faq-icon-saved-location-modal'}/>
                    </div>
                    <div className={'address-container'}>
                        {saved_locations.map((item) => {
                            return (
                                <div
                                    className={clsx('address-detail-container',
                                        item.id === location.id &&
                                        !regModal ? 'address-detail-container-active' :
                                            item.id === location.id &&
                                            regModal && 'address-detail-modal-container-active'
                                    )}
                                    style={{display: 'flex'}}>
                                    <div className={'address-detail-info-container'}
                                         onClick={() => setAddress(item)}
                                    >
                                        <img src={pin}/>
                                        <div style={{margin: 'auto auto auto 10px'}}>
                                            <p className={'address-line1'}>
                                                {get_address(item.locationText)}
                                            </p>
                                            <p className={'address-line2'}>
                                                {get_address(item.line2)}
                                            </p>
                                        </div>
                                    </div>
                                    {item.id === defaultServiceAddress ?
                                        <AiFillStar className={'locations-star star-selected'} style={{
                                            margin: 'auto 1px auto 0',
                                            fontSize: 28,
                                            cursor: 'pointer',
                                            color: 'gold'
                                        }} onClick={() => {
                                            // setDefaultServiceAddress(null)
                                        }}/>
                                        :
                                        <AiOutlineStar className={'locations-star'}
                                                       onClick={() => {
                                                           // set_default_service_address(item)
                                                       }}/>
                                    }
                                    <div className="dropdown" style={{margin: 'auto 0', padding: "0 0 0 10px"}}>
                                        <BsThreeDotsVertical className={'location-option'}
                                                             type="button" id="dropdownMenuButton1"
                                                             data-bs-toggle="dropdown" aria-expanded="false"/>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><a className="dropdown-item"
                                                   onClick={() => {
                                                       set_edit_data(item)
                                                   }}
                                            >Edit</a></li>
                                            <li><a className="dropdown-item"
                                                   onClick={() => delete_location(item.id)}
                                            >Delete</a></li>
                                        </ul>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

        </div>
    );
}

export default SavedLocations;