import axios from "axios";
import Config from "./Config";
import {load_user_alert} from "./actions/accounts";

function AddAlert(dispatch, userId, item, status, sub_type, refrence_id) {
    const config = Config()

    let formData = new FormData();

    formData.append('alert_type', status);
    formData.append('sub_type', sub_type)
    formData.append('refrence_id', refrence_id)
    formData.append('title', item.packageName)
    formData.append('description', item.shortDescription)
    formData.append('alert_image', item.packageImage)
    formData.append('status', 0)

    axios.post('https://clapp.clykk.com//customer_alerts/public/index.php/api/addalert', formData, config)
        .then(response => {
            dispatch(load_user_alert(userId))
        })
}

export default AddAlert;