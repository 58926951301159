export const taxCodes = {
    '28': {
        code: '998533',
        percentage: 0.18
    },
    '29': {
        code: '998533',
        percentage: 0.18
    },
    '30': {
        code: '998533',
        percentage: 0.18
    },
    '33': {
        code: '998533',
        percentage: 0.18
    },
    '34': {
        code: '998533',
        percentage: 0.18
    },
    '35': {
        code: '998533',
        percentage: 0.18
    },
    '31': {
        code: '998531',
        percentage: 0.18
    },
    '32': {
        code: '998531',
        percentage: 0.18
    },
    '36': {
        code: '998531',
        percentage: 0.18
    },
    '37': {
        code: '998531',
        percentage: 0.18
    },
    '38': {
        code: '998531',
        percentage: 0.18
    },
    '39': {
        code: '996821',
        percentage: 0.18
    },
    '40': {
        code: '996821',
        percentage: 0.18
    },
    '41': {
        code: '996821',
        percentage: 0.18
    },
    '42': {
        code: '996821',
        percentage: 0.18
    },
    '92': {
        code: '996821',
        percentage: 0.18
    },
    '93': {
        code: '996821',
        percentage: 0.18
    },
    '58': {
        code: '999721',
        percentage: 0.12
    },
    '59': {
        code: '999721',
        percentage: 0.12
    },
    '60': {
        code: '999721',
        percentage: 0.12
    },
    '61': {
        code: '999721',
        percentage: 0.12
    },
    '62': {
        code: '999721',
        percentage: 0.12
    },
    '63': {
        code: '999721',
        percentage: 0.12
    },
    '64': {
        code: '999721',
        percentage: 0.12
    },
    '65': {
        code: '998299',
        percentage: 0.18
    },
    '72': {
        code: '998299',
        percentage: 0.18
    },
    '73': {
        code: '998299',
        percentage: 0.18
    },
    '74': {
        code: '998299',
        percentage: 0.18
    },
    '75': {
        code: '998299',
        percentage: 0.18
    },
    '76': {
        code: '998299',
        percentage: 0.18
    },
    '77': {
        code: '998299',
        percentage: 0.18
    },
    '78': {
        code: '998299',
        percentage: 0.18
    },
    '79': {
        code: '998299',
        percentage: 0.18
    },
    '80': {
        code: '998299',
        percentage: 0.18
    },
    '81': {
        code: '998299',
        percentage: 0.18
    },
    '82': {
        code: '998299',
        percentage: 0.18
    },
    '83': {
        code: '998299',
        percentage: 0.18
    },
    '84': {
        code: '998299',
        percentage: 0.18
    },
    '87': {
        code: '998299',
        percentage: 0.18
    },
    '88': {
        code: '998299',
        percentage: 0.18
    },
    '89': {
        code: '998299',
        percentage: 0.18
    },
    '67': {
        code: '999314',
        percentage: 0.18
    },
    '68': {
        code: '999314',
        percentage: 0.18
    },
    '69': {
        code: '999314',
        percentage: 0.18
    },
    '70': {
        code: '999314',
        percentage: 0.18
    },
    '71': {
        code: '999314',
        percentage: 0.18
    },
}