import clsx from "clsx";
import {useSelector} from "react-redux";


const Categories = ({
                        openCatModal, setCatOpenModal, handleCategoryClick,
                        categories, categoriesLoading = false
                    }) => {

    const location = useSelector(state => state.accounts.location)

    return (
        <div className={clsx('container my-2')}>

            <div style={{display: 'flex'}}>
                <p style={{fontSize: 15, fontWeight: 600, color: '#424B5A'}}>Categories</p>
            </div>

            <div className="row">
                {categoriesLoading ?
                    [1, 2].map((category) => {
                        return (
                            <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-4 col-4 my-2">
                                <div className={'cat-img-container'}>
                                    <div style={{width: 70, height: 75, margin: 'auto', display: 'flex'}}>
                                        <div className="spinner-grow text-secondary lazy-loading" role="status"
                                             style={{margin: 'auto', width: '3rem', height: '3rem'}}>
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    :
                    categories.length ?
                        categories.map((category) => {
                            return (
                                <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-4 col-4 my-2">
                                    <div className={'cat-img-container'}
                                         onClick={() => {
                                             handleCategoryClick(location.city, category.category_id)
                                             setCatOpenModal(!openCatModal)
                                         }}>
                                        <img className={'category-img'} src={category.category_image}/>
                                    </div>
                                    <p className={'category-text'}>{category.category_name}</p>
                                </div>
                            )
                        })
                        :
                        <p>No Categories found !</p>
                }
            </div>

        </div>
    );
}

export default Categories;