import Navbar from "./Navbar";
import Hero from "./Hero";
import clsx from 'clsx'
import {useEffect, useState} from "react";
import Config from "../Config";
import axios from "axios";
import {useSelector} from "react-redux";

const Header = ({isMobile, setShowSidebar, handleOpenModal, handleLocationModalOpen, customer}) => {

    const [heroData, setHeroData] = useState(null)

    const location = useSelector(state => state.accounts.location)
    const userType = useSelector(state => state.accounts.userType)
    const access_token = useSelector(state => state.accounts.access_token)

    useEffect(() => {
        const config = Config()

        const date = new Date().toLocaleString().split(',')[0].split('/')

        const get_date = (date) => {
            return date.length > 1 ? date : '0' + date
        }

        // const url = `http://cgapp.clykk.com:8088/clykk/ext/hpi/v2/app/client/date/location/homepageimage/\?' +
        //     'client=service-provider&date=2021-05-15&cityLocation=Bengaluru`
        let url = ""
        if (userType === "Customer") {
            url = `https://cgapp.clykk.com/homepage/clykk/ext/hpi/v2/app/client/date/location/homepageimage/?client=customer&date=${date[2]}-${get_date(date[0])}-${get_date(date[1])}&cityLocation=${location.city}`
        } else if (userType === "Service Provider") {
            url = `https://cgapp.clykk.com/homepage/clykk/ext/hpi/v2/app/client/date/location/homepageimage/?client=service-provider&date=${date[2]}-${get_date(date[0])}-${get_date(date[1])}&cityLocation=${location.city}`
        }

        axios.get(url, config)
            .then(response => {
                setHeroData(response.data)
            })
            .catch(error => console.log('home page data also failing ', error.message))
    }, [userType, location, access_token])


    return (
        <div className={clsx('header')}
             style={{
                 backgroundImage: heroData && `url("${heroData.imageUrl}")`,
                 backgroundColor: "#F6F7F9"
             }}
        >

            <div className={clsx(!isMobile ? "container" : "header-container-sm", 'header-container')}>
                <Navbar setShowSidebar={setShowSidebar} handleOpenModal={handleOpenModal} customer={customer}/>
                <Hero handleLocationModalOpen={handleLocationModalOpen} handleOpenModal={handleOpenModal}
                      heroData={heroData} customer={customer}/>
            </div>
        </div>
    );
}

export default Header;


