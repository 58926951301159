import React, {useContext, useState} from 'react';
import {AiOutlineClose} from "react-icons/ai";
import {Context} from "../Contexts/Context";
import {update_account_settings} from "../actions/accounts";
import {useDispatch, useSelector} from "react-redux";

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}


function PermissionBlocker() {
    const dispatch = useDispatch()
    const {blockerType, handleCloseBlockerModal} = useContext(Context);

    const [modalStyle] = useState(getModalStyle);


    const account_settings = useSelector(state => state.accounts.account_settings)

    const update_settings = (key) => {
        dispatch(update_account_settings({...account_settings, [key]: true}))
        handleCloseBlockerModal()
    }

    return (
        <div style={modalStyle} className={'modal-blocker-paper'}>
            <div style={{display: 'flex'}}>
                <AiOutlineClose style={{marginLeft: "auto", cursor: "pointer"}} onClick={handleCloseBlockerModal}/>
            </div>

            {blockerType === 'media' &&
                <>
                    <p style={{textAlign: 'center', fontSize: 12, fontWeight: 400}}>"CLYKK" Would like to Access Photos,
                        Media Content and Files on your device.</p>

                    <p style={{textAlign: 'center', fontSize: 10, fontWeight: 400, marginTop: 10}}>These will be used to
                        setup your profile, and provide requirements.
                        Please Click <b>Allow</b></p>
                </>
            }
            {blockerType === 'calendar' &&
                <>
                    <p style={{textAlign: 'center', fontSize: 12, fontWeight: 400}}>"CLYKK" Would like to Access Your
                        Calender.</p>

                    <p style={{textAlign: 'center', fontSize: 10, fontWeight: 400, marginTop: 10}}>Please activate
                        Calendar in Account Settings. Please Click <b>Allow</b></p>
                </>
            }
            {blockerType === 'contacts' &&
                <>
                    <p style={{textAlign: 'center', fontSize: 12, fontWeight: 400}}>"CLYKK" Would like to Access Your
                        Contacts.</p>

                    <p style={{textAlign: 'center', fontSize: 10, fontWeight: 400, marginTop: 10}}>You can use the Share
                        & Earn capability with your Contacts. Please Click <b>Allow</b></p>
                </>
            }
            <div style={{display: 'flex', marginTop: 10}}>
                <button className={'btn btn-outline-danger block-allow'} onClick={handleCloseBlockerModal}>Don't Allow
                </button>
                <button className={'btn block-allow-accept block-allow'} onClick={() => {
                    update_settings(blockerType)
                }}>Allow
                </button>
            </div>

        </div>
    );
}

export default PermissionBlocker;