import usePlacesAutocomplete, {getGeocode, getLatLng} from "use-places-autocomplete";


const getAddressCity = (address, length, level) => {
    const findType = type => type.types[0] === level
    const location = address.map(obj => obj)
    const rr = location.filter(findType)[0]

    if (rr) {
        return (
            length === 'short'
                ? rr.short_name
                : rr.long_name
        )
    } else {
        return ''
    }
}

const Search = ({panTo, setServiceAddress, setHomeAddress, setUpdateId}) => {
    const {
        ready,
        value,
        suggestions: {status, data},
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({});

    const handleInput = (e) => {
        setValue(e.target.value);
    };

    const handleSelect = async (address) => {
        setValue(address, false);
        clearSuggestions();

        try {
            const results = await getGeocode({address});

            const {lat, lng} = await getLatLng(results[0]);

            const searched_address = results[0].formatted_address;
            const formatted_address = results[0].formatted_address.split(',')

            const address_components = results[0].address_components
            //
            const line1 = formatted_address[0]
            const line2 = searched_address

            const postal_code = getAddressCity(address_components, 'long', "postal_code")
            const city = getAddressCity(address_components, 'long', "locality")
            const state = getAddressCity(address_components, 'long', "administrative_area_level_1")
            const country = getAddressCity(address_components, 'long', "country")


            setServiceAddress((prev) => ({
                ...prev,
                line1: line1,
                line2: line2,
                latitude: lat,
                longitude: lng,
                city: city,
                state: state,
                country: country,
                postal_code: postal_code
            }))

            let landmark = ''
            let home_num = ''
            console.log(line2.split(','))
            if (line2.split(',').length > 2) {
                const arr = line2.split(',')
                home_num = arr[0]
                landmark = arr[1]
                // if (home_num.match(/\d+/g) === null) {
                //     home_num = 1
                // }
            }

            setHomeAddress((prev) => ({
                ...prev,
                address: line2,
                landmark: landmark,
                home_num: home_num
            }))

            panTo({lat, lng});
        } catch (error) {
            console.log("😱 Error: ", error);
        }
    };

    const handleChange = (value) => {
        handleSelect(value.description).then(() => {
        }).catch(() => {
        })
    }

    return (
        <div className="map-search">
            <input placeholder={'Search Location ...'}
                   value={value}
                   onChange={handleInput}
                   className={'form-control'}
                   style={{fontSize: 10, width: '100%'}}/>
            {status === 'OK' &&
                <div className={'places-list'}>
                    {data.map((item) => {
                        return (
                            <p style={{fontSize: 10}} onClick={() => handleChange(item)}>
                                {item.description}
                            </p>
                        )
                    })}
                </div>
            }
        </div>
    );
}

export default Search;
