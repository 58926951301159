import {Box} from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

export function is_number(num) {
    return !isNaN(parseFloat(num)) && isFinite(num);
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function return_city_id(city_name) {
    let cityId = 27
    if (city_name === 'Hyderabad') {
        cityId = 31
    } else if (city_name === 'Pune') {
        cityId = 32
    } else if (city_name === 'Chennai') {
        cityId = 33
    } else if (city_name === 'Ahmedabad') {
        cityId = 34
    } else if (city_name === 'Coimbatore') {
        cityId = 35
    } else if (city_name === 'Mumbai') {
        cityId = 36
    } else if (city_name === 'Nagpur') {
        cityId = 37
    } else if (city_name === 'Visakhapatnam') {
        cityId = 38
    }
    return cityId
}