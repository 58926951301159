import React, {useEffect, useState} from "react";
import {Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import "swiper/css/pagination";
import axios from "axios";
import clsx from "clsx";
import {AiOutlineLeft} from "react-icons/ai";
import {useDispatch, useSelector} from "react-redux";
import {update_user_preferences} from "../../actions/accounts";


const PreferencesTab = () => {
    const [my_swiper, set_my_swiper] = useState({});

    let _pref = useSelector(state => state.accounts.preferences)


    const [preferences, setPreferences] = useState(_pref)

    useEffect(() => {
        setPreferences(_pref)
    }, [_pref])

    const dispatch = useDispatch()
    const save_preferences = () => {
        let _options = []
        Object.keys(preferences).map((key, i) => {
            preferences[key].options.map((item) => {
                _options.push(item)
            })
        })

        dispatch(update_user_preferences({options: _options}))
    }

    const enable_item = (key, item) => {
        let options = preferences[key]['options']
        setPreferences({
            ...preferences,
            [key]: {
                ...preferences[key],
                options: options.map((option) => {
                    if (option === item)
                        option.selected = !option.selected
                    return option
                })
            }
        })
    }

    return (
        <>
            <Swiper
                pagination={{
                    clickable: false,
                }}
                onClick={() => {
                }}

                modules={[Pagination]}
                allowTouchMove={false}
                slidesPerView={1}
                className={'accounts-swiper'}
                onInit={(ev) => {
                    set_my_swiper(ev)
                }}>
                {
                    Object.keys(preferences).map((key, index) => {
                        return (
                            preferences[key]['enabled'] &&
                            <SwiperSlide>
                                <div className={'preferences-inner-container'}>
                                    <div className={'modal-top-nav'}
                                         onClick={() => {
                                             my_swiper.slidePrev()
                                         }}
                                    >
                                        {index !== 0 &&
                                        <AiOutlineLeft
                                            style={{margin: 'auto 15px auto 0', fontSize: 14, fontWeight: 700}}/>
                                        }
                                        <p style={{margin: 'auto auto auto 0', fontSize: 14, fontWeight: 700}}>Select
                                            Preferences</p>
                                    </div>
                                    <hr className={'hr-line'}/>
                                    <p style={{width: '100%'}}
                                       className={'consumer-process-modal-content consumer-process-modal-text'}
                                    >{preferences[key]['display_name']}</p>
                                    <p className={'preference-meta-text'} style={{marginBottom: 20}}
                                    >{preferences[key]['display_text']}</p>
                                    <div
                                        className="row consumer-process-modal-options">
                                        {preferences[key]['options'].map((item) => {
                                            return (
                                                <div
                                                    className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 my-2 preference-option-container">
                                                    <div
                                                        onClick={() => enable_item(key, item)}
                                                        className={clsx('shadow-sm options-container', item.selected && 'active-option')}>
                                                        <p className={'options-text'}>{item.display_text}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div style={{padding: '30px 0 40px'}}>
                                        {index < Object.keys(preferences).length - 1 ?
                                            <button
                                                onClick={() => {
                                                    my_swiper.slideNext()
                                                }}
                                                className={'p-2 btn form-control preferences-btn'}>Next
                                            </button>
                                            :
                                            <button
                                                onClick={() => {
                                                    save_preferences()
                                                }}
                                                className={'p-2 btn form-control preferences-btn'}>Save
                                            </button>
                                        }
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    })}
            </Swiper>
        </>
    );
}

export default PreferencesTab;
