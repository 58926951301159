import {useEffect, useState} from 'react';
import {Box, Modal, useTheme} from "@material-ui/core";
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Config from "../Config";
import axios from "axios";
import {AiOutlineClose} from "react-icons/ai";
import {load_user_cart} from "../actions/accounts";
import {toast} from "react-hot-toast";
import yellow_glitter from "../assets/images/yellow star.png";
import pink_glitter from "../assets/images/star.svg";
import green_glitter from "../assets/images/greenstar.png";
import purple_glitter from "../assets/images/glitter.png";
import AppBar from "../component/PackageDetails/AppBar";
import SwipeableViews from "react-swipeable-views";
import PackageDetailTabs from "../component/PackageDetails/tabs/PackageDetailTabs";
import PackageRequirementsTabs from "../component/PackageDetails/tabs/PackageRequirementsTabs";
import PackageCheckListTabs from "../component/PackageDetails/tabs/PackageCheckListTabs";
import PackageAddOnsTabs from "../component/PackageDetails/tabs/PackageAddOnsTabs";
import Footer from "../component/LandingPage/Footer";
import PropTypes from "prop-types";
import SubscriptionAddOnsTabs from "../component/UpdateSubscriptionDetail/SubscriptionAddOnsTabs";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            style={{backgroundColor: "white"}}
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <p>{children}</p>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

function UpdateSubscriptionDetails() {
    const theme = useTheme();
    const location = useLocation()
    const [value, setValue] = useState(0);

    const [changedFields, setChangedFields] = useState({})
    const [changed, setChanged] = useState({})
    const currency = useSelector(state => state.accounts.currency)

    useEffect(() => {
        setChangedFields(changed)
    }, [changed])

    const dispatch = useDispatch()

    const [modalStyle] = useState(getModalStyle);
    const [openWarningModal, setOpenWarningModal] = useState(false)
    const handleOpenWarningModal = () => {
        setOpenWarningModal(true)
    }
    const handleCloseWarningModal = () => {
        setOpenWarningModal(false)
    }

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const [data, setData] = useState(null)

    const scheduleId = location.pathname.split('/')[2]

    useEffect(() => {
        const config = Config()

        let tabData = {
            details: [], requirements: [], checklist: [], addOns: [], package: {}
        }
        axios.get(`https://cgapp.clykk.com/cart/api/v2/cart-management/subscription/${scheduleId}/schedule`, config)
            .then((response) => {
                response.data['qnA'].map((item) => {
                    if (item['questionType'] === 'detail')
                        tabData.details.push(item)
                    else if (item['questionType'] === 'requirements')
                        tabData.requirements.push(item)
                    else if (item['questionType'] === 'checklist')
                        tabData.checklist.push(item)
                })
                tabData.package = response.data.package
                response.data.addOns.map((item) => {
                    tabData.addOns.push({
                        id: item.ID,
                        basePrice: item.basePrice,
                        sellingPrice: item.sellingPrice,
                        addonName: item.packageName,
                        addonDescription: item.shortDescription,
                        addonImage: item.packageImage,
                        itemCount: item.itemCount
                    })
                })
            })
        setTimeout(() => {
            setData(tabData)
        }, 1000)

    }, [])

    const history = useHistory()

    const body = (
        <div style={modalStyle} className={'leave-page-paper'}>
            <div style={{display: 'flex'}}>
                <AiOutlineClose style={{marginLeft: "auto", cursor: "pointer", fontSize: 12}}
                                onClick={handleCloseWarningModal}/>
            </div>
            <p style={{color: 'red', fontSize: 14, fontWeight: 600}}>You have changes that have not been saved. Please
                save before proceeding</p>
            <div style={{width: '100%', display: 'flex', margin: "10px 0"}}>
                <button className={'btn leave-btn'}
                        onClick={handleCloseWarningModal}
                >Cancel
                </button>
                <button className={'btn stay-btn'}
                        onClick={handleCloseWarningModal}
                >Ok
                </button>
            </div>
        </div>
    )

    const update_package = () => {
        const config = Config()

        // data.addOns.map((item) => {
        //     axios.put(`http://cgapp.clykk.com/cart/api/v2/cart-management/cart/${cartID}/addon/${item.id}`
        //         , {
        //             "id": item.id,
        //             "packageName": item.addonName,
        //             "shortDescription": item.addonDescription,
        //             "packageimage": item.addonImage,
        //             "basePrice": item.basePrice,
        //             "sellingPrice": item.sellingPrice,
        //             "itemCount": item.itemCount
        //         }
        //         , config
        //     ).then(response => {
        //         console.log(response.data)
        //     })
        //         .catch(err => console.log(err.message))
        // })

        let allow = true

        // data.requirements.map(question => {
        //     question.answers.map(answer => {
        //         if (changedFields.hasOwnProperty(answer.id)) {
        //             if (changedFields[answer.id].original !== null && answer.customerAnswer === null) {
        //                 allow = false
        //             } else if (changedFields[answer.id].original === null && answer.customerAnswer !== null) {
        //                 allow = false
        //             } else {
        //                 if (changedFields[answer.id].original[0].answer !== answer.customerAnswer[0].answer) {
        //                     allow = false
        //                 }
        //             }
        //         }
        //     })
        // })

        for (const key in changedFields) {
            if (changedFields.hasOwnProperty(key)) {
                allow = false
                break;
            }
        }

        if (allow) {
            dispatch(load_user_cart())
            setTimeout(() => {
                history.push(`/order-subscription-detail/${scheduleId}/`)
            }, 1000)
        } else {
            handleOpenWarningModal()
        }
    }

    const onAnswerChange = (e, id) => {
        const check = {
            ...data,
            requirements: data.requirements.map(question => {
                question.answers.map(answer => {
                    if (answer.id === id) {
                        if (!changedFields.hasOwnProperty(id)) {
                            setChangedFields({...changedFields, [id]: {original: answer.customerAnswer, changed: true}})
                        }
                        if (e.target.value === '') {
                            answer['customerAnswer'] = null
                        } else {
                            answer['customerAnswer'] = [{answer: e.target.value}]
                        }
                    }
                })
                return question
            })
        }
        setData(check)
    }

    const customerAnswer = (id, images = false) => {
        const config = Config()

        data.requirements.map(question => {
            question.answers.map(answer => {
                if (answer.id === id) {
                    // axios.put(`http://cgapp.clykk.com/cart/api/v2/cart-management/cart/${cartID}/qna/${id}/`,
                    axios.put(`https://cgapp.clykk.com/cart/api/v2/cart-management/subscription/${scheduleId}/qna/${id}/`,
                        JSON.stringify(answer.customerAnswer),
                        config)
                        .then(response => {
                            let changed = {}
                            for (const key in changedFields) {
                                if (key != id) {
                                    changed[key] = changedFields[key]
                                }
                            }
                            setChangedFields(changed)
                            if (!images)
                                toast.success("Answer submitted successfully.", {duration: 1000})
                            else
                                toast.success("Images Uploaded successfully.", {duration: 1000})
                        })
                        .catch(error => {
                            console.log(error.message)
                            toast.error("Error please try again.", {duration: 1000})
                        })
                }
            })
        })
    }

    const [images, setImages] = useState([])
    const [newImages, setNewImages] = useState([])
    useEffect(() => {
        setImages([...images, ...newImages])

        const imageUrls = [...images, ...newImages]

        if (imageUrls.length > 0) {
            setData({
                ...data,
                requirements: data.requirements.map(question => {
                    question.answers.map(answer => {
                        if (answer.answerType === 'upload') {
                            if (!changedFields.hasOwnProperty(answer.id)) {
                                setChangedFields({
                                    ...changedFields,
                                    [answer.id]: {original: answer.customerAnswer, changed: true}
                                })
                            }
                            answer.customerAnswer = imageUrls
                        }
                    })
                    return question
                })
            })
        }
    }, [newImages])

    return (
        <div style={{position: "relative"}}>
            <>
                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '1%', left: '98%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 15, top: '10%', left: '10%'}}/>
                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '16%', left: '60%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '30%', left: '2%'}}/>


                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 12, top: '32%', left: '90%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '40%', left: '55%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '50%', left: '10%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 12, top: '65%', left: '5%'}}/>

                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 12, top: '70%', left: '89%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '80%', left: '51%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '97%', left: '5%'}}/>

            </>
            {data &&
                <>
                    <AppBar value={value} setValue={setValue} handleChangeIndex={handleChangeIndex}/>
                    <div className="container">
                        <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={value}
                            onChangeIndex={handleChangeIndex}
                        >
                            <TabPanel value={value} index={0} dir={theme.direction}>
                                <PackageDetailTabs data={data.details} add_update_cart={update_package} update={true}
                                                   loadedPackage={data.package} currency={currency}/>
                            </TabPanel>
                            <TabPanel value={value} index={1} dir={theme.direction}>
                                <PackageRequirementsTabs data={data} add_update_cart={update_package} update={true}
                                                         loadedPackage={data.package} onAnswerChange={onAnswerChange}
                                                         customerAnswer={customerAnswer}
                                                         changedFields={changedFields}
                                                         images={images} setImages={setImages}
                                                         newImages={newImages} setNewImages={setNewImages}/>
                            </TabPanel>
                            <TabPanel value={value} index={2} dir={theme.direction}>
                                <PackageCheckListTabs data={data.checklist} add_update_cart={update_package}
                                                      update={true}
                                                      loadedPackage={data.package}/>
                            </TabPanel>
                            <TabPanel value={value} index={3} dir={theme.direction}>
                                <SubscriptionAddOnsTabs
                                    data={data.addOns} setData={setData}
                                    add_update_cart={update_package}
                                    loadedPackage={data.package}
                                />
                            </TabPanel>
                        </SwipeableViews>
                    </div>
                </>
            }
            <Modal
                open={openWarningModal}
                onClose={handleCloseWarningModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
            <Footer/>
        </div>
    );
}

export default UpdateSubscriptionDetails;