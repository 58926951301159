import React, {useContext, useEffect, useState} from 'react';
import axios from "axios";
import yellow_glitter from "../assets/images/yellow star.png";
import pink_glitter from "../assets/images/star.svg";
import green_glitter from "../assets/images/greenstar.png";
import purple_glitter from "../assets/images/glitter.png";
import {AiFillQuestionCircle, AiOutlineArrowLeft} from "react-icons/ai";
import {useHistory, useLocation} from "react-router-dom";
import {DistanceMatrixService} from "@react-google-maps/api";
import BookingAddress from "../component/Booking/BookingAddress";
import clsx from "clsx";
import ReSubscriptionOrder from "../component/Booking/ReSubscriptionOrder";
import {toast} from "react-hot-toast";
import Config from "../Config";
import {useSelector} from "react-redux";
import edit_pen from "../assets/images/Editpen.png";
import {Modal} from "@material-ui/core";
import ProvideRequirementsModal from "../component/Booking/ProvideRequirementsModal";
import PackageAddOnSection from "../component/OrderSubscriptionDetail/PackageAddOnSection";
import logo from "../assets/images/logo.svg";
import {Context} from "../Contexts/Context";
import Footer from "../component/LandingPage/Footer";
import TransactionDetail from "../component/OrderSubscriptionDetail/TransactionDetail";
import {BsChevronRight} from "react-icons/bs";
import {numberWithCommas} from "../component/Utils";
import {Prompt} from 'react-router-dom';


function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

function OrderSubscriptionDetail() {

    const history = useHistory()

    const location = useLocation()

    const userData = useSelector(state => state.accounts.data)

    const {setFaqNum, handleOpenFaqModal} = useContext(Context);

    const [subscription, setSubscription] = useState(null);

    const [distance, setDistance] = useState(1)
    const [slide, setSlide] = useState(2)

    const currency = useSelector(state => state.accounts.currency)

    const [allowedTime, setAllowedTime] = useState([])
    const [changeSubscriptionData, setChangeSubscriptionData] = useState({
        subID: "",
        serviceTime: "",
        serviceDate: "",
    })
    const get_date_format = () => {
        const date = new Date()
        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()
        if (`${month}`.length > 2) {
            return `${year}-${month}-${day}`
        } else {
            return `${year}-0${month}-${day}`
        }
    }
    const change_in_schedule = (value, key) => {
        if (key === 'serviceDate') {
            const current_date = Date.parse(get_date_format())
            const date = Date.parse(value)
            if (current_date > date) {
                toast.error('You can not set a date before the current date', {duration: 3000})
                return
            }
        }
        setChangeSubscriptionData({...changeSubscriptionData, [key]: value})
    }
    const changeSubscription = () => {
        const config = Config()
        axios.put(`https://cgapp.clykk.com/cart/api/v2/cart-management/subscription/${changeSubscriptionData.subID}/schedule`, changeSubscriptionData, config)
            .then(response => {
                axios.patch(`https://cgapp.clykk.com/cart/api/v2/cart-management/subscription/${changeSubscriptionData.subID}/status`, {status: 'ReScheduled'}, config)

                setSubscription(response.data)
                setSlide(2)
            })
            .catch(error => console.log(error.message))
    }

    const format_date = (date_str) => {
        const date = new Date(date_str)
        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()
        if (`${month}`.length > 2) {
            return `${year}-${month}-${day}`
        } else {
            return `${year}-0${month}-${day}`
        }
    }

    const scheduleId = location.pathname.split('/')[2]
    useEffect(() => {
        const config = Config()
        axios.get(`https://cgapp.clykk.com/cart/api/v2/cart-management/subscription/${scheduleId}/schedule`, config)
            .then(response => {
                setSubscription(response.data)
                setAddOnBaseAmounts(response.data)
            })
            .catch(error => {
                console.log(error.message)
            })
    }, [])

    const calculateServiceTime = (openTime, closeTime, ServiceDuration) => {
        const openTimeInt = parseInt(openTime.substring(0, 2))
        const closeTimeInt = parseInt(closeTime.substring(0, 2))
        const openTimeSecInt = parseInt(openTime.substring(3, 5))

        let times = []
        for (let i = openTimeInt; i <= closeTimeInt; i = i + 1) {
            if (i === openTimeInt && openTimeSecInt > 30) {

            } else if (i === openTimeInt && openTimeSecInt < 30) {
                times.push(`${i}:30`)
            } else {
                times.push(`${i}:00`)
                times.push(`${i}:30`)
            }
        }
        setAllowedTime(times)
    }

    useEffect(() => {
        if (subscription !== null) {
            const config = Config()
            axios.get(`https://cgapp.clykk.com/routing/api/v2/ext/route-setting/routes?cityID=27&ruleID=${subscription.package.serviceRuleID}&subcategoryID=${subscription.package.subcategoryID}&date=${changeSubscriptionData.serviceDate}`, config)
                .then(resp => {
                    const data = resp.data
                    const openTime = data.ServiceTime.substring(11, 16)
                    const closeTime = data.LastServiceTime.substring(11, 16)

                    if (changeSubscriptionData.serviceDate === data.ServiceTime.substring(0, 10)) {
                        calculateServiceTime(openTime, closeTime, parseInt(data.ServiceDuration))
                    } else {
                        toast.error('No service available on this date. Please another date')
                        setAllowedTime([])
                    }
                })
                .catch(err => console.log(err.message))
        }
    }, [changeSubscriptionData.serviceDate])

    const [openModal, setOpenModal] = useState(false)
    const handleOpenModal = () => {
        setOpenModal(true)
    }
    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const [requirementsComplete, setRequirementsComplete] = useState(false)
    useEffect(() => {
        if (subscription !== null) {
            let complete = true

            subscription.qnA.map((question) => {
                if (question.questionType === "requirements") {
                    question.answers.map((answer) => {
                        if (answer.answerType === 'text' && answer.customerAnswer === null) {
                            complete = false
                        }
                    })
                }
            })

            setRequirementsComplete(complete)
        }
    }, [subscription])

    const [prices, setPrices] = useState({
        subTotal: 0,
        saved: 0
    })
    const [addOnActive, setAddOnActive] = useState(false)

    const addOnsApiUpdate = () => {
        const config = Config()
        subscription.addOns.map((addOn) => {
            axios.put(`https://cgapp.clykk.com/cart/api/v2/cart-management/subscription/${scheduleId}/extendaddon/${addOn.ID}`,
                {...addOn, itemCount: addOn.itemCount},
                config
            )
        })
        setAddOnActive(false)
    }

    const [baseAmounts, setBaseAmounts] = useState({})

    const setAddOnBaseAmounts = (subscription) => {
        let _baseAmount = {}
        subscription.addOns.map((addOn) => {
            _baseAmount[addOn.ID] = addOn.itemCount
        })
        setBaseAmounts(_baseAmount)
    }

    const increase_decrease_add_ons = (item, symbol) => {
        let _addOnActive = false

        const addOns = subscription.addOns.map((addOn) => {
            if (item.ID === addOn.ID) {
                if (symbol === '+') {
                    setPrices({
                        subTotal: prices.subTotal + item.sellingPrice,
                        saved: prices.saved + (item.basePrice - item.sellingPrice)
                    })
                    addOn = {...addOn, itemCount: addOn.itemCount + 1}

                } else {
                    if (addOn.itemCount > 0 && addOn.itemCount > baseAmounts[addOn.ID]) {
                        setPrices({
                            subTotal: prices.subTotal - item.sellingPrice,
                            saved: prices.saved - (item.basePrice - item.sellingPrice)
                        })
                        addOn = {...addOn, itemCount: addOn.itemCount - 1}
                    }
                }
            }

            if (!_addOnActive) {
                _addOnActive = addOn.itemCount !== baseAmounts[addOn.ID]
            }

            return addOn
        })
        setSubscription((prev) => {
            return {...prev, addOns: addOns}
        })
        setAddOnActive(_addOnActive)
    }

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function displayRazorpay() {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        const config = Config()
        const result = await axios.post(`https://cgapp.clykk.com/cart/api/v2/cart-management/cart/${subscription.cartID}/order/book`,
            {
                "total": parseFloat(prices.subTotal),
                "payableAmount": prices.subTotal * 100,
                "amountBeforeTax": 0,
                "taxAmount": 0,
                "paymentType": "onetime",
                "description": 'additional-services'
            },
            config
        )

        const {payableAmount, order_id} = result.data;

        const options = {
            key: "rzp_live_YdTGPolg4K9L7h", // Enter the Key ID generated from the Dashboard
            amount: payableAmount,
            currency: "INR",
            name: "CLYKK.",
            description: "CLYKK",
            image: {logo},
            order_id: order_id,
            handler: async function (response) {
                const data = {
                    orderCreationId: order_id,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                    "description": 'additional-services'
                };
                axios.put(`https://cgapp.clykk.com/cart/api/v2/cart-management/subscription/${scheduleId}/addpayment`,
                    {
                        "total": parseFloat(prices.subTotal),
                        "payableAmount": parseFloat(prices.subTotal),
                        "amountBeforeTax": 0,
                        "taxAmount": 0,
                        "transactionID": data.razorpayPaymentId,
                        "paymentType": "onetime",
                        "description": 'additional-services'
                    }, config)
                    .then(response => {
                        toast.success('Payment Successful')
                        addOnsApiUpdate()
                        setSubscription({...subscription, transaction: response.data.transaction})
                        setAddOnBaseAmounts(subscription)
                    })
                    .catch(err => console.log(err.message))

            },
            prefill: {
                name: userData.firstName,
                email: userData.email,
                contact: userData.mobile,
            },
            notes: {
                address: "",
            },
            theme: {
                color: "#61dafb",
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    const [selectedTransaction, setSelectedTransaction] = useState(null)
    const [transactionDetailModal, setTransactionDetailModal] = useState(false)

    const openTransactionDetailModal = () => {
        setTransactionDetailModal(true)
    }

    const closeTransactionDetailModal = () => {
        setTransactionDetailModal(false)
    }

    const [modalStyle] = useState(getModalStyle);

    const [displayPopUp, setDisplayPopUp] = useState(false)

    return (
        <div style={{position: 'relative'}}>
            <Modal
                open={displayPopUp}
                onClose={() => {
                    setDisplayPopUp(false)
                }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div style={modalStyle} className={'popup-modal'}>
                    <p>Would you like to confirm adding the additional services, You will be taken to a payment screen
                        to pay for the services.</p>
                    Button
                </div>

            </Modal>
            <>
                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 12, top: '5%', left: '70%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 12, top: '10%', left: '10%'}}/>
                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 10, top: '12%', left: '60%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 10, top: '31%', left: '20%'}}/>


                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 12, top: '32%', left: '65%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 10, top: '40%', left: '55%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 10, top: '50%', left: '70%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '60%', left: '82%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '70%', left: '12%'}}/>

                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 12, top: '75%', left: '65%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 12, top: '80%', left: '49%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '84%', left: '15%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '97%', left: '87%'}}/>
            </>

            <div className="container mt-3 mb-3 ">
                <div style={{display: 'flex'}}>
                    <AiOutlineArrowLeft onClick={() => {
                        history.push(`/service-execution/${scheduleId}/`)
                    }} style={{margin: "auto 10px auto 0", cursor: 'pointer'}}/>
                    <p className={'booking-heading'}>Update Subscription
                        <span style={{fontSize: 12, marginLeft: 10}}>{scheduleId}</span>
                    </p>
                    <AiFillQuestionCircle
                        className={'faq-icon-selection-location booking-faq'}
                        onClick={() => {
                            setFaqNum(26)
                            handleOpenFaqModal()
                        }}/>
                </div>
            </div>

            {subscription !== null &&
                <>
                    <div className={'container'}>
                        {
                            subscription.package.serviceRuleID !== 1 &&
                            <DistanceMatrixService
                                options={{
                                    destinations: [{
                                        lng: subscription.serviceAddress.longitude,
                                        lat: subscription.serviceAddress.latitude
                                    }],
                                    origins: [{
                                        lng: subscription.deliveryAddress.longitude,
                                        lat: subscription.deliveryAddress.latitude
                                    }],
                                    travelMode: "DRIVING",
                                }}
                                callback={(res) => {
                                    try {
                                        const distance = res.rows[0].elements[0].distance.value
                                        if (Math.round(distance / 1000) > 0) {
                                            setDistance(Math.round(distance / 1000))
                                        } else {
                                            setDistance(1)
                                        }
                                    } catch {
                                        setDistance(1)
                                    }
                                }}
                            />
                        }
                        <img src={subscription.package.packageImage}
                             className={'w-100 booking-main-img'}/>

                        <div className="row mt-4 mb-5">

                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2">

                                {subscription.package.serviceRuleID === 1 &&
                                    <BookingAddress
                                        cartItem={subscription} setCartItem={setSubscription}
                                        addressHeading={'Service Address'}
                                        addressType={'service'} detail={false}/>
                                }
                                {subscription.package.serviceRuleID === 2 &&
                                    <>
                                        <BookingAddress
                                            cartItem={subscription} setCartItem={setSubscription}
                                            addressHeading={'Your Address'}
                                            addressType={'service'} detail={false}/>
                                        <BookingAddress
                                            cartItem={subscription} setCartItem={setSubscription}
                                            addressHeading={'Drop Location'}
                                            addressType={'delivery'} detail={false}/>
                                    </>
                                }
                                {subscription.package.serviceRuleID === 3 &&
                                    <>
                                        <BookingAddress
                                            cartItem={subscription} setCartItem={setSubscription}
                                            addressHeading={'Customer Address'}
                                            addressType={'service'} detail={false}/>
                                    </>
                                }
                                {subscription.package.serviceRuleID === 4 &&
                                    <>
                                        <BookingAddress
                                            cartItem={subscription} setCartItem={setSubscription}
                                            addressHeading={'Pickup Address'}
                                            addressType={'service'} detail={false}/>
                                        <BookingAddress
                                            cartItem={subscription} setCartItem={setSubscription}
                                            addressHeading={'Delivery Address'}
                                            addressType={'delivery'} detail={false}/>
                                    </>
                                }

                                <div style={{width: '100%'}}>

                                    <div style={{display: 'flex', marginBottom: 10}}>
                                        <u><p
                                            onClick={() => history.push(`/update-subscription-detail/${subscription.id}/${subscription.cartID}`)}
                                            className={'booking-packing-heading'}>Package</p></u>
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        <div style={{margin: 'auto 10px auto 0'}}>
                                            <p className={'booking-package-name'}>{subscription.package.packageName}
                                                {subscription.package.serviceRuleID !== 1 &&
                                                    <span>
                                                [ 0 - {distance} km]
                                        </span>
                                                }
                                            </p>
                                        </div>
                                        <div style={{margin: 'auto 0 auto auto'}}>
                                            {subscription.package.serviceRuleID !== 1 ?
                                                <>
                                                    <p className={'booking-base-price'}>
                                                        <del>{currency}{numberWithCommas(parseFloat(subscription.package.basePrice + subscription.package.sellingPrice * distance).toFixed(2))}</del>
                                                    </p>
                                                    <p className={'booking-selling-price'}>
                                                        {currency}{numberWithCommas((parseFloat(subscription.package.basePrice) / 2 + subscription.package.sellingPrice * distance).toFixed(2))}
                                                    </p>
                                                </>
                                                :
                                                <>
                                                    <p className={'booking-base-price'}>
                                                        <del>{currency}{numberWithCommas(parseFloat(subscription.package.basePrice).toFixed(2))}</del>
                                                    </p>
                                                    <p className={'booking-selling-price'}>{currency}{numberWithCommas(parseFloat(subscription.package.sellingPrice).toFixed(2))}</p>
                                                </>
                                            }
                                        </div>
                                    </div>


                                    <PackageAddOnSection subscription={subscription}
                                                         increase_decrease_add_ons={increase_decrease_add_ons}/>

                                    <div style={{display: 'flex'}}>
                                        <p className={'provide-requirement-link'}
                                           onClick={handleOpenModal}
                                           style={{
                                               marginRight: 'auto',
                                               fontSize: 12,
                                               fontWeight: 700,
                                               cursor: 'pointer'
                                           }}>
                                            Provide Requirements
                                        </p>

                                        <button
                                            onClick={handleOpenModal}
                                            className={clsx('btn btn-provide-requirement', requirementsComplete ? 'complete-status' : 'incomplete-status')}>
                                            {requirementsComplete ? "Complete" : "Incomplete"}
                                        </button>
                                    </div>

                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                {slide === 1 &&
                                    <ReSubscriptionOrder
                                        changeSubscriptionData={changeSubscriptionData}
                                        allowedTime={allowedTime}
                                        change_in_schedule={change_in_schedule}
                                        changeSubscription={changeSubscription}
                                    />
                                }
                                {slide === 2 &&
                                    <div style={{width: '100%'}}>
                                        <div style={{display: 'flex'}}>
                                            <p
                                                style={{fontSize: 14, fontWeight: 600, margin: "auto auto auto 0"}}>
                                                Service Date - Time
                                            </p>
                                        </div>

                                        <div style={{padding: 10, backgroundColor: "#F6F7F9", marginBottom: 10}}>
                                            <div className="row g-0 mb-2">
                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
                                                    <p className={'schedule-info-text'}>Date</p>
                                                    <p className={'schedule-info-detail'}>{subscription.serviceDate}</p>
                                                </div>
                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
                                                    <p className={'schedule-info-text'}>Time</p>
                                                    <p className={'schedule-info-detail'}>{subscription.serviceTime}</p>
                                                </div>
                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
                                                    <p className={'schedule-info-text'}>Subscription</p>
                                                    <p className={'schedule-info-detail'}>
                                                        {subscription.subscriptionFrequency}
                                                    </p>
                                                </div>
                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
                                                    <p className={'schedule-info-text'}>Free Re-schedule</p>
                                                    <div style={{display: 'flex'}}>
                                                        <p className={'schedule-info-detail'}
                                                           style={{margin: "auto auto auto 0"}}>
                                                            {(subscription.reschedCount === 0 && subscription.status === 'Scheduled') ?
                                                                'Available ' :
                                                                'Not Available'
                                                            }
                                                        </p>
                                                        {subscription.reschedCount === 0 &&
                                                            <img src={edit_pen} className={'booking-address-icon'}
                                                                 style={{margin: 'auto 0', fontSize: 12}}
                                                                 onClick={() => {
                                                                     setChangeSubscriptionData({
                                                                         subID: subscription.id,
                                                                         serviceDate: format_date(subscription.serviceDate)
                                                                     })
                                                                     setSlide(1)
                                                                 }}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <p style={{fontSize: 14, fontWeight: 600}}>Transaction</p>
                                        <div style={{padding: 10, backgroundColor: "#F6F7F9", marginBottom: 10}}>
                                            {subscription.transaction.map((transaction) => {
                                                return (
                                                    <div className="row g-0 mb-2"
                                                         onClick={() => {
                                                             setSelectedTransaction(transaction)
                                                             openTransactionDetailModal()
                                                         }}>
                                                        <div
                                                            className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4">
                                                            <p className={'schedule-info-text'}>Transaction ID</p>
                                                            <p className={'schedule-info-detail'}>{transaction.transactionID}</p>
                                                        </div>
                                                        <div
                                                            className="col-xl-2 col-lg-2 col-md-2 col-sm-3 col-xs-3 col-3">
                                                            <p className={'schedule-info-text'}>Paid Amount</p>
                                                            <p className={'schedule-info-detail'}>{parseFloat(transaction.payableAmount).toFixed(2)}</p>
                                                        </div>
                                                        <div
                                                            className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2">
                                                            <p className={'schedule-info-text'}>Date</p>
                                                            <p className={'schedule-info-detail'}>
                                                                {transaction.createdAt.substring(0, 10)}
                                                            </p>
                                                        </div>
                                                        <div
                                                            className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2">
                                                            <p className={'schedule-info-text'}>Description</p>
                                                            <p className={'schedule-info-detail'}
                                                               style={{textTransform: "capitalize"}}
                                                            >
                                                                {transaction.description}
                                                            </p>
                                                        </div>
                                                        <div
                                                            className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-1 col-1"
                                                            style={{display: 'flex'}}>
                                                            <BsChevronRight style={{margin: "auto"}}
                                                                            className={'cart-right-arrow'}/>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>

                                        {addOnActive &&
                                            <>
                                                <p style={{fontSize: 14, fontWeight: 600}}>New Payment</p>
                                                <div
                                                    style={{padding: 10, backgroundColor: "#F6F7F9", marginBottom: 10}}>
                                                    <div style={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        margin: '10px 0 10px 0'
                                                    }}>
                                                        <p className={'total-booking'}>Sub Total</p>
                                                        <p className={'total-booking-text'}>{currency}{numberWithCommas(prices.subTotal)}</p>
                                                    </div>
                                                    <div style={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        margin: '10px 0 10px 0'
                                                    }}>
                                                        <p className={'total-booking'}>Payable Amount</p>
                                                        <p className={'total-booking-text'}>{currency}{numberWithCommas(prices.subTotal)}</p>
                                                    </div>
                                                    <div style={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        margin: '10px 0 10px 0'
                                                    }}>
                                                        <p className={'total-booking'}>Payment Method</p>
                                                        <p className={'total-booking-text'}>RazorPay</p>
                                                    </div>
                                                </div>
                                                <div className={'row my-4'}>
                                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <div className={'proceed-booking-btn'}
                                                             onClick={displayRazorpay}
                                                        >
                                                            <div style={{margin: 'auto auto auto 0'}}>
                                                                <p style={{fontSize: 12}}>{currency} {numberWithCommas(prices.subTotal)}</p>
                                                                <p className={'total-saving-text'}>You are
                                                                    saving {currency}{numberWithCommas(prices.saved)}</p>
                                                            </div>
                                                            <p style={{fontSize: 12, margin: 'auto 0'}}>Proceed to
                                                                RazorPay</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }

                                    </div>
                                }
                            </div>

                        </div>

                    </div>
                </>
            }

            {subscription !== null &&
                <Modal
                    open={openModal}
                    onClose={() => {
                    }}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <ProvideRequirementsModal cartItem={subscription} setCartItem={setSubscription}
                                              handleCloseModal={handleCloseModal}
                                              booked={true}
                    />
                </Modal>
            }
            {subscription !== null &&
                <Modal
                    open={transactionDetailModal}
                    onClose={closeTransactionDetailModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <TransactionDetail
                        subcategoryID={subscription.package.subcategoryID}
                        sellingPrice={subscription.package.serviceRuleID !== 1 ? parseFloat(subscription.package.basePrice) / 2 + subscription.package.sellingPrice * distance : parseFloat(subscription.package.sellingPrice)}
                        cartID={subscription.cartID} selectedTransaction={selectedTransaction}
                        closeTransactionDetailModal={closeTransactionDetailModal}/>

                </Modal>
            }
            <Footer/>

        </div>
    );
}

export default OrderSubscriptionDetail;