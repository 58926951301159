import Footer from "../component/LandingPage/Footer";
import {Box, Tab, Tabs, useTheme} from "@material-ui/core";
import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import chat from '../assets/images/chat.png';
import {useHistory} from "react-router-dom";
import clsx from "clsx";
import pin from "../assets/images/pin copy 6.png";
import yellow_glitter from "../assets/images/yellow star.png";
import pink_glitter from "../assets/images/star.svg";
import purple_glitter from "../assets/images/glitter.png";
import green_glitter from "../assets/images/greenstar.png";
import {useSelector} from "react-redux";
import {BsChevronRight, BsThreeDotsVertical} from "react-icons/bs";
import Config from "../Config";
import axios from "axios";
import {AiFillQuestionCircle} from "react-icons/ai";
import {Context} from "../Contexts/Context";
import {atcb_action} from "add-to-calendar-button";
import {numberWithCommas} from "../component/Utils";

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            style={{backgroundColor: "white"}}
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <p>{children}</p>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const OrdersPage = () => {
    const {handleOpenBlockerModal, setBlockerType} = useContext(Context);
    const account_settings = useSelector(state => state.accounts.account_settings)
    const [orders, setOrders] = useState([])

    const {setFaqNum, handleOpenFaqModal} = useContext(Context);

    const theme = useTheme();
    const [value, setValue] = useState(0);
    const handleChangeIndex = (index) => {
        setValue(index);
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const history = useHistory()
    const currency = useSelector(state => state.accounts.currency)

    useEffect(() => {
        const config = Config()
        axios.get(`https://cgapp.clykk.com/cart/api/v2/cart-management/cart/detailed`, config)
            .then(response => {
                if (response.data) {
                    console.log(response.data)
                    let _orders = []
                    response.data.map((order) => {
                        if (order.subscriptions !== null) {
                            order.subscriptions.map((subscription) => {
                                _orders.push(subscription)
                            })
                        }
                    })
                    _orders.sort(function (a, b) {
                        return new Date(a.serviceDate) - new Date(b.serviceDate);
                    })
                    setOrders(_orders)
                }
            })
            .catch(err => console.log(err.message))
    }, [])

    const reminder_func = (subscription) => {
        atcb_action({
            name: `CLYKK: ${subscription.package.packageName} - ${new Date()}`,
            startDate: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`,
            endDate: "",
            options: ['Google', 'Apple', 'Outlook.com'],
            description: `Setting a Order Reminder for ${subscription.package.packageName} from CLYKK.`,
            timeZone: "Asia/Kolkata",
            iCalFileName: "Reminder-Event",
        });
    }

    return (
        <div style={{position: 'relative'}}>
            <>
                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '5%', left: '70%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '10%', left: '10%'}}/>
                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 17, top: '15%', left: '60%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '25%', left: '90%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '30%', left: '20%'}}/>


                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '32%', left: '65%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 10, top: '40%', left: '55%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 17, top: '50%', left: '70%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '60%', left: '82%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '70%', left: '12%'}}/>

                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 17, top: '75%', left: '63%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '80%', left: '51%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 20, top: '85%', left: '87%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '90%', left: '52%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 17, top: '95%', left: '69%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '97%', left: '87%'}}/>
            </>
            <div className={'container my-5'}>
                <p style={{fontSize: 28, fontWeight: 700}}>My Orders</p>

                <div className={'tabs-lg'}>
                    <Tabs
                        value={value}
                        onChange={handleChange}>
                        <Tab label="Upcoming" {...a11yProps(0)} />
                        <Tab label="History" {...a11yProps(1)} />
                    </Tabs>
                </div>

                <div className={'tabs-sm mt-4'}>
                    <div onClick={() => {
                        handleChangeIndex(0)
                    }}
                         className={clsx('tab-container', value === 0 && 'cart-tab-active')}>
                        <p>Upcoming</p>
                    </div>
                    <div
                        onClick={() => {
                            handleChangeIndex(1)
                        }}
                        className={clsx('tab-container', value === 1 && 'cart-tab-active')}>
                        <p>History</p>
                    </div>
                </div>

                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    <TabPanel value={value} index={0} dir={theme.direction}>

                        <div style={{display: 'flex', marginBottom: 10}}>
                            <p style={{fontSize: 15, fontWeight: 600}}>Pending</p>
                            <AiFillQuestionCircle
                                className={'faq-icon-selection-location'}
                                style={{margin: 'auto 0 auto auto', fontSize: 22}}
                                onClick={() => {
                                    setFaqNum(35)
                                    handleOpenFaqModal()
                                }}/>
                        </div>

                        {
                            orders.map((subscription) => {
                                return (
                                    subscription.status !== 'Completed' &&
                                    <div className={'cart-container'}>
                                        <div style={{width: '100%'}}>
                                            <div style={{display: 'flex'}}>
                                                <div className={'package-image-container'}
                                                     style={{backgroundImage: `url(${subscription.package.packageImage})`}}>
                                                </div>
                                                <p className={'cart-package-name'}>{subscription.package.packageName}</p>
                                                <img className={'cart-chat-img'} src={chat}
                                                     onClick={() => {
                                                         history.push(`/chat/${subscription.id}/2`)
                                                     }}
                                                />
                                                <p className={'cart-chat-id'}>{subscription.cartID} - {subscription.id}</p>
                                            </div>
                                            <div style={{display: 'flex', marginTop: 12}}>
                                                <img src={pin} style={{width: 10, height: 12, margin: "auto 0"}}/>
                                                <p style={{
                                                    fontSize: 12,
                                                    fontWeight: 500,
                                                    color: '#A1A2A4',
                                                    margin: "auto 0 auto 5px"
                                                }}>{subscription.serviceAddress.city}</p>
                                            </div>
                                            <div style={{display: 'flex', marginTop: 10, padding: '0 '}}>
                                                <div style={{margin: 'auto auto auto 0'}}>
                                                    <p className={'cart-detail-heading'}>Date</p>
                                                    <p className={'cart-detail-meta-text'}>{subscription.serviceDate}</p>
                                                </div>
                                                <div style={{margin: 'auto'}}>
                                                    <p className={'cart-detail-heading'}>Time</p>
                                                    <p className={'cart-detail-meta-text'}>{subscription.serviceTime}</p>
                                                </div>
                                                <div style={{margin: 'auto 0 auto auto'}}>
                                                    <p className={'cart-detail-heading'}>Subscription</p>
                                                    <p className={'cart-detail-meta-text'}>{subscription.subscriptionFrequency}</p>
                                                </div>
                                                <div style={{margin: 'auto 0 auto auto'}}>
                                                    <p className={'cart-detail-heading'}>Price</p>
                                                    <p className={'cart-detail-meta-text'}>{currency} {numberWithCommas(subscription.transaction[0].payableAmount.toFixed(2))}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{marginLeft: 'auto', padding: '0 5px 0 10px'}}>
                                            <div className="dropstart cart-dots">
                                                <BsThreeDotsVertical className={'cart-dots'}

                                                                     role="button"
                                                                     id="dropdownMenuLink"
                                                                     data-bs-toggle="dropdown"
                                                                     aria-expanded="false"/>
                                                <ul className="dropdown-menu"
                                                    aria-labelledby="dropdownMenuLink">
                                                    {subscription.reschedCount === 0 &&
                                                        <li><a onClick={() => {
                                                            history.push(`/order-subscription-detail/${subscription.id}/`)
                                                        }} className="dropdown-item"
                                                               style={{fontSize: 12}}>Schedule</a>
                                                        </li>
                                                    }
                                                    <li><a className="dropdown-item dropend"
                                                           style={{fontSize: 12}}
                                                           onClick={() => {
                                                               if (account_settings.calendar) {
                                                                   reminder_func(subscription)
                                                               } else {
                                                                   setBlockerType('calendar')
                                                                   handleOpenBlockerModal()
                                                                   // setTriggerReminder(true)
                                                               }
                                                           }}
                                                    >Reminder</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <BsChevronRight
                                                className={'cart-right-arrow'}
                                                onClick={() => {
                                                    history.push(`/service-execution/${subscription.id}/`)
                                                }}/>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>

                        <div style={{display: 'flex', marginBottom: 10}}>
                            <p style={{fontSize: 15, fontWeight: 600}}>Completed</p>
                            <AiFillQuestionCircle
                                className={'faq-icon-selection-location'}
                                style={{margin: 'auto 0 auto auto', fontSize: 22}}
                                onClick={() => {
                                    setFaqNum(35)
                                    handleOpenFaqModal()
                                }}/>
                        </div>

                        {
                            orders.map((subscription) => {
                                return (
                                    subscription.status === 'Completed' &&
                                    <div className={'cart-container'}>
                                        <div style={{width: '100%'}}>
                                            <div style={{display: 'flex'}}>
                                                <div className={'package-image-container'}
                                                     style={{backgroundImage: `url(${subscription.package.packageImage})`}}>
                                                </div>
                                                <p className={'cart-package-name'}>{subscription.package.packageName}</p>
                                                <img className={'cart-chat-img'} src={chat}
                                                     onClick={() => {
                                                         history.push(`/chat/${subscription.id}/2`)
                                                     }}
                                                />
                                                <p className={'cart-chat-id'}>{subscription.cartID} - {subscription.id}</p>
                                            </div>
                                            <div style={{display: 'flex', marginTop: 12}}>
                                                <img src={pin} style={{width: 10, height: 12, margin: "auto 0"}}/>
                                                <p style={{
                                                    fontSize: 12,
                                                    fontWeight: 500,
                                                    color: '#A1A2A4',
                                                    margin: "auto 0 auto 5px"
                                                }}>{subscription.serviceAddress.city}</p>
                                            </div>
                                            <div style={{display: 'flex', marginTop: 10, padding: '0 '}}>
                                                <div style={{margin: 'auto auto auto 0'}}>
                                                    <p className={'cart-detail-heading'}>Date</p>
                                                    <p className={'cart-detail-meta-text'}>{subscription.serviceDate}</p>
                                                </div>
                                                <div style={{margin: 'auto'}}>
                                                    <p className={'cart-detail-heading'}>Time</p>
                                                    <p className={'cart-detail-meta-text'}>{subscription.serviceTime}</p>
                                                </div>
                                                <div style={{margin: 'auto 0 auto auto'}}>
                                                    <p className={'cart-detail-heading'}>Subscription</p>
                                                    <p className={'cart-detail-meta-text'}>{subscription.subscriptionFrequency}</p>
                                                </div>
                                                <div style={{margin: 'auto 0 auto auto'}}>
                                                    <p className={'cart-detail-heading'}>Price</p>
                                                    <p className={'cart-detail-meta-text'}>{currency} {numberWithCommas(subscription.transaction[0].payableAmount.toFixed(2))}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{marginLeft: 'auto', padding: '0 5px 0 10px'}}>
                                            <div className="dropstart cart-dots">
                                                <BsThreeDotsVertical className={'cart-dots'}

                                                                     role="button"
                                                                     id="dropdownMenuLink"
                                                                     data-bs-toggle="dropdown"
                                                                     aria-expanded="false"/>
                                                <ul className="dropdown-menu"
                                                    aria-labelledby="dropdownMenuLink">
                                                    {subscription.reschedCount === 0 &&
                                                        <li><a onClick={() => {
                                                            history.push(`/order-subscription-detail/${subscription.id}/`)
                                                        }} className="dropdown-item"
                                                               style={{fontSize: 12}}>Schedule</a>
                                                        </li>
                                                    }
                                                    <li><a className="dropdown-item dropend"
                                                           style={{fontSize: 12}}
                                                           onClick={() => {
                                                               if (account_settings.calendar) {
                                                                   reminder_func(subscription)
                                                               } else {
                                                                   setBlockerType('calendar')
                                                                   handleOpenBlockerModal()
                                                                   // setTriggerReminder(true)
                                                               }
                                                           }}
                                                    >Reminder</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <BsChevronRight
                                                className={'cart-right-arrow'}
                                                onClick={() => {
                                                    history.push(`/service-execution/${subscription.id}/`)
                                                }}/>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </TabPanel>
                </SwipeableViews>
            </div>
            <Footer/>
        </div>
    );
}

export default OrdersPage;