import Navbar from "./Navbar";
import clsx from 'clsx'
import PackageDetailHero from "./PackageDetailHero";

const HeaderPackageDetail = ({isMobile, setShowSidebar,handleOpenModal}) => {
    return (
        <div className={'header-package-detail'}>
            <div className={clsx(!isMobile ? "container" : "header-container-sm", 'header-container')}>
                <Navbar setShowSidebar={setShowSidebar} handleOpenModal={handleOpenModal}/>
            </div>
            {/*<PackageDetailHero/>*/}
        </div>
    );
}

export default HeaderPackageDetail;