import {RiComputerLine} from "react-icons/ri";
import {AiOutlineComment} from "react-icons/ai";
import {HiOutlineDocumentDuplicate} from "react-icons/hi";
import Footer from "../component/LandingPage/Footer";

function AboutUsPage() {
    return (
        <>
            <div className={'about-us-paralax'}>
                <div className={'container'} style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                    <div style={{textAlign: "center", margin: "auto", color: "white"}}>
                        <p className={'contact-us-banner-heading'}>About
                            CLYKK</p>
                        <p className={'contact-us-banner-text'}>Your Service are Just a CLYKK Away</p>
                    </div>
                </div>
            </div>
            <div className="container my-4" style={{textAlign: "left"}}>
                <p style={{fontWeight: 400, fontSize: 14, margin: "10px 0", color: '#5a6167', lineHeight: 1.8}}>
                    A New Start-Up out of Bangalore is offering something unique in the home services space. <em>It
                    is constituted Of The People (Local Service Providers), By The Peop</em><span
                    className="text_exposed_show"><em>le (CLYKK Services Marketplace), and For the People (Local Consumers).&nbsp;</em> </span>The
                    concept is to provide one-time or recurring home services to the consumers, so they don’t have
                    to do the work themselves. The consumer will CLYKK through our Mobile APP to select the Services
                    they would like to get done.&nbsp; We at CLYKK will deploy the Service Providers to do their
                    work.&nbsp; Our goal is to make life easier for the Consumer and to also provide additional
                    business to qualified Services Providers in your area.</p>
                <p style={{fontWeight: 400, fontSize: 14, margin: "10px 0", color: '#5a6167', lineHeight: 1.8}}>
                    <em><span style={{color: "#000080"}}><span style={{color: "#000080"}}><b
                        style={{color: "#000080"}}>We are in the process of signing up Services Providers in the Bangalore area. Our </b><span
                        style={{color: "#000080"}}><b>Marketplace</b></span><b style={{color: "#000080"}}> Offering to the Consumers </b>
                    <b style={{color: "#000080"}}>will be starting with Individuals and Companies who can provide one-time or recurring Services to Consumers – like Cleaning, Pest Control, Elder Care, Pick up &amp; Delivery, Tutor, Salon and many more…</b></span></span></em>
                </p>
                <p style={{fontWeight: 400, fontSize: 14, margin: "10px 0", color: '#5a6167', lineHeight: 1.8}}>
                    We would love to tell you more about the Services we are launching.&nbsp; Reach us on <a
                    href="https://m.me/CLYKKServices" target="_blank" rel="noopener noreferrer"><span
                    style={{color: "#0000ff"}}><strong>CLYKK Services Messenger</strong></span></a> and one of our
                    Partner Managers will reach out to you.</p>
            </div>
            <div className={'mid-parallax'}>
                <div className="container" style={{margin: 'auto'}}>
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                            <div style={{width: "100%", display: 'flex'}}>
                                <div className="icon-container">
                                    <RiComputerLine className={'contact-us-icon'}/>
                                </div>
                            </div>
                            <p className={'contact-icon-name'}>Transparency</p>
                            <p className={'contact-icon-meta'}>
                                Consumers will have transparency on the Package Details and The Checklist to be followed
                                by
                                the Service Provider.
                            </p>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                            <div style={{width: "100%", display: 'flex'}}>
                                <div className="icon-container">
                                    <HiOutlineDocumentDuplicate className={'contact-us-icon'}/>
                                </div>
                            </div>
                            <p className={'contact-icon-name'}>Personalized</p>
                            <p className={'contact-icon-meta'}>At CLYKK we are working on a better way to deliver
                                services, see the difference by joining us and support the efforts you care about!</p>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                            <div style={{width: "100%", display: 'flex'}}>
                                <div className="icon-container">
                                    <AiOutlineComment className={'contact-us-icon'}/>
                                </div>
                            </div>
                            <p className={'contact-icon-name'}>New Currency</p>
                            <p className={'contact-icon-meta'}>
                                Time is the New Currency - Saves Money & Time for the Consumer, know where your Service
                                Provider is and Status of the Execution of the Services.</p>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex', width: "100%",
                        margin: "30px 0"
                    }}>
                        <p className={'mid-parallax-lg-text'}>
                            Get the most from CLYKK at your free online
                            workshop.</p>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default AboutUsPage;
