import faq_img from '../../assets/images/faq-img.png'
import faq_mobile_illustration from '../../assets/images/FAQ Illustratio.png'
import {useContext, useEffect, useState} from "react";
import Config from "../../Config";
import axios from "axios";
import {Context} from "../../Contexts/Context";
import {AiOutlineClose} from "react-icons/ai";
import {useSelector} from "react-redux"

const Faq = ({faqNum, page, home = false, terms = false}) => {
    const [faq, setFaq] = useState([])
    const {handleCloseFaqModal} = useContext(Context);
    const access_token = useSelector(state => state.accounts.access_token)

    useEffect(() => {
        const config = Config()
        axios.get(`https://cgapp.clykk.com/faq/api/v2/faq-manager/faq-info/${faqNum}/topics`, config)
            .then(response => {
                setFaq(response.data.qna !== null ? response.data.qna : [])
            })
            .catch(err => console.log(err.message))
    }, [access_token, faqNum])

    return (
        <div className={'container my-3'}>

            {!terms &&
                !page &&
                <div style={{display: 'flex', width: '100%'}}>
                    <p style={{margin: 'auto 0', fontWeight: 600, fontSize: 13}}>Frequently Asked Questions</p>
                    <img src={faq_img} style={{width: 40, margin: 'auto 20px'}}/>
                    {!home &&
                        <AiOutlineClose style={{marginLeft: 'auto', fontSize: 15, cursor: 'pointer'}}
                                        onClick={handleCloseFaqModal}/>
                    }
                </div>
            }

            {page &&
                <div style={{width: '100%'}}>
                    <div className={'faq-page-lg'} style={{display: 'flex', width: '100%'}}>
                        <p style={{margin: 'auto 0', fontWeight: 600, fontSize: 13}}>Frequently Asked Questions</p>
                        <img src={faq_img} style={{width: 40, margin: 'auto 20px'}}/>
                    </div>
                    <div className={'faq-page-sm'}>
                        <img src={faq_mobile_illustration} style={{margin: '0 auto', width: 290}}/>
                    </div>
                </div>
            }

            <div className="accordion my-3" id="accordionExample">
                {faq.map((item, index) => {
                    return (
                        <div className="accordion-item mb-2">
                            <h2 className="accordion-header" id={`heading${index}`}>
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target={`#collapse${index}`} aria-expanded="false"
                                        aria-controls={`collapse${index}`}>
                                    <p style={{fontSize: 13}}>{item.questionName}</p>
                                </button>
                            </h2>
                            <div id={`collapse${index}`} className="accordion-collapse collapse"
                                 aria-labelledby={`heading${index}`}
                                 data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className={'page-list'}>
                                        <ul>
                                            {item.answers.map((point) => {
                                                return (
                                                    point.enabled &&
                                                    <li className={'package-points'}>{point.answer}</li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>

        </div>
    );
}

export default Faq;
