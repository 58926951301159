import React, {useRef, useState} from 'react';
import {BiArrowBack} from "react-icons/bi";
import clsx from "clsx";
import {toast} from "react-hot-toast";
import Config from "../../Config";
import axios from "axios";
import {load_user_cart} from "../../actions/accounts";
import {useDispatch} from "react-redux";

function SubscriptionOrder({
                               cartItem,
                               setCartItem,
                               setSubscriptions,
                               schedule,
                               setSchedule,
                               openSubscribe,
                               setOpenSubscribe,
                               slide,
                               setSlide,
                               allowedTime,
                               orderDetail = false
                           }) {
    const dispatch = useDispatch()
    const formRef = useRef()
    const days = [
        'SU', 'M', "T", 'W', 'TH', 'F', 'S'
    ]

    const get_date_format = () => {
        const date = new Date()
        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()
        if (`${month}`.length > 2) {
            return `${year}-${month}-${day}`
        } else {
            return `${year}-0${month}-${day}`
        }
    }

    const add_days_to_schedule = (index) => {
        let weekdays = []
        if (schedule.weekdays.includes(index)) {
            weekdays = schedule.weekdays.filter(item => item !== index)
        } else {
            weekdays = [...schedule.weekdays, index]
        }
        setSchedule({...schedule, weekdays: weekdays})
    }

    const change_in_schedule = (value, key) => {
        if (key === 'startDate') {
            const current_date = Date.parse(get_date_format())
            const date = Date.parse(value)
            if (current_date > date) {
                toast.error('You can not set a date before the current date', {duration: 3000})
                return
            }
        } else if (key === 'endDate') {
            const start_date = Date.parse(schedule['startDate'])
            const end_date = Date.parse(value)

            if (end_date <= start_date) {
                toast.error('Subscription Start and End date can not be on the same day or any day before Start Date, please select a date ahead of Start Date', {duration: 3000})
                return
            }
        }
        setSchedule({...schedule, [key]: value})
    }

    const updateSubscription = (e) => {
        const config = Config()
        if (!Date.parse(schedule.startDate)) {
            toast.error('Please select start date', {duration: 3000})
            return
        }
        if (openSubscribe) {
            if (!Date.parse(schedule.endDate)) {
                toast.error('Please select end date', {duration: 3000})
                return
            }
            var d1 = Date.parse(schedule.startDate);
            var d2 = Date.parse(schedule.endDate);
            if (d1 >= d2) {
                toast.error('Subscription End Dates needs to come after the Subscription Start Date', {duration: 3000})
                return
            }
        }
        if (openSubscribe && schedule.subscriptionFrequency === 'Daily' && schedule.weekdays.length === 0) {
            toast.error('Please select atleast one day of the week.', {duration: 3000})
            return;
        }
        if (schedule.serviceTime === '') {
            toast.error('Please select service time', {duration: 3000})
            return
        }
        if (!openSubscribe) {
            schedule.endDate = schedule.startDate
        }

        if (!orderDetail) {
            axios.put(`https://cgapp.clykk.com/cart/api/v2/cart-management/cart/${cartItem.cartID}/schedule`,
                schedule, config)
                .then((response) => {
                    setCartItem((prev) => ({...prev, schedule: response.data}))
                    axios.get(`https://cgapp.clykk.com/cart/api/v2/cart-management/cart/${cartItem.cartID}`, config)
                        .then(response => {
                            setSubscriptions(response.data.subscriptions)
                        })
                        .catch(err => console.log(err.message))
                    dispatch(load_user_cart())
                })
                .catch(err => console.log(err.message))
        } else {
            axios.put(`https://cgapp.clykk.com/cart/api/v2/cart-management/subscription/${cartItem.id}/schedule`,
                {...schedule, serviceDate:schedule.startDate}, config)
                .then((response) => {
                    setCartItem(response.data)
                })
                .catch(err => console.log(err.message))
        }
        setSlide(slide + 1)
    }

    return (
        <div style={{width: '100%'}}>
            <div style={{padding: 15, backgroundColor: '#F6F7F9', borderRadius: 5}}>
                <p style={{fontSize: 15, fontWeight: 600, color: '#424B5A'}}>Schedule Your Service</p>
                <p style={{color: '#5a6167', fontSize: 12, margin: '0 0 10px 0'}}>Schedule your service
                    time
                    and
                    date or subscribe for recurring service option</p>
                <form ref={formRef}>
                    <p style={{fontSize: 12, color: '#5a6167'}}>Date</p>
                    <div style={{display: 'flex', margin: '0 0 12px 0'}}>
                        <input
                            id={'date_picker'}
                            value={schedule.startDate}
                            key={'startDate'}
                            onChange={(e) => {
                                change_in_schedule(e.target.value, 'startDate')
                            }}
                            type={'date'}
                            className={'form-control calendar-input'}/>
                    </div>
                    <p style={{fontSize: 12, color: '#5a6167'}}>Time</p>
                    <div style={{display: 'flex', margin: '0 0 12px 0'}}>
                        <select className="form-select"
                                key={'serviceTime'}
                                onChange={(e) => {
                                    change_in_schedule(e.target.value, 'serviceTime')
                                }}
                                value={schedule.serviceTime}
                                aria-label="Default select example"
                                style={{fontSize: 12, padding: 10}}
                        >
                            <option value={''}>Select Service Time</option>
                            {allowedTime.map((item) => {
                                return (
                                    <option value={item}>{item}</option>
                                )
                            })}
                        </select>
                    </div>

                    {!openSubscribe &&
                    <button
                        className={'form-control mb-3 p-2 booking-confirm-btn'}
                        onClick={(e) => {
                            e.preventDefault()
                            const form = formRef.current;
                            if (!form.checkValidity()) {
                                form.reportValidity()
                                return
                            } else {
                                updateSubscription(e)
                            }
                        }}
                    >Confirm
                    </button>
                    }


                    {!openSubscribe &&
                    <>
                        <p style={{fontSize: 12, color: '#5a6167'}}>Or Subscribe for Recurring
                            Service</p>
                        <button onClick={(e) => {
                            e.preventDefault()
                            setOpenSubscribe(!openSubscribe)
                        }}
                                className={'form-control mb-3 p-2 booking-confirm-btn'}
                        >Subscribe
                        </button>
                    </>
                    }

                    {openSubscribe &&
                    <button onClick={(e) => {
                        e.preventDefault()
                        setOpenSubscribe(!openSubscribe)
                    }}
                            style={{
                                backgroundColor: '#6CD7BB',
                                color: 'white',
                                fontSize: 10,
                                fontWeight: 500,
                                width: 200
                            }}
                            className={'form-control mb-3 p-2'}
                    ><BiArrowBack/> Back to one time schedule
                    </button>
                    }

                    {openSubscribe &&
                    <>
                        <p style={{fontSize: 15, fontWeight: 600, marginBottom: 15}}>Subscribe for
                            Recurring
                            Service</p>
                        <p style={{fontSize: 12, marginBottom: 5}}>Subscription Type</p>
                        <select
                            key={'subscriptionFrequency'}
                            className="form-select"
                            value={schedule.subscriptionFrequency}
                            onChange={(e) => {
                                change_in_schedule(e.target.value, 'subscriptionFrequency')
                            }}
                            aria-label="Default select example"
                            style={{fontSize: 12, padding: 10, marginBottom: 15}}
                        >
                            <option value={'daily'} selected>Daily</option>
                            <option value={'monthly'}>Monthly</option>
                            <option value={'yearly'}>Yearly</option>
                        </select>

                        {schedule.subscriptionFrequency === 'daily' &&
                        <>
                            <p style={{fontSize: 12, color: '#5a6167', marginBottom: 5}}>Pick Days of
                                the
                                week</p>
                            <div style={{display: 'flex', marginBottom: 30}}>
                                {days.map((day, index) => {
                                    return (
                                        <div
                                            onClick={() => {
                                                add_days_to_schedule(index)
                                            }}
                                            className={clsx('day-container', !schedule.weekdays.includes(index) ? 'day-container-inactive' : 'day-container-active', 'shadow-sm')}>
                                            <p style={{fontSize: 14}}>{day}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </>
                        }

                        <p style={{fontSize: 12, color: '#5a6167'}}>Subscription End Date</p>
                        <div style={{display: 'flex', margin: '0 0 12px 0'}}>
                            <input
                                key={'endDate'}
                                onChange={(e) => {
                                    change_in_schedule(e.target.value, 'endDate')
                                }}
                                value={schedule.endDate} type={'date'}
                                className={'form-control calendar-input'}/>
                        </div>
                        <button
                            className={'form-control mb-3 p-2 booking-confirm-btn'}
                            onClick={(e) => {
                                e.preventDefault()
                                const form = formRef.current;
                                if (!form.checkValidity()) {
                                    form.reportValidity()
                                    return
                                } else {
                                    updateSubscription(e)
                                }
                            }}
                        >Confirm
                        </button>
                    </>
                    }
                </form>
            </div>
        </div>
    );
}

export default SubscriptionOrder;
