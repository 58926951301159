const StoreBtn = ({img, text, store, url}) => {
    return (
        <a className={'store-btn'} target={"_blank"} href={url}>
            <img src={img} alt={'img'} className={'store-img'}/>
            <div style={{color: 'white'}}>
                <p className={'store-sm-text'}>{text}</p>
                <p className={'store-lg-text'}>{store}</p>
            </div>
        </a>
    );
}

export default StoreBtn;