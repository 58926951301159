import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {Pagination} from "swiper";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import Config from "../../Config";
import {numberWithCommas} from "../Utils";

function LikedService() {
    const isAuthenticated = useSelector(state => state.accounts.isAuthenticated)
    const [likedServices, setLikedServices] = useState([])

    const history = useHistory()
    const currency = useSelector(state => state.accounts.currency)
    const location = useSelector(state => state.accounts.location)

    const config = Config()

    useEffect(() => {
        axios.get('https://cgapp.clykk.com/like/api/v2/user/packageLike', config)
            .then((response) => {
                setLikedServices(response.data.rs)
            })
            .catch(error => console.log(error.message))
    }, [location, isAuthenticated])


    return (
        <div className={'container my-3'}>
            <div style={{display: 'flex', width: '100%'}}>
                <p style={{margin: 'auto 0', fontWeight: 600, fontSize: 15, color: '#424B5A'}}>
                    Liked Services
                </p>
            </div>
            <Swiper
                slidesPerView={"auto"}
                autoplay={{delay: 2000}}
                spaceBetween={30}
                pagination={{
                    clickable: false,
                }}
                modules={[Pagination]}
                className={"mySwiper my-3 swiper-sm"}
            >
                {likedServices.map((slide) => {
                    return (
                        <SwiperSlide>
                            <div style={{cursor: "pointer"}}
                                 onClick={() => {
                                     history.push(`/package-detail/${slide.subcategoryID}/${slide.id}`)
                                 }}
                            >
                                <div style={{position: 'relative'}}>
                                    <div style={{
                                        padding: "17px 5px",
                                        textAlign: 'left',
                                        zIndex: 10,
                                        position: 'absolute',
                                        height: "100%",
                                        width: "100%",
                                        display: 'flex',
                                        flexDirection: "column"
                                    }}>
                                        <div style={{
                                            display: 'flex'
                                        }}>
                                            <div style={{marginLeft: "10px"}}>
                                                <p style={{
                                                    fontSize: 10,
                                                    fontWeight: 600,
                                                    color: 'white',
                                                    textAlign: 'center',
                                                    letterSpacing: '0.3px'
                                                }}>
                                                    <del
                                                        style={{color: 'red'}}>{currency}{numberWithCommas(Math.round(slide.basePrice))}</del>
                                                    <br/>
                                                    {currency}{numberWithCommas(Math.round(slide.sellingPrice))}
                                                </p>
                                            </div>
                                            <div style={{
                                                marginLeft: 15
                                            }}>
                                                <p style={{
                                                    fontSize: 12,
                                                    fontWeight: 600,
                                                    color: '#87494d'
                                                }}>{slide.packageName}</p>
                                                <p style={{fontSize: 12, fontWeight: 400}}>{slide.shortDescription}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <img style={{position: 'absolute', zIndex: 4}} src={slide.packageImage}
                                         alt={'offer-img'}/>
                                </div>
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    );
}

export default LikedService;