import React, {useContext, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {Context} from "../../Contexts/Context";
import {AiFillQuestionCircle} from "react-icons/ai";
import Config from "../../Config";
import axios from "axios";
import logo from "../../assets/images/logo.svg";
import {toast} from "react-hot-toast";
import {useLocation} from "react-router-dom";
import {numberWithCommas} from "../Utils";

function SubscriptionAddOnsTabs({
                                    data,
                                    setData,
                                    add_update_cart,
                                    handleOpenModal,
                                    loadedPackage,
                                }) {
    const currency = useSelector(state => state.accounts.currency)

    let isAuthenticated = useSelector(state => state.accounts.isAuthenticated)
    const userData = useSelector(state => state.accounts.data)
    const location = useLocation()
    const scheduleId = location.pathname.split('/')[2]
    const cartID = location.pathname.split('/')[3];

    const {setFaqNum, handleOpenFaqModal} = useContext(Context);

    const [baseAmounts, setBaseAmounts] = useState({})
    const [showPayment, setShowPayment] = useState(false)

    const [payAbleAmount, setPayAbleAmount] = useState(0)
    const [savedAmount, setSavedAmount] = useState(0)

    const increase_decrease_add_ons = (id, sign) => {
        let _addOns = []
        let _showPayment = false
        data.map((item) => {
            if (item.id === id) {
                if (sign === '+') {
                    item.itemCount += 1
                    setPayAbleAmount(payAbleAmount + item.sellingPrice)
                    setSavedAmount(savedAmount + (item.basePrice - item.sellingPrice))
                } else if (sign === '-') {
                    if (item.itemCount > 0 && item.itemCount > baseAmounts[item.id]) {
                        item.itemCount -= 1
                        setPayAbleAmount(payAbleAmount - item.sellingPrice)
                        setSavedAmount(savedAmount - (item.basePrice - item.sellingPrice))
                    }
                }
            }
            if (!_showPayment) {
                _showPayment = baseAmounts[item.id] !== item.itemCount
            }
            _addOns.push(item)
        })

        setData((prev) => ({...prev, addOns: _addOns}))
        setShowPayment(_showPayment)
    }

    const setAddOnBaseAmounts = () => {
        let _baseAmounts = {}
        data.map((item) => {
            if (!(item.id in _baseAmounts)) {
                _baseAmounts = {..._baseAmounts, [item.id]: item.itemCount}
            }
        })
        setBaseAmounts(_baseAmounts)
    }

    useEffect(() => {
        setAddOnBaseAmounts()
    }, [])

    const showAddons = (item) => {
        return (
            <div className={'package-single-addon-container'}>
                <div className={'add-on-package-img'}>
                    <img src={item.addonImage} className={'package-add-img'}/>
                </div>

                <div className={'package-add-on-services-text-container'}>
                    <p className={'package-add-on-service'}>{item.addonName}</p>
                </div>

                <div className={'package-add-on-price-container'}>
                    <p className={'package-add-on-price-1'}>
                        <del>{currency}{numberWithCommas(parseFloat(item.basePrice).toFixed(2))}</del>
                    </p>
                    <p className={'package-add-on-price-2'}>{currency}{numberWithCommas(parseFloat(item.sellingPrice).toFixed(2))}</p>
                </div>
                <div
                    className={'package-add-on-quantity-container'}>
                    <div
                        onClick={() => increase_decrease_add_ons(item.id, '-')}
                        className={'quantity-controller-container'}>
                        <p className={'quantity-controller'}>-</p>
                    </div>
                    <p className={'quantity'}
                       style={{margin: '0 auto'}}>{item.itemCount}</p>
                    <div
                        onClick={() => increase_decrease_add_ons(item.id, '+')}
                        className={'quantity-controller-container'}>
                        <p className={'quantity-controller'}>+</p>
                    </div>
                </div>
            </div>
        )
    }

    const addOnsApiUpdate = () => {
        const config = Config()
        data.map((addOn) => {
            axios.put(`https://cgapp.clykk.com/cart/api/v2/cart-management/subscription/${scheduleId}/extendaddon/${addOn.id}`,
                {...addOn, itemCount: addOn.itemCount},
                config
            )
        })
        setAddOnBaseAmounts()
        setShowPayment(false)
    }

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function displayRazorpay() {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        const config = Config()
        const result = await axios.post(`https://cgapp.clykk.com/cart/api/v2/cart-management/cart/${cartID}/order/book`,
            {
                "total": parseFloat(payAbleAmount),
                "payableAmount": payAbleAmount * 100,
                "amountBeforeTax": 0,
                "taxAmount": 0,
                "paymentType": "onetime",
                "description": 'additional-services'
            },
            config
        )

        const {payableAmount, order_id} = result.data;

        const options = {
            key: "rzp_live_YdTGPolg4K9L7h", // Enter the Key ID generated from the Dashboard
            amount: payableAmount,
            currency: "INR",
            name: "CLYKK.",
            description: "CLYKK",
            image: {logo},
            order_id: order_id,
            handler: async function (response) {
                const data = {
                    orderCreationId: order_id,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                };
                axios.put(`https://cgapp.clykk.com/cart/api/v2/cart-management/subscription/${scheduleId}/addpayment`,
                    {
                        "total": parseFloat(payAbleAmount),
                        "payableAmount": parseFloat(payAbleAmount),
                        "amountBeforeTax": 0,
                        "taxAmount": 0,
                        "transactionID": data.razorpayPaymentId,
                        "paymentType": "onetime",
                        "description": 'additional-services'
                    }, config)
                    .then(response => {
                        toast.success('Payment Successful')
                        addOnsApiUpdate()
                    })
                    .catch(err => console.log(err.message))

            },
            prefill: {
                name: userData.firstName,
                email: userData.email,
                contact: userData.mobile,
            },
            notes: {
                address: "",
            },
            theme: {
                color: "#61dafb",
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    return (
        <div className={'container'}>
            <div className={'row page-detail-container'}>
                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12">
                    <div style={{display: 'flex'}}>
                        <p className={'page-name'}>Package Add-On's</p>
                        <div className={'update-cart-btn-sm'}>
                            <button
                                onClick={add_update_cart}
                                style={{
                                    backgroundColor: '#6CD7BB',
                                    color: 'white',
                                    fontSize: 12
                                }}
                                className={'btn'}>Update
                            </button>
                        </div>
                        <AiFillQuestionCircle
                            className={'question-faq-icon-package'}
                            onClick={() => {
                                setFaqNum(20)
                                handleOpenFaqModal()
                            }}
                            style={{color: '#ce5e97'}}/>
                    </div>

                    <div style={{margin: '15px 0'}}>
                        <p style={{margin: '5px 0 10px 0', fontSize: 12, fontWeight: 600}}>Choose Add-On's to
                            include</p>
                        <div className={'package-addon-container'}>
                            {data.map((item) => {
                                return (
                                    showAddons(item)
                                )
                            })}
                        </div>
                    </div>

                </div>

                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12">
                    <div style={{width: '100%', display: 'flex'}}>
                        <div style={{marginLeft: 'auto', textAlign: 'right', width: '100%'}}>
                            <p className={'red-price body-price'} style={{fontSize: 15}}>
                                <del>{currency}{numberWithCommas(parseFloat(loadedPackage.basePrice).toFixed(2))}</del>
                            </p>
                            <p className={'black-price body-price'}
                               style={{fontSize: 15}}>{currency}{numberWithCommas(parseFloat(loadedPackage.sellingPrice).toFixed(2))}</p>
                            <div className={'update-cart-btn-bg'}>
                                <button
                                    onClick={add_update_cart}
                                    style={{
                                        backgroundColor: '#6CD7BB',
                                        color: 'white',
                                        fontSize: 12
                                    }}
                                    className={'btn'}>Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12">
                    {showPayment && <>
                        <p style={{fontSize: 14, fontWeight: 600}}>New Payment</p>
                        <div
                            style={{padding: 10, backgroundColor: "#F6F7F9", marginBottom: 10}}>
                            <div style={{
                                width: '100%',
                                display: 'flex',
                                margin: '10px 0 10px 0'
                            }}>
                                <p className={'total-booking'}>Sub Total</p>
                                <p className={'total-booking-text'}>{currency}{numberWithCommas(parseFloat(payAbleAmount).toFixed(2))}</p>
                            </div>
                            <div style={{
                                width: '100%',
                                display: 'flex',
                                margin: '10px 0 10px 0'
                            }}>
                                <p className={'total-booking'}>Payable Amount</p>
                                <p className={'total-booking-text'}>{currency}{numberWithCommas(payAbleAmount)}</p>
                            </div>
                            <div style={{
                                width: '100%',
                                display: 'flex',
                                margin: '10px 0 10px 0'
                            }}>
                                <p className={'total-booking'}>Payment Method</p>
                                <p className={'total-booking-text'}>RazorPay</p>
                            </div>
                        </div>
                        <div className={'row my-4'}>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className={'proceed-booking-btn'}
                                     onClick={displayRazorpay}
                                >
                                    <div style={{margin: 'auto auto auto 0'}}>
                                        <p style={{fontSize: 12}}>{currency} {numberWithCommas(payAbleAmount)}</p>
                                        <p className={'total-saving-text'}>You are
                                            saving {currency}{numberWithCommas(savedAmount)}</p>
                                    </div>
                                    <p style={{fontSize: 12, margin: 'auto 0'}}>Proceed to
                                        RazorPay</p>
                                </div>
                            </div>
                        </div>
                    </>}
                </div>
            </div>

        </div>
    );
}

export default SubscriptionAddOnsTabs;