import {Pagination} from "swiper";
import SetLocation from "../Modals/Locations/SetLocation";
import React, {useEffect, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {save_user_address, update_user_address_locally, update_user_address_server} from "../../actions/accounts";

const ManageAccessTab = () => {
    const history = useHistory()
    const [my_swiper, set_my_swiper] = useState({});
    const [defaultScreen, setDefaultScreen] = useState(1)

    const regModal = false

    const dispatch = useDispatch()

    // addresses
    const [updateId, setUpdateId] = useState(null)
    const location = useSelector(state => state.accounts.location)
    const [homeAddress, setHomeAddress] = useState({
        addressType: "",
        home_num: "",
        display_title: '',
        landmark: '',
        title: 'Home',
        address: null
    })
    const [serviceAddress, setServiceAddress] = useState(location)
    const center = {
        lat: serviceAddress.latitude,
        lng: serviceAddress.longitude,
    };
    useEffect(() => {
        setServiceAddress(location)
    }, [location])
    useEffect(() => {
        if (updateId === null) {
            setHomeAddress({
                addressType: "",
                home_num: "",
                display_title: '',
                landmark: '',
                title: 'Home',
                address: ''
            })
        }
    }, [updateId])
    const save_address = () => {
        let city = serviceAddress.city
        if (city.includes('Bangalore')) {
            city = 'Bengaluru'
        }
        const body = {
            "addressType": homeAddress.addressType,
            "line1": homeAddress.home_num,
            "line2": homeAddress.address,
            "line3": "",
            "pincode": serviceAddress.postal_code,
            "city": city,
            "state": serviceAddress.state,
            "country": serviceAddress.country,
            "latitude": serviceAddress.latitude,
            "longitude": serviceAddress.longitude,
            "landmark": homeAddress.landmark,
            "locationText": homeAddress.title,
            "defaultServiceAddress": false,
            "defaultBillingAddress": false
        }

        if (!updateId) {
            dispatch(save_user_address(body))
            setUpdateId(null)
        } else {
            dispatch(update_user_address_server(body, updateId))
            setUpdateId(null)
        }
        if (!regModal) {
            setDefaultScreen(1)
        }
        // setHomeAddress({
        //     addressType: "",
        //     home_num: "",
        //     display_title: '',
        //     landmark: '',
        //     title: 'Home',
        //     address: null
        // })
        history.push('/')
    }
    const set_edit_data = (location) => {
        setUpdateId(location.id)
        setHomeAddress({
            addressType: location.addressType,
            home_num: location.line1,
            display_title: location.locationText,
            landmark: location.landmark,
            title: location.locationText,
            address: location.line2
        })
        setDefaultScreen(0)
    }
    const setAddress = (address) => {
        dispatch(update_user_address_locally(address))
        setUpdateId(null)
        history.push('/')
    }

    return (
        <div style={{width: '100%'}}>
            <SetLocation my_swiper={my_swiper} handleCloseModal={() => {
            }}
                         defaultScreen={defaultScreen} setDefaultScreen={setDefaultScreen}

                         updateId={updateId}
                         setUpdateId={setUpdateId}
                         location={location}
                         homeAddress={homeAddress}
                         setHomeAddress={setHomeAddress}

                         serviceAddress={serviceAddress}
                         setServiceAddress={setServiceAddress}
                         center={center}
                         save_address={save_address}

                         set_edit_data={set_edit_data}
                         setAddress={setAddress}

                         regModal={false}/>
        </div>
    );
}

export default ManageAccessTab;