import SocialBannerBackground from '../../assets/images/SocialBannerBackground.png'
import facebook from '../../assets/images/facebook.png'
import whatsapp from '../../assets/images/whatsapp.png'
import gmail from '../../assets/images/Gmail_Logo_512px.png'
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton, TwitterIcon, TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";
import React, {useContext} from "react";
import {AiFillQuestionCircle} from "react-icons/ai";
import {useHistory} from "react-router-dom";
import {Context} from "../../Contexts/Context";
import {useSelector} from "react-redux";

const SocialsBanner = ({customer=true}) => {
    const {setFaqNum, handleOpenFaqModal} = useContext(Context);
    const account_settings = useSelector(state => state.accounts.account_settings)
    const {handleOpenBlockerModal, setBlockerType} = useContext(Context);


    // const title = customer ? "Checkout this amazing deal\n\n" +
    //     "CLYKK Home Services Package Recommendation:\n\n" +
    //     "Download CLYKK From Play Store\n" +
    //     "https://play.google.com/store/apps/details?id=com.clykk" + "\n\n" +
    //     "Download CLYKK From Apple Store\n" +
    //     "https://apps.apple.com/us/app/clykk/id1542592627?platform=iphone" + "\n\n" +
    //     "Visit us on \n" : "Checkout this amazing deal\n\n" +
    //     "CLYKK Home Services Package Recommendation:\n\n" +
    //     "Download CLYKK From Play Store\n" +
    //     "https://play.google.com/store/apps/details?id=com.clykk" + "\n\n" +
    //     "Download CLYKK From Apple Store\n" +
    //     "https://apps.apple.com/us/app/clykk-service-provider/id1550083789" + "\n\n" +
    //     "Visit us on \n"

    const title = "Check out these amazing offers, deals, and coupons at CLYKK\n\n" +
        "Download CLYKK From Play Store\n" +
        "CLYKK Customer\n"+
        "https://play.google.com/store/apps/details?id=com.clykk" + "\n" +
        "CLYKK Service Provider\n"+
        "https://play.google.com/store/apps/details?id=com.clykksp" + "\n\n" +
        "Download CLYKK From Apple Store\n" +
        "CLYKK Customer\n"+
        "https://apps.apple.com/us/app/clykk/id1542592627" + "\n" +
        "CLYKK Service Provider\n"+
        "https://apps.apple.com/us/app/clykk-service-provider/id1550083789" + "\n" +
        "Visit us on \n"

    // const email = customer ? "https://mail.google.com/mail/?view=cm&fs=1&tf=1&&su=Checkout%20this%20amazing%20deal&body=Checkout this amazing deal%0A%0ACLYKK Home Services Package Recommendation:%0A%0ADownload CLYKK From Play Store%0Ahttps://play.google.com/store/apps/details?id=com.clykk%0A%0ADownload CLYKK From Apple Store%0Ahttps://apps.apple.com/us/app/clykk/id1542592627?platform=iphone%0A%0AVisit us on %0Ahttps://clykk.com/" : "https://mail.google.com/mail/?view=cm&fs=1&tf=1&&su=Checkout%20this%20amazing%20deal&body=Checkout this amazing deal%0A%0ACLYKK Home Services Package Recommendation:%0A%0ADownload CLYKK From Play Store%0Ahttps://play.google.com/store/apps/details?id=com.clykk%0A%0ADownload CLYKK From Apple Store%0Ahttps://apps.apple.com/us/app/clykk-service-provider/id1550083789%0A%0AVisit us on %0Ahttps://clykk.com/"

    const email = "https://mail.google.com/mail/?view=cm&fs=1&tf=1&&su=Checkout%20this%20amazing%20deal&body=Checkout this amazing deal%0A%0ADownload CLYKK From Play Store%0ACLYKK Customer%0Ahttps://play.google.com/store/apps/details?id=com.clykk%0ACLYKK Service Provider%0Ahttps://play.google.com/store/apps/details?id=com.clykksp%0A%0ADownload CLYKK From Apple Store%0ACLYKK Customer%0Ahttps://apps.apple.com/us/app/clykk/id1542592627%0ACLYKK Service Provider%0Ahttps://apps.apple.com/us/app/clykk-service-provider/id1550083789%0A%0AVisit us on%0Ahttps://clykk.com/"

    return (
        <div style={{position: 'relative'}} className={'mt-5'}>
            <div style={{
                zIndex: -50,
                opacity: 0.3,
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundColor: "#fff0e5"
            }}/>

            <div className="container p-3">
                <div className="row socials-row">
                    <div className="d-none d-sm-none d-md-none d-lg-block d-xl-block col-xl-1 col-lg-1"/>
                    <div className="col-xl-6 col-lg-5 col-md-7 col-sm-12 col-xs-12">
                        <div className={'socials-detail-container'}
                             style={{display: 'flex', width: "100%", height: "100%"}}>
                            <div style={{margin: 'auto', textAlign: "center"}}>
                                <div style={{display: 'flex', width: '100%'}}>
                                    <div style={{margin: "0 auto", display: 'flex'}}>
                                        <p style={{fontSize: 30, fontWeight: 700}}>Share & Earn</p>
                                        <AiFillQuestionCircle
                                            onClick={() => {
                                                setFaqNum(30)
                                                handleOpenFaqModal()
                                            }}
                                            className={'share-earn-question-icon'}/>
                                    </div>
                                </div>

                                <p style={{fontSize: 15, lineHeight: 2}}>
                                    CLYKK Lifestyle Home Services, Offering that is Customized by You.
                                </p>
                                <div className={'my-2'}>
                                    <p style={{fontSize: 14}}>Share with your Friends and Family</p>
                                    <div className={'social-icons my-2'}>
                                        <div className={'social-icons-container'}
                                            // onClick={() => {
                                            //     if (!account_settings.contacts) {
                                            //         setBlockerType('contacts')
                                            //         handleOpenBlockerModal()
                                            //     }
                                            // }}
                                        >
                                            {/*{!account_settings.contacts &&*/}
                                            {/*    <div*/}
                                            {/*        style={{position: 'absolute', width: '100%', height: '100%'}}></div>*/}
                                            {/*}*/}
                                            <div className={'share-btn-icons'}>
                                                <WhatsappShareButton
                                                    url={`https://clykk.com/`}
                                                    title={title}
                                                    windowWidth={800}
                                                    windowHeight={550}
                                                    separator=""
                                                    className="Demo__some-network__share-button"
                                                >
                                                    <WhatsappIcon size={35} round/>
                                                </WhatsappShareButton>
                                            </div>
                                            <div className={'share-btn-icons'}>
                                                <EmailShareButton
                                                    url={`https://clykk.com/`}
                                                    subject={'This is an amazing offer !!'}
                                                    body={title}
                                                    className="Demo__some-network__share-button"
                                                >
                                                    <EmailIcon size={35} round/>
                                                </EmailShareButton>
                                            </div>
                                            <div className={'share-btn-icons'}>
                                                <TwitterShareButton
                                                    url={`https://clykk.com/`}
                                                    title={title}
                                                    className="Demo__some-network__share-button"
                                                >
                                                    <TwitterIcon size={35} round/>
                                                </TwitterShareButton>
                                            </div>
                                            <div className={'share-btn-icons'}>
                                                <FacebookShareButton
                                                    url={`https://clykk.com/`}
                                                    quote={title}
                                                    className="Demo__some-network__share-button"
                                                >
                                                    <FacebookIcon size={35} round/>
                                                </FacebookShareButton>
                                            </div>
                                            <div style={{
                                                backgroundColor: '#CBEDE3',
                                                borderRadius: '50%',
                                                display: 'flex',
                                                padding: 5,
                                                width: 35
                                            }}>
                                                <a href={email} style={{
                                                    padding: 0,
                                                    textDecoration: "none",
                                                    margin: "auto"
                                                }}
                                                   target={'_blank'}>
                                                    <img src={gmail} style={{width: 22, margin: 'auto 0'}}/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-5 col-sm-12 col-xs-12">
                        <img src={SocialBannerBackground} className={'social-background-img'}/>
                    </div>
                    <div className="d-none d-sm-none d-md-none d-lg-block d-xl-block col-xl-1 col-lg-1"/>
                </div>
            </div>
        </div>
    );
}

export default SocialsBanner;
