import service1 from "../assets/images/service-img-1.png";
import service2 from "../assets/images/service-img-2.png";
import Footer from "../component/LandingPage/Footer";
import {BsTrash} from "react-icons/bs";
import {useSelector} from "react-redux";
import {numberWithCommas} from "../component/Utils";

const WishlistPage = () => {

    const services = [
        {
            img: service1,
            service_name: 'General Cleaning 1 BHK',
            location: 'Bengalore',
            price1: 3000.00,
            price2: 1500,
            offer: null
        },
        {
            img: service2,
            service_name: 'General Cleaning 1 BHK',
            location: 'Bengalore',
            price1: 3000.00,
            price2: 1500,
            offer: null
        },
        {
            img: service1,
            service_name: 'General Cleaning 1 BHK',
            location: 'Lucknow',
            price1: 3000.00,
            price2: 1500,
            offer: 'offer'
        },
        {
            img: service2,
            service_name: 'General Cleaning 2 BHK',
            location: 'Lucknow',
            price1: 3000.00,
            price2: 1500,
            offer: null
        },
        {
            img: service1,
            service_name: 'General Cleaning 2 BHK',
            location: 'Noida',
            price1: 3000.00,
            price2: 1500,
            offer: 'offer'
        },
        {
            img: service2,
            service_name: 'General Cleaning 2 BHK',
            location: 'Noida',
            price1: 3000.00,
            price2: 1500,
            offer: null
        },
        {
            img: service2,
            service_name: 'General Cleaning 2 BHK',
            location: 'Lucknow',
            price1: 3000.00,
            price2: 1500,
            offer: null
        },
        {
            img: service1,
            service_name: 'General Cleaning 2 BHK',
            location: 'Noida',
            price1: 3000.00,
            price2: 1500,
            offer: 'offer'
        },
        {
            img: service2,
            service_name: 'General Cleaning 2 BHK',
            location: 'Noida',
            price1: 3000.00,
            price2: 1500,
            offer: null
        },
        {
            img: service2,
            service_name: 'General Cleaning 2 BHK',
            location: 'Lucknow',
            price1: 3000.00,
            price2: 1500,
            offer: null
        },
        {
            img: service1,
            service_name: 'General Cleaning 2 BHK',
            location: 'Noida',
            price1: 3000.00,
            price2: 1500,
            offer: 'offer'
        },
        {
            img: service2,
            service_name: 'General Cleaning 2 BHK',
            location: 'Noida',
            price1: 3000.00,
            price2: 1500,
            offer: null
        },
        {
            img: service2,
            service_name: 'General Cleaning 2 BHK',
            location: 'Lucknow',
            price1: 3000.00,
            price2: 1500,
            offer: null
        },
        {
            img: service1,
            service_name: 'General Cleaning 2 BHK',
            location: 'Noida',
            price1: 3000.00,
            price2: 1500,
            offer: 'offer'
        },
        {
            img: service2,
            service_name: 'General Cleaning 2 BHK',
            location: 'Noida',
            price1: 3000.00,
            price2: 1500,
            offer: null
        }
    ]

    const currency = useSelector(state => state.accounts.currency)

    return (
        <>
            <div className={'container my-5'}>
                <p style={{fontSize: 25, fontWeight: 700}}>Wishlist</p>
                <div className="row">
                    {services.map((service) => {
                        return (
                            <div className={'col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 my-2'}>
                                <div
                                    onClick={() => {
                                        // history.push('/package-detail/')
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                        padding: 15,
                                        backgroundColor: '#f6f7f9',
                                        display: 'flex',
                                        borderRadius: 10,
                                        height: '100%'
                                    }}>
                                    <img src={service.img} className={'service-img'}/>
                                    <div className={'mx-2'} style={{display: 'flex', flexDirection: 'column'}}>
                                        <p className={'service-name'}>{service.service_name}</p>
                                        <p style={{fontSize: 12}}>{service.location}</p>
                                        <div style={{display: 'flex', marginTop: 'auto'}}>
                                            <p className={'service-price-1'}
                                               style={{color: 'red', marginRight: 22}}>{currency}{numberWithCommas(service.price1)}</p>
                                            <p className={'service-price-2'}>{currency}{numberWithCommas(service.price2)}</p>
                                        </div>
                                    </div>
                                    <div style={{
                                        height: '100%',
                                        margin: 'auto 0 auto auto',
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: '100%',
                                        }}>
                                            {service.offer &&
                                            <button className={'btn service-offer-btn'}>Offer</button>
                                            }
                                            <BsTrash style={{margin: 'auto 0 0 auto'}}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default WishlistPage;