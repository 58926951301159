import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Config from "../../Config";
import axios from "axios";
import {load_user_cart} from "../../actions/accounts";
import {BsBookmark, BsShare} from "react-icons/bs";
import {AiFillHeart} from "react-icons/ai";
import {Modal} from "@material-ui/core";
import ShareModal from "../PackageDetails/ShareModal";
import AddAlert from "../../AddAlert";

function AccountsLikedServicesTab() {
    const dispatch = useDispatch()

    const [likedServices, setLikedServices] = useState([])

    const [selectedLikedService, setSelectedLikedService] = useState({})

    const history = useHistory()

    const currency = useSelector(state => state.accounts.currency)
    const user = useSelector(state => state.accounts.data)
    const curr_location = useSelector(state => state.accounts.location)
    const location = useSelector(state => state.accounts.location)

    const config = Config()

    useEffect(() => {
        axios.get('https://cgapp.clykk.com/like/api/v2/user/packageLike', config)
            .then((response) => {
                setLikedServices(response.data.rs)
            })
            .catch(error => console.log(error.message))
    }, [location])

    const add_to_cart = (likedService) => {
        const config = Config()
        const get_date = (date) => {
            return date.length > 1 ? date : '0' + date
        }
        const date = new Date().toLocaleString().split(',')[0].split('/')

        let data = {qna: [], addOns: []}

        axios.get(`https://cgapp.clykk.com/catalog/api/v2/catalog-manager/package/${likedService.id}/qna`, config)
            .then((response) => {
                data.qna = response.data.qna
            })

        axios.get(`https://cgapp.clykk.com/catalog/api/v2/catalog-manager/package/${likedService.id}/addOn`, config)
            .then((response) => {
                data.qna = response.data.addOns
            })


        const body = {
            "status": 'todo',
            "qna": data.qna,
            "serviceAddress": curr_location,
            "deliveryAddress": curr_location,
            "schedule": {
                "serviceTime": "08:30",
                "startDate": date[2] + '-' + get_date(date[0]) + '-' + get_date(date[1]),
                "endDate": date[2] + '-' + get_date(date[0]) + '-' + get_date(date[1]),
                "subscriptionFrequency": "once",
                "weekdays": []
            },
            "items": {
                "package": {
                    id: likedService.id,
                    packageName: likedService.packageName,
                    shortDescription: likedService.shortDescription,
                    packageImage: likedService.packageImage,
                    basePrice: likedService.basePrice,
                    sellingPrice: likedService.sellingPrice,
                    serviceRuleID: likedService.subCategoryServiceRuleID,
                    subcategoryID: parseInt(likedService.subcategoryID)
                },
                "addOns": data.addOns.map(item => ({
                    packageName: item.addonName,
                    shortDescription: item.addonDescription,
                    packageimage: item.addonImage,
                    basePrice: item.basePrice,
                    sellingPrice: item.sellingPrice,
                    itemCount: item.itemCount
                }))
            }
        }

        axios.post(`https://cgapp.clykk.com/cart/api/v2/cart-management/cart`, body, config)
            .then(response => {
                dispatch(load_user_cart())
                AddAlert(dispatch, user.userId, body['items']['package'], 'ToDo', 'Pending', response.data.cartID)
                history.push('/cart/2')
            })
            .catch(e => console.log(e.message))
    }

    const disLike = (likedService) => {
        const config = Config()
        axios.post(`https://cgapp.clykk.com/like/api/v2/packageLike`,
            {"packageId": parseInt(likedService.id), "likes": 0}
            , config)
            .then(response => {
                setLikedServices(likedServices.filter(item => item.id !== likedService.id))
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    const [openShareModal, setShareModalOpen] = useState(false);
    const handleShareCloseModal = () => {
        setShareModalOpen(false)
    }
    const handleShareOpenModal = (likedService) => {
        setSelectedLikedService(likedService)
        setTimeout(() => {
            setShareModalOpen(true)
        }, 500)
    }

    return (
        <div className={'my-3'}>
            <div style={{display: 'flex', width: '100%'}}>
                <p style={{margin: 'auto 0', fontWeight: 600, fontSize: 15, color: '#424B5A'}}>
                    Liked Services
                </p>
            </div>

            <div className={'my-3 accounts-liked-list'}>
                {likedServices.map((likedService) => {
                    return (
                        <div className={'account-liked-service'}>
                            <div style={{display: 'flex', width: '100%'}} onClick={() => {
                                history.push(`/package-detail/${likedService.subcategoryID}/${likedService.id}`)
                            }}>
                                <div className={'account-liked-service-img'}
                                     style={{backgroundImage: `url(${likedService.packageImage})`}}>
                                </div>

                                <div className={'account-liked-services-text-container'}>
                                    <p className={'account-liked-services-text-name'}>{likedService.packageName}</p>
                                    <p className={'account-liked-services-text-desc'}>{likedService.shortDescription}</p>
                                </div>
                            </div>

                            <div className={'account-liked-controls'}>
                                <button className={'btn account-liked-controls-btn'}
                                        onClick={() => handleShareOpenModal(likedService)}>
                                    <BsShare/>
                                </button>
                                <button className={'btn account-liked-controls-btn'}
                                        onClick={() => {
                                            add_to_cart(likedService)
                                        }}>
                                    <BsBookmark/>
                                </button>
                                <button className={'btn account-liked-controls-btn'}
                                        onClick={() => {
                                            disLike(likedService)
                                        }}>
                                    <AiFillHeart style={{color: 'red'}}/>
                                </button>
                            </div>

                        </div>
                    )
                })}
            </div>

            {openShareModal &&
                <Modal
                    open={openShareModal}
                    onClose={handleShareCloseModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <ShareModal shareData={{
                        packageName: selectedLikedService.packageName,
                        shortDescription: selectedLikedService.shortDescription,
                        url: `https://clykk.com/package-detail/${selectedLikedService.subcategoryID}/${selectedLikedService.id}`,
                        id: selectedLikedService.id,
                        subcategoryID: selectedLikedService.subcategoryID
                    }} handleShareCloseModal={handleShareCloseModal}
                    />
                </Modal>
            }

        </div>
    );
}

export default AccountsLikedServicesTab;