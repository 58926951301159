import Footer from "../component/LandingPage/Footer";
import {useHistory, useLocation} from "react-router-dom";
import {BsChevronRight, BsThreeDotsVertical} from "react-icons/bs";
import {Box, Tab, Tabs, useTheme} from "@material-ui/core";
import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import clsx from "clsx";
import chat from '../assets/images/chat.png'
import pin from '../assets/images/pin copy 6.png'
import axios from "axios";
import Config from "../Config";
import {useDispatch, useSelector} from "react-redux";
import {load_user_cart} from "../actions/accounts";
import yellow_glitter from "../assets/images/yellow star.png";
import pink_glitter from "../assets/images/star.svg";
import purple_glitter from "../assets/images/glitter.png";
import green_glitter from "../assets/images/greenstar.png";
import {AiFillQuestionCircle} from "react-icons/ai";
import {Context} from "../Contexts/Context";
import {numberWithCommas} from "../component/Utils";

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            style={{backgroundColor: "white"}}
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <p>{children}</p>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const Cart = () => {

    const theme = useTheme();
    const dispatch = useDispatch()
    const history = useHistory()

    const currency = useSelector(state => state.accounts.currency)
    const cart = useSelector(state => state.accounts.cart)
    const isAuthenticated = useSelector(state => state.accounts.isAuthenticated)

    const {setFaqNum, handleOpenFaqModal} = useContext(Context);

    const location = useLocation()
    const status = location.pathname.split('/')[2]

    if (!isAuthenticated) {
        history.push('/')
    }

    const [cartItems, setCartItem] = useState(cart ? cart : [])
    const [cartNum, setCartNum] = useState("");
    const [todoNum, setTodoNum] = useState("");
    const [value, setValue] = useState(status ? 1 : 0);

    const handleChangeIndex = (index) => {
        setValue(index);
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const changeValue = (newValue) => {
        setValue(newValue)
    }

    const change_status = (id, status) => {
        const config = Config()
        axios.patch(`https://cgapp.clykk.com/cart/api/v2/cart-management/cart/${id}/status/${status}`
            , {}
            , config)
            .then((response) => {
                dispatch(load_user_cart())
            })
            .catch((e) => {
                console.log(e.message)
            })
    }

    const delete_cart = (id) => {
        const config = Config()
        axios.delete(`https://cgapp.clykk.com/cart/api/v2/cart-management/cart/${id}`, config)
            .then(response => {
                dispatch(load_user_cart())
            })
            .catch(err => console.log(err.message))
    }

    useEffect(() => {
        setCartItem(cart ? cart : [])
        let _cart = 0;
        let _todo = 0;
        if (cart) {
            cart.map((item) => {
                if (item.status === 'cart')
                    _cart = _cart + 1
                if (item.status === 'todo')
                    _todo = _todo + 1
            })
        }
        setCartNum(`Cart (${_cart})`)
        setTodoNum(`To-Do (${_todo})`)
    }, [cart])

    return (
        <div style={{position: 'relative'}}>
            <>
                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '5%', left: '70%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '10%', left: '10%'}}/>
                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 17, top: '15%', left: '60%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '25%', left: '90%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '30%', left: '20%'}}/>


                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '32%', left: '65%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 10, top: '40%', left: '55%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 17, top: '50%', left: '70%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '60%', left: '82%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '70%', left: '12%'}}/>

                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 17, top: '75%', left: '63%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '80%', left: '51%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 20, top: '85%', left: '87%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '90%', left: '52%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 17, top: '95%', left: '69%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '97%', left: '87%'}}/>
            </>
            <div className="container my-3">
                <div className={'tabs-lg'}>
                    <Tabs
                        value={value}
                        onChange={handleChange}>
                        <Tab label={cartNum} {...a11yProps(0)} />
                        <Tab label={todoNum}
                             style={{}} {...a11yProps(1)} />
                    </Tabs>
                </div>
                <div className={'tabs-sm mt-4'}>
                    <div onClick={() => {
                        changeValue(0)
                    }}
                         className={clsx('tab-container', value === 0 && 'cart-tab-active')}>
                        <p>{cartNum}</p>
                    </div>
                    <div
                        onClick={() => {
                            changeValue(1)
                        }}
                        className={clsx('tab-container', value === 1 && 'cart-tab-active')}>
                        <p>{todoNum}</p>
                    </div>
                </div>


                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    <TabPanel value={value} index={0} dir={theme.direction}>

                        <div style={{display: 'flex', marginBottom: 10}}>
                            <p style={{fontSize: 15, fontWeight: 600}}>Schedule Service</p>
                            <AiFillQuestionCircle
                                className={'faq-icon-selection-location'}
                                style={{margin: 'auto 0 auto auto', fontSize: 22}}
                                onClick={() => {
                                    setFaqNum(23)
                                    handleOpenFaqModal()
                                }}/>
                        </div>

                        {cartItems.map(item => {
                            return (
                                item.status === 'cart' &&
                                <div className={'cart-container'}>
                                    <div style={{width: '100%'}}>
                                        <div style={{display: 'flex'}}>
                                            <div className={'package-image-container'}
                                                 style={{backgroundImage: `url(${item.items.package.packageImage})`}}>
                                            </div>
                                            <p className={'cart-package-name'}>{item.items.package.packageName}</p>
                                            <img className={'cart-chat-img'}
                                                 onClick={() => {
                                                     history.push(`/chat/${item.cartID}/1`)
                                                 }}
                                                 src={chat}/>
                                            <p className={'cart-chat-id'}>{item.cartID}</p>
                                        </div>
                                        <div style={{display: 'flex', marginTop: 12}}>
                                            <img src={pin} style={{width: 10, height: 12, margin: "auto 0"}}/>
                                            <p style={{
                                                fontSize: 11,
                                                fontWeight: 600,
                                                color: '#A1A2A4',
                                                margin: "auto 0 auto 5px"
                                            }}>{item.serviceAddress.city}</p>
                                        </div>
                                        <div style={{display: 'flex', marginTop: 10, padding: '0 '}}>
                                            <div style={{margin: 'auto auto auto 0'}}>
                                                <p className={'cart-detail-heading'}>Date</p>
                                                <p className={'cart-detail-meta-text'}>{item.schedule.startDate}</p>
                                            </div>
                                            <div style={{margin: 'auto'}}>
                                                <p className={'cart-detail-heading'}>Time</p>
                                                <p className={'cart-detail-meta-text'}>{item.schedule.serviceTime}</p>
                                            </div>
                                            <div style={{margin: 'auto 0 auto auto'}}>
                                                <p className={'cart-detail-heading'}>Subscription</p>
                                                <p className={'cart-detail-meta-text'}>{item.schedule.subscriptionFrequency}</p>
                                            </div>
                                            <div style={{margin: 'auto 0 auto auto'}}>
                                                <p className={'cart-detail-heading'}>Price</p>
                                                <p className={'cart-detail-meta-text'}>{currency} {numberWithCommas(item.items.package.sellingPrice.toFixed(2))}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{marginLeft: 'auto', padding: '0 0 0 10px'}}>
                                        <div className="dropstart cart-dots">
                                            <BsThreeDotsVertical className={'cart-dots'}
                                                                 role="button"
                                                                 id="dropdownMenuLink"
                                                                 data-bs-toggle="dropdown"
                                                                 aria-expanded="false"/>
                                            <ul className="dropdown-menu"
                                                aria-labelledby="dropdownMenuLink">
                                                <li><a className="dropdown-item"
                                                       style={{fontSize: 12}}
                                                       onClick={() => {
                                                           delete_cart(item.cartID)
                                                       }}
                                                >Delete</a>
                                                </li>
                                                <li><a onClick={() => {
                                                    history.push(`/booking/${item.cartID}/`)
                                                }} className="dropdown-item"
                                                       style={{fontSize: 12}}>Schedule</a>
                                                </li>
                                                <li><a className="dropdown-item"
                                                       style={{fontSize: 12}}
                                                       onClick={() => {
                                                           change_status(item.cartID, 'todo')
                                                       }}
                                                >Move to To-Dos</a></li>
                                            </ul>
                                        </div>
                                        <BsChevronRight
                                            className={'cart-right-arrow'}
                                            onClick={() => {
                                                history.push(`/booking/${item.cartID}/`)
                                            }}/>
                                    </div>
                                </div>
                            )
                        })}
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>

                        <div style={{display: 'flex', marginBottom: 10}}>
                            <p style={{fontSize: 15, fontWeight: 600}}>Schedule Service</p>
                            <AiFillQuestionCircle
                                className={'faq-icon-selection-location'}
                                style={{margin: 'auto 0 auto auto', fontSize: 22}}
                                onClick={() => {
                                    setFaqNum(23)
                                    handleOpenFaqModal()
                                }}/>
                        </div>
                        {cartItems.map(item => {
                            return (
                                item.status === 'todo' &&
                                <div className={'cart-container'}>
                                    <div style={{width: '100%'}} onClick={() => {
                                        history.push(`/booking/${item.cartID}/`)
                                    }}>
                                        <div style={{display: 'flex'}}>
                                            <div className={'package-image-container'}
                                                 style={{backgroundImage: `url(${item.items.package.packageImage})`}}></div>
                                            <p className={'cart-package-name'}>{item.items.package.packageName}</p>
                                            <img className={'cart-chat-img'} src={chat}/>
                                            <p className={'cart-chat-id'}>{item.cartID}</p>
                                        </div>
                                        <div style={{display: 'flex', marginTop: 12}}>
                                            <img src={pin} style={{width: 10, height: 12}}/>
                                            <p style={{
                                                fontSize: 11,
                                                fontWeight: 400,
                                                color: '#A1A2A4',
                                                marginLeft: 5
                                            }}>{item.serviceAddress.city}</p>
                                        </div>
                                        <div style={{display: 'flex', marginTop: 10, padding: '0 '}}>
                                            <div style={{margin: 'auto auto auto 0'}}>
                                                <p className={'cart-detail-heading'}>Date</p>
                                                <p className={'cart-detail-meta-text'}>{item.schedule.startDate}</p>
                                            </div>
                                            <div style={{margin: 'auto'}}>
                                                <p className={'cart-detail-heading'}>Time</p>
                                                <p className={'cart-detail-meta-text'}>{item.schedule.serviceTime}</p>
                                            </div>
                                            <div style={{margin: 'auto 0 auto auto'}}>
                                                <p className={'cart-detail-heading'}>Subscription</p>
                                                <p className={'cart-detail-meta-text'}>{item.schedule.subscriptionFrequency}</p>
                                            </div>
                                            <div style={{margin: 'auto 0 auto auto'}}>
                                                <p className={'cart-detail-heading'}>Price</p>
                                                <p className={'cart-detail-meta-text'}>$ {item.items.package.sellingPrice}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{marginLeft: 'auto', padding: '0 5px 0 10px'}}>
                                        <div className="dropstart cart-dots">
                                            <BsThreeDotsVertical style={{fontSize: 17}}

                                                                 role="button"
                                                                 id="dropdownMenuLink"
                                                                 data-bs-toggle="dropdown"
                                                                 aria-expanded="false"/>
                                            <ul className="dropdown-menu"
                                                aria-labelledby="dropdownMenuLink">
                                                <li><a className="dropdown-item"
                                                       style={{fontSize: 12}}
                                                       onClick={() => {
                                                           delete_cart(item.cartID)
                                                       }}
                                                >Delete</a>
                                                </li>
                                                <li><a onClick={() => {
                                                    history.push(`/booking/${item.cartID}/`)
                                                }} className="dropdown-item"
                                                       style={{fontSize: 12}}>Schedule</a>
                                                </li>
                                                <li><a className="dropdown-item"
                                                       style={{fontSize: 12}}
                                                       onClick={() => {
                                                           change_status(item.cartID, 'cart')
                                                       }}
                                                >Move to Cart</a></li>
                                            </ul>
                                        </div>
                                        <BsChevronRight
                                            className={'cart-right-arrow'}
                                            onClick={() => {
                                                history.push(`/booking/${item.cartID}/`)
                                            }}/>
                                    </div>
                                </div>
                            )
                        })}
                    </TabPanel>
                </SwipeableViews>


            </div>

            <Footer/>
        </div>
    );
}

export default Cart;
