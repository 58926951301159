import {useContext, useEffect, useState} from 'react';
import yellow_glitter from "../assets/images/yellow star.png";
import pink_glitter from "../assets/images/star.svg";
import purple_glitter from "../assets/images/glitter.png";
import green_glitter from "../assets/images/greenstar.png";
import Footer from "../component/LandingPage/Footer";
import {AiFillQuestionCircle, AiOutlineArrowLeft} from "react-icons/ai";
import {useHistory, useLocation} from "react-router-dom";
import pin from "../assets/images/map-location pin.png";
import axios from "axios";
import {useSelector} from "react-redux";
import ReactStars from "react-rating-stars-component";
import clsx from "clsx";
import Config from "../Config";
import logo from "../assets/images/logo.svg";
import {Context} from "../Contexts/Context";
import {numberWithCommas} from "../component/Utils";
import {toast} from "react-hot-toast";

function ServiceRatingPage() {
    const history = useHistory()
    const [order, setOrder] = useState(null)
    const currency = useSelector(state => state.accounts.currency)
    const location = useLocation()
    const userData = useSelector(state => state.accounts.data)
    const {setFaqNum, handleOpenFaqModal} = useContext(Context);

    const [subscriptionTip, setSubscriptionTip] = useState(null)
    useEffect(() => {
        const config = Config()
        axios.get(`https://cgapp.clykk.com/cart/api/v2/cart-management/subscription/${scheduleId}/schedule`, config)
            .then(response => {
                response.data.transaction.map((transaction) => {
                    if (transaction['description'] === 'tips') {
                        setSubscriptionTip(transaction)
                    }
                })
            })
            .catch(error => {
                console.log(error.message)
            })
    }, [])

    const [rating, setRating] = useState(null)
    const ratingChanged = (newRating) => {
        setRating(newRating)
    };
    const [comment, setComment] = useState("")
    const handleChangeComment = (e) => {
        setComment(e.target.value)
    }

    const scheduleId = location.pathname.split('/')[2]
    const data = useSelector(state => state.accounts.data)

    useEffect(() => {
        const config = Config()
        axios.get(`https://cgapp.clykk.com/cart/api/v2/cart-management/subscription/${scheduleId}/schedule`, config)
            .then(response => {
                setOrder(response.data)
            })
            .catch(error => {
                console.log(error.message)
            })
    }, [])

    const tips = [0.05, 0.1, 0.15, 0.2]
    const [selectedTip, setSelectedTip] = useState(0)
    const [actualTip, setActualTip] = useState(0)

    const selectTip = (tip) => {
        setSelectedTip(tip)
    }

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function displayRazorpay() {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        const config = Config()
        const result = await axios.post(`https://cgapp.clykk.com/cart/api/v2/cart-management/cart/${order.cartID}/order/book`,
            {
                "total": parseFloat(actualTip),
                "payableAmount": actualTip * 100,
                "amountBeforeTax": 0,
                "taxAmount": 0,
                "paymentType": "onetime",
                "description": 'tips'
            },
            config
        )

        const {payableAmount, order_id} = result.data;

        const options = {
            key: "rzp_live_YdTGPolg4K9L7h", // Enter the Key ID generated from the Dashboard
            amount: payableAmount,
            currency: "INR",
            name: "CLYKK.",
            description: "CLYKK",
            image: {logo},
            order_id: order_id,
            handler: async function (response) {
                const data = {
                    orderCreationId: order_id,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                };
                axios.put(`https://cgapp.clykk.com/cart/api/v2/cart-management/subscription/${scheduleId}/addpayment`,
                    {
                        "total": parseFloat(actualTip),
                        "payableAmount": parseFloat(actualTip),
                        "amountBeforeTax": 0,
                        "taxAmount": 0,
                        "transactionID": data.razorpayPaymentId,
                        "paymentType": "onetime",
                        "description": 'tips'
                    }, config)
                    .then(response => {
                        toast.success('Payment Successful')
                    })
                    .catch(err => console.log(err.message))
            },
            prefill: {
                name: userData.firstName,
                email: userData.email,
                contact: userData.mobile,
            },
            notes: {
                address: "",
            },
            theme: {
                color: "#61dafb",
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    const submitFeedBack = () => {
        const config = Config()
        if (!rated) {
            axios.post(`https://cgapp.clykk.com/sp/clykk/ext/sp/v1/user/rating/${scheduleId}`,
                {
                    "rating": rating,
                    "description": comment
                }, config)
                .then(() => {
                    setRated(true)
                    toast.success("Rating Saved !")
                })
        }
        if (selectedTip !== '0') {
            displayRazorpay()
        }
    }

    const [rated, setRated] = useState(null)
    useEffect(() => {
        const config = Config()
        axios.get(`https://cgapp.clykk.com/sp/clykk/ext/sp/v1/user/rating?subscription_id=${scheduleId}`, config)
            .then(response => {
                if (response.data.data.data.length > 0) {
                    const rating = response.data.data.data[0]
                    setRating(rating.user_rating)
                    setComment(rating.description)
                    setRated(true)
                } else {
                    setRating(5)
                    setRated(false)
                }
            })
    }, [])

    return (
        <div style={{position: 'relative'}}>
            <>
                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '5%', left: '70%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '10%', left: '10%'}}/>
                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 17, top: '15%', left: '60%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '25%', left: '90%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '30%', left: '20%'}}/>


                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '32%', left: '65%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 10, top: '40%', left: '55%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 17, top: '50%', left: '70%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '60%', left: '82%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '70%', left: '12%'}}/>

                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 17, top: '75%', left: '63%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '80%', left: '51%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 20, top: '85%', left: '87%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '90%', left: '52%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 17, top: '95%', left: '69%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '97%', left: '87%'}}/>
            </>

            <div className="container mt-3 mb-3 ">
                <div style={{display: 'flex'}}>
                    <AiOutlineArrowLeft onClick={() => {
                        history.goBack()
                    }} style={{margin: "auto 10px auto 0", cursor: 'pointer'}}/>
                    <p className={'booking-heading'}>Service Rating</p>
                    <AiFillQuestionCircle
                        className={'faq-icon-selection-location booking-faq'}
                        onClick={() => {
                            setFaqNum(11)
                            handleOpenFaqModal()
                        }}/>
                </div>
            </div>

            <div className="container">
                {order &&
                    <>
                        <div style={{width: '100%'}}>
                            <div style={{display: 'flex'}}>
                                <div style={{width: '100%'}}>
                                    <div style={{display: 'flex'}}>
                                        <div className={'package-image-container'}
                                             style={{backgroundImage: `url(${order.package.packageImage})`}}>
                                        </div>
                                        <p className={'cart-package-name'}>{order.package.packageName}</p>
                                        <p className={'cart-chat-id'}
                                           style={{margin: "auto 0 auto auto"}}>{order.cartID} - {order.id}</p>
                                    </div>
                                    <div style={{display: 'flex', marginTop: 12}}>
                                        <img src={pin} style={{width: 10, height: 12}}/>
                                        <p style={{
                                            fontSize: 11,
                                            fontWeight: 400,
                                            color: '#A1A2A4',
                                            marginLeft: 5
                                        }}>{order.serviceAddress.line2}</p>
                                    </div>
                                    <div style={{display: 'flex', marginTop: 10, padding: '0 '}}>
                                        <div style={{margin: 'auto auto auto 0'}}>
                                            <p className={'cart-detail-heading'}>Date</p>
                                            <p className={'cart-detail-meta-text'}>{order.serviceDate}</p>
                                        </div>
                                        <div style={{margin: 'auto'}}>
                                            <p className={'cart-detail-heading'}>Time</p>
                                            <p className={'cart-detail-meta-text'}>{order.serviceTime}</p>
                                        </div>
                                        <div style={{margin: 'auto 0 auto auto'}}>
                                            <p className={'cart-detail-heading'}>Subscription</p>
                                            <p className={'cart-detail-meta-text'}>{order.subscriptionFrequency}</p>
                                        </div>
                                        <div style={{margin: 'auto 0 auto auto'}}>
                                            <p className={'cart-detail-heading'}>Price</p>
                                            <p className={'cart-detail-meta-text'}>{currency} {numberWithCommas(order.transaction[0].payableAmount)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{
                            width: '100%',
                            padding: 20,
                            display: 'flex',
                            backgroundColor: '#F6F7F9',
                            margin: '20px 0'
                        }}>
                            <div style={{margin: 'auto', textAlign: 'center'}}>
                                <p className={'service-rating-heading'}>
                                    Service Rating & Feedback
                                </p>
                                <p className={'service-rating-meta'}>Your feedback will help to improve
                                    us</p>
                                <div style={{width: '100%', display: 'flex'}}>
                                    <div style={{margin: "0 auto"}}>
                                        {(rating) &&
                                            <ReactStars
                                                count={5}
                                                edit={!rated}
                                                onChange={ratingChanged}
                                                size={24}
                                                value={rating}
                                                isHalf={true}
                                                emptyIcon={<i className="far fa-star"></i>}
                                                halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                fullIcon={<i className="fa fa-star"></i>}
                                                activeColor="#ffd700"
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <textarea style={{height: 120, width: '100%', fontSize: 12, marginBottom: 20}}
                                  value={comment} onChange={handleChangeComment}
                                  className={'form-control'} placeholder={'Additional Comment ...'}>

                        </textarea>

                        {!subscriptionTip ?
                            <>
                                <div style={{
                                    width: '100%',
                                    padding: 20,
                                    backgroundColor: '#F6F7F9',
                                    margin: '20px 0'
                                }}>
                                    <p style={{fontSize: 14, fontWeight: 700, color: '#424B5A'}}>Thank Your Service
                                        Provider
                                        with a
                                        tip</p>
                                    <p style={{fontSize: 12, fontWeight: 400, color: '#5A6167'}}>This amount will be
                                        credited to
                                        the
                                        service provider</p>
                                </div>

                                <div className={'row'} style={{marginBottom: 20}}>
                                    {tips.map((tip) => {
                                        return (
                                            <div className="col-xl-1 col-lg-1 col-md-2 col-sm-3 col-3 mb-3">
                                                <div
                                                    onClick={() => {
                                                        selectTip(tip)
                                                        setActualTip(Math.round(tip * order.transaction[0].payableAmount))
                                                    }}
                                                    className={clsx('tip-container', selectedTip === tip && 'selected-tip-container')}>
                                                    {currency}{numberWithCommas(Math.round(tip * order.transaction[0].payableAmount))}
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <div className="col-xl-1 col-lg-1 col-md-2 col-sm-3 col-3 mb-3">
                                        <input className={'form-control tip-container'} placeholder={'custom'}
                                               type={'number'}
                                               onChange={(e) => {
                                                   selectTip(0)
                                                   if (e.target.value !== '') {
                                                       setActualTip(e.target.value)
                                                   } else {
                                                       setActualTip(0)
                                                   }
                                               }}
                                        />
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-3 col-3 mb-3">
                                        <div
                                            onClick={() => {
                                                selectTip(0)
                                                setActualTip(0)
                                            }}
                                            className={clsx('tip-container no-thanks-btn')}>
                                            No Thanks
                                        </div>
                                    </div>
                                    <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                        <div onClick={submitFeedBack}
                                             style={{
                                                 borderRadius: 5,
                                                 padding: 10,
                                                 backgroundColor: "#6CD7BB",
                                                 display: 'flex',
                                                 color: 'white'
                                             }}>
                                            {actualTip !== 0 &&
                                                <p style={{
                                                    fontSize: 12,
                                                    fontWeight: 400,
                                                    margin: "auto 0"
                                                }}>{currency}{numberWithCommas(actualTip)}</p>
                                            }
                                            <p style={{
                                                fontSize: 12,
                                                fontWeight: 400,
                                                margin: "auto 0 auto auto"
                                            }}>{actualTip !== 0 ? "Submit Feedback & Tip"
                                                : "Submit Feedback"}</p>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div style={{
                                    width: '100%',
                                    padding: 20,
                                    backgroundColor: '#F6F7F9',
                                    margin: '20px 0'
                                }}>
                                    <p style={{fontSize: 14, fontWeight: 700, color: '#424B5A'}}>Service
                                        Provider thanks you for your tip of {currency}{subscriptionTip.payableAmount}</p>
                                </div>
                            </>
                        }
                    </>
                }
            </div>
            <Footer/>
        </div>
    );
}

export default ServiceRatingPage;