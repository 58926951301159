import React, {useContext, useEffect, useState} from 'react';
import {AiFillQuestionCircle} from "react-icons/ai";
import {Context} from "../../Contexts/Context";
import {useDispatch, useSelector} from "react-redux";
import {update_account_settings} from "../../actions/accounts";

function ApplicationSettingsTab() {
    const dispatch = useDispatch()
    const {setFaqNum, handleOpenFaqModal} = useContext(Context);
    const account_settings = useSelector(state => state.accounts.account_settings)

    const [accountSettings, setAccountSettings] = useState(account_settings)

    useEffect(() => {
        setAccountSettings(account_settings)
    }, [account_settings])

    const update_settings = (key, value) => {
        dispatch(update_account_settings({...accountSettings, [key]: value}))
    }

    return (
        <div className={'my-3'}>

            <div style={{display: 'flex', width: '100%'}}>
                <p style={{margin: 'auto 0', fontWeight: 600, fontSize: 15, color: '#424B5A'}}>
                    Application Settings
                </p>
                <AiFillQuestionCircle
                    onClick={() => {
                        setFaqNum(32)
                        handleOpenFaqModal()
                    }}
                    className={'accounts-question-icon'}/>
            </div>

            <div className={'my-3'}>
                <div className={'account-settings-item-container'}>
                    <div style={{marginRight: "auto"}}>
                        <p className={'account-settings-item-text'}>
                            SMS
                        </p>
                        <p className={'account-settings-item-meta-text'}>
                            Login OTP, Service Delivery OTP
                        </p>
                    </div>

                    <div className="form-check form-switch" style={{margin: "auto 0"}}>
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                               onChange={() => {update_settings('sms', !accountSettings.sms)}}
                               checked={accountSettings.sms} />
                    </div>
                </div>

                <div className={'account-settings-item-container'}>
                    <div style={{marginRight: "auto"}}>
                        <p className={'account-settings-item-text'}>
                            Media
                        </p>
                        <p className={'account-settings-item-meta-text'}>
                            Manage Profile & Provider Service Requirements
                        </p>
                    </div>
                    <div className="form-check form-switch" style={{margin: "auto 0"}}>
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                               onChange={() => {update_settings('media', !accountSettings.media)}}
                               checked={accountSettings.media}/>
                    </div>
                </div>

                <div className={'account-settings-item-container'}>
                    <div style={{marginRight: "auto"}}>
                        <p className={'account-settings-item-text'}>
                            Calender
                        </p>
                        <p className={'account-settings-item-meta-text'}>
                            Service Booking & Reminders
                        </p>
                    </div>
                    <div className="form-check form-switch" style={{margin: "auto 0"}}>
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                               onChange={() => {update_settings('calendar', !accountSettings.calendar)}}
                               checked={accountSettings.calendar}/>
                    </div>
                </div>

                <div className={'account-settings-item-container'}>
                    <div style={{marginRight: "auto"}}>
                        <p className={'account-settings-item-text'}>
                            Contacts
                        </p>
                        <p className={'account-settings-item-meta-text'}>
                            Share & Earn
                        </p>
                    </div>
                    <div className="form-check form-switch" style={{margin: "auto 0"}}>
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                               onChange={() => {update_settings('contacts', !accountSettings.contacts)}}
                               checked={accountSettings.contacts}/>
                    </div>
                </div>

                <div className={'account-settings-item-container'}>
                    <div style={{marginRight: "auto"}}>
                        <p className={'account-settings-item-text'}>
                            Calls
                        </p>
                        <p className={'account-settings-item-meta-text'}>
                            Avail Customer Support & Check Service Provider ETA
                        </p>
                    </div>
                    <div className="form-check form-switch" style={{margin: "auto 0"}}>
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                               onChange={() => {update_settings('calls', !accountSettings.calls)}}
                               checked={accountSettings.calls}/>
                    </div>
                </div>

                <div className={'account-settings-item-container'}>
                    <div style={{marginRight: "auto"}}>
                        <p className={'account-settings-item-text'}>
                            Location
                        </p>
                        <p className={'account-settings-item-meta-text'}>
                            Provider Direction
                        </p>
                    </div>
                    <div className="form-check form-switch" style={{margin: "auto 0"}}>
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                               onChange={() => {update_settings('location', !accountSettings.location)}}
                               checked={accountSettings.location}/>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default ApplicationSettingsTab;