import React, {useEffect, useState} from "react";
import {MdArrowBackIos, MdArrowForwardIos} from "react-icons/md";
import {Modal} from "@material-ui/core";
import category1 from "../../assets/images/category-1.png";
import category2 from "../../assets/images/category-2.png";
import category3 from "../../assets/images/category-3.png";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import Config from "../../Config";
import axios from "axios";

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const CategoryDropDown = () => {

    const history = useHistory()

    const [categories, setCategories] = useState([])

    const location = useSelector(state => state.accounts.location)

    useEffect(() => {
        const config = Config()
        axios.get(`https://cgapp.clykk.com/catalog/api/v2/catalog-manager/by-location/${location.city}`, config)
            .then(response => setCategories(response.data ? response.data : []))
            .catch(err => console.log(err.message))
    }, [])


    const [subCategory, setSubCategory] = useState([])

    const [modalStyle] = useState(getModalStyle);

    const [openModal, setOpenModal] = useState(false)

    const [selectCat, setSelectCat] = useState(null)

    const handleCategoryClick = (category) => {
        setSelectCat(category)
        const config = Config()
        axios.get(`https://cgapp.clykk.com/catalog/api/v2/catalog-manager/by-location/${location.city}/categories/${category.category_id}`, config)
            .then(response => setSubCategory(response.data ? response.data : []))
            .catch(err => console.log(err.message))
    }

    const handleCloseModal = () => {
        setOpenModal(!openModal)
    }

    const body = (
        <div style={modalStyle} className={'modal-paper'}>
            {selectCat &&
            <>
                <div className={'category-modal-navbar'}>
                    <MdArrowBackIos style={{margin: "auto 0", cursor: 'pointer', fontSize: 14}} onClick={() => {
                        setOpenModal(!openModal)
                    }}/>
                    <p className={'category-modal-navbar-text'}>{selectCat.category_name}</p>
                </div>
                {subCategory.map((sub_category) => {
                    return (
                        <div
                            onClick={() => {
                                history.push(`/sub-category-detail/${sub_category.category_name}/${selectCat.category_id}/${sub_category.sub_category_id}/`)
                            }}
                            className={'category-modal-container'}>
                            <img className={'modal-cat-img'}
                                 src={sub_category.category_image}/>
                            <p className={'modal-cat-text'}
                               style={{margin: "auto auto auto 0", fontWeight: 600}}>{sub_category.category_name}</p>
                            <MdArrowForwardIos style={{margin: "auto 10px", fontSize: 14}}/>
                        </div>
                    )
                })}
            </>
            }
        </div>
    )

    return (
        <div className="cat-dropdown">
            <div className={'row'} style={{backgroundColor: 'white'}}>
                {categories.map((cat) => {
                    return (
                        <div className={'col-xl-4 col-lg-4 mb-1 cat-dropdown-item'}
                             onClick={() => {
                                 handleCategoryClick(cat)
                                 setOpenModal(!openModal)
                             }}
                        >
                            <div style={{
                                display: 'flex',
                                borderRadius: 10,
                                width: 65,
                                padding: 10,
                                backgroundColor: '#F8FAFC'
                            }}>
                                <img src={cat.category_image} style={{width: '100%'}}/>
                            </div>
                            <p style={{fontSize: 8, fontWeight: 600}}>{cat.category_name}</p>
                        </div>
                    )
                })}
            </div>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </div>
    );
}

export default CategoryDropDown;