import React, {useContext, useState} from 'react';
import {AiFillQuestionCircle, AiOutlineClose} from "react-icons/ai";
import {Context} from "../../Contexts/Context";

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

function ProvideChecklistModal({cartItem, handleCloseModal}) {
    const [modalStyle] = useState(getModalStyle);
    const {setFaqNum, handleOpenFaqModal} = useContext(Context);

    return (
        <div style={modalStyle} className={'package-requirement-modal'}>
            <div style={{display: 'flex', marginBottom: 5}}>
                <AiOutlineClose style={{cursor: "pointer", marginLeft: 'auto'}}
                                onClick={handleCloseModal}/>
            </div>

            <div className={'package-requirement-modal-body'} style={{position: 'relative'}}>
                {cartItem.hasOwnProperty('items') ?
                    <img src={cartItem.items.package.packageImage} className={'w-100'}/>
                    :
                    <img src={cartItem.package.packageImage} className={'w-100'}/>
                }

                <div style={{display: 'flex', marginTop: 5}}>
                    <p className={'package-requirements-heading'}>Package Checklist</p>
                    <AiFillQuestionCircle
                        className={'faq-icon-selection-location package-requirements-modal'}
                        onClick={() => {
                            setFaqNum(27)
                            handleOpenFaqModal()
                        }}/>
                </div>

                {cartItem.qnA.map((qna, index) => {
                    return (
                        qna.questionType === 'checklist' &&
                        <>
                            <p style={{
                                fontSize: 12,
                                fontWeight: 600,
                                margin: '10px 0'
                            }}>{qna.questionName}</p>

                            <div className={'package-requirement-container'}>
                                {qna.answers.map((point) => {
                                    return (
                                        <p style={{
                                            fontSize: 12,
                                            color: '#98999B',
                                            marginBottom: 10
                                        }}>{point.answer}
                                        </p>
                                    )
                                })}
                            </div>

                        </>
                    )
                })}

            </div>

        </div>
    );
}

export default ProvideChecklistModal;