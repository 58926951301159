import React, {useState} from 'react';
import {AiOutlineClose} from "react-icons/ai";
import {useSelector} from "react-redux";
import {numberWithCommas} from "../Utils";
import {taxCodes} from "../../assets/taxCodes";

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

function TransactionDetail({subcategoryID, sellingPrice, cartID, selectedTransaction, closeTransactionDetailModal}) {
    const [modalStyle] = useState(getModalStyle);
    const currency = useSelector(state => state.accounts.currency);

    return (
        <div style={modalStyle} className={'transaction-detail-modal'}>

            <div style={{display: 'flex', marginBottom: 5}}>
                <AiOutlineClose style={{cursor: "pointer", marginLeft: 'auto'}}
                                onClick={closeTransactionDetailModal}/>
            </div>

            <p className={'transaction-detail-header'}>Transaction Details</p>

            <hr/>

            {selectedTransaction !== null &&
                <>
                    <div style={{display: 'flex', marginBottom: 10}}>
                        <p className={'transaction-detail-key'}>Order</p>
                        <p className={'transaction-detail-value'}>{cartID} - {selectedTransaction.subscriptionID}</p>
                    </div>

                    <div style={{display: 'flex', marginBottom: 10}}>
                        <p className={'transaction-detail-key'}>Payment</p>
                        <p className={'transaction-detail-value'}>{selectedTransaction.transactionID}</p>
                    </div>

                    <div style={{display: 'flex', marginBottom: 10}}>
                        <p className={'transaction-detail-key'}>Sub Total</p>
                        <p className={'transaction-detail-value'}>{currency} {numberWithCommas(parseFloat(selectedTransaction.total).toFixed(2))}</p>
                    </div>

                    <div style={{display: 'flex', marginBottom: 10}}>
                        <p className={'transaction-detail-key'}>Discount</p>
                        <p className={'transaction-detail-value'}>{selectedTransaction.discount}</p>
                    </div>

                    <div style={{display: 'flex', marginBottom: 10}}>
                        <p className={'transaction-detail-key'}>Discount Value</p>
                        {selectedTransaction.description === 'order' ?
                            <p className={'transaction-detail-value'}>{currency} {numberWithCommas(parseFloat(sellingPrice) - parseFloat(selectedTransaction.payableAmount).toFixed(2))}</p>
                            :
                            <p className={'transaction-detail-value'}>-</p>
                        }
                    </div>

                    <div style={{display: 'flex', marginBottom: 10}}>
                        <p className={'transaction-detail-key'}>Taxes (GST -
                            SAC {taxCodes[subcategoryID]?.code} - {taxCodes[subcategoryID]?.percentage * 100}%)</p>
                        <p className={'transaction-detail-value'}>{currency} {numberWithCommas(parseFloat(selectedTransaction.taxAmount).toFixed(2))}</p>
                    </div>
                    <div style={{display: 'flex', marginBottom: 10}}>
                        <p className={'transaction-detail-key'}>Paid Amount</p>
                        <p className={'transaction-detail-value'}>{currency} {numberWithCommas(parseFloat(selectedTransaction.payableAmount).toFixed(2))}</p>
                    </div>

                    <div style={{display: 'flex', marginBottom: 10}}>
                        <p className={'transaction-detail-key'}>Payment Type</p>
                        <p className={'transaction-detail-value'}>{selectedTransaction.paymentType}</p>
                    </div>

                    <div style={{display: 'flex', marginBottom: 10}}>
                        <p className={'transaction-detail-key'}>Payment Status</p>
                        <p className={'transaction-detail-value'}>{selectedTransaction.collectionStatus}</p>
                    </div>
                </>
            }

        </div>
    );
}

export default TransactionDetail;