import clsx from "clsx";
import Navbar from "../Navbar";
import SubCatHero from "./SubCatHero";

const SubCatHeader = ({isMobile, setShowSidebar,handleOpenModal}) => {
    return (
        <div className={'cat-header'}>
            <div className={clsx(!isMobile ? "container" : "header-container-sm", 'header-container')}>
                <Navbar setShowSidebar={setShowSidebar} handleOpenModal={handleOpenModal}/>
            </div>
            {/*<SubCatHero />*/}
        </div>
    );
}

export default SubCatHeader;