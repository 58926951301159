import ApplyStore from "../../assets/images/apply-stone-btn.png";
import StoreBtn from "../Hero/StoreBtn";
import GoogleStore from "../../assets/images/google-play-btn.png";
import {BsHourglassSplit} from "react-icons/bs";
import {GiMagnifyingGlass} from "react-icons/gi";
import {ImPriceTags} from "react-icons/im";
import {useSelector} from "react-redux";

const JoinUsBanner = () => {

    const userType = useSelector(state => state.accounts.userType)
    return (
        <div style={{width: "100%", backgroundColor: '#e3f8fa'}}>
            <div className={'container p-5 my-2'} style={{textAlign: 'left'}}>
                <div className={'join-us-heading-container'}>
                    <p className={'join-us-heading'}>Join us now!</p>
                </div>
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 my-3"
                         style={{display: 'flex'}}>
                        <div className={'join-us-left'}>
                            <p className={'join-us-meta-text'}>What can Consumers Expect</p>
                            <div className={'join-us-item-container'}>
                                <GiMagnifyingGlass
                                    className={'join-us-icon'}/>
                                <p className={'join-us-item-text'}>Transparency
                                    and
                                    Details of Services Offered</p>
                            </div>

                            <div className={'join-us-item-container'}>
                                <ImPriceTags
                                    className={'join-us-icon'}/>
                                <p className={'join-us-item-text'}>Standardized
                                    Pricing and Satisfaction Guarantee</p>
                            </div>

                            <div className={'join-us-item-container'}>
                                <BsHourglassSplit
                                    className={'join-us-icon'}/>
                                <p className={'join-us-item-text'}>Time is
                                    the
                                    New
                                    Currency -Saves Money & Time</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 my-3" style={{display: 'flex'}}>
                        <div className="row join-btn-container g-0">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 my-3"
                                 style={{display: 'flex'}}>
                                <div className={'download-btn-left'}>
                                    <StoreBtn img={ApplyStore} text={userType} store={'App Store'}
                                      url={userType === 'Customer' ? 'https://apps.apple.com/us/app/clykk/id1542592627?platform=iphone' : 'https://apps.apple.com/us/app/clykk-service-provider/id1550083789'}/>
                                </div>
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 my-1"
                                 style={{display: 'flex'}}>
                                <div className={'download-btn-right'}>
                                    <StoreBtn img={GoogleStore} text={userType} store={'Google Play'}
                                      url={userType === 'Customer' ? 'https://play.google.com/store/apps/details?id=com.clykk' : 'https://play.google.com/store/apps/details?id=com.clykksp'}/>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default JoinUsBanner;