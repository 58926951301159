import {DistanceMatrixService} from "@react-google-maps/api";
import edit_pen from '../../assets/images/Editpen.png'
import Footer from "../LandingPage/Footer";
import {useHistory, useLocation} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import clsx from "clsx";
import {useDispatch, useSelector} from "react-redux";
import BookingAddress from "./BookingAddress";
import axios from "axios";
import Config from "../../Config";
import {load_user_cart} from "../../actions/accounts";
import {toast} from "react-hot-toast";
import yellow_glitter from "../../assets/images/yellow star.png";
import pink_glitter from "../../assets/images/star.svg";
import purple_glitter from "../../assets/images/glitter.png";
import green_glitter from "../../assets/images/greenstar.png";
import {AiFillQuestionCircle} from "react-icons/ai";
import {Modal} from "@material-ui/core";
import ProvideRequirementsModal from "./ProvideRequirementsModal";
import SubscriptionOrder from "./SubscriptionOrder";
import {Context} from "../../Contexts/Context";
import {numberWithCommas} from "../Utils";
import {taxCodes} from "../../assets/taxCodes";

const Booking = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const location = useLocation()
    const {setFaqNum, handleOpenFaqModal} = useContext(Context);

    const [cartItem, setCartItem] = useState(null)

    const [subscriptions, setSubscriptions] = useState([])
    const [slide, setSlide] = useState(1)
    const [subTotalPrice, setSubTotalPrice] = useState(0)
    const [totalPrice, setTotalPrice] = useState(0)
    const [savingPrice, setSavingPrice] = useState(0)
    const [couponPrice, setCouponPrice] = useState(0)
    const [offers, setOffers] = useState([])
    const [selectedOffer, setSelectedOffer] = useState({
        discountCode: ""
    })

    const [allowDiscountChange, setAllowDiscountChange] = useState(false)
    const [schedule, setSchedule] = useState({
        startDate: "",
        endDate: '',
        serviceTime: "",
        subscriptionFrequency: "once",
        weekdays: []
    })
    const [allowedTime, setAllowedTime] = useState([])
    const [moneySavedByCoupon, setMoneySavedByCoupon] = useState(0)

    const [distance, setDistance] = useState(1)

    const data = useSelector(state => state.accounts.data)
    const cart = useSelector(state => state.accounts.cart)
    const currency = useSelector(state => state.accounts.currency)

    const cartID = location.pathname.split('/')[2]

    const addOnsApiUpdate = (item, itemCount, id, cartID) => {
        const config = Config()
        axios.put(`https://cgapp.clykk.com/cart/api/v2/cart-management/cart/${cartID}/addon/${id}`
            , {...item, itemCount: itemCount}
            , config
        )
            .then((response) => {
                const addOns = cartItem.items.addOns.map((addOn) => {
                    if (item.id === addOn.id)
                        return response.data
                    else
                        return addOn
                })
                setCartItem((prev) => {
                    return {...prev, items: {...prev.items, addOns: addOns}}
                })
                dispatch(load_user_cart())
            })
    }

    const increase_decrease_add_ons = (item, sign) => {
        if (sign === '+') {
            addOnsApiUpdate(item, item.itemCount + 1, item.id, cartItem.cartID)
        } else {
            if (item.itemCount > 0) {
                addOnsApiUpdate(item, item.itemCount - 1, item.id, cartItem.cartID)
            }
        }
    }

    const handleCoupOnChange = (e) => {
        if (e.target.value) {
            setSelectedOffer(offers.filter(offer => offer.discountCode === e.target.value)[0])
        } else {
            setSelectedOffer({
                discountCode: ""
            })
        }
        setAllowDiscountChange(true)
    }

    const getRandomNumber = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const get_offer_option = (offer) => {
        if ((offer.userID === 0 || offer.userID === data.userId) && (subTotalPrice - offer.maxDiscount) > 25) {
            return (
                <option className={'coupon-options'}
                        value={offer.discountCode}>
                    {offer.title} &nbsp;&nbsp;
                    {offer.discountType === 'value'
                        ? `max ${currency}${offer.maxDiscount} OFF`
                        : `max ${offer.maxDiscount}% OFF`}
                </option>
            )
        }
    }

    useEffect(() => {
        const config = Config()
        axios.get(`https://cgapp.clykk.com/cart/api/v2/cart-management/cart/${cartID}`, config)
            .then(response => {
                setCartItem(response.data)
                axios.get(`https://cgapp.clykk.com/discount/api/v2/discount-management/city/27?default=true`, config)
                    .then(resp => {
                        setOffers(
                            resp.data.filter(
                                offer => (offer.status === 'approved') &&
                                    (offer.subcategoryID === response.data.items.package.subcategoryID || offer.subcategoryID === 0)
                            ))
                    })
                    .catch(err => console.log(err.message))
            })
            .catch(err => console.log(err.message))
    }, [cart])

    const calculateServiceTime = (openTime, closeTime, ServiceDuration) => {
        const openTimeInt = parseInt(openTime.substring(0, 2))
        const closeTimeInt = parseInt(closeTime.substring(0, 2))
        const openTimeSecInt = parseInt(openTime.substring(3, 5))

        let times = []
        for (let i = openTimeInt; i <= closeTimeInt; i = i + 1) {
            if (i === openTimeInt && openTimeSecInt > 30) {

            } else if (i === openTimeInt && openTimeSecInt < 30) {
                times.push(`${i}:30`)
            } else {
                times.push(`${i}:00`)
                times.push(`${i}:30`)
            }
        }

        setAllowedTime(times)
    }

    useEffect(() => {
        if (cartItem !== null) {
            const config = Config()
            axios.get(`https://cgapp.clykk.com/routing/api/v2/ext/route-setting/routes?cityID=27&ruleID=${cartItem.items.package.serviceRuleID}&subcategoryID=${cartItem.items.package.subcategoryID}&date=${schedule.startDate}`, config)
                .then(resp => {
                    const data = resp.data
                    const openTime = data.ServiceTime.substring(11, 16)
                    const closeTime = data.LastServiceTime.substring(11, 16)

                    if (schedule.startDate === data.ServiceTime.substring(0, 10)) {
                        calculateServiceTime(openTime, closeTime, parseInt(data.ServiceDuration))
                    } else {
                        toast.error('No service available on this date. Please another date')
                        setAllowedTime([])
                    }
                })
                .catch(err => console.log(err.message))
        }
    }, [schedule.startDate])

    const [onePrice, setOnePrice] = useState(0)
    const [tax, setTax] = useState(0)

    useEffect(() => {
        if (cartItem) {

            let subTotal = cartItem.items.package.sellingPrice
            let base = cartItem.items.package.basePrice

            if (cartItem.items.package.serviceRuleID !== 1) {
                subTotal = (cartItem.items.package.basePrice) / 2 + cartItem.items.package.sellingPrice * distance
                base = cartItem.items.package.basePrice + cartItem.items.package.sellingPrice * distance
            }

            let saving = 0
            let payable = 0

            let _onePrice = 0

            if (cartItem.items.hasOwnProperty('addOns')) {
                cartItem.items.addOns.map((item) => {
                    subTotal += item.sellingPrice * item.itemCount
                    base += item.basePrice * item.itemCount
                })
            }
            saving = base.toFixed(2) - subTotal.toFixed(2)
            saving = saving * subscriptions.length
            payable = subTotal

            _onePrice = payable

            subTotal = parseFloat((subTotal * subscriptions.length).toFixed(2))
            payable = payable * subscriptions.length

            if (selectedOffer.discountCode !== '') {

                if (subTotal - selectedOffer.maxDiscount <= 25) {
                    setSelectedOffer({discountCode: ""})
                }

                let discount = couponPrice

                if (allowDiscountChange)
                    discount = getRandomNumber(selectedOffer.minDiscount, selectedOffer.maxDiscount)

                if (selectedOffer.discountType === 'value') {
                    discount = Math.round(parseFloat(parseFloat(discount).toFixed(2)))
                    payable = payable - discount
                    saving = saving + discount
                    setMoneySavedByCoupon(discount)
                } else {
                    payable = payable - Math.round(parseFloat(parseFloat(payable * discount / 100).toFixed(2)))
                    const saved_by_coupon = Math.round(parseFloat(subTotal * discount / 100).toFixed(2))

                    saving = parseFloat(saving) + parseFloat(saved_by_coupon)
                    setMoneySavedByCoupon(saved_by_coupon)
                }
                setCouponPrice(discount)
                setAllowDiscountChange(false)
            }
            let tax = 0
            setOnePrice(parseFloat(_onePrice).toFixed(2))
            setSavingPrice(parseFloat(saving).toFixed(2))
            setSubTotalPrice(parseFloat(subTotal).toFixed(2))
            tax = payable * taxCodes[cartItem.items.package.subcategoryID]?.percentage
            setTax(parseFloat(tax).toFixed(2))
            setTotalPrice(parseFloat(payable + tax).toFixed(2))
        }
    }, [cartItem, selectedOffer])

    const [openSubscribe, setOpenSubscribe] = useState(false)
    useEffect(() => {
        if (openSubscribe) {
            setSchedule({...schedule, subscriptionFrequency: "daily"})
        } else {
            setSchedule({
                ...schedule,
                subscriptionFrequency: "once",
                weekdays: [],
                endDate: '',
            })
        }
    }, [openSubscribe])

    const proceed_to_checkout = () => {
        // toast("Placing an order or booking will be enabled for customers late January / Early February 2023", {duration: 5000})
        // return

        localStorage.setItem('cartID', cartID)
        localStorage.setItem('couponName', selectedOffer.title)
        localStorage.setItem('discountCode', selectedOffer.discountCode)
        localStorage.setItem('discountType', selectedOffer.discountType)
        localStorage.setItem('discount', selectedOffer.discount)
        localStorage.setItem('moneySavedByCoupon', moneySavedByCoupon)
        localStorage.setItem('subTotal', subTotalPrice)
        localStorage.setItem('totalPrice', totalPrice)
        localStorage.setItem('savingPrice', savingPrice)
        localStorage.setItem('tax', tax)
        localStorage.setItem('taxCode', taxCodes[cartItem.items.package.subcategoryID]?.code)
        localStorage.setItem('taxPercentage', taxCodes[cartItem.items.package.subcategoryID]?.percentage * 100)
        history.push(`/check-out/`)
    }

    // const [load, setLoad] = useState(false)
    // useEffect(() => {
    //     setTimeout(() => {
    //         setLoad(true)
    //     }, 1000)
    // }, [])

    const [openModal, setOpenModal] = useState(false)
    const handleOpenModal = () => {
        setOpenModal(true)
    }
    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const [images, setImages] = useState([])
    const [imgs, setImgs] = useState([])

    const [requirementsComplete, setRequirementsComplete] = useState(false)
    useEffect(() => {
        if (cartItem !== null) {
            let complete = true

            cartItem.qnA.map((question) => {
                if (question.questionType === "requirements") {
                    question.answers.map((answer) => {
                        if (answer.answerType === 'text' && answer.customerAnswer === null) {
                            complete = false
                        }
                    })
                }
            })

            setRequirementsComplete(complete)
        }
    }, [cartItem])


    return (
        <div style={{position: 'relative'}}>
            <>
                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 12, top: '5%', left: '70%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 12, top: '10%', left: '10%'}}/>
                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 10, top: '12%', left: '60%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 10, top: '31%', left: '20%'}}/>


                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 12, top: '32%', left: '65%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 10, top: '40%', left: '55%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 10, top: '50%', left: '70%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '60%', left: '82%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '70%', left: '12%'}}/>

                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 12, top: '75%', left: '65%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 12, top: '80%', left: '49%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '84%', left: '15%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '97%', left: '87%'}}/>
            </>

            <div className={'container my-3'}>
                {cartItem &&
                    <>
                        {
                            cartItem.items.package.serviceRuleID !== 1 &&
                            <DistanceMatrixService
                                options={{
                                    destinations: [{
                                        lng: cartItem.serviceAddress.longitude,
                                        lat: cartItem.serviceAddress.latitude
                                    }],
                                    origins: [{
                                        lng: cartItem.deliveryAddress.longitude,
                                        lat: cartItem.deliveryAddress.latitude
                                    }],
                                    travelMode: "DRIVING",
                                }}
                                callback={(res) => {
                                    try {
                                        const distance = res.rows[0].elements[0].distance.value
                                        if (Math.round(distance / 1000) > 0) {
                                            setDistance(Math.ceil(distance / 1000))
                                        } else {
                                            setDistance(1)
                                        }
                                    } catch {
                                        setDistance(1)
                                    }
                                }}
                            />
                        }

                        <img src={cartItem.items.package.packageImage} className={'w-100 booking-main-img'}/>

                        <div className="row mb-5">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2">

                                <div style={{display: 'flex', marginTop: 12}}>
                                    <p style={{fontSize: 14, fontWeight: 600}}>Booking Details</p>
                                    <AiFillQuestionCircle
                                        className={'faq-icon-selection-location'}
                                        style={{margin: 'auto 0 auto auto', fontSize: 25}}
                                        onClick={() => {
                                            setFaqNum(24)
                                            handleOpenFaqModal()
                                        }}/>
                                </div>

                                {cartItem.items.package.serviceRuleID === 1 &&
                                    <>
                                        <BookingAddress
                                            cartItem={cartItem} setCartItem={setCartItem}
                                            addressHeading={'Service Address'}
                                            addressType={'service'}/>
                                    </>
                                }
                                {cartItem.items.package.serviceRuleID === 2 &&
                                    <>
                                        <BookingAddress
                                            cartItem={cartItem} setCartItem={setCartItem}
                                            addressHeading={'Your Address'}
                                            addressType={'service'}/>
                                        <BookingAddress
                                            cartItem={cartItem} setCartItem={setCartItem}
                                            addressHeading={'Drop Location'}
                                            addressType={'delivery'}/>
                                    </>
                                }
                                {cartItem.items.package.serviceRuleID === 3 &&
                                    <>
                                        <BookingAddress
                                            cartItem={cartItem} setCartItem={setCartItem}
                                            addressHeading={'Customer Address'}
                                            addressType={'service'}
                                            serviceRuleID={3}
                                        />
                                    </>
                                }
                                {cartItem.items.package.serviceRuleID === 4 &&
                                    <>
                                        <BookingAddress
                                            cartItem={cartItem} setCartItem={setCartItem}
                                            addressHeading={'Pickup Address'}
                                            addressType={'service'}/>
                                        <BookingAddress
                                            cartItem={cartItem} setCartItem={setCartItem}
                                            addressHeading={'Delivery Address'}
                                            addressType={'delivery'}/>
                                    </>
                                }

                                <div style={{width: '100%'}}>

                                    <div style={{display: 'flex', marginBottom: 10}}>
                                        <p className={'booking-packing-heading'} onClick={() => {
                                            history.push(`/update-package-detail/${cartItem.cartID}/`)
                                        }}>Package</p>
                                        <img src={edit_pen} className={'booking-package-edit-icon'}
                                             onClick={() => {
                                                 history.push(`/update-package-detail/${cartItem.cartID}/`)
                                             }}/>
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        <div style={{margin: 'auto 10px auto 0'}}>
                                            <p className={'booking-package-name'}>{cartItem.items.package.packageName}
                                                {cartItem.items.package.serviceRuleID !== 1 &&
                                                    <span>
                    [ 0 - {distance} km]
                    </span>
                                                }
                                            </p>
                                        </div>
                                        <div style={{margin: 'auto 0 auto auto'}}>

                                            {cartItem.items.package.serviceRuleID !== 1
                                                ?
                                                <>
                                                    <p className={'booking-base-price'}>
                                                        <del>{currency}{numberWithCommas((cartItem.items.package.basePrice + cartItem.items.package.sellingPrice * distance).toFixed(2))}</del>
                                                    </p>
                                                    <p className={'booking-selling-price'}>{currency}{numberWithCommas(((cartItem.items.package.basePrice) / 2 + cartItem.items.package.sellingPrice * distance).toFixed(2))}</p>
                                                </>
                                                :
                                                <>
                                                    <p className={'booking-base-price'}>
                                                        <del>{currency}{numberWithCommas((cartItem.items.package.basePrice).toFixed(2))}</del>
                                                    </p>
                                                    <p className={'booking-selling-price'}>{currency}{numberWithCommas((cartItem.items.package.sellingPrice).toFixed(2))}</p>
                                                </>
                                            }
                                        </div>
                                    </div>


                                    <p className={'package-add-on-text'}>Package Add-On's</p>
                                    <div style={{margin: "10px 0"}}>
                                        {cartItem.items.hasOwnProperty('addOns') &&
                                            cartItem.items.addOns.map((item) => {
                                                return (
                                                    <div style={{display: 'flex'}} className={'mb-3'}>
                                                        <img src={item.packageimage} className={'package-add-img'}/>
                                                        <div className={'package-add-on-services-text-container'}>
                                                            <p className={'package-add-on-service'}>{item.packageName}</p>
                                                        </div>
                                                        <div className={'booking-price-container'}>
                                                            <p className={'package-add-on-price-1'}>
                                                                <del>{currency}{numberWithCommas(parseFloat(item.basePrice).toFixed(2))}</del>
                                                            </p>
                                                            <p className={'package-add-on-price-2'}>{currency}{parseFloat(item.sellingPrice).toFixed(2)}</p>
                                                        </div>
                                                        <div
                                                            className={'package-add-on-quantity-container'}>
                                                            <div className={'quantity-controller-container'}
                                                                 onClick={() => {
                                                                     increase_decrease_add_ons(item, '-')
                                                                 }}
                                                            >
                                                                <p className={'quantity-controller'}>-</p>
                                                            </div>
                                                            <p className={'quantity'}
                                                               style={{margin: 'auto'}}>{item.itemCount}</p>
                                                            <div className={'quantity-controller-container'}
                                                                 onClick={() => {
                                                                     increase_decrease_add_ons(item, '+')
                                                                 }}
                                                            >
                                                                <p className={'quantity-controller'}>+</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                    </div>

                                    <hr/>

                                    <div style={{display: 'flex', marginBottom: 10}}>
                                        <p style={{fontSize: 12}}>Total</p>
                                        <p style={{marginLeft: 'auto', fontSize: 12}}>
                                            {currency} {numberWithCommas(onePrice)}
                                        </p>
                                    </div>

                                    <div style={{display: 'flex'}}>
                                        <p className={'provide-requirement-link'}
                                           style={{
                                               marginRight: 'auto',
                                               fontSize: 12,
                                               fontWeight: 700,
                                               cursor: "pointer"
                                           }}
                                           onClick={handleOpenModal}
                                        >
                                            Provide Requirements
                                        </p>
                                        <button
                                            onClick={handleOpenModal}
                                            className={clsx('btn btn-provide-requirement', requirementsComplete ? 'complete-status' : 'incomplete-status')}>
                                            {requirementsComplete ? "Complete" : "Incomplete"}
                                        </button>
                                        {/*<img src={edit_pen} className={'booking-package-edit-icon'} style={{marginLeft: 10}}*/}
                                        {/*     onClick={handleOpenModal}*/}
                                        {/*/>*/}
                                    </div>

                                </div>

                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">

                                <div style={{display: 'flex', marginTop: 12}}>
                                    <p style={{fontSize: 14, fontWeight: 600}}>Service Date - Time</p>
                                    <img src={edit_pen} className={'booking-address-icon'} style={{marginLeft: 'auto'}}
                                         onClick={() => {
                                             setSubscriptions([])
                                             setSelectedOffer({
                                                 discountCode: ""
                                             })
                                             setSlide(1)
                                         }}
                                    />
                                    <AiFillQuestionCircle
                                        className={'faq-icon-selection-location'}
                                        style={{margin: 'auto 0 auto 10px', fontSize: 25}}
                                        onClick={() => {
                                            setFaqNum(25)
                                            handleOpenFaqModal()
                                        }}/>
                                </div>

                                {slide === 1 &&
                                    <SubscriptionOrder
                                        cartItem={cartItem}
                                        setCartItem={setCartItem}
                                        setSubscriptions={setSubscriptions}
                                        schedule={schedule}
                                        setSchedule={setSchedule}
                                        openSubscribe={openSubscribe}
                                        setOpenSubscribe={setOpenSubscribe}
                                        slide={slide}
                                        setSlide={setSlide}
                                        allowedTime={allowedTime}
                                        orderDetail={false}
                                    />
                                }
                                {slide === 2 &&
                                    <div style={{width: '100%'}}>
                                        <div style={{
                                            padding: 15,
                                            backgroundColor: '#F6F7F9',
                                            borderRadius: 5,
                                            marginBottom: 10
                                        }}>
                                            {subscriptions.map((subscription) => {
                                                return (
                                                    <div className="row g-0 mb-2">
                                                        <div
                                                            className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
                                                            <p className={'schedule-info-text'}>Date</p>
                                                            <p className={'schedule-info-detail'}>{subscription.substring(0, 10)}</p>
                                                        </div>
                                                        <div
                                                            className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
                                                            <p className={'schedule-info-text'}>Time</p>
                                                            <p className={'schedule-info-detail'}>{schedule.serviceTime}</p>
                                                        </div>
                                                        <div
                                                            className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
                                                            <p className={'schedule-info-text'}>Subscription</p>
                                                            <p className={'schedule-info-detail'}>
                                                                {openSubscribe ? schedule.subscriptionFrequency : "One Time"}
                                                            </p>
                                                        </div>
                                                        <div
                                                            className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 col-3">
                                                            <p className={'schedule-info-text'}>Price</p>
                                                            <p className={'schedule-info-detail'}>{currency}{numberWithCommas(onePrice)}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>

                                        <div style={{padding: 15, backgroundColor: '#F6F7F9', borderRadius: 5}}>
                                            <div style={{display: 'flex', width: '100%'}}>
                                                <p className={'apply-coupon-text'}>
                                                    Apply Coupon Code
                                                </p>
                                                <div style={{display: 'flex'}}>
                                                    <div className={'code-container'}
                                                         style={{margin: 'auto 0 auto auto'}}>
                                                        <select className="form-select coupon-select"
                                                                value={selectedOffer.discountCode}
                                                                onChange={handleCoupOnChange}
                                                                aria-label="Default select example">
                                                            <option value={""}>Select Coupon</option>
                                                            {offers.map((offer) => {
                                                                return (
                                                                    get_offer_option(offer)
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>


                                            <div style={{width: '100%', display: 'flex', margin: '10px 0 10px 0'}}>
                                                <p className={'total-booking'}>Sub Total</p>
                                                <p className={'total-booking-text'}>{currency}{numberWithCommas(subTotalPrice)}</p>
                                            </div>

                                            {selectedOffer.discountCode !== '' &&
                                                <>
                                                    <div style={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        margin: '20px 0 5px 0'
                                                    }}>
                                                        <p className={'total-booking'}>Coupon</p>
                                                        <p className={'total-booking-text'}>
                                                            {selectedOffer.title}
                                                        </p>
                                                    </div>

                                                    <div style={{width: '100%', display: 'flex', margin: '5px 0'}}>
                                                        <p className={'total-booking'}>Coupon Value</p>
                                                        <p className={'total-booking-text'}>
                                                            {`${currency} ${numberWithCommas(moneySavedByCoupon.toFixed(2))}`}
                                                        </p>
                                                    </div>
                                                </>
                                            }

                                            <div style={{width: '100%', display: 'flex', margin: '5px 0'}}>
                                                <p className={'total-booking'}>Taxes (GST -
                                                    SAC {taxCodes[cartItem.items.package.subcategoryID]?.code})
                                                    - {taxCodes[cartItem.items.package.subcategoryID]?.percentage * 100}%</p>
                                                <p className={'total-booking-text'}>{currency}{tax}</p>
                                            </div>
                                            <div style={{width: '100%', display: 'flex', margin: '5px 0'}}>
                                                <p className={'total-booking'}>Payable Amount</p>
                                                <p className={'total-booking-text'}>{currency}{numberWithCommas(totalPrice)}</p>
                                            </div>
                                            <div style={{width: '100%', display: 'flex', margin: '5px 0'}}>
                                                <p className={'total-booking'}>Payment Method</p>
                                                <p className={'total-booking-text'}>RazorPay</p>
                                            </div>

                                            <div className={'row my-4'}>
                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <div className={'proceed-booking-btn'}
                                                         onClick={proceed_to_checkout}
                                                    >
                                                        <div style={{margin: 'auto auto auto 0'}}>
                                                            <p style={{fontSize: 12}}>{currency} {numberWithCommas(parseFloat(totalPrice).toFixed(2))}</p>
                                                            <p className={'total-saving-text'}>You are
                                                                saving {currency}{numberWithCommas(parseFloat(savingPrice).toFixed(2))}</p>
                                                        </div>
                                                        <p style={{fontSize: 12, margin: 'auto 0'}}>Proceed to
                                                            Payment</p>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </>
                }
            </div>
            <Modal
                open={openModal}
                onClose={() => {
                }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {cartItem &&
                    <ProvideRequirementsModal cartItem={cartItem} setCartItem={setCartItem}
                                              handleCloseModal={handleCloseModal}
                                              images={images} setImages={setImages}
                                              imgs={imgs} setImgs={setImgs}
                    />
                }
            </Modal>
            <Footer/>
        </div>
    );
}

export default Booking;


