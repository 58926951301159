import {useEffect, useState} from "react";

const CountDownTimer = ({time, setTime, setProgress, setOtpSent}) => {


    useEffect(() => {
        const timerId = setInterval(() => {
            setProgress((prevProgress) => (prevProgress - 2.22));
            if (time[0] === 0 && time[1] === 0 && time[2] === 0) {
                setOtpSent(false)
            } else if (time[1] === 0 && time[2] === 0) {
                setTime([time[0] - 1, 59, 59]);
            } else if (time[2] === 0) {
                setTime([time[0], time[1] - 1, 59]);
            } else {
                setTime([time[0], time[1], time[2] - 1]);
            }
        }, 1000);
        return () => clearInterval(timerId);
    });


    return (
        <>
            <p style={{fontSize: 15, fontWeight: 600, marginBottom: 5}}>{`${time[1]
                .toString()
                .padStart(2, '0')}:${time[2].toString().padStart(2, '0')}`}
            </p>
        </>
    );
}

export default CountDownTimer;