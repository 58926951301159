import {IoIosCloseCircle} from "react-icons/io";
import profile from '../assets/images/profile-image.png'
import clsx from "clsx";
import {useHistory, useLocation} from "react-router-dom";
import {useEffect} from "react";
import {useContext} from "react";
import {Context} from '../../src/Contexts/Context'
import {useDispatch, useSelector} from "react-redux";
import {Logout} from "../actions/accounts";

const Sidebar = ({showSidebar, setShowSidebar, handleOpenModal, setValue, customer}) => {
    const location = useLocation()

    let isAuthenticated = useSelector(state => state.accounts.isAuthenticated)

    let user_data = useSelector(state => state.accounts.data)

    const dispatch = useDispatch();

    useEffect(() => {
        setShowSidebar(false)
    }, [location])

    const history = useHistory()
    return (
        <div className={clsx('sidebar', showSidebar ? 'sidebar-active' : 'sidebar-inactive')}>
            <IoIosCloseCircle className={showSidebar ? 'display-item' : 'indisplay'} onClick={() => {
                setShowSidebar(false)
            }}/>

            <div
                className={clsx(showSidebar ? 'display-item' : 'indisplay', 'sidebar-item-list', showSidebar && 'sidebar-item-list-active')}>
                {isAuthenticated &&
                    <>
                        <div className={showSidebar ? 'display-item' : 'indisplay'}
                             style={{display: 'flex', margin: '20px 0'}}>
                            <div
                                className={clsx('profile-image-container', showSidebar ? 'display-item' : 'indisplay')}>
                                <img style={{width: 90, borderRadius: '50%', height: 90, border: '3px solid #6CD7BB'}}
                                     alt={''} src={user_data.picture}/>
                            </div>
                            <div className={showSidebar ? 'display-item' : 'indisplay'}
                                 style={{margin: 'auto 0 auto 10px'}}>
                                <p style={{fontSize: 14, fontWeight: 700}}>{user_data.firstName}</p>
                                <p style={{fontSize: 10}}>{user_data.email}</p>
                            </div>
                        </div>

                        <button
                            style={{
                                margin: '0 0 20px 0',
                                fontSize: 13,
                                fontWeight: 600,
                                color: '#d86a9d',
                                borderRadius: 5,
                                border: '1px solid #d86a9d'
                            }}
                            onClick={() => {
                                history.push('/account/')
                            }}
                            className={clsx('btn form-control', showSidebar ? 'display-item' : 'indisplay')}>My Account
                        </button>
                    </>
                }
                <div
                    onClick={() => {
                        history.push('')
                    }}
                    className={clsx('sidebar-item', showSidebar ? 'display-item' : 'indisplay')}>
                    <p>Home</p>
                </div>
                <div
                    className={clsx('sidebar-item', showSidebar ? 'display-item' : 'indisplay')}>
                    <a style={{textDecoration: 'None', color: "#5a6167"}} href={'https://blog.clykk.com/'} target={'_blank'}>Blog</a>
                </div>
                <div
                    onClick={() => {
                        history.push('/offers/')
                    }}
                    className={clsx('sidebar-item', showSidebar ? 'display-item' : 'indisplay')}>
                    <p>Offers</p>
                </div>
                <div className={clsx('sidebar-item', showSidebar ? 'display-item' : 'indisplay')}
                     onClick={() => {
                         history.push('/about-us/')
                     }}>
                    <p>About us</p>
                </div>
                <div
                    onClick={() => {
                        if (isAuthenticated) {
                            history.push('/my-orders/')
                        } else {
                            setShowSidebar(!showSidebar)
                            setValue(0)
                            handleOpenModal(1)
                        }
                    }}
                    className={clsx('sidebar-item', showSidebar ? 'display-item' : 'indisplay')}>
                    <p>Orders</p>
                </div>
                {customer &&
                    <div className={clsx('sidebar-item', showSidebar ? 'display-item' : 'indisplay')}
                         onClick={() => {
                             history.push('/service-provider/')
                         }}>
                        <p>Service Provider</p>
                    </div>
                }
                {!customer &&
                    <div className={clsx('sidebar-item', showSidebar ? 'display-item' : 'indisplay')}
                         onClick={() => {
                             history.push('')
                         }}>
                        <p>Customer</p>
                    </div>
                }

                {!isAuthenticated ?
                    <>
                        <div className={clsx('sidebar-item', showSidebar ? 'display-item' : 'indisplay')}>
                            <button className={'btn nav-btn login-btn'} onClick={() => {
                                setShowSidebar(!showSidebar)
                                setValue(0)
                                handleOpenModal()
                            }}>Login
                            </button>
                        </div>
                        <div className={clsx('sidebar-item', showSidebar ? 'display-item' : 'indisplay')}>
                            <button className={'btn nav-btn join-us-btn'} onClick={() => {
                                setShowSidebar(!showSidebar)
                                setValue(1)
                                handleOpenModal()
                            }}>Register
                            </button>
                        </div>
                    </>
                    :
                    <>
                        <div className={clsx('sidebar-item', showSidebar ? 'display-item' : 'indisplay')}>
                            <button className={'btn nav-btn join-us-btn'} onClick={() => {
                                setShowSidebar(!showSidebar)
                                dispatch(Logout())
                                history.push('/')
                            }}>Logout
                            </button>
                        </div>
                    </>
                }
            </div>

        </div>
    );
}

export default Sidebar;