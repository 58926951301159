import React from 'react';

function ReSubscriptionOrder({changeSubscriptionData, changeSubscription, allowedTime, change_in_schedule}) {
    return (
        <div style={{width: '100%'}}>
            <div style={{padding: 15, backgroundColor: '#F6F7F9', borderRadius: 5}}>
                <p style={{fontSize: 15, fontWeight: 600, color: '#424B5A'}}>Schedule Your Service</p>
                <p style={{color: '#5a6167', fontSize: 12, margin: '0 0 10px 0'}}>Schedule your service
                    time
                    and
                    date or subscribe for recurring service option</p>

                <p style={{fontSize: 12, color: '#5a6167'}}>Date</p>
                    <div style={{display: 'flex', margin: '0 0 12px 0'}}>
                        <input
                            value={changeSubscriptionData.serviceDate}
                            key={'startDate'}
                            onChange={(e) => {
                                change_in_schedule(e.target.value, 'serviceDate')
                            }}
                            type={'date'}
                            className={'form-control calendar-input'}/>
                    </div>
                    <p style={{fontSize: 12, color: '#5a6167'}}>Time</p>
                    <div style={{display: 'flex', margin: '0 0 12px 0'}}>
                        <select className="form-select"
                                key={'serviceTime'}
                                onChange={(e) => {
                                    change_in_schedule(e.target.value, 'serviceTime')
                                }}
                                value={changeSubscriptionData.serviceTime}
                                aria-label="Default select example"
                                style={{fontSize: 12, padding: 10}}
                        >
                            <option value={''}>Select Service Time</option>
                            {allowedTime.map((item) => {
                                return (
                                    <option value={item}>{item}</option>
                                )
                            })}
                        </select>
                    </div>

                    <button
                        className={'form-control mb-3 p-2 booking-confirm-btn'}
                        onClick={changeSubscription}
                    >Confirm
                    </button>


            </div>
        </div>
    );
}

export default ReSubscriptionOrder;