import {useContext, useEffect, useState} from 'react';
import yellow_glitter from "../assets/images/yellow star.png";
import pink_glitter from "../assets/images/star.svg";
import purple_glitter from "../assets/images/glitter.png";
import green_glitter from "../assets/images/greenstar.png";
import chat from "../assets/images/chat.png";
import pin from "../assets/images/pin copy 6.png";
import {useSelector} from "react-redux";
import {AiFillQuestionCircle, AiOutlineArrowLeft} from "react-icons/ai";
import {useHistory, useLocation} from "react-router-dom";
import Footer from "../component/LandingPage/Footer";
import {Context} from "../Contexts/Context";
import axios from "axios";
import Config from "../Config";
import {numberWithCommas} from "../component/Utils";
import logo from "../assets/images/logo.svg";
import {toast} from "react-hot-toast";

function AdditionalChargePage({}) {
    const history = useHistory()
    const currency = useSelector(state => state.accounts.currency)
    const {setFaqNum, handleOpenFaqModal} = useContext(Context);

    const [order, setOrder] = useState(null)
    const userData = useSelector(state => state.accounts.data)

    const location = useLocation()
    const scheduleId = location.pathname.split('/')[2]

    const [amount, setAmount] = useState(0)

    useEffect(() => {
        const config = Config()
        axios.get(`https://cgapp.clykk.com/cart/api/v2/cart-management/subscription/${scheduleId}/schedule`, config)
            .then(response => {
                setOrder(response.data)
                setAmount(response.data.additionalMaterials.amount ? response.data.additionalMaterials.amount : 0)
            })
            .catch(error => {
                console.log(error.message)
            })
    }, [])

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function displayRazorpay() {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        const config = Config()
        const result = await axios.post(`https://cgapp.clykk.com/cart/api/v2/cart-management/cart/${order.cartID}/order/book`,
            {
                "total": parseFloat(amount),
                "payableAmount": amount * 100,
                "amountBeforeTax": 0,
                "taxAmount": 0,
                "paymentType": "onetime",
                "description": 'additional-services'
            },
            config
        )

        const {payableAmount, order_id} = result.data;

        const options = {
            key: "rzp_live_YdTGPolg4K9L7h", // Enter the Key ID generated from the Dashboard
            amount: payableAmount,
            currency: "INR",
            name: "CLYKK.",
            description: "CLYKK",
            image: {logo},
            order_id: order_id,
            handler: async function (response) {
                const data = {
                    orderCreationId: order_id,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                };

                axios.put(`https://cgapp.clykk.com/cart/api/v2/cart-management/subscription/${scheduleId}/addpayment`,
                    {
                        "total": parseFloat(amount),
                        "payableAmount": parseFloat(amount),
                        "amountBeforeTax": 0,
                        "taxAmount": 0,
                        "transactionID": data.razorpayPaymentId,
                        "paymentType": "onetime"
                    }, config)
                    .then(response => {
                        toast.success('Payment Successful')
                    })
                    .catch(err => console.log(err.message))

            },
            prefill: {
                name: userData.firstName,
                email: userData.email,
                contact: userData.mobile,
            },
            notes: {
                address: "",
            },
            theme: {
                color: "#61dafb",
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    return (
        <div style={{position: 'relative'}}>
            <>
                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '5%', left: '70%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '10%', left: '10%'}}/>
                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 17, top: '15%', left: '60%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '25%', left: '90%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '30%', left: '20%'}}/>


                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '32%', left: '65%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 10, top: '40%', left: '55%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 17, top: '50%', left: '70%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '60%', left: '82%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '70%', left: '12%'}}/>

                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 17, top: '75%', left: '63%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '80%', left: '51%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 20, top: '85%', left: '87%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '90%', left: '52%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 17, top: '95%', left: '69%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '97%', left: '87%'}}/>
            </>

            <div className="container my-4">

                <div style={{display: 'flex', margin: "20px 0"}}>
                    <AiOutlineArrowLeft onClick={() => {
                        history.goBack()
                    }} style={{margin: "auto 10px auto 0", cursor: 'pointer'}}/>
                    <p style={{fontSize: 17, fontWeight: 650}}>Additional Charges</p>
                    <AiFillQuestionCircle
                        className={'faq-icon-selection-location'}
                        style={{margin: 'auto 0 auto auto'}}
                        onClick={() => {
                            setFaqNum(33)
                            handleOpenFaqModal()
                        }}/>
                </div>

                {order &&
                    <>
                        <div className={'cart-container'}>
                            <div style={{width: '100%'}}>
                                <div style={{display: 'flex'}}>
                                    <div className={'package-image-container'}
                                         style={{backgroundImage: `url(${order.package.packageImage})`}}>
                                    </div>
                                    <p className={'cart-package-name'}>{order.package.packageName}</p>
                                    <img className={'cart-chat-img'} src={chat}/>
                                    <p className={'cart-chat-id'}>{order.cartID}</p>
                                </div>
                                <div style={{display: 'flex', marginTop: 12}}>
                                    <img src={pin} style={{width: 10, height: 12}}/>
                                    <p style={{
                                        fontSize: 11,
                                        fontWeight: 400,
                                        color: '#A1A2A4',
                                        marginLeft: 5
                                    }}>{order.serviceAddress.city}</p>
                                </div>
                                <div style={{display: 'flex', marginTop: 10, padding: '0 '}}>
                                    <div style={{margin: 'auto auto auto 0'}}>
                                        <p className={'cart-detail-heading'}>Date</p>
                                        <p className={'cart-detail-meta-text'}>{order.serviceDate}</p>
                                    </div>
                                    <div style={{margin: 'auto'}}>
                                        <p className={'cart-detail-heading'}>Time</p>
                                        <p className={'cart-detail-meta-text'}>{order.serviceTime}</p>
                                    </div>
                                    <div style={{margin: 'auto 0 auto auto'}}>
                                        <p className={'cart-detail-heading'}>Subscription</p>
                                        <p className={'cart-detail-meta-text'}>{order.subscriptionFrequency}</p>
                                    </div>
                                    <div style={{margin: 'auto 0 auto auto'}}>
                                        <p className={'cart-detail-heading'}>Price</p>
                                        <p className={'cart-detail-meta-text'}>{currency} {numberWithCommas(order.transaction[0].payableAmount)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12">
                                <div style={{display: "flex"}}>
                                    <p style={{fontSize: 14, fontWeight: 600}}>Additional Bill To Customer</p>
                                </div>
                                <p style={{fontSize: 10, color: "#BABCBF", fontWeight: 400, marginBottom: 10}}>
                                    These are additional charges for material or services to be submitted to customer
                                    for
                                    payment.
                                </p>
                                <p style={{fontSize: 12, fontWeight: 600}}>Material Bills (Optional)</p>

                                <div className={'additional-charges-bill-container'}>
                                    <div style={{display: 'flex'}}>
                                        <p style={{fontSize: 12, fontWeight: 600, margin: 'auto auto auto 0'}}>Material
                                            Bill
                                            Amount</p>
                                        <input placeholder={'200.00'} value={amount} disabled={true}
                                               className={'form-control material-bill'}/>
                                    </div>
                                    <p style={{fontSize: 12, fontWeight: 600, margin: "10px 0 5px 0"}}>Description of
                                        Charges</p>
                                    <textarea disabled={true} placeholder={'Ex: Extra Cleaning'}
                                              value={order.additionalMaterials.description}
                                              className={'form-control description-charges'}/>

                                    <p style={{fontSize: 12, fontWeight: 600, marginTop: 10}}>Material Bills Upload</p>
                                    <p style={{
                                        fontSize: 10,
                                        color: "#BABCBF",
                                        fontWeight: 400,
                                        marginBottom: 10
                                    }}>Upload
                                        Picture Of The Material Bill Here</p>
                                </div>

                                <div style={{display: 'flex', margin: "10px 0 30px 0"}}>
                                    <p style={{marginRight: "auto", fontSize: 12, fontWeight: 600}}>Total Amount for
                                        Material</p>
                                    <p style={{fontSize: 12, fontWeight: 400}}>{currency} {amount}</p>
                                </div>

                            </div>
                            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12">

                                {order.addOns.map((item) => {
                                    return (
                                        <div style={{display: 'flex'}} className={'mb-4'}>
                                            <img src={item.packageImage} className={'package-add-img'}/>
                                            <div className={'package-add-on-services-text-container'}>
                                                <p className={'package-add-on-service'}>{item.packageName}</p>
                                            </div>
                                            <div className={'booking-price-container'}>
                                                <p className={'package-add-on-price-1'}>
                                                    <del>{currency}{numberWithCommas(item.basePrice)}</del>
                                                </p>
                                                <p className={'package-add-on-price-2'}>{currency}{numberWithCommas(item.sellingPrice)}</p>
                                            </div>
                                            <div
                                                className={'package-add-on-quantity-container'}>
                                                <div className={'quantity-controller-container'}>
                                                    <p className={'quantity-controller'}>-</p>
                                                </div>
                                                <p className={'quantity'} style={{margin: 'auto'}}>{item.itemCount}</p>
                                                <div className={'quantity-controller-container'}>
                                                    <p className={'quantity-controller'}>+</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}


                                <div style={{display: 'flex'}}>
                                    <p style={{fontSize: 12, fontWeight: 600, marginRight: 'auto'}}>
                                        Total Amount of Additional Service
                                    </p>
                                    <p style={{fontSize: 12, fontWeight: 400}}>{currency} {amount}</p>
                                </div>

                                <div style={{display: 'flex', marginTop: 10}}>
                                    <p style={{fontSize: 12, fontWeight: 600, margin: "auto auto auto 0"}}>
                                        Total
                                    </p>
                                    <div style={{textAlign: 'right'}}>
                                        <p className={'total-booking-text'}>{currency} {amount}</p>
                                    </div>
                                </div>

                                <div className={'proceed-booking-btn'} style={{marginTop: 10}}
                                     onClick={displayRazorpay}
                                >
                                    <div style={{margin: 'auto auto auto 0'}}>
                                        <p style={{fontSize: 12}}>{currency} {amount}</p>
                                        <p className={'total-saving-text'}>You are
                                            saving {currency} {amount}</p>
                                    </div>
                                    {amount !== 0 &&
                                        <p style={{fontSize: 12, margin: 'auto 0'}}>Proceed to RazorPay</p>
                                    }
                                </div>

                            </div>
                        </div>
                    </>
                }
            </div>

            <Footer/>
        </div>
    );
}

export default AdditionalChargePage;