import {AiFillQuestionCircle, AiOutlineLeft} from "react-icons/ai";
import location_icon from "../../assets/images/map-location1.png";
import React, {useContext} from "react";
import {useHistory} from "react-router-dom";
import {BiCurrentLocation} from "react-icons/bi";
import {Context} from "../../Contexts/Context";

const LocationChoice = ({my_swiper, setDefaultScreen, handleCloseModal, regModal = true}) => {
    const history = useHistory()
    const swipe = (screen) => {
        setDefaultScreen(screen)
        my_swiper.slideNext()
    }
    const {setFaqNum, handleOpenFaqModal} = useContext(Context);
    return (
        <div className={'location-choice-inner-container'}>
            {regModal &&
                <>
                    <div className={'modal-top-nav'}
                         onClick={() => {
                             my_swiper.slidePrev()
                         }}
                    >

                        <AiOutlineLeft style={{margin: 'auto 15px auto 0', fontSize: 14, fontWeight: 700}}/>
                        <p style={{margin: 'auto auto auto 0', fontSize: 14, fontWeight: 700}}>Set Location</p>
                    </div>
                    <hr className={'hr-line'}/>
                </>
            }
            <p className={'consumer-process-modal-text'}
            >Great. Now its time to set your location!</p>
            <div style={{display: 'flex', margin: '5px 0'}}>
                <p className={'consumer-process-modal-metatext'}
                >Begin your Journey into CLYKK Lifestyle
                    <sup style={{fontSize: 8, color: 'red', fontWeight: 600}}>TM</sup>.
                    Choose your Service or Pickup Location.</p>
                <AiFillQuestionCircle
                    className={'question-faq-icon question-faq-icon-choice-modal'}
                    onClick={() => {
                        setFaqNum(8)
                        handleOpenFaqModal()
                    }}
                    style={{color: '#ce5e97'}}/>
            </div>
            <img
                className={'consumer-process-modal-map-location'}
                src={location_icon}/>

            <button
                onClick={() => {
                    swipe(0)
                }}
                className={'p-2 btn form-control choose-location-btn'}><BiCurrentLocation
                className={'set-location-icon'}/> Set Location
            </button>
            <button
                onClick={() => {
                    swipe(1)
                }}
                className={'p-2 btn form-control choose-location-btn choose-saved-location-btn'}>Choose Saved Location
            </button>
        </div>
    );
}

export default LocationChoice;