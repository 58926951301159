import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import SelectLocation from "./SelectLocation";
import SavedLocations from "./SavedLocations";


const SetLocation = ({
                         my_swiper,
                         handleCloseModal,
                         defaultScreen,
                         setDefaultScreen,
                         updateId,
                         setUpdateId,
                         location,
                         homeAddress,
                         setHomeAddress,

                         serviceAddress,
                         setServiceAddress,
                         center,
                         save_address,

                         set_edit_data,
                         setAddress,

                         regModal = false,
                     }) => {


    return (
        <>
            {defaultScreen === 0 ?
                <SelectLocation
                    my_swiper={my_swiper}
                    setDefaultScreen={setDefaultScreen}
                    location={location}
                    updateId={updateId}
                    setUpdateId={setUpdateId}
                    homeAddress={homeAddress}
                    setHomeAddress={setHomeAddress}
                    handleCloseModal={handleCloseModal}
                    serviceAddress={serviceAddress}
                    setServiceAddress={setServiceAddress}
                    center={center}
                    save_address={save_address}
                    regModal={regModal}
                />
                : defaultScreen === 1 &&
                <SavedLocations
                    my_swiper={my_swiper}
                    setDefaultScreen={setDefaultScreen}
                    location={location}
                    setUpdateId={setUpdateId}
                    setHomeAddress={setHomeAddress}
                    handleCloseModal={handleCloseModal}

                    set_edit_data={set_edit_data}
                    setAddress={setAddress}

                    regModal={regModal}
                />
            }
        </>
    );
}

export default SetLocation;