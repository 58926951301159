import clsx from "clsx";
import Navbar from "./Navbar";
import Hero from "./Hero";

const CategoryHeader = ({isMobile, setShowSidebar, handleOpenModal}) => {
    return (
        <div className={'category-header'}>
            <div className={clsx(!isMobile ? "container" : "header-container-sm", 'header-container')}>
                <Navbar setShowSidebar={setShowSidebar} handleOpenModal={handleOpenModal}/>
            </div>
        </div>
    );
}

export default CategoryHeader;