import {useContext} from 'react'
import Header from "../Header";
import Sidebar from "../Sidebar";
import {useEffect, useState} from "react";
import SubCatHeader from "../SubCatDetail/SubCatHeader";
import HeaderPackageDetail from "../HeaderPackageDetail";
import {useHistory, useLocation} from "react-router-dom";
import CategoryHeader from "../CategoryHeader";
import WishListHeader from "../Wishlist/WishListHeader";
import {Modal} from "@material-ui/core";
import "@reach/combobox/styles.css";
import LocationModalBody from "../Modals/locationModalBody";
import RegisterBody from "../Modals/registerBody";
import {useDispatch, useSelector} from "react-redux";
import {
    changeUserType,
    load_default_account,
    load_user_profile,
    refresh_access_token
} from "../../actions/accounts";
import {Context} from "../../Contexts/Context";
import {BsChevronLeft, BsChevronRight} from "react-icons/bs";
import Faq from "../LandingPage/FAQ";
import {AiFillQuestionCircle} from "react-icons/ai";
import PermissionBlocker from "../PermissionBlocker";

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const Layout = ({children}) => {
    const dispatch = useDispatch()

    let isAuthenticated = useSelector(state => state.accounts.isAuthenticated)

    useEffect(() => {
        if (!isAuthenticated) {
            dispatch(load_default_account())
        }
    }, [isAuthenticated])

    useEffect(() => {
        dispatch(refresh_access_token())
    }, [])

    useEffect(() => {
        setInterval(() => {
            dispatch(refresh_access_token())
        }, 12 * 60000);
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(load_user_profile())
        }
    }, [isAuthenticated])

    const {
        openModal,
        setOpenModal,
        handleOpenModal,
        value,
        setValue,
        openFaqModal,
        handleCloseFaqModal,
        handleOpenFaqModal,
        faqNum,
        setFaqNum
    } = useContext(Context);

    const handleCloseModal = () => {
        setValue(0)
        setOpenModal(!openModal)
    }

    const [locationModalOpen, setLocationModalOpen] = useState(false)

    const handleLocationModalClose = () => {
        setLocationModalOpen(!locationModalOpen)
    }

    const handleLocationModalOpen = () => {
        setLocationModalOpen(!locationModalOpen)
    }

    const [isMobile, setIsMobile] = useState(window.innerWidth < 992)

    const [showSidebar, setShowSidebar] = useState(false)

    const location = useLocation()

    useEffect(() => {
        window.addEventListener("resize", () => {
            if (window.innerWidth < 992) {
                setIsMobile(true)
            } else {
                setIsMobile(false)
            }
        });
    })

    const header = () => {
        if (location.pathname.includes('category')) {
            if (location.pathname === '/category/') {
                return <CategoryHeader isMobile={isMobile} setShowSidebar={setShowSidebar}
                                       handleOpenModal={handleOpenModal}/>
            } else if (location.pathname.includes('sub-category-detail')) {
                return <SubCatHeader isMobile={isMobile} setShowSidebar={setShowSidebar}
                                     handleOpenModal={handleOpenModal}/>
            }
        } else if (location.pathname.includes('booking')) {
            return <WishListHeader isMobile={isMobile} setShowSidebar={setShowSidebar}
                                   handleOpenModal={handleOpenModal}/>
        } else if (location.pathname.includes('alert')) {
            return <WishListHeader isMobile={isMobile} setShowSidebar={setShowSidebar}
                                   handleOpenModal={handleOpenModal}/>
        } else if (location.pathname.includes('account')) {
            return <WishListHeader isMobile={isMobile} setShowSidebar={setShowSidebar}
                                   handleOpenModal={handleOpenModal}/>
        } else if (location.pathname.includes('cart')) {
            return <WishListHeader isMobile={isMobile} setShowSidebar={setShowSidebar}
                                   handleOpenModal={handleOpenModal}/>
        } else if (location.pathname.includes('chat')) {
            return <WishListHeader isMobile={isMobile} setShowSidebar={setShowSidebar}
                                   handleOpenModal={handleOpenModal}/>
        } else if (location.pathname.includes('check-out')) {
            return <WishListHeader isMobile={isMobile} setShowSidebar={setShowSidebar}
                                   handleOpenModal={handleOpenModal}/>
        } else if (location.pathname.includes('order-detail')) {
            return <WishListHeader isMobile={isMobile} setShowSidebar={setShowSidebar}
                                   handleOpenModal={handleOpenModal}/>
        } else if (location.pathname.includes('terms')) {
            return <WishListHeader isMobile={isMobile} setShowSidebar={setShowSidebar}
                                   handleOpenModal={handleOpenModal}/>
        } else if (location.pathname.includes('about-us')) {
            return <WishListHeader isMobile={isMobile} setShowSidebar={setShowSidebar}
                                   handleOpenModal={handleOpenModal}/>
        } else if (location.pathname.includes('privacy-policy')) {
            return <WishListHeader isMobile={isMobile} setShowSidebar={setShowSidebar}
                                   handleOpenModal={handleOpenModal}/>
        } else if (location.pathname.includes('package-detail')) {
            return <HeaderPackageDetail isMobile={isMobile} setShowSidebar={setShowSidebar}
                                        handleOpenModal={handleOpenModal}/>
        } else if (location.pathname.includes('faq')) {
            return <CategoryHeader isMobile={isMobile} setShowSidebar={setShowSidebar}
                                   handleOpenModal={handleOpenModal}/>
        } else if (location.pathname.includes('offers')) {
            return <WishListHeader isMobile={isMobile} setShowSidebar={setShowSidebar}
                                   handleOpenModal={handleOpenModal}/>
        } else if (location.pathname.includes('contact-us')) {
            return <WishListHeader isMobile={isMobile} setShowSidebar={setShowSidebar}
                                   handleOpenModal={handleOpenModal}/>
        } else if (location.pathname.includes('wishlist')) {
            return <WishListHeader isMobile={isMobile} setShowSidebar={setShowSidebar}
                                   handleOpenModal={handleOpenModal}/>
        } else if (location.pathname.includes('my-orders')) {
            return <WishListHeader isMobile={isMobile} setShowSidebar={setShowSidebar}
                                   handleOpenModal={handleOpenModal}/>
        } else if (location.pathname.includes('service-execution')) {
            return <WishListHeader isMobile={isMobile} setShowSidebar={setShowSidebar}
                                   handleOpenModal={handleOpenModal}/>
        } else if (location.pathname.includes('update-subscription-detail')) {
            return <WishListHeader isMobile={isMobile} setShowSidebar={setShowSidebar}
                                   handleOpenModal={handleOpenModal}/>
        } else if (location.pathname.includes('service-track')) {
            return <WishListHeader isMobile={isMobile} setShowSidebar={setShowSidebar}
                                   handleOpenModal={handleOpenModal}/>
        } else if (location.pathname.includes('additional-charge')) {
            return <WishListHeader isMobile={isMobile} setShowSidebar={setShowSidebar}
                                   handleOpenModal={handleOpenModal}/>
        } else if (location.pathname.includes('order-subscription-detail')) {
            return <WishListHeader isMobile={isMobile} setShowSidebar={setShowSidebar}
                                   handleOpenModal={handleOpenModal}/>

        } else if (location.pathname.includes('service-rating')) {
            return <WishListHeader isMobile={isMobile} setShowSidebar={setShowSidebar}
                                   handleOpenModal={handleOpenModal}/>

        } else if (location.pathname.includes('service-provider')) {
            dispatch(changeUserType('Service Provider'))
            return <Header isMobile={isMobile} setShowSidebar={setShowSidebar}
                           handleOpenModal={handleOpenModal} handleLocationModalOpen={handleLocationModalOpen}
                           customer={false}
            />
        } else {
            dispatch(changeUserType('Customer'))
            return <Header isMobile={isMobile} setShowSidebar={setShowSidebar}
                           handleOpenModal={handleOpenModal} handleLocationModalOpen={handleLocationModalOpen}
                           customer={true}
            />
        }
    }

    const history = useHistory()

    const {
        openCatModal, handleCatCloseModal, selectCat, subCategory,
        openBlockerModal, handleCloseBlockerModal
    } = useContext(Context);

    const [modalStyle] = useState(getModalStyle);
    const body = (
        <div style={modalStyle} className={'modal-paper'}>
            {selectCat &&
                <>
                    <div className={'category-modal-navbar'}>
                        <BsChevronLeft style={{margin: "auto 0", cursor: 'pointer', fontSize: 14, strokeWidth: 2}}
                                       onClick={handleCatCloseModal}/>
                        <p className={'category-modal-navbar-text'}>{selectCat.category_name}</p>
                        <AiFillQuestionCircle
                            className={'faq-icon-selection-location'}
                            style={{margin: 'auto 0 auto auto'}}
                            onClick={() => {
                                setFaqNum(14)
                                handleOpenFaqModal()
                            }}/>
                    </div>
                    <p className={'category-modal-category-promotion-text'}>Top-rated {selectCat.category_name} Services</p>
                    {subCategory.map((sub_category) => {
                        return (
                            <div
                                onClick={() => {
                                    handleCatCloseModal()
                                    history.push(`/sub-category-detail/${sub_category.category_name}/${selectCat.category_id}/${sub_category.sub_category_id}/`)
                                }}
                                className={'category-modal-container'}>
                                <img className={'modal-cat-img'}
                                     src={sub_category.category_image}/>
                                <p className={'modal-cat-text'}>{sub_category.category_name}</p>
                                <BsChevronRight style={{margin: "auto 0", fontSize: 14, strokeWidth: 2}}/>
                            </div>
                        )
                    })}
                </>
            }
        </div>
    )


    return (
        <div style={{display: 'relative'}}>
            {location.pathname.includes('service-provider') ?
                <Sidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} handleOpenModal={handleOpenModal}
                         setValue={setValue} customer={false}/>
                :
                <Sidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} handleOpenModal={handleOpenModal}
                         setValue={setValue} customer={true}/>
            }
            {header()}

            {children}

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <RegisterBody value={value} setValue={setValue} handleCloseModal={handleCloseModal}/>
            </Modal>

            <Modal
                open={locationModalOpen}
                onClose={handleLocationModalClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <LocationModalBody setLocationModalOpen={setLocationModalOpen}
                                   handleLocationModalClose={handleLocationModalClose}
                />
            </Modal>
            <Modal
                open={openCatModal}
                onClose={handleCatCloseModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>

            <Modal
                open={openFaqModal}
                onClose={handleCloseFaqModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div style={modalStyle} className={'modal-paper'}>
                    <Faq faqNum={faqNum} page={false}/>
                </div>
            </Modal>

            <Modal
                open={openBlockerModal}
                onClose={handleCloseBlockerModal}
            >
                <PermissionBlocker/>
            </Modal>

        </div>
    );
}

export default Layout;


