import Geocode from "react-geocode";
import {GoogleMap, useLoadScript, DirectionsRenderer} from "@react-google-maps/api";
import {useContext, useEffect} from "react";
import {useRef, useState} from 'react'
import {Box, Flex} from '@chakra-ui/react'
import delivery from '../assets/images/delivery.png'
import pin from '../assets/images/pin copy 6.png'
import start from '../assets/images/Icon search cop.png'
import dots from '../assets/images/directions-dots.png'
import profile_img from '../assets/images/profile-image.png'
import Footer from "../component/LandingPage/Footer";
import {useHistory, useLocation} from "react-router-dom";
import {FiStar} from "react-icons/fi";
import {useSelector} from "react-redux";
import {AiFillQuestionCircle, AiOutlineArrowLeft} from "react-icons/ai";
import {Context} from "../Contexts/Context";
import Config from "../Config";
import axios from "axios";
import {numberWithCommas} from "../component/Utils";
import {getFirestore, collection, getDocs} from 'firebase/firestore';


import {initializeApp} from "firebase/app";
import ReactStars from "react-rating-stars-component";

const ServiceTrackPage = () => {
    Geocode.setApiKey("AIzaSyDR2jp3zFzoBvTfFcg0eM-H5OwjgkxkQaU");

    const firebaseConfig = {
        apiKey: "AIzaSyCq7z36nZYY2m1E4qPf6AzaP_XgI-A5C_8",
        authDomain: "clykk-356df.firebaseapp.com",
        databaseURL: "https://clykk-356df.firebaseio.com",
        projectId: "clykk-356df",
        storageBucket: "clykk-356df.appspot.com",
        messagingSenderId: "11379513871",
        appId: "1:11379513871:web:e6d15083b499d10ade617a",
        measurementId: "G-9SVF06531T"
    };

    const app = initializeApp(firebaseConfig);

    const [waypts, setWaypts] = useState([{
        location: "2J38+8P3, Richards Town, Bengaluru, Karnataka 560005, India",
        stopover: true
    }]);

    useEffect(() => {
        const db = getFirestore(app);

        const querySnapshot = getDocs(collection(db, "location_tracking"));

        querySnapshot.then((response) => {
            response.forEach((location) => {
                if (location.id === '1321') {
                    Geocode.fromLatLng(location.data().lat, location.data().lng).then(
                        (response) => {
                            const address = response.results[0].formatted_address;
                            setWaypts([{location: address, stopover: true}]);
                        },
                        (error) => {
                            console.error(error);
                        }
                    );
                }
            });
        });
    }, [])


    const location = useLocation()
    const currency = useSelector(state => state.accounts.currency)
    const history = useHistory()

    const [order, setOrder] = useState(null)

    const scheduleId = location.pathname.split('/')[2]

    useEffect(() => {
        const config = Config()
        axios.get(`https://cgapp.clykk.com/cart/api/v2/cart-management/subscription/${scheduleId}/schedule`, config)
            .then(response => {
                setOrder(response.data)
            })
            .catch(error => {
                console.log(error.message)
            })
    }, [])

    const {setFaqNum, handleOpenFaqModal} = useContext(Context);

    const center = {
        lat: 43.6532,
        lng: -79.3832,
    };

    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: ['places'],
    })

    const [map, setMap] = useState(/** @type google.maps.Map */ (null))
    const [directionsResponse, setDirectionsResponse] = useState(null)
    const [distance, setDistance] = useState('')
    const [duration, setDuration] = useState('')

    /** @type React.MutableRefObject<HTMLInputElement> */
    const originRef = useRef()
    /** @type React.MutableRefObject<HTMLInputElement> */
    const destiantionRef = useRef()


    async function calculateRoute() {
        if (order) {
            // eslint-disable-next-line no-undef
            const directionsService = new google.maps.DirectionsService()
            let results = null
            if (order.package.serviceRuleID !== 1) {
                results = await directionsService.route({
                    origin: order.serviceAddress.line2,
                    destination: order.deliveryAddress.line2,
                    waypoints: waypts,
                    // eslint-disable-next-line no-undef
                    travelMode: google.maps.TravelMode.DRIVING,
                })
            } else {
                results = await directionsService.route({
                    origin: waypts[0].location,
                    destination: order.serviceAddress.line2,
                    // eslint-disable-next-line no-undef
                    travelMode: google.maps.TravelMode.DRIVING,
                })
            }
            setDirectionsResponse(results)

            setDistance(results.routes[0].legs[0].distance.text)
            setDuration(results.routes[0].legs[0].duration.text)
        }
    }


    useEffect(() => {
        calculateRoute()
    }, [order, waypts])


    const [serviceProviderData, setServiceProviderData] = useState(null)


    useEffect(() => {
        const config = Config()
        axios.get(`https://clapp.clykk.com/routing_management/public/index.php/api/getorderdetails?sub_id=${scheduleId}`, config)
            .then(response => {
                if (response.data.status) {
                    setServiceProviderData(response.data.data[0])
                }
            })
            .catch(e => console.log(e.message))
    }, [])

    if (!isLoaded) return "Loading...";

    return (
        <>
            {order &&
                <div className={'container my-5'}>

                    <div style={{display: 'flex', marginBottom: 10}}>
                        <AiOutlineArrowLeft onClick={() => {
                            history.goBack()
                        }} style={{margin: "auto 10px auto 0", cursor: 'pointer'}}/>
                        <p className={'booking-heading'}>Track Location</p>
                        <AiFillQuestionCircle
                            className={'faq-icon-selection-location booking-faq'}
                            onClick={() => {
                                setFaqNum(34)
                                handleOpenFaqModal()
                            }}/>
                    </div>

                    <Flex
                        position='relative'
                        flexDirection='column'
                        alignItems='center'
                        h='400px'
                        w='100%'
                    >
                        <Box position='absolute' left={0} top={0} h='100%' w='100%'>
                            <GoogleMap
                                center={center}
                                zoom={30}
                                mapContainerStyle={{width: '100%', height: '100%'}}
                                options={{
                                    zoomControl: false,
                                    streetViewControl: false,
                                    mapTypeControl: false,
                                    fullscreenControl: false,
                                }}
                                onLoad={map => setMap(map)}
                            >
                                {directionsResponse && (
                                    <DirectionsRenderer directions={directionsResponse}/>
                                )}
                            </GoogleMap>
                        </Box>
                        <div style={{display: 'flex', zIndex: 99, marginTop: 10}}>
                            <div style={{
                                width: 340,
                                margin: '0 auto',
                                padding: 10,
                                backgroundColor: 'white',
                                display: 'flex',
                                borderRadius: 10
                            }}>
                                {order.package.serviceRuleID !== 1 ?
                                    <>
                                        <div style={{display: 'flex', flexDirection: 'column'}}>
                                            <img src={delivery} style={{width: 30}}/>
                                            <img src={dots} style={{width: 15, margin: 'auto'}}/>
                                            <img src={start} style={{width: 15, margin: "0 auto"}}/>
                                            <img src={dots} style={{width: 15, margin: 'auto'}}/>
                                            <img src={pin} style={{width: 15, margin: "0 auto"}}/>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'column', margin: "5px 0 0 5px"}}>
                                            <input value={waypts[0].location}
                                                   className={'form-control directions-input'}/>
                                            <hr style={{margin: '8px 0', height: '0.5px', width: 260, marginLeft: 10}}/>
                                            <input value={order.serviceAddress.line2}
                                                   className={'form-control  directions-input'}/>
                                            <hr style={{margin: '8px 0', height: '0.5px', width: 260, marginLeft: 10}}/>
                                            <input value={order.deliveryAddress.line2}
                                                   className={'form-control  directions-input'}/>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div style={{display: 'flex', flexDirection: 'column', marginTop: 10}}>
                                            <img src={pin} style={{width: 15}}/>
                                            <img src={dots} style={{width: 15, margin: '5px 0'}}/>
                                            <img src={start} style={{width: 15}}/>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'column', margin: "5px 0 0 5px"}}>
                                            <input value={waypts[0].location}
                                                   className={'form-control directions-input'}/>
                                            <hr style={{margin: '8px 0', height: '0.5px', width: 260, marginLeft: 10}}/>
                                            <input value={order.serviceAddress.line2}
                                                   className={'form-control  directions-input'}/>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>

                        <div style={{display: 'flex', zIndex: 1000, marginTop: 10, position: 'absolute', bottom: -25}}>
                            <div style={{
                                borderRadius: '50%', backgroundColor: 'white', padding: 7
                            }}>
                                <div style={{
                                    backgroundColor: 'white',
                                    width: 59,
                                    height: 59,
                                    padding: 9,
                                    borderRadius: '50%',
                                    border: '3px solid #1dbf73',
                                    textAlign: 'center'
                                }}>
                                    <p style={{fontSize: 10, fontWeight: 600}}>{duration}<br/></p>
                                </div>
                            </div>
                        </div>
                    </Flex>
                    <div style={{display: 'flex', width: '100%', marginTop: 30}}>
                        <div className={'package-image-container'}
                             style={{backgroundImage: `url(${order.package.packageImage})`}}></div>
                        <div style={{display: 'flex', flexDirection: 'column', marginLeft: 10}}>
                            <p style={{fontSize: 12, fontWeight: 600}}>{order.package.packageName} [0
                                - {distance}]</p>
                            <p style={{fontSize: 12}}>{currency} {numberWithCommas(order.transaction[0].payableAmount)}</p>
                        </div>
                        <button className={'btn'} style={{
                            marginLeft: 'auto',
                            border: '1px solid #424b5a',
                            backgroundColor: 'white',
                            color: '#424b5a',
                            fontSize: 10,
                            height: 27
                        }}>
                            OTP-5315
                        </button>
                    </div>
                    {order.status !== "Completed" &&
                        <>
                            {serviceProviderData &&
                                <div style={{display: 'flex', marginTop: 30}}>
                                    <img src={serviceProviderData.get_user_details.picture} style={{width: 30, height: 30}}/>
                                    <div style={{marginLeft: 10}}>
                                        <p style={{
                                            fontSize: 14,
                                            fontWeight: 600
                                        }}>{serviceProviderData.get_s_p_assign_details.first_name} {serviceProviderData.get_s_p_assign_details.last_name}</p>
                                        <div style={{display: 'flex'}}>
                                            <ReactStars
                                                count={5}
                                                size={24}
                                                value={serviceProviderData.get_s_p_assign_details.rating}
                                                isHalf={true}
                                                emptyIcon={<i className="far fa-star"></i>}
                                                halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                fullIcon={<i className="fa fa-star"></i>}
                                                activeColor="#ffd700"
                                            />
                                            <p style={{
                                                fontSize: 11,
                                                fontWeight: 600,
                                                marginLeft: 'auto'
                                            }}>{serviceProviderData.get_s_p_assign_details.rating}</p>
                                        </div>
                                    </div>
                                    <button
                                        onClick={() => {
                                            history.push(`/chat/${order.id}/3`)
                                        }}
                                        style={{
                                            marginLeft: 'auto', border: '1px solid #424b5a',
                                            backgroundColor: 'white',
                                            color: '#424b5a',
                                            fontSize: 10, height: 45
                                        }} className={'btn'}>Chat
                                        with {serviceProviderData.get_s_p_assign_details.first_name} {serviceProviderData.get_s_p_assign_details.last_name}
                                        <br/>
                                        {serviceProviderData.get_s_p_assign_details.mobile}
                                    </button>
                                </div>
                            }

                            <div style={{display: 'flex', marginTop: 10, padding: '0 '}}>
                                <div style={{margin: 'auto auto auto 0'}}>
                                    <p className={'cart-detail-heading'}>Date</p>
                                    <p className={'cart-detail-meta-text'}>{order.serviceDate}</p>
                                </div>
                                <div style={{margin: 'auto'}}>
                                    <p className={'cart-detail-heading'}>Time</p>
                                    <p className={'cart-detail-meta-text'}>{order.serviceTime}</p>
                                </div>
                                <div style={{margin: 'auto 0 auto auto'}}>
                                    <p className={'cart-detail-heading'}>Subscription</p>
                                    <p className={'cart-detail-meta-text'}>{order.subscriptionFrequency}</p>
                                </div>
                                <div style={{margin: 'auto 0 auto auto'}}>
                                    <p className={'cart-detail-heading'}>Price</p>
                                    <p className={'cart-detail-meta-text'}>{currency} {numberWithCommas(order.transaction[0].payableAmount)}</p>
                                </div>
                            </div>
                        </>
                    }
                </div>
            }
            <Footer/>
        </>
    );
}

export default ServiceTrackPage;