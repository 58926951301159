import "firebase/messaging";
import {getToken, onMessage} from "firebase/messaging";
import {initializeApp} from "firebase/app";
import {getMessaging} from "firebase/messaging/sw";

const firebaseConfig = {
    apiKey: "AIzaSyCq7z36nZYY2m1E4qPf6AzaP_XgI-A5C_8",
    authDomain: "clykk-356df.firebaseapp.com",
    databaseURL: "https://clykk-356df.firebaseio.com",
    projectId: "clykk-356df",
    storageBucket: "clykk-356df.appspot.com",
    messagingSenderId: "11379513871",
    appId: "1:11379513871:web:e6d15083b499d10ade617a",
    measurementId: "G-9SVF06531T"
};

const firebaseApp = initializeApp(firebaseConfig);

const messaging = getMessaging(firebaseApp);

export const requestForToken = async () => {
    var token = null;

    getToken(messaging, {vapidKey: 'BCA_bflkEALRPctLPAMPZTSWeOspPjmUj3P4mLnxz7d1GYGrpYaia0ctfh43xqIH6EeBIbSBuV6Y8iHlPeRx-hI'}).then((currentToken) => {
        if (currentToken) {
            console.log("currentToken =>", currentToken)
            token = currentToken
        } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
            // ...
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
    });

    return token;
};


export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage((payload) => {
            resolve(payload);
        });
    });

