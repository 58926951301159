import logo from '../../assets/images/logo.svg'
import facebook from '../../assets/images/footer-fb-icon.png'
import linkedin from '../../assets/images/Linkedin.svg'
import youtube from '../../assets/images/Youtube.svg'
import insta from '../../assets/images/footer-insta-icon.png'
import twitter from '../../assets/images/footer-twitter.icon.png'
import StoreBtn from "../Hero/StoreBtn";
import ApplyStore from "../../assets/images/apply-stone-btn.png";
import GoogleStore from "../../assets/images/google-play-btn.png";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";

const Footer = () => {
    const history = useHistory()
    const userType = useSelector(state => state.accounts.userType)

    return (
        <div style={{backgroundColor: "#f6f7f9"}}>
            <div className="container px-5 pt-3">
                <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                        <img src={logo} style={{width: 100}}/>
                        <p className={'footer-info'}>Copyright © 2022 CLYKK Services India Private Limited</p>
                        <p className={'footer-info'} style={{color: "#6CD7BB"}}>Follow Us: Breaking News on Roll-outs,
                            Deals & Offers</p>
                        <div style={{display: 'flex'}}>
                            <a target={"_black"} className={'footer-icon-link'}
                               href={'https://www.facebook.com/CLYKKServices'}>
                                <img src={facebook} className={'footer-icon'}/>
                            </a>

                            <a target={"_black"} className={'footer-icon-link'}
                               href={'https://www.linkedin.com/company/clykkservices'}>
                                <img src={linkedin} className={'footer-icon'}/>
                            </a>

                            <a target={"_black"} className={'footer-icon-link'}
                               href={'https://www.youtube.com/@CLYKKServices'}>
                                <img src={youtube} className={'footer-icon'}/>
                            </a>

                            <a target={"_black"} className={'footer-icon-link'}
                               href={'https://www.instagram.com/clykkservices/'}>
                                <img src={insta} className={'footer-icon'}/>
                            </a>

                            <a target={"_black"} className={'footer-icon-link'}
                               href={'https://twitter.com/CLYKKServices'}>
                                <img src={twitter} className={'footer-icon'}/>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6">
                                <p className={'footer-text'} onClick={() => {
                                    history.push('/faq/')
                                }}>FAQ</p>
                                <p className={'footer-text'} onClick={() => {
                                    history.push('/contact-us/')
                                }}>Contact Us</p>
                                <p className={'footer-text'} onClick={() => {
                                    history.push('/about-us/')
                                }}>About CLYKK</p>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6">
                                <p className={'footer-text'} onClick={() => {
                                    history.push('/terms/')
                                }}>Terms</p>
                                <p className={'footer-text'} onClick={() => {
                                    history.push('/privacy-policy/')
                                }}>Privacy Policy</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
                        <p className={'footer-info'}>
                            Download Application
                        </p>
                        <div style={{margin: "10px 0"}}>
                            {/*<StoreBtn img={ApplyStore} text={userType} store={'App Store'}*/}
                            {/*          url={'https://play.google.com/store/apps/details?id=com.clykk'}/>*/}
                            <StoreBtn img={ApplyStore} text={userType} store={'App Store'}
                                      url={userType === 'Customer' ? 'https://apps.apple.com/us/app/clykk/id1542592627?platform=iphone' : 'https://apps.apple.com/us/app/clykk-service-provider/id1550083789'}/>
                        </div>
                        <div style={{margin: "10px 0"}}>
                            {/*<StoreBtn img={GoogleStore} text={userType} store={'Google Play'}*/}
                            {/*          url={'https://play.google.com/store/apps/details?id=com.clykk'}/>*/}

                            <StoreBtn img={GoogleStore} text={userType} store={'Google Play'}
                                      url={userType === 'Customer' ? 'https://play.google.com/store/apps/details?id=com.clykk' : 'https://play.google.com/store/apps/details?id=com.clykksp'}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
