import React, {useEffect, useState} from "react";
import {IoIosCloseCircle} from "react-icons/io";
import SetLocation from "./Locations/SetLocation";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {Pagination} from "swiper";
import LocationChoice from "./LocationChoice";
import purple_glitter from "../../assets/images/glitter.png";
import yellow_glitter from "../../assets/images/yellow star.png";
import green_glitter from "../../assets/images/greenstar.png";
import {useDispatch, useSelector} from "react-redux";
import {save_user_address, update_user_address_locally, update_user_address_server} from "../../actions/accounts";
import {useHistory} from "react-router-dom";

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const LocationModalBody = ({setLocationModalOpen, handleLocationModalClose}) => {
    const history = useHistory()
    const [modalStyle] = useState(getModalStyle);
    const [my_swiper, set_my_swiper] = useState({});
    const regModal = true

    const dispatch = useDispatch()

    // addresses
    const [defaultScreen, setDefaultScreen] = useState(1)
    const [updateId, setUpdateId] = useState(null)
    const location = useSelector(state => state.accounts.location)
    const [homeAddress, setHomeAddress] = useState({
        addressType: "",
        home_num: "",
        display_title: '',
        landmark: '',
        title: 'Home',
        address: null
    })
    const [serviceAddress, setServiceAddress] = useState(location)
    const center = {
        lat: serviceAddress.latitude,
        lng: serviceAddress.longitude,
    };
    useEffect(() => {
        setServiceAddress(location)
    }, [location])
    useEffect(() => {
        if (updateId === null) {
            setHomeAddress({
                addressType: "",
                home_num: "",
                display_title: '',
                landmark: '',
                title: 'Home',
                address: ''
            })
        }
    }, [updateId])
    const save_address = () => {
        let city = serviceAddress.city

        if(city.includes('Bangalore')) {
            city = 'Bengaluru'
        }

        const body = {
            "addressType": homeAddress.addressType,
            "line1": homeAddress.home_num,
            "line2": homeAddress.address,
            "line3": "",
            "pincode": serviceAddress.postal_code,
            // "city": "Bengaluru",
            "city": city,
            "state": serviceAddress.state,
            "country": serviceAddress.country,
            "latitude": serviceAddress.latitude,
            "longitude": serviceAddress.longitude,
            "landmark": homeAddress.landmark,
            "locationText": homeAddress.title,
            "defaultServiceAddress": false,
            "defaultBillingAddress": false
        }

        if (!updateId) {
            dispatch(save_user_address(body))
            setUpdateId(null)
            handleLocationModalClose()
        } else {
            dispatch(update_user_address_server(body, updateId))
            setUpdateId(null)
            handleLocationModalClose()
        }
        if (!regModal) {
            setDefaultScreen(1)
        }
        // setHomeAddress({
        //     addressType: "",
        //     home_num: "",
        //     display_title: '',
        //     landmark: '',
        //     title: 'Home',
        //     address: null
        // })
    }
    const set_edit_data = (location) => {
        setUpdateId(location.id)
        setHomeAddress({
            addressType: location.addressType,
            home_num: location.line1,
            display_title: location.locationText,
            landmark: location.landmark,
            title: location.locationText,
            address: location.line2
        })
        setDefaultScreen(0)
    }
    const setAddress = (address) => {
        dispatch(update_user_address_locally(address))
        setUpdateId(null)
        handleLocationModalClose()
        // history.push('/')
    }

    return (
        <div style={modalStyle} className={'swiper-modal-paper'}>
            <div style={{width: '100%', height: '100%', position: 'relative'}}>
                <>
                    <img src={purple_glitter}s
                         style={{position: 'absolute', zIndex: -10, width: 15, top: '7%', left: '12%'}}/>
                    <img src={purple_glitter}
                         style={{position: 'absolute', zIndex: -10, width: 10, top: '33%', left: '82%'}}/>

                    <img src={yellow_glitter}
                         style={{position: 'absolute', zIndex: -10, width: 15, top: '10%', left: '90%'}}/>
                    <img src={yellow_glitter}
                         style={{position: 'absolute', zIndex: -10, width: 8, top: '8%', left: '86%'}}/>

                    <img src={green_glitter}
                         style={{position: 'absolute', zIndex: -10, width: 17, top: '60%', left: '12%'}}/>

                    <img src={purple_glitter}
                         style={{position: 'absolute', zIndex: -10, width: 8, top: '88%', left: '7%'}}/>
                    <img src={green_glitter}
                         style={{position: 'absolute', zIndex: -10, width: 15, top: '95%', left: '90%'}}/>
                </>
                <div style={{width: '100%', display: 'flex'}}>
                    <IoIosCloseCircle style={{marginLeft: 'auto', fontSize: 14, cursor: 'pointer'}}
                                      onClick={handleLocationModalClose}
                    />
                </div>

                <Swiper
                    pagination={{
                        clickable: false,
                    }}
                    onClick={() => {
                    }}
                    modules={[Pagination]}
                    allowTouchMove={false}
                    slidesPerView={1}
                    onInit={(ev) => {
                        set_my_swiper(ev)
                    }}>
                    <SwiperSlide style={{width: '100%', height: '100%'}}>
                        <div style={{width: '100%', height: '100%'}}>
                            <LocationChoice my_swiper={my_swiper}
                                            setDefaultScreen={setDefaultScreen}
                                            handleCloseModal={handleLocationModalClose}
                                            regModal={false}
                            />
                        </div>
                    </SwiperSlide>

                    <SwiperSlide style={{width: '100%', height: '100%'}}>
                        <div style={{width: '100%', height: '100%'}}>
                            <SetLocation my_swiper={my_swiper} handleCloseModal={handleLocationModalClose}
                                         defaultScreen={defaultScreen} setDefaultScreen={setDefaultScreen}

                                         updateId={updateId}
                                         setUpdateId={setUpdateId}
                                         location={location}
                                         homeAddress={homeAddress}
                                         setHomeAddress={setHomeAddress}

                                         serviceAddress={serviceAddress}
                                         setServiceAddress={setServiceAddress}
                                         center={center}
                                         save_address={save_address}

                                         set_edit_data={set_edit_data}
                                         setAddress={setAddress}

                                         regModal={regModal}
                            />
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    );
}

export default LocationModalBody;