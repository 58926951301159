import React, {useState} from 'react';
import LocationPin from "../../assets/images/map-location pin.png";
import edit_pen from "../../assets/images/Editpen.png";
import LocationModalBody from "../Modals/locationModalBody";
import {Modal} from "@material-ui/core";
import BookingAddressModal from "../BookingAddressModal/BookingAddressModal";

const BookingAddress = ({cartItem, setCartItem, addressHeading, addressType, detail = false, serviceRuleID}) => {
    const [bookingModalOpen, setBookingModalOpen] = useState(false)
    const handleBookingModalOpen = () => {
        setBookingModalOpen(!bookingModalOpen)
    }
    const handleBookingModalClose = () => {
        setBookingModalOpen(!bookingModalOpen)
    }
    console.log("serviceRuleID =>", serviceRuleID)
    return (
        <>
            {detail ?
                <div className={'booking-address-container'}>
                    <div style={{display: 'flex'}}>
                        <img className={'booking-address-icon'} src={LocationPin}/>

                        <p className={'booking-address-heading'}>{addressHeading}</p>
                    </div>
                    {addressType === 'service' ?
                        <p className={'booking-address-text'}>{cartItem.subscriptions[0].serviceAddress.line2}</p>
                        :
                        <p className={'booking-address-text'}>{cartItem.subscriptions[0].deliveryAddress.line2}</p>
                    }
                </div>
                :
                <div className={'booking-address-container'}>
                    <div style={{display: 'flex'}}>
                        <img className={'booking-address-icon'} src={LocationPin}/>

                        <p className={'booking-address-heading'}>{addressHeading} <span>{serviceRuleID === 3 && "(Services Online)"}</span></p>

                        <img src={edit_pen} className={'booking-address-icon'}
                             onClick={handleBookingModalOpen}/>
                    </div>
                    {addressType === 'service' ?
                        <p className={'booking-address-text'}>{cartItem.serviceAddress.line2}</p>
                        :
                        <p className={'booking-address-text'}>{cartItem.deliveryAddress.line2}</p>
                    }
                </div>
            }
            <Modal
                open={bookingModalOpen}
                onClose={handleBookingModalClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <BookingAddressModal setBookingModalOpen={setBookingModalOpen}
                                     handleBookingModalClose={handleBookingModalClose}
                                     cartItem={cartItem}
                                     setCartItem={setCartItem}
                                     addressType={addressType}
                />
            </Modal>
        </>
    );
}

export default BookingAddress;