import React, {useContext, useState} from 'react';
import {
    EmailIcon,
    EmailShareButton, FacebookIcon,
    FacebookShareButton, TwitterIcon, TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";
import {BsShare} from "react-icons/bs";
import {GrFormClose} from "react-icons/gr";
import gmail from '../../assets/images/Gmail_Logo_512px.png'
import Config from "../../Config";
import axios from "axios";
import {useSelector} from "react-redux";
import {Context} from "../../Contexts/Context";

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

function ShareModal({shareData, handleShareCloseModal}) {


    const title = "Check out these amazing offers, deals, and coupons at CLYKK\n\n" +
        "CLYKK Home Services Package Recommendation:\n\n" +
        "Package Name: " + shareData.packageName + "\n" +
        "Package Description: " + shareData.shortDescription + "\n" +
        shareData.url + "\n" +
        "Download CLYKK From Play Store\n" +
        "CLYKK Customer\n"+
        "https://play.google.com/store/apps/details?id=com.clykk" + "\n" +
        "CLYKK Service Provider\n"+
        "https://play.google.com/store/apps/details?id=com.clykksp" + "\n\n" +
        "Download CLYKK From Apple Store\n" +
        "CLYKK Customer\n"+
        "https://apps.apple.com/us/app/clykk/id1542592627" + "\n" +
        "CLYKK Service Provider\n"+
        "https://apps.apple.com/us/app/clykk-service-provider/id1550083789" + "\n" +
        "Visit us on \n"

    const email = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&&su=Checkout%20this%20amazing%20deal&body=Checkout this amazing deal%0A%0ACLYKK Home Services Package Recommendation:%0A%0A${shareData.packageName}%0A${shareData.url}%0A%0ADownload CLYKK From Play Store%0ACLYKK Customer%0Ahttps://play.google.com/store/apps/details?id=com.clykk%0ACLYKK Service Provider%0Ahttps://play.google.com/store/apps/details?id=com.clykksp%0A%0ADownload CLYKK From Apple Store%0ACLYKK Customer%0Ahttps://apps.apple.com/us/app/clykk/id1542592627%0ACLYKK Service Provider%0Ahttps://apps.apple.com/us/app/clykk-service-provider/id1550083789%0A%0AVisit us on%0Ahttps://clykk.com/`

    const data = useSelector(state => state.accounts.data)

    const [modalStyle] = useState(getModalStyle);

    const sharePackage = (type) => {
        const config = Config()
        axios.post(`https://cgapp.clykk.com/like/api/v2/packageShare`,
            {
                "packageId": shareData.id,
                "sharedVai": type,
                "receiverName": data.firstName,
                "contactinfo": data.mobile
            }
            , config)
            .then(response => {
                console.log(response.data)
            })
            .catch(err => {
                console.log(err.message)
            })
    }

    const account_settings = useSelector(state => state.accounts.account_settings)
    const {handleOpenBlockerModal, setBlockerType} = useContext(Context);

    return (
        <div style={modalStyle} className={'share-modal'}>
            <div style={{display: 'flex'}}>
                <p style={{fontSize: 12, fontWeight: 700, marginBottom: 10}}>
                    <BsShare/> Share with friends and family
                </p>
                <GrFormClose onClick={handleShareCloseModal} style={{marginLeft: "auto", cursor: 'pointer'}}/>
            </div>
            <div style={{width: "100%", display: 'flex', position: 'relative'}}>
                <div style={{display: 'flex', margin: "10px auto"}}
                     onClick={() => {
                         if (!account_settings.contacts) {
                             setBlockerType('contacts')
                             handleOpenBlockerModal()
                         }
                     }}
                >
                    {!account_settings.contacts &&
                        <div
                            style={{position: 'absolute', width: '100%', height: '100%'}}></div>
                    }
                    <div className={'share-btn-icons'} onClick={() => {
                        sharePackage('WhatsApp')
                    }}>
                        <WhatsappShareButton
                            url={`https://clykk.com/package-detail/${shareData.id}/${shareData.subcategoryID}`}
                            title={title}
                            windowWidth={900}
                            windowHeight={600}
                            separator=""
                            className="Demo__some-network__share-button"
                        >
                            <WhatsappIcon size={35} round/>
                        </WhatsappShareButton>
                    </div>
                    <div className={'share-btn-icons'} onClick={() => {
                        sharePackage('Email')
                    }}>
                        <EmailShareButton
                            url={`https://clykk.com/package-detail/${shareData.id}/${shareData.subcategoryID}`}
                            subject={'This is an amazing offer !!'}
                            body={title}
                            className="Demo__some-network__share-button"
                        >
                            <EmailIcon size={35} round/>
                        </EmailShareButton>
                    </div>
                    <div className={'share-btn-icons'} onClick={() => {
                        sharePackage('Twitter')
                    }}>
                        <TwitterShareButton
                            url={`https://clykk.com/package-detail/${shareData.id}/${shareData.subcategoryID}`}
                            title={title}
                            className="Demo__some-network__share-button"
                        >
                            <TwitterIcon size={35} round/>
                        </TwitterShareButton>
                    </div>
                    <div className={'share-btn-icons'} onClick={() => {
                        sharePackage('Facebook')
                    }}>
                        <FacebookShareButton
                            url={`https://clykk.com/package-detail/${shareData.id}/${shareData.subcategoryID}`}
                            quote={title}
                            className="Demo__some-network__share-button"
                        >
                            <FacebookIcon size={35} round/>
                        </FacebookShareButton>
                    </div>
                    <div style={{
                        backgroundColor: '#CBEDE3',
                        borderRadius: '50%',
                        display: 'flex',
                        padding: 5,
                        width: 35
                    }} onClick={() => {
                        sharePackage('Gmail')
                    }}>
                        <a href={email} style={{
                            padding: 0,
                            textDecoration: "none",
                            margin: "auto"
                        }}
                           target={'_blank'}>
                            <img src={gmail} style={{width: 22, margin: 'auto 0'}}/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
        ;
}

export default ShareModal;