import {AiFillQuestionCircle, AiOutlineLeft} from "react-icons/ai";
import Locate from "./Locate";
import Search from "./Search";
import {GoogleMap, useLoadScript} from "@react-google-maps/api";
import pin from "../../../assets/images/map-location pin.png";
import React, {useContext, useEffect, useRef, useState} from "react";
import Geocode from "react-geocode";
import {useDispatch} from "react-redux";
import {save_user_address, update_user_address_server} from "../../../actions/accounts";
import {useHistory} from "react-router-dom";
import {Context} from "../../../Contexts/Context";

const libraries = ["places"];

const mapContainerStyle = {};

const options = {
    disableDefaultUI: true,
    zoomControl: false,
};

const get_address = (address) => {
    if (address.substring().length < 60) {
        return address
    } else {
        return address.substring(0, 60) + '...'
    }
}


const SelectLocation = ({
                            my_swiper,
                            setDefaultScreen,
                            location,
                            updateId,
                            setUpdateId,
                            homeAddress,
                            setHomeAddress,
                            handleCloseModal,
                            serviceAddress,
                            setServiceAddress,
                            center,
                            save_address,
                            regModal
                        }) => {

    const {setFaqNum, handleOpenFaqModal} = useContext(Context);

    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
    const mapRef = React.useRef();
    const onMapLoad = React.useCallback((map) => {
        mapRef.current = map;
    }, []);
    const panTo = React.useCallback(({lat, lng}) => {
        mapRef.current.panTo({lat, lng});
        mapRef.current.setZoom(14);
    }, []);


    const handleHomeAddressChange = (key, e) => {
        setHomeAddress({...homeAddress, [key]: e.target.value})
    }

    const formRef = useRef()

    const getAddressCity = (address, length, level) => {
        const findType = type => type.types[0] === level
        const location = address.map(obj => obj)
        const rr = location.filter(findType)[0]

        if (rr) {
            return (
                length === 'short'
                    ? rr.short_name
                    : rr.long_name
            )
        } else {
            return ''
        }
    }

    const set_current_location = () => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
                    response => {
                        const address = response.results[0].formatted_address;
                        const formatted_address = response.results[0].formatted_address.split(',')

                        const address_components = response.results[0].address_components

                        const line1 = formatted_address[0]
                        const line2 = address
                        console.log(address_components)
                        const postal_code = getAddressCity(address_components, 'long', "postal_code")
                        const city = getAddressCity(address_components, 'long', "administrative_area_level_2")
                        const state = getAddressCity(address_components, 'long', "administrative_area_level_1")
                        const country = getAddressCity(address_components, 'long', "country")
                        // setUpdateId(null)

                        setServiceAddress((prev) => ({
                            ...prev,
                            line1: line1,
                            line2: line2,
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                            city: city,
                            state: state,
                            country: country,
                            postal_code: postal_code
                        }))

                        let landmark = ''
                        let home_num = ''

                        if (line2.split(',').length > 2) {
                            const arr = line2.split(',')
                            home_num = arr[0]
                            landmark = arr[1]
                        }

                        setHomeAddress((prev) => ({
                            ...prev,
                            address: line2,
                            landmark: landmark,
                            home_num: home_num
                        }))
                    },
                    error => {
                        console.error(error);
                    }
                );
                panTo({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                });
            },
            (error) => {
                console.log(error)
            }, {timeout: 10000}
        );
    }

    useEffect(() => {
        if (!homeAddress.address) {
            set_current_location()
        }
    }, [])

    if (loadError) return "Error";
    if (!isLoaded) return "Loading...";


    return (
        <div style={{width: '100%'}}>
            {regModal &&
                <>
                    <div style={{textAlign: 'left'}}>
                        <p style={{fontSize: 12, fontWeight: 700, cursor: 'pointer'}}
                           onClick={() => {
                               setUpdateId(null)
                               my_swiper.slidePrev()
                           }}>
                            <AiOutlineLeft
                                style={{margin: '0 15px', fontSize: 14, fontWeight: 700}}/>
                            Enter Location
                        </p>
                    </div>
                    <hr className={'hr-line'}/>
                </>
            }

            <div className={'map-modal-container'}>
                {/*<p className={'consumer-process-modal-content consumer-process-modal-text'}*/}
                {/*>Enter Service Address</p>*/}
                <div className={'map-container'}>

                    <Locate set_current_location={set_current_location}
                    />

                    <Search panTo={panTo}
                            setServiceAddress={setServiceAddress}
                            setHomeAddress={setHomeAddress}
                            setUpdateId={setUpdateId}
                    />

                    <GoogleMap
                        id="map"
                        zoom={8}
                        center={center}
                        options={options}
                        onLoad={onMapLoad}
                    />

                    <div className={'address-info-container'}>
                        <div className={'location-modal-serviceaddress-container display-location-lg'}>
                            <div style={{display: 'flex'}}>
                                <img src={pin} className={'select-location-pin'}/>
                                <p className={'display_title'}>{homeAddress.display_title}</p>
                            </div>
                            <p className={'service-address'}>{homeAddress.address && get_address(homeAddress.address)}</p>
                        </div>
                        <button className={'btn location-modal-saved-locations-btn'}
                                onClick={() => {
                                    setUpdateId(null)
                                    setDefaultScreen(1)
                                }}>Saved Location
                        </button>
                        <AiFillQuestionCircle
                            className={'faq-icon-selection-location'}
                            onClick={() => {
                                setFaqNum(12)
                                handleOpenFaqModal()
                            }}/>
                    </div>

                    <div style={{textAlign: 'left'}}>
                        <form ref={formRef}>
                            <div className={'location-modal-serviceaddress-container display-location-sm'}>
                                <div style={{display: 'flex'}}>
                                    <img src={pin} className={'select-location-pin'}/>
                                    <p className={'display_title'}>{homeAddress.display_title}</p>
                                </div>
                                <p className={'service-address'}>{homeAddress.address && get_address(homeAddress.address)}</p>
                            </div>
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <p className={'location-modal-label'}>House No./ Fla No./ Apartment No.</p>
                                    <input className={'location-modal-input form-control'}
                                           placeholder={'16 Lewis Road'}
                                           value={homeAddress.home_num}
                                           required={true}
                                           onChange={(e) => {
                                               handleHomeAddressChange('home_num', e)
                                           }}
                                    />
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <p className={'location-modal-label'}>Landmark</p>
                                    <input className={'location-modal-input form-control'}
                                           placeholder={'Landmark'}
                                           value={homeAddress.landmark}
                                           required={true}
                                           onChange={(e) => {
                                               handleHomeAddressChange('landmark', e)
                                           }}
                                    />
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-6 col-xs-12">
                                    <p className={'location-modal-label'}>Save As</p>
                                    <input
                                        className={'location-modal-input location-modal-input-title form-control '}
                                        placeholder={'Ex. Home, Work, CLYKK Office'}
                                        value={homeAddress.title}
                                        required={true}
                                        onChange={(e) => {
                                            handleHomeAddressChange('title', e)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={'location-modal-save-btn-container'}>
                                <button className={'btn location-modal-save-btn'}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            const form = formRef.current;
                                            if (!form.checkValidity()) {
                                                form.reportValidity()
                                                return
                                            } else {
                                                save_address()
                                            }
                                        }}
                                >Save Location
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SelectLocation;