import {BrowserRouter, Switch, Route} from 'react-router-dom';
import LandingPage from "./pages/LandingPage";
import PackageDetails from './pages/PackageDetails';
import SubCategoryDetail from "./pages/SubCategoryDetail";
import ScrollToTop from "./ScrollToTop";
import SwiperCore, {Autoplay} from 'swiper';
import Layout from "./component/Layout/Layout";
import FaqPage from "./pages/FAQPage";
import OffersPage from "./pages/OffersPage";
import ContactUsPage from "./pages/ContactUsPage";
import WishlistPage from "./pages/WishlistPage";
import OrdersPage from "./pages/OrdersPage";
import Booking from "./component/Booking/booking";
import {Context} from "./Contexts/Context";
import {useState} from "react";
import AccountsPage from "./pages/AccountsPage";
import Cart from "./pages/Cart";
import CheckOutPage from "./pages/CheckOutPage";
import ChatPage from "./pages/ChatPage";
import ServiceExecutionPage from "./pages/ServiceExecutionPage";
import ServiceTrackPage from "./pages/ServiceTrackPage";
import {Provider} from "react-redux";
import {store, persistor} from "./store";
import {PersistGate} from "redux-persist/integration/react";
import {Toaster} from 'react-hot-toast';
import UpdatePackageDetails from "./pages/UpdatePackageDetails";
import Alert from "./pages/Alert";
import OrderDetail from "./pages/OrderDetail";
import AboutUsPage from "./pages/AboutUsPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermsPage from "./pages/TermsPage";
import RegisterAsAProfessional from "./pages/RegisterAsAProfessional";
import AdditionalChargePage from "./pages/AdditionalChargePage";
import OrderSubscriptionDetail from "./pages/OrderSubscriptionDetail";
import UpdateSubscriptionDetails from "./pages/UpdateSubscriptionDetails";
import ServiceRatingPage from "./pages/ServiceRatingPage";
import ChatOrderPage from "./pages/ChatOrderPage";
import { HelmetProvider } from 'react-helmet-async';

function App() {
    const [value, setValue] = useState(0);

    const [openModal, setOpenModal] = useState(false)

    const handleOpenModal = (slide) => {
        setOpenModal(!openModal)
    }

    const [openCatModal, setCatOpenModal] = useState(false)
    const [selectCat, setSelectCat] = useState(null)

    const [categories, setCategories] = useState([])
    const [filterCategories, setFilterCategories] = useState([])

    const [subCategory, setSubCategory] = useState([])

    const handleCatCloseModal = () => {
        setCatOpenModal(!openCatModal)
        setSelectCat(null)
        setSubCategory([])
    }

    const login = false

    const [faqNum, setFaqNum] = useState(11)
    const [openFaqModal, setOpenFaqModal] = useState(false);

    const handleCloseFaqModal = () => {
        setOpenFaqModal(false)
    }

    const handleOpenFaqModal = () => {
        setOpenFaqModal(true)
    }

    const [openBlockerModal, setOpenBlockerModal] = useState(false)
    const handleOpenBlockerModal = () => {
        setOpenBlockerModal(true)
    }
    const handleCloseBlockerModal = () => {
        setOpenBlockerModal(false)
    }
    const [blockerType, setBlockerType] = useState("")

    SwiperCore.use([Autoplay])


    return (
        <HelmetProvider>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <Context.Provider
                        value={{
                            login, openModal, setOpenModal, handleOpenModal, value, setValue,
                            openCatModal, setCatOpenModal, selectCat, categories, filterCategories, setFilterCategories,
                            subCategory, setCategories, setSubCategory, setSelectCat, handleCatCloseModal,
                            openFaqModal, handleCloseFaqModal, handleOpenFaqModal, faqNum, setFaqNum,
                            openBlockerModal, handleOpenBlockerModal, handleCloseBlockerModal, setBlockerType, blockerType
                        }}>
                        <BrowserRouter>
                            <Toaster/>
                            <ScrollToTop/>
                            <Layout>
                                <Switch>
                                    <Route path="/" exact component={LandingPage}/>

                                    <Route path="/sub-category-detail/:id/" component={SubCategoryDetail}/>

                                    <Route path="/package-detail/:id/" component={PackageDetails}/>

                                    <Route path="/cart/" component={Cart}/>


                                    <Route path="/booking/:id/" component={Booking}/>

                                    <Route path="/update-package-detail/:id/" component={UpdatePackageDetails}/>

                                    <Route path="/check-out/" component={CheckOutPage}/>

                                    <Route path="/my-orders/" component={OrdersPage}/>

                                    <Route path="/service-execution/" component={ServiceExecutionPage}/>

                                    <Route path="/order-subscription-detail/" component={OrderSubscriptionDetail}/>

                                    <Route path="/update-subscription-detail/:id/" component={UpdateSubscriptionDetails}/>

                                    <Route path="/order-detail/" component={OrderDetail}/>

                                    <Route path="/service-track/" component={ServiceTrackPage}/>

                                    <Route path="/additional-charge/" component={AdditionalChargePage}/>

                                    <Route path="/service-rating/" component={ServiceRatingPage}/>

                                    <Route path="/contact-us/" component={ContactUsPage}/>

                                    {/* Navbar */}

                                    <Route path="/account/" component={AccountsPage}/>

                                    <Route path="/alert/" component={Alert}/>

                                    <Route path="/offers/" component={OffersPage}/>

                                    <Route path="/about-us/" component={AboutUsPage}/>

                                    <Route path="/privacy-policy/" component={PrivacyPolicyPage}/>

                                    <Route path="/terms/" component={TermsPage}/>

                                    {/* Others */}
                                    <Route path="/service-provider/" component={RegisterAsAProfessional}/>

                                    <Route path="/chat/" component={ChatPage}/>

                                    <Route path="/chat-orders/" component={ChatOrderPage}/>

                                    <Route path="/chat-card/" component={ChatPage}/>

                                    <Route path="/wishlist/" component={WishlistPage}/>

                                    <Route path="/faq/" component={FaqPage}/>
                                </Switch>
                            </Layout>
                        </BrowserRouter>
                    </Context.Provider>
                </PersistGate>
            </Provider>
        </HelmetProvider>
    );
}

export default App;

// +91 8310546938
