import axios from "axios";

function CheckAuthenticated() {
    if (localStorage.getItem('access_token') && localStorage.getItem('isAuthenticated') === 'true') {
        return true
    } else {
        return false
    }
}

export default CheckAuthenticated;