import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {initializeApp} from "firebase/app";
import {addDoc, collection, getFirestore, onSnapshot, orderBy, query, serverTimestamp} from "firebase/firestore";
import clsx from "clsx";
import Footer from "../component/LandingPage/Footer";

function ChatOrderPage({}) {
    const location = useLocation()
    const user = useSelector(state => state.accounts.data)

    const subscription_id = location.pathname.split('/')[2]

    const chat_type_num = location.pathname.split('/')[3]

    const chat_type = chat_type_num === 1 ? 'customer_support_cart' : chat_type_num === 2 ? "customer_support_order" : "sp_support"

    const [chats, setChats] = useState({})

    const [chatHistory, setChatHistory] = useState([])

    const firebaseConfig = {
        apiKey: "AIzaSyCq7z36nZYY2m1E4qPf6AzaP_XgI-A5C_8",
        authDomain: "clykk-356df.firebaseapp.com",
        databaseURL: "https://clykk-356df.firebaseio.com",
        projectId: "clykk-356df",
        storageBucket: "clykk-356df.appspot.com",
        messagingSenderId: "11379513871",
        appId: "1:11379513871:web:e6d15083b499d10ade617a",
        measurementId: "G-9SVF06531T"
    };

    const app = initializeApp(firebaseConfig);

    const db = getFirestore(app);

    const [selected, setSelected] = useState(null)

    useEffect(() => {
        onSnapshot(query(collection(db, "subscription_customer_support")), (snapshot) => {
            var _chats = []
            snapshot.forEach((sub) => {
                console.log(sub.id)
                _chats[sub.id.toString()] = {id: sub.id.toString(), name: sub.id.toString()}
            })
            _chats[subscription_id] = {id: subscription_id, name: subscription_id}
            setSelected(subscription_id)
            setChats(_chats)
        })
    }, [])

    useEffect(() => {
        if (selected) {
            onSnapshot(query(collection(db, "subscription_chat", selected, "customer_support_order"), orderBy('created_at')), (messages) => {
                var messages_list = []
                messages.forEach((message) => {
                    messages_list.push(message.data())
                })
                setChatHistory(messages_list)
            })
        }
    }, [selected])


    async function send_message(message) {
        await addDoc(collection(db, "subscription_chat", selected, "customer_support_order"), {
            additional_meta: "",
            created_at: serverTimestamp(),
            message: message,
            sent_by: user.userId,
            subscription_id: subscription_id,
            type: 1,
            user_id: user.userId
        })
    }

    return (
        <>
            <div className={'container my-5'}>
                <div className="row g-0" style={{backgroundColor: '#f3f2f2'}}>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                        <div style={{height: '80vh', overflowY: 'auto'}}>
                            {Object.keys(chats).map(function (key, index) {
                                return (<div
                                    className={clsx('chat-item-container', chats[key].id === selected ? 'chat-item-container-selected' : 'chat-item-container-not-selected')}
                                    onClick={() => {
                                        setSelected(chats[key].id)
                                        // setChatHistory(chats[key].history)
                                    }}>
                                    <div style={{display: 'flex', margin: "auto 0"}}>
                                        {/*<img src={profile_img} style={{width: 40, height: 40}}/>*/}
                                        <p style={{
                                            fontSize: 14, fontWeight: 600, margin: "auto 10px"
                                        }}>{chats[key].name}</p>
                                    </div>
                                </div>)
                            })}
                        </div>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 col-12">
                        <div style={{width: '100%', position: 'relative'}}>
                            <div style={{height: '80vh', overflowY: 'auto'}}>
                                <div className={'container'}
                                     style={{display: 'relative', padding: '30px 30px 80px 30px'}}>
                                    {chatHistory.map(item => {
                                        return (<div style={{width: '100%', display: 'flex'}}>
                                            {user.userId == item['sent_by'] ? <div className={'chat-received'}>
                                                <p>{item.message}</p>
                                            </div> : <div className={'chat-sent'}>
                                                <p>{item.message}</p>
                                            </div>}
                                        </div>)
                                    })}
                                </div>
                            </div>
                            <div style={{
                                display: 'flex',
                                bottom: 0,
                                padding: 10,
                                position: 'absolute',
                                backgroundColor: 'white',
                                width: '100%'
                            }}>
                                <input className={'chat-input form-control'} placeholder={'Type message'}
                                       onKeyPress={event => {
                                           if (event.key === 'Enter') {
                                               console.log(event.target.value)
                                               send_message(event.target.value)
                                           }
                                       }}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default ChatOrderPage;