import Footer from "../component/LandingPage/Footer";
import {AiFillQuestionCircle} from "react-icons/ai";
import {useHistory} from "react-router-dom";
import Faq from "../component/LandingPage/FAQ";

function PrivacyPolicyPage() {
    const history = useHistory();

    return (
        <>
            <div style={{
                backgroundColor: "#F6F6F6",
                display: 'flex',
                flexDirection: 'column',
                margin: "30px 0"
            }}>
                <AiFillQuestionCircle
                    className={'faq-icon-selection-location'}
                    style={{margin: '10px 10px auto auto'}}
                    onClick={() => {
                        history.push('/contact-us/22')
                    }}/>
                <div style={{
                    padding: "70px 20px 90px 20px"
                }}>
                    <div className={'container'} style={{display: 'flex'}}>
                        <p style={{
                            color: "#424B5A",
                            fontSize: 42,
                            fontWeight: 400,
                            flexDirection: 'auto 0',
                            letterSpacing: 1
                        }}>Privacy Policy</p>
                    </div>
                </div>
            </div>

            <Faq faqNum={22} page={false} home={true} terms={true}/>

            <Footer/>
        </>
    );
}

export default PrivacyPolicyPage;