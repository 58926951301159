import Footer from "../component/LandingPage/Footer";
import {
    AiOutlineHeart,
    AiOutlineHome,
    AiOutlineUser,
    AiOutlineShareAlt,
    AiOutlineSetting,
    AiOutlineCalendar, AiFillQuestionCircle
} from "react-icons/ai";
import {RiBarChartHorizontalLine} from "react-icons/ri";
import React, {useContext, useEffect} from "react";
import {useState} from "react";
import deal_icon from '../assets/images/coupon-svgrepo-.png'
import {BsHeadphones} from "react-icons/bs";
import {useSelector} from "react-redux";
import PreferencesTab from "../component/AccountTabs/PreferencesTab";
import {useHistory} from "react-router-dom";
import ManageAccessTab from "../component/AccountTabs/ManageAccessTab";
import AccountsSidebar from "../component/Accounts/AccountsSidebar";
import UserInformationTab from "../component/AccountTabs/UserInformationTab";
import {GrClose} from "react-icons/gr";
import {GiHamburgerMenu} from "react-icons/gi";
import clsx from "clsx";
import AccountsSocialsBanner from "../component/AccountTabs/AccountsSocialsBanner";
import AccountsLikedServicesTab from "../component/AccountTabs/AccountsLikedServicesTab";
import ApplicationSettingsTab from "../component/AccountTabs/ApplicationSettingsTab";
import {Context} from "../Contexts/Context";

const AccountsPage = () => {

    const {setFaqNum, handleOpenFaqModal} = useContext(Context);

    let user_data = useSelector(state => state.accounts.data)

    const history = useHistory();

    const isAuthenticated = useSelector(state => state.accounts.isAuthenticated)

    if (!isAuthenticated) {
        history.push('/')
    }

    useEffect(() => {
        if (!isAuthenticated) {
            history.push('/')
        }
    }, [isAuthenticated])


    const [accountSideBar, setAccountSideBar] = useState(
        [
            {
                icon: <AiOutlineUser className={'account-sidebar-item account-sidebar-item-icon '}/>,
                text: 'User Information',
                active: true
            },
            {
                icon: <AiOutlineHome className={'account-sidebar-item account-sidebar-item-icon '}/>,
                text: 'Manage Locations',
                active: false
            },
            {
                icon: <AiOutlineHeart className={'account-sidebar-item account-sidebar-item-icon '}/>,
                text: 'Liked',
                active: false
            },
            {
                icon: <RiBarChartHorizontalLine className={'account-sidebar-item account-sidebar-item-icon '}/>,
                text: 'Preferences',
                active: false
            },
            {
                icon: <AiOutlineShareAlt className={'account-sidebar-item account-sidebar-item-icon '}/>,
                text: 'Share & Earn',
                active: false
            },
            {
                icon: <AiOutlineSetting className={'account-sidebar-item account-sidebar-item-icon '}/>,
                text: 'Application Settings',
                active: false
            },

            {
                icon: <img style={{width: 15}} src={deal_icon}
                           className={'account-sidebar-item account-sidebar-item-icon'}/>,
                text: 'Deal',
                active: false
            },
            {
                icon: <AiOutlineCalendar className={'account-sidebar-item account-sidebar-item-icon '}/>,
                text: 'To Do',
                active: false
            },
            {
                icon: <AiOutlineCalendar className={'account-sidebar-item account-sidebar-item-icon '}/>,
                text: 'Orders',
                active: false
            },
            {
                icon: <BsHeadphones src={deal_icon} className={'account-sidebar-item account-sidebar-item-icon'}/>,
                text: 'Support',
                active: false
            }
        ]
    )
    const [active, setActive] = useState({
        icon: <AiOutlineUser className={'account-sidebar-item account-sidebar-item-icon '}/>,
        text: 'User Information',
        active: true
    })

    const onClickAccountSideBar = (text) => {
        setAccountSideBar(accountSideBar.map(item => {
            if (item.text === text) {
                item.active = true
                setActive(item)
            } else {
                item.active = false
            }
            return item
        }))
    }

    const [mobileSideBarOpen, setMobileSideBarOpen] = useState(false)

    return (
        <>
            <div className="container accounts-lg my-5">
                <div style={{display: 'flex'}}>
                    <p style={{fontSize: 22, fontWeight: 700}}>My Account</p>
                    <AiFillQuestionCircle
                        onClick={() => {
                            setFaqNum(28)
                            handleOpenFaqModal()
                        }}
                        className={'accounts-question-icon'}/>
                </div>
                <div className="row my-3">
                    <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 mb-2">

                        <div className={'open-accounts-sidebar-logo mb-2'}>
                            <div style={{width: '100%', display: 'flex'}}>
                                {mobileSideBarOpen ?
                                    <GrClose style={{marginLeft: 'auto', fontSize: 20}}
                                             onClick={() => {
                                                 setMobileSideBarOpen(!mobileSideBarOpen)
                                             }}/>
                                    :
                                    <GiHamburgerMenu style={{marginLeft: 'auto', fontSize: 20}}
                                                     onClick={() => {
                                                         setMobileSideBarOpen(!mobileSideBarOpen)
                                                     }}
                                    />
                                }
                            </div>
                        </div>

                        <div className={'accounts-sidebar-lg'}>
                            <AccountsSidebar accountSideBar={accountSideBar}
                                             onClickAccountSideBar={onClickAccountSideBar} setMobileSideBarOpen={setMobileSideBarOpen}/>
                        </div>

                        <div className={clsx('accounts-sidebar-sm',
                            mobileSideBarOpen ? 'open-accounts-sidebar-sm' : 'close-accounts-sidebar-sm')}>
                            <AccountsSidebar accountSideBar={accountSideBar}
                                             onClickAccountSideBar={onClickAccountSideBar} setMobileSideBarOpen={setMobileSideBarOpen}/>
                        </div>
                    </div>

                    <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12">
                        <div className={'right-tabs-container'}>
                            {active.text === 'User Information' &&
                            <UserInformationTab user_data={user_data}/>
                            }

                            {active.text === 'Manage Locations' &&
                            <ManageAccessTab/>
                            }

                            {active.text === 'Preferences' &&
                            <PreferencesTab/>
                            }

                            {active.text === 'Liked' &&
                            <AccountsLikedServicesTab/>
                            }

                            {active.text === 'Share & Earn' &&
                            <AccountsSocialsBanner/>
                            }

                            {active.text === 'Application Settings' &&
                            <ApplicationSettingsTab/>
                            }

                        </div>
                    </div>

                </div>
            </div>
            <Footer/>
        </>
    );
}

export default AccountsPage;