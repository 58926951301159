import profile_img from '../assets/images/profile-image.png'
import Footer from "../component/LandingPage/Footer";
import {useEffect, useState} from "react";
import clsx from "clsx";
import {initializeApp} from "firebase/app";
import {collection, getDocs, getFirestore, orderBy} from "firebase/firestore";
import {onSnapshot, query, serverTimestamp} from "firebase/firestore";
import {addDoc, doc, setDoc} from "firebase/firestore";
import {useRef} from "react";
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import Config from "../Config";
import axios from "axios";
import pin from "../assets/images/map-location pin.png";
import {numberWithCommas} from "../component/Utils";

const ChatPage = () => {
    const location = useLocation()
    const user = useSelector(state => state.accounts.data)

    const currency = useSelector(state => state.accounts.currency)
    const reference_id = location.pathname.split('/')[2]

    const chat_type_num = location.pathname.split('/')[3]

    const [chatType, setChatType] = useState(1)

    const [subscription, setSubscription] = useState(null)

    useEffect(() => {
        if (chat_type_num == 1) {
            setChatType("cart_conversations")

            const config = Config()
            axios.get(`https://cgapp.clykk.com/cart/api/v2/cart-management/cart/${reference_id}`, config)
                .then(response => {
                    setSubscription(response.data)
                })

        } else if (chat_type_num == 2) {
            setChatType("customer_support")
            const config = Config()
            axios.get(`https://cgapp.clykk.com/cart/api/v2/cart-management/subscription/${reference_id}/schedule`, config)
                .then(response => {
                    setSubscription(response.data)
                })
                .catch(error => {
                    console.log(error.message)
                })
        } else {
            setChatType("conversations")
            const config = Config()
            axios.get(`https://cgapp.clykk.com/cart/api/v2/cart-management/subscription/${reference_id}/schedule`, config)
                .then(response => {
                    setSubscription(response.data)
                })
                .catch(error => {
                    console.log(error.message)
                })
        }
    }, [chat_type_num])

    const sp_id = location.pathname.split('/')[4]

    const [chats, setChats] = useState({})

    const [chatHistory, setChatHistory] = useState([])

    const firebaseConfig = {
        apiKey: "AIzaSyCq7z36nZYY2m1E4qPf6AzaP_XgI-A5C_8",
        authDomain: "clykk-356df.firebaseapp.com",
        databaseURL: "https://clykk-356df.firebaseio.com",
        projectId: "clykk-356df",
        storageBucket: "clykk-356df.appspot.com",
        messagingSenderId: "11379513871",
        appId: "1:11379513871:web:e6d15083b499d10ade617a",
        measurementId: "G-9SVF06531T"
    };

    const app = initializeApp(firebaseConfig);

    const db = getFirestore(app);

    const [selected, setSelected] = useState(null)

    useEffect(() => {
        if (chatType === 'cart_conversations') {
            onSnapshot(query(collection(db, "cart_customer_support")), (snapshot) => {
                var _chats = []
                snapshot.forEach((sub) => {
                    _chats[sub.id.toString()] = {id: sub.id.toString(), name: sub.id.toString()}
                })
                _chats[reference_id] = {id: reference_id, name: reference_id}
                setSelected(reference_id)
                setChats(_chats)
            })
        } else {
            onSnapshot(query(collection(db, "subscription_chat")), (snapshot) => {
                var _chats = []
                snapshot.forEach((sub) => {
                    _chats[sub.id.toString()] = {id: sub.id.toString(), name: sub.id.toString()}
                })
                _chats[reference_id] = {id: reference_id, name: reference_id}
                setSelected(reference_id)
                setChats(_chats)
            })
        }
    }, [])

    useEffect(() => {
        if (selected) {
            if (chatType === 'cart_conversations') {
                onSnapshot(query(collection(db, "cart_customer_support", selected, chatType), orderBy('created_at')), (messages) => {
                    var messages_list = []
                    messages.forEach((message) => {
                        messages_list.push(message.data())
                    })
                    setChatHistory(messages_list)
                })
            } else {
                onSnapshot(query(collection(db, "subscription_chat", selected, chatType), orderBy('created_at')), (messages) => {
                    var messages_list = []
                    messages.forEach((message) => {
                        messages_list.push(message.data())
                    })
                    setChatHistory(messages_list)
                })
            }
        }
    }, [selected])


    async function send_message(message) {
        let body = {}
        if (chatType === 'sp_support') {
            body = {
                additional_meta: "",
                created_at: serverTimestamp(),
                message: message,
                sent_by: user.userId,
                sp_id: sp_id,
                reference_id: reference_id,
                type: 1,
                user_id: user.userId
            }
        } else {
            body = {
                additional_meta: "",
                created_at: serverTimestamp(),
                message: message,
                sent_by: user.userId,
                reference_id: reference_id,
                type: 1,
                user_id: user.userId
            }
        }
        if (chatType === 'cart_conversations') {
            await addDoc(collection(db, "cart_customer_support", selected, chatType), body)
        } else {
            await addDoc(collection(db, "subscription_chat", selected, chatType), body)
        }
    }

    return (
        <>
            <div className="container mt-5">

                {(chatType !== 'cart_conversations' && subscription) &&
                    <div style={{width: '100%'}}>
                        <div style={{display: 'flex'}}>
                            <div style={{width: '100%'}}>
                                <div style={{display: 'flex'}}>
                                    <div className={'package-image-container'}
                                         style={{backgroundImage: `url(${subscription.package.packageImage})`}}>
                                    </div>
                                    <p className={'cart-package-name'}>{subscription.package.packageName}</p>
                                    <p className={'cart-chat-id'}
                                       style={{margin: "auto 0 auto auto"}}>{subscription.cartID} - {subscription.id}</p>
                                </div>
                                <div style={{display: 'flex', marginTop: 12}}>
                                    <img src={pin} style={{width: 10, height: 12}}/>
                                    <p style={{
                                        fontSize: 11,
                                        fontWeight: 400,
                                        color: '#A1A2A4',
                                        marginLeft: 5
                                    }}>{subscription.serviceAddress.line2}</p>
                                </div>
                                <div style={{display: 'flex', marginTop: 10, padding: '0 '}}>
                                    <div style={{margin: 'auto auto auto 0'}}>
                                        <p className={'cart-detail-heading'}>Date</p>
                                        <p className={'cart-detail-meta-text'}>{subscription.serviceDate}</p>
                                    </div>
                                    <div style={{margin: 'auto'}}>
                                        <p className={'cart-detail-heading'}>Time</p>
                                        <p className={'cart-detail-meta-text'}>{subscription.serviceTime}</p>
                                    </div>
                                    <div style={{margin: 'auto 0 auto auto'}}>
                                        <p className={'cart-detail-heading'}>Subscription</p>
                                        <p className={'cart-detail-meta-text'}>{subscription.subscriptionFrequency}</p>
                                    </div>
                                    <div style={{margin: 'auto 0 auto auto'}}>
                                        <p className={'cart-detail-heading'}>Price</p>
                                        <p className={'cart-detail-meta-text'}>{currency} {numberWithCommas(subscription.transaction[0].payableAmount)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {(chatType === 'cart_conversations' && subscription) &&
                    <div style={{width: '100%'}}>
                        <div style={{display: 'flex'}}>
                            <div style={{width: '100%'}}>
                                <div style={{display: 'flex'}}>
                                    <div className={'package-image-container'}
                                         style={{backgroundImage: `url(${subscription.items.package.packageImage})`}}>
                                    </div>
                                    <p className={'cart-package-name'}>{subscription.items.package.packageName}</p>
                                    <p className={'cart-chat-id'}
                                       style={{margin: "auto 0 auto auto"}}>{subscription.cartID}</p>
                                </div>
                                <div style={{display: 'flex', marginTop: 12}}>
                                    <img src={pin} style={{width: 10, height: 12}}/>
                                    <p style={{
                                        fontSize: 11,
                                        fontWeight: 400,
                                        color: '#A1A2A4',
                                        marginLeft: 5
                                    }}>{subscription.serviceAddress.line2}</p>
                                </div>
                                <div style={{display: 'flex', marginTop: 10, padding: '0 '}}>
                                    <div style={{margin: 'auto auto auto 0'}}>
                                        <p className={'cart-detail-heading'}>Date</p>
                                        <p className={'cart-detail-meta-text'}>{subscription.schedule.startDate}</p>
                                    </div>
                                    <div style={{margin: 'auto'}}>
                                        <p className={'cart-detail-heading'}>Time</p>
                                        <p className={'cart-detail-meta-text'}>{subscription.schedule.serviceTime}</p>
                                    </div>
                                    <div style={{margin: 'auto 0 auto auto'}}>
                                        <p className={'cart-detail-heading'}>Subscription</p>
                                        <p className={'cart-detail-meta-text'}>{subscription.schedule.subscriptionFrequency}</p>
                                    </div>
                                    <div style={{margin: 'auto 0 auto auto'}}>
                                        <p className={'cart-detail-heading'}>Price</p>
                                        <p className={'cart-detail-meta-text'}>{currency} {numberWithCommas(subscription.items.package.sellingPrice)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className={'container my-5'}>
                <div className="row g-0" style={{backgroundColor: '#f3f2f2'}}>
                    {/*<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">*/}
                    {/*    <div style={{height: '80vh', overflowY: 'auto'}}>*/}
                    {/*        {Object.keys(chats).map(function (key, index) {*/}
                    {/*            return (*/}
                    {/*                <div*/}
                    {/*                    className={clsx('chat-item-container', chats[key].id === selected ? 'chat-item-container-selected' : 'chat-item-container-not-selected')}*/}
                    {/*                    onClick={() => {*/}
                    {/*                        setSelected(chats[key].id)*/}
                    {/*                        // setChatHistory(chats[key].history)*/}
                    {/*                    }}>*/}
                    {/*                    <div style={{display: 'flex', margin: "auto 0"}}>*/}
                    {/*                        /!*<img src={profile_img} style={{width: 40, height: 40}}/>*!/*/}
                    {/*                        <p style={{*/}
                    {/*                            fontSize: 14,*/}
                    {/*                            fontWeight: 600,*/}
                    {/*                            margin: "auto 10px"*/}
                    {/*                        }}>{chats[key].name}</p>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            )*/}
                    {/*        })}*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                        <div style={{width: '100%', position: 'relative'}}>
                            <div style={{height: '50vh', overflowY: 'auto'}}>
                                <div className={'container'}
                                     style={{display: 'relative', padding: '30px 30px 80px 30px'}}>
                                    {chatHistory.map(item => {
                                        return (
                                            <div style={{width: '100%', display: 'flex'}}>
                                                {user.userId == item['sent_by'] ?
                                                    <div className={'chat-received'}>
                                                        <p>{item.message}</p>
                                                    </div>
                                                    :
                                                    <div className={'chat-sent'}>
                                                        <p>{item.message}</p>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div style={{
                                display: 'flex',
                                bottom: 0,
                                padding: 10,
                                position: 'absolute',
                                backgroundColor: 'white',
                                width: '100%'
                            }}>
                                <input className={'chat-input form-control'} placeholder={'Type message'}
                                       onKeyPress={event => {
                                           if (event.key === 'Enter') {
                                               console.log(event.target.value)
                                               send_message(event.target.value)
                                           }
                                       }}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default ChatPage;
