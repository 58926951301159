import {Box, makeStyles, Tab, Tabs, useTheme} from "@material-ui/core";
import PropTypes from "prop-types";
import {useState} from "react";
import clsx from "clsx";

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}


const useStyles = makeStyles((theme) => ({
    indicator: {
        backgroundColor: "#6CD7BB"
    }
}))

const AppBar = ({value, setValue}) => {
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const changeValue = (newValue) => {
        setValue(newValue)
    }
    const classes = useStyles()
    return (
        <>
            <div className={'container my-3 tabs-lg'}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    classes={{
                        indicator: classes.indicator
                    }}>
                    <Tab label="Package Details" {...a11yProps(0)} />
                    <Tab label="Package Requirements"
                         style={{}} {...a11yProps(1)} />
                    <Tab label="Package Checklist"
                         {...a11yProps(2)} />
                    <Tab label="Package Add-On's"
                         {...a11yProps(3)} />
                </Tabs>
            </div>
            <div className={'container tabs-sm'}>
                <div onClick={() => {
                    changeValue(0)
                }}
                     className={clsx('tab-container', value === 0 && 'tab-active')}>
                    <p>Package Details</p>
                </div>
                <div
                    onClick={() => {
                        changeValue(1)
                    }}
                    className={clsx('tab-container', value === 1 && 'tab-active')}>
                    <p>Package Requirements</p>
                </div>
                <div
                    onClick={() => {
                        changeValue(2)
                    }}
                    className={clsx('tab-container', value === 2 && 'tab-active')}>
                    <p>Package Checklist</p>
                </div>
                <div
                    onClick={() => {
                        changeValue(3)
                    }}
                    className={clsx('tab-container', value === 3 && 'tab-active')}>
                    <p>Package Add-On's</p>
                </div>
            </div>
        </>
    );
}

export default AppBar;