import Footer from "../component/LandingPage/Footer";
import base_copy from '../assets/images/base copy 2.png'
import React, {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {load_user_cart, update_user_profile} from "../actions/accounts";
import {useHistory} from "react-router-dom";
import Config from "../Config";
import axios from "axios";
import logo from "../assets/images/logo.svg";
import {Context} from "../Contexts/Context";
import {AiFillQuestionCircle} from "react-icons/ai";
import AddAlert from "../AddAlert";
import {numberWithCommas} from "../component/Utils";


const CheckOutPage = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const currency = useSelector(state => state.accounts.currency)
    const data = useSelector(state => state.accounts.data)

    const {setFaqNum, handleOpenFaqModal} = useContext(Context);

    const user = useSelector(state => state.accounts.data)
    const [userData, setUserData] = useState(null)
    const [editable, setEditable] = useState(true)

    const [cartID, setCartID] = useState(localStorage.getItem('cartID') ? localStorage.getItem('cartID') : 0)
    const [subTotal, setSubTotal] = useState(localStorage.getItem('subTotal') ? localStorage.getItem('subTotal') : 0)
    const [totalPrice, setTotalPrice] = useState(localStorage.getItem('totalPrice') ? localStorage.getItem('totalPrice') : 0)
    const [moneySavedByCoupon, setMoneySavedByCoupon] = useState(localStorage.getItem('moneySavedByCoupon') ? localStorage.getItem('moneySavedByCoupon') : 0)

    const [savingPrice, setSavingPrice] = useState(localStorage.getItem('savingPrice') ? localStorage.getItem('savingPrice') : 0)

    const [couponName, setCouponName] = useState(localStorage.getItem('couponName') ? localStorage.getItem('couponName') : null)

    const [discountCode, setDiscountCode] = useState(localStorage.getItem('discountCode') ? localStorage.getItem('discountCode') : null)
    const [discountType, setDiscountType] = useState(localStorage.getItem('discountType') ? localStorage.getItem('discountType') : null)
    const [discount, setDiscount] = useState(localStorage.getItem('discount') ? localStorage.getItem('discount') : null)
    const [tax, setTax] = useState(localStorage.getItem('tax') ? localStorage.getItem('tax') : null)
    const taxCode = localStorage.getItem('taxCode') ? localStorage.getItem('taxCode') : null
    const taxPercentage = localStorage.getItem('taxPercentage') ? localStorage.getItem('taxPercentage') : null

    const onTextChange = (e) => {
        setUserData({...userData, [e.target.name]: e.target.value})
    }
    const save_account_info = () => {
        dispatch(update_user_profile(userData))
        setEditable(!editable)
    }

    useEffect(() => {
        setUserData(data)
    }, [data])

    const placeOrder = () => {
        localStorage.removeItem('cartID')
        localStorage.removeItem('couponName')
        localStorage.removeItem('discountCode')
        localStorage.removeItem('discountType')
        localStorage.removeItem('discount')
        localStorage.removeItem('moneySavedByCoupon')
        localStorage.removeItem('subTotal')
        localStorage.removeItem('totalPrice')
        localStorage.removeItem('savingPrice')
        localStorage.removeItem('tax')
        localStorage.removeItem('taxCode')
        localStorage.removeItem('taxPercentage')
        dispatch(load_user_cart())
        history.push('/my-orders/')
    }

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function displayRazorpay() {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        const config = Config()
        const result = await axios.post(`https://cgapp.clykk.com/cart/api/v2/cart-management/cart/${cartID}/order/book`,
            {
                "total": parseFloat(subTotal),
                "payableAmount": parseFloat(totalPrice),
                "amountBeforeTax": parseFloat(subTotal),
                "taxAmount": parseFloat(tax),
                "paymentType": "onetime",
                "discount": discount === "undefined" ? "" : discount,
                "discountType": discountType === "undefined" ? "" : discountType,
                "discountCode": discountCode === null ? "" : discountCode,
                "discountValue": parseFloat(moneySavedByCoupon),
                "discountAmount": parseFloat(moneySavedByCoupon),
                'description': "order"
            }, config
        )

        const {payableAmount, orderID} = result.data;

        const options = {
            key: "rzp_live_YdTGPolg4K9L7h", // Enter the Key ID generated from the Dashboard
            amount: parseFloat(payableAmount * 100),
            currency: "INR",
            name: "CLYKK.",
            description: "CLYKK",
            image: {logo},
            order_id: orderID,
            handler: async function (response) {
                axios.post(`https://cgapp.clykk.com/cart/api/v2/cart-management/cart/${cartID}/verify/book`,
                    {
                        "orderID": response.razorpay_order_id,
                        "paymentID": response.razorpay_payment_id,
                        "generatedSignature": response.razorpay_signature,
                        'description': "order"
                    }, config)
                    .then(res => {
                        placeOrder()
                        const order = res.data
                        order.subscriptions.forEach(item => {
                            AddAlert(dispatch, user.userId, order.package, 'Order', "Scheduled", item.id)
                        })
                    })

            },
            prefill: {
                name: userData.firstName,
                email: userData.email,
                contact: userData.mobile,
            },
            notes: {
                address: "",
            },
            theme: {
                color: "#61dafb",
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    return (
        <>
            <div className="container my-5">
                <div style={{display: 'flex'}}>
                    <p style={{fontSize: 28, fontWeight: 700}}>Billing & Checkout</p>
                    <AiFillQuestionCircle
                        className={'question-faq-icon-package'}
                        onClick={() => {
                            setFaqNum(39)
                            handleOpenFaqModal()
                        }}
                        style={{color: '#ce5e97'}}/>

                </div>

                <div className="row my-3">
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
                        <div style={{width: '100%', padding: '10px', backgroundColor: '#f6f7f9'}}>
                            <p style={{fontSize: 18, fontWeight: 600, margin: '0 0 10px 0'}}>Billing Details</p>
                            {userData &&
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 my-2">
                                        <input className={'form-control'} placeholder={'First Name'}
                                               value={userData.firstName}
                                               name={'firstName'}
                                               onChange={onTextChange}
                                               disabled={!editable}
                                               style={{color: '#5a6167', padding: 10, fontSize: 12}}/>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 my-2">
                                        <input className={'form-control'} placeholder={'Last Name'}
                                               value={userData.lastName}
                                               name={'lastName'}
                                               onChange={onTextChange}
                                               disabled={!editable}
                                               style={{color: '#5a6167', padding: 10, fontSize: 12}}/>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 my-2">
                                        <input className={'form-control'} placeholder={'Email Address'}
                                               value={userData.email}
                                               name={'email'}
                                               onChange={onTextChange}
                                               disabled={!editable}
                                               style={{color: '#5a6167', padding: 10, fontSize: 12}}/>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 my-2">
                                        <input disabled={true} value={userData.mobile} className={'form-control'}
                                               placeholder={'Phone Number'}
                                               style={{color: '#5a6167', padding: 10, fontSize: 12}}/>
                                    </div>
                                </div>
                            }
                            <div style={{width: '100%', display: 'flex', margin: '10px 0'}}>
                                <button
                                    onClick={save_account_info}
                                    style={{
                                        color: 'white',
                                        backgroundColor: '#6CD7BB',
                                        width: 120,
                                        fontSize: 14,
                                        fontWeight: 400,
                                        letterSpacing: 1,
                                        marginLeft: 'auto'
                                    }}
                                    className={'btn'}>{editable ? 'Save' : 'Edit'}
                                </button>
                            </div>

                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div style={{width: '100%', padding: 10, backgroundColor: '#F6F7F9'}}>
                            <p style={{fontSize: 14, fontWeight: 700}}>Order Summary</p>

                            <div style={{width: '100%', display: 'flex', margin: '10px 0 10px 0'}}>
                                <p className={'total-booking'}>Sub Total</p>
                                <p className={'total-booking-text'}>{currency}
                                    {numberWithCommas(parseFloat(subTotal).toFixed(2))}
                                </p>
                            </div>

                            {couponName !== "undefined" &&
                                <>
                                    <div style={{width: '100%', display: 'flex', margin: '20px 0 5px 0'}}>
                                        <p className={'total-booking'}>Coupon</p>
                                        <p className={'total-booking-text'}>
                                            {couponName}
                                        </p>
                                    </div>

                                    <div style={{width: '100%', display: 'flex', margin: '5px 0'}}>
                                        <p className={'total-booking'}>Discount Code</p>
                                        <p className={'total-booking-text'}>
                                            {discountCode}
                                        </p>
                                    </div>

                                    <div style={{width: '100%', display: 'flex', margin: '5px 0'}}>
                                        <p className={'total-booking'}>Coupon Value</p>
                                        <p className={'total-booking-text'}>
                                            {`${currency} ${numberWithCommas(parseFloat(moneySavedByCoupon).toFixed(2))}`}
                                        </p>
                                    </div>
                                </>
                            }

                            <div style={{width: '100%', display: 'flex', margin: '5px 0'}}>
                                <p className={'total-booking'}>Taxes (GST - SAC {taxCode} - {taxPercentage}%)</p>
                                <p className={'total-booking-text'}>{currency}
                                    {numberWithCommas(parseFloat(tax).toFixed(2))}
                                </p>
                            </div>
                            <div style={{width: '100%', display: 'flex', margin: '5px 0'}}>
                                <p className={'total-booking'}>Payable Amount</p>
                                <p className={'total-booking-text'}>{currency}
                                    {numberWithCommas(parseFloat(totalPrice).toFixed(2))}
                                </p>
                            </div>
                            <div style={{width: '100%', display: 'flex', margin: '5px 0'}}>
                                <p className={'total-booking'}>Payment Method</p>
                                <p className={'total-booking-text'}>RazorPay</p>
                            </div>

                            <p style={{fontSize: 12, color: "#424B5A", marginBottom: 15}}>Credit Card/Debit
                                Card/NetBanking</p>

                            <div style={{display: 'flex'}}>
                                <img src={base_copy} style={{width: 40}}/>
                                <div className={'mx-2'}>
                                    <p style={{fontSize: 12, color: '#424B5A'}}>Pay by Razorpay</p>
                                    <p style={{fontSize: 10, color: '#424B5A'}}>Cards, NetBanking, Wallet & UPI </p>
                                </div>
                            </div>

                            <div className={'row my-2'}>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className={'proceed-booking-btn'}
                                         onClick={() => {
                                             displayRazorpay()
                                         }}
                                    >
                                        <div style={{margin: 'auto auto auto 0'}}>
                                            <p style={{fontSize: 12}}>{currency}
                                                {numberWithCommas(parseFloat(totalPrice).toFixed(2))}
                                            </p>
                                            <p className={'total-saving-text'}>You are
                                                saving {currency}{numberWithCommas(parseFloat(savingPrice).toFixed(2))}
                                            </p>
                                        </div>
                                        <p
                                            style={{fontSize: 12, margin: 'auto 0'}}>Place Order</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default CheckOutPage;
