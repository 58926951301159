import Geocode from "react-geocode";
import {BiCurrentLocation} from "react-icons/bi";
import React from "react";



const Locate = ({set_current_location}) => {
    return (
        <div className={'locate'}
             onClick={set_current_location}
        >
            <BiCurrentLocation style={{fontSize: 13, color: '#424b5a'}}/>
        </div>
    );
}

export default Locate;