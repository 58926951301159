import Footer from "../component/LandingPage/Footer";
import Offers from "../component/LandingPage/Offers/offers";
import React, {useContext, useEffect, useState} from "react";
import Config from "../Config";
import axios from "axios";
import {useSelector} from "react-redux";
import {Context} from "../Contexts/Context";
import {useHistory} from "react-router-dom";

const OffersPage = () => {
    const [offers, setOffers] = useState([])
    const history = useHistory()
    const data = useSelector(state => state.accounts.data)
    const location = useSelector(state => state.accounts.location)
    const isAuthenticated = useSelector(state => state.accounts.isAuthenticated)

    const {setCatOpenModal, setSelectCat, setSubCategory} = useContext(Context);

    const [filteredCategories, setFilteredCategories] = useState([])

    const openSubCatPage = (category_id, subcategoryID) => {
        const cat_name = filteredCategories.filter(item => item.category_id === category_id)[0]
        history.push(`/sub-category-detail/${cat_name.category_name}/${category_id}/${subcategoryID}/`)
    }

    useEffect(() => {
        const config = Config()
        axios.get(`https://cgapp.clykk.com/catalog/api/v2/catalog-manager/by-location/${location.city}`, config)
            .then(response => {
                setFilteredCategories(response.data ? response.data : [])
            })
            .catch(err => console.log('This one failed', err.message))
    }, [location])


    const handleCategoryClick = (city, category_id) => {
        setSelectCat(filteredCategories.filter(item => item.category_id === category_id)[0])
        const config = Config()
        axios.get(`https://cgapp.clykk.com/catalog/api/v2/catalog-manager/by-location/${city}/categories/${category_id}`, config)
            .then(response => {
                setSubCategory(response.data ? response.data : [])
            })
            .catch(err => console.log(err.message))
    }

    useEffect(() => {
        const config = Config()
        axios.get(`https://cgapp.clykk.com/discount/api/v2/discount-management/city/27?default=true`, config)
            .then((response) => {
                setOffers(response.data)
            })
    }, [location])

    const get_offers_slide = (slide, type) => {
        const types = type.split(',')
        let yes = false
        for (let i = 0; i < types.length; i++) {
            if (slide.discount === types[i]) {
                yes = true
                break
            }
        }
        if (!yes) {
            return false
        }
        if (!isAuthenticated) {
            // && Date.parse(slide.expireAt) >= Date.parse(get_date_format())
            return (slide.status === 'approved') && (slide.userID === 0)
        } else {
            return (slide.status === 'approved') && (slide.userID === 0 || slide.userID === data.userId)
        }
    }

    const small = true

    return (
        <>

            <Offers offers={offers} get_slide={get_offers_slide} small={small} offers_text={'Offers for You'}
                    type={'offer'} setCatOpenModal={setCatOpenModal}
                    openSubCatPage={openSubCatPage}
                    handleCategoryClick={handleCategoryClick} showFaq={true}/>

            <Offers offers={offers} get_slide={get_offers_slide} small={small} offers_text={'Coupons for You'}
                    type={'coupon'} setCatOpenModal={setCatOpenModal}
                    openSubCatPage={openSubCatPage}
                    handleCategoryClick={handleCategoryClick}/>

            <Offers offers={offers} get_slide={get_offers_slide} small={small} offers_text={'Deals for You'}
                    type={'deal'} setCatOpenModal={setCatOpenModal}
                    openSubCatPage={openSubCatPage}
                    handleCategoryClick={handleCategoryClick}/>

            <Footer/>
        </>
    );
}

export default OffersPage;