import {useHistory} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {load_user_cart} from "../../../actions/accounts";
import Config from "../../../Config";
import {AiFillQuestionCircle} from "react-icons/ai";
import {Context} from "../../../Contexts/Context";
import {numberWithCommas} from "../../Utils";

const PackageAddOnsTabs = ({
                               data,
                               setData,
                               add_update_cart,
                               handleOpenModal,
                               loadedPackage,
                               currency,
                               update = false
                           }) => {

    let isAuthenticated = useSelector(state => state.accounts.isAuthenticated)

    const {setFaqNum, handleOpenFaqModal} = useContext(Context);

    const increase_decrease_add_ons = (id, sign) => {
        let _addOns = []

        data.map((item) => {
            if (item.id === id) {
                if (sign === '+') {
                    item.itemCount += 1
                } else if (sign === '-') {
                    if (item.itemCount > 0) {
                        item.itemCount -= 1
                    }
                }
            }
            _addOns.push(item)
        })

        setData((prev) => ({...prev, addOns: _addOns}))
    }

    const showAddons = (item) => {
        if (item.enabled || update) {
            return (
                <div className={'package-single-addon-container'}>
                    <div className={'add-on-package-img'}>
                        <img src={item.addonImage} className={'package-add-img'}/>
                    </div>

                    <div className={'package-add-on-services-text-container'}>
                        <p className={'package-add-on-service'}>{item.addonName}</p>
                    </div>

                    <div className={'package-add-on-price-container'}>
                        <p className={'package-add-on-price-1'}>
                            <del>{currency}{numberWithCommas(parseFloat(item.basePrice).toFixed(2))}</del>
                        </p>
                        <p className={'package-add-on-price-2'}>{currency}{numberWithCommas(parseFloat(item.sellingPrice).toFixed(2))}</p>
                    </div>
                    <div
                        className={'package-add-on-quantity-container'}>
                        <div
                            onClick={() => increase_decrease_add_ons(item.id, '-')}
                            className={'quantity-controller-container'}>
                            <p className={'quantity-controller'}>-</p>
                        </div>
                        <p className={'quantity'}
                           style={{margin: '0 auto'}}>{item.itemCount}</p>
                        <div
                            onClick={() => increase_decrease_add_ons(item.id, '+')}
                            className={'quantity-controller-container'}>
                            <p className={'quantity-controller'}>+</p>
                        </div>
                    </div>


                </div>
            )
        }
    }

    return (
        <div className={'container'}>
            <div className={'row page-detail-container'}>
                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12">
                    <div style={{display: 'flex'}}>
                        <p className={'page-name'}>Package Add-On's</p>
                        <div className={'update-cart-btn-sm'}>
                            {update ?
                                <button
                                    onClick={add_update_cart}
                                    style={{
                                        backgroundColor: '#6CD7BB',
                                        color: 'white',
                                        fontSize: 12
                                    }}
                                    className={'btn'}>Update Package</button>
                                :
                                <button

                                    onClick={() => {
                                        if (!isAuthenticated) {
                                            handleOpenModal()
                                        } else {
                                            add_update_cart('cart')
                                        }
                                    }}
                                    style={{
                                        backgroundColor: '#6CD7BB',
                                        color: 'white',
                                        fontSize: 12
                                    }}
                                    className={'btn'}>Add to Cart</button>
                            }
                        </div>
                        <AiFillQuestionCircle
                            className={'question-faq-icon-package'}
                            onClick={() => {
                                setFaqNum(20)
                                handleOpenFaqModal()
                            }}
                            style={{color: '#ce5e97'}}/>
                    </div>

                    <div style={{margin: '15px 0'}}>
                        <p style={{margin: '5px 0 10px 0', fontSize: 12, fontWeight: 600}}>Choose Add-On's to
                            include</p>
                        <div className={'package-addon-container'}>
                            {data.map((item) => {
                                return (
                                    showAddons(item)
                                )
                            })}
                        </div>
                    </div>

                </div>

                {/*add on 2 decimal, update package is crashing*/}

                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12">
                    <div style={{width: '100%', display: 'flex'}}>
                        <div style={{marginLeft: 'auto', textAlign: 'right', width: '100%'}}>
                            <p className={'red-price body-price'} style={{fontSize: 15}}>
                                <del>{currency}{numberWithCommas(parseFloat(loadedPackage.basePrice).toFixed(2))}</del>
                            </p>
                            <p className={'black-price body-price'}
                               style={{fontSize: 15}}>{currency}{numberWithCommas(parseFloat(loadedPackage.sellingPrice).toFixed(2))}</p>
                            <div className={'update-cart-btn-bg'}>
                                {update ?
                                    <button
                                        onClick={add_update_cart}
                                        style={{
                                            backgroundColor: '#6CD7BB',
                                            color: 'white',
                                            fontSize: 14
                                        }}
                                        className={'btn'}>Update Package</button>
                                    :
                                    <button

                                        onClick={() => {
                                            if (!isAuthenticated) {
                                                handleOpenModal()
                                            } else {
                                                add_update_cart('cart')
                                            }
                                        }}
                                        style={{
                                            backgroundColor: '#6CD7BB',
                                            color: 'white',
                                            fontSize: 14
                                        }}
                                        className={'btn'}>Add to Cart</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PackageAddOnsTabs;