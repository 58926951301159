import React from "react";
import {Autocomplete} from "@material-ui/lab";
import {TextField} from "@material-ui/core";

const ContactUsHero = ({faqNum, setFaqNum}) => {

    const faqs = [
        {title: 'Location Choice', value: 8},
        {title: 'Save Location', value: 12},
        {title: 'Manage Locations', value: 13},
        {title: 'Home Screen', value: 11},
        {title: 'Sub Category', value: 14},
        {title: "Package List", value: 15},
        {title: 'Package View', value: 16},
        {title: 'Package Detail', value: 17},
        {title: 'Package Requirements', value: 18},

        {title: 'Package Checklist', value: 19},
        {title: 'Package Add-On', value: 20},
        {title: 'Terms of Use', value: 21},
        {title: 'Privacy Policy', value: 22},
        {title: 'Cart & To-Do', value: 23},
        {title: "Booking", value: 24},
        {title: 'Booking Confirmation', value: 26},
        {title: 'Customer Requirements', value: 27},
        {title: 'Account', value: 28},

        {title: 'Offers', value: 29},
        {title: 'Share & Earn', value: 30},
        {title: 'Service Rating', value: 31},
        {title: 'App Settings', value: 32},
        {title: 'Additional Services & Material', value: 33},
        {title: 'Service Track & OTP', value: 34},
        {title: 'Orders', value: 35},
        {title: "Order Execution", value: 36},
        {title: 'Alerts', value: 37},
        {title: 'Order Confirmation', value: 38},
        {title: 'Payment', value: 39},
    ]

    return (
        <div className={'container my-5'}>
            <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12" style={{display: 'flex'}}>
                    <div style={{margin: 'auto 0'}}>
                        <div>
                            <p className={'contact-us-hero-meta'}>How may we help you?</p>
                            <p style={{fontSize: 14, fontWeight: 500}}>
                                You can Contact Us directly or go to the provided FAQs to the search for your
                                answers.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12" style={{display: 'flex'}}>
                    <Autocomplete
                        options={faqs}
                        getOptionLabel={(option) => option.title}
                        style={{width: '100%'}}
                        onChange={(e, value) => {
                            setFaqNum(value.value)
                        }}
                        renderInput={(params) => <TextField {...params} label="Search for your needs"/>}
                    />
                </div>
            </div>
        </div>
    );
}

export default ContactUsHero;