import {useHistory} from "react-router-dom";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {AiFillQuestionCircle} from "react-icons/ai";
import {useDropzone} from "react-dropzone";
import Config from "../../../Config";
import {toast} from "react-hot-toast";
import axios from "axios";
import {Context} from "../../../Contexts/Context";
import {numberWithCommas} from "../../Utils";

const PackageRequirementsTabs = ({
                                     data,
                                     setData,
                                     add_update_cart,
                                     handleOpenModal,
                                     loadedPackage,
                                     currency,
                                     update = false,
                                     changedFields,
                                     setValue,
                                     onAnswerChange,
                                     customerAnswer,

                                     images,
                                     setImages,
                                     newImages, setNewImages
                                 }) => {
    const history = useHistory()
    let isAuthenticated = useSelector(state => state.accounts.isAuthenticated)


    useEffect(() => {
        if (data !== null && setImages) {
            data.requirements.map(question => {
                question.answers.map(answer => {
                    if (answer.answerType === 'upload') {
                        setImages(answer.customerAnswer !== null ? answer.customerAnswer : [])
                    }
                })
            })
        }
    }, [data])

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const config = Config()
            let form_data = new FormData();
            form_data.append('imageFile', file);
            axios.post('https://cgapp.clykk.com/s3/api/s3manager/order-execution/images', form_data, config)
                .then(response => {
                        setNewImages([...newImages, {answer: response.data.url}])
                    }
                )
        })
    }, [])

    const {getRootProps, getInputProps} = useDropzone({onDrop})

    const {setFaqNum, handleOpenFaqModal} = useContext(Context);

    return (
        <div className={'container'}>
            <div className={'row page-detail-container'}>
                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12">
                    <div style={{display: 'flex'}}>
                        <p className={'page-name'}>Package Requirements</p>
                        <div className={'update-cart-btn-sm'}>
                            {update ?
                                <button
                                    onClick={add_update_cart}
                                    style={{backgroundColor: '#6CD7BB', color: 'white', fontSize: 12}}
                                    className={'btn'}>Update Cart</button>
                                :
                                <button
                                    onClick={() => {
                                        if (!isAuthenticated) {
                                            handleOpenModal()
                                        } else {
                                            add_update_cart('cart')
                                        }
                                    }}
                                    style={{backgroundColor: '#6CD7BB', color: 'white', fontSize: 12}}
                                    className={'btn'}>Add to Cart</button>
                            }
                        </div>
                        <AiFillQuestionCircle
                            className={'question-faq-icon-package'}
                            onClick={() => {
                                setFaqNum(18)
                                handleOpenFaqModal()
                            }}
                            style={{color: '#ce5e97'}}/>
                    </div>


                    {update ?
                        data.requirements.map((item) => {
                            return (
                                <div className={'my-4'}>
                                    <p style={{fontSize: 12, fontWeight: 600, margin: '10px 0'}}>
                                        {item.questionName}
                                    </p>
                                    {item.answers.map((answer) => {
                                        return (
                                            answer.answerType === 'text' ?
                                                <>
                                                    <p style={{
                                                        fontSize: 12,
                                                        color: '#98999B',
                                                        marginBottom: 10
                                                    }}>{answer.answer}</p>
                                                    {update &&
                                                    <>
                                                        <input className={'form-control'} style={{fontSize: 12}}
                                                               value={answer.customerAnswer === null ? "" : answer.customerAnswer[0].answer}
                                                               onChange={(e) => {
                                                                   onAnswerChange(e, answer.id)
                                                               }}
                                                               placeholder={'Enter Your Answer'}/>
                                                        <div style={{display: 'flex'}}>
                                                            <button
                                                                onClick={() => {
                                                                    customerAnswer(answer.id)
                                                                }}
                                                                style={{
                                                                    backgroundColor: answer.id in changedFields ? 'orange' : '#6CD7BB',
                                                                    color: 'white',
                                                                    fontSize: 12,
                                                                    margin: "10px 0 0 auto"
                                                                }}
                                                                className={'btn'}>Update
                                                            </button>
                                                        </div>
                                                    </>
                                                    }
                                                </>
                                                :
                                                answer.answerType === 'upload' &&
                                                <>
                                                    <p style={{
                                                        fontSize: 12,
                                                        color: '#98999B',
                                                        marginBottom: 10
                                                    }}>{answer.answer}</p>
                                                    {update &&
                                                    <>
                                                        <div className="row" style={{margin: "30px 0"}}>
                                                            <div {...getRootProps()}
                                                                 style={{
                                                                     margin: "0 auto",
                                                                     padding: "0",
                                                                     backgroundColor: "white",
                                                                     height: 200,
                                                                     border: "1px solid #50515e",
                                                                     display: "flex"
                                                                 }}>
                                                                <input {...getInputProps()} />
                                                                <p style={{
                                                                    margin: "auto",
                                                                    fontSize: 17,
                                                                    fontWeight: 600,
                                                                    color: "#424B5A"
                                                                }}>Upload Images
                                                                    Image</p>
                                                            </div>
                                                        </div>
                                                        <div className={'selected-images-container'}>
                                                            {images.map((img, index) => {
                                                                return (
                                                                    <img className={'selected-image'}
                                                                         src={img.answer}/>
                                                                )
                                                            })}
                                                        </div>
                                                        <div style={{display: 'flex'}}>
                                                            <button
                                                                onClick={() => {
                                                                    customerAnswer(answer.id, true)
                                                                }}
                                                                style={{
                                                                    backgroundColor: answer.id in changedFields ? 'orange' : '#6CD7BB',
                                                                    color: 'white',
                                                                    fontSize: 12,
                                                                    margin: "10px 0 0 auto"
                                                                }}
                                                                className={'btn'}>Upload
                                                            </button>
                                                        </div>
                                                    </>
                                                    }
                                                </>
                                        )
                                    })}
                                </div>
                            )
                        })
                        :
                        data.requirements.map((item) => {
                            return (
                                <div className={'my-4'}>
                                    <p className={'page-sub-heading'}>{item.questionName}</p>
                                    <div className={'page-list'} style={{paddingLeft: 15}}>
                                        <ul>
                                            {item.answers.map((point) => {
                                                return (
                                                    <li className={'package-points'}>{point.answer}</li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>

                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12">
                    <div style={{width: '100%', display: 'flex'}}>
                        <div style={{marginLeft: 'auto', textAlign: 'right'}}>
                            <p className={'red-price body-price'} style={{fontSize: 15}}>
                                <del>{currency}{numberWithCommas(loadedPackage.basePrice.toFixed(2))}</del>
                            </p>
                            <p className={'black-price body-price'}
                               style={{fontSize: 15}}>{currency}{numberWithCommas(loadedPackage.sellingPrice.toFixed(2))}</p>
                            <div className={'update-cart-btn-bg'}>
                                {update ?
                                    <button
                                        onClick={add_update_cart}
                                        style={{
                                            backgroundColor: '#6CD7BB',
                                            color: 'white',
                                            fontSize: 14,
                                            marginTop: 15
                                        }}
                                        className={'btn'}>Update Cart</button>
                                    :
                                    <button
                                        onClick={() => {
                                            if (!isAuthenticated) {
                                                handleOpenModal()
                                            } else {
                                                add_update_cart('cart')
                                            }
                                        }}
                                        style={{
                                            backgroundColor: '#6CD7BB',
                                            color: 'white',
                                            fontSize: 14,
                                            marginTop: 15
                                        }}
                                        className={'btn'}>Add to Cart</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PackageRequirementsTabs;
