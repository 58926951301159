import {AiOutlineLeft} from "react-icons/ai";
import {IoIosCloseCircle} from "react-icons/io";
import {makeStyles, Tab, Tabs} from "@material-ui/core";
import LoginModal from "./LoginModal";
import RegisterModal from "./RegisterModal";
import {Pagination} from "swiper";
import ConsumerProcessOne from "./ConsumerProcessOne";
import clsx from "clsx";
import LocationChoice from "./LocationChoice";
import SetLocation from "./Locations/SetLocation";
import React, {useEffect, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {useDispatch, useSelector} from "react-redux";
import {
    save_user_address,
    update_user_address_locally,
    update_user_address_server,
    update_user_preferences
} from "../../actions/accounts";
import purple_glitter from '../../assets/images/glitter.png';
import green_glitter from '../../assets/images/greenstar.png';
import yellow_glitter from '../../assets/images/yellow star.png';

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    indicator: {
        backgroundColor: "#6CD7BB"
    }
}))


const RegisterBody = ({value, setValue, handleCloseModal}) => {
    const regModal = true

    const classes = useStyles()
    const [modalStyle] = useState(getModalStyle);
    const dispatch = useDispatch()

    const [my_swiper, set_my_swiper] = useState({});

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // preferences
    let _pref = useSelector(state => state.accounts.preferences)


    const [preferences, setPreferences] = useState([])
    const [showP1, setShowP1] = useState(false)
    const [firstRun, setFirstRun] = useState(true)

    useEffect(() => {
        if (firstRun && Object.keys(_pref).length !== 0) {
            let _showP1 = true
            let _perferences = {}
            Object.keys(_pref).map((key, index) => {
                if (_pref[key]['enabled']) {
                    console.log("key =>", key, _pref[key]['display_name'])
                    let selected = false
                    _pref[key]['options'].map((item) => {
                        console.log(item.selected)
                        if (item.selected) {
                            selected = true
                            _showP1 = false
                        }
                    })
                    if (!selected) {
                        _perferences = {
                            ..._perferences,
                            [key]: _pref[key]
                        }
                    }
                }
            })
            setPreferences(_perferences)
            setShowP1(_showP1)
            setFirstRun(false)
        }
    }, [_pref])

    const enable_item = (key, item) => {
        let options = preferences[key]['options']
        setPreferences({
            ...preferences,
            [key]: {
                ...preferences[key],
                options: options.map((option) => {
                    if (option === item)
                        option.selected = !option.selected
                    return option
                })
            }
        })
    }
    const save_preferences = () => {
        let _options = []
        let keys = []
        Object.keys(preferences).map((key, i) => {
            preferences[key].options.map((item) => {
                keys.push(key)
                _options.push(item)
            })
        })
        Object.keys(_pref).map((key, i) => {
            if (!keys.includes(key)) {
                _pref[key].options.map((item) => {
                    _options.push(item)
                })
            }
        })

        dispatch(update_user_preferences({options: _options}))
    }


    // addresses
    const [defaultScreen, setDefaultScreen] = useState(0)
    const [updateId, setUpdateId] = useState(null)
    const location = useSelector(state => state.accounts.location)
    const [homeAddress, setHomeAddress] = useState({
        addressType: "",
        home_num: "",
        display_title: '',
        landmark: '',
        title: 'Home',
        address: null
    })

    const [serviceAddress, setServiceAddress] = useState(location)
    const center = {
        lat: serviceAddress.latitude,
        lng: serviceAddress.longitude,
    };
    useEffect(() => {
        setServiceAddress(location)
    }, [location])
    useEffect(() => {
        if (updateId === null) {
            setHomeAddress({
                addressType: "",
                home_num: "",
                display_title: '',
                landmark: '',
                title: 'Home',
                address: ''
            })
        }
    }, [updateId])
    const save_address = () => {
        let city = serviceAddress.city
        if (city.includes('Bangalore')) {
            city = 'Bengaluru'
        }
        const body = {
            "addressType": homeAddress.addressType,
            "line1": homeAddress.home_num,
            "line2": homeAddress.address,
            "line3": "",
            "pincode": serviceAddress.postal_code,
            // "city": 'Bengaluru',
            "city": city,
            "state": serviceAddress.state,
            "country": serviceAddress.country,
            "latitude": serviceAddress.latitude,
            "longitude": serviceAddress.longitude,
            "landmark": homeAddress.landmark,
            "locationText": homeAddress.title,
            "defaultServiceAddress": false,
            "defaultBillingAddress": false
        }

        if (!updateId) {
            dispatch(save_user_address(body))
            setUpdateId(null)
            handleCloseModal()
        } else {
            dispatch(update_user_address_server(body, updateId))
            setUpdateId(null)
            handleCloseModal()
        }
        if (!regModal) {
            setDefaultScreen(1)
        }
        // setHomeAddress({
        //     addressType: "",
        //     home_num: "",
        //     display_title: '',
        //     landmark: '',
        //     title: 'Home',
        //     address: null
        // })
    }
    const set_edit_data = (location) => {
        setUpdateId(location.id)
        setHomeAddress({
            addressType: location.addressType,
            home_num: location.line1,
            display_title: location.locationText,
            landmark: location.landmark,
            title: location.locationText,
            address: location.line2
        })
        console.log("location ===>", location)
        setDefaultScreen(0)
    }
    const setAddress = (address) => {
        dispatch(update_user_address_locally(address))
        setUpdateId(null)
        handleCloseModal()
        // history.push('/')
    }

    return (
        <div style={modalStyle} className={(value === 2 ? "swiper-modal-paper" : 'register-modal-paper')}>
            <div style={{width: '100%', height: '100%', position: 'relative'}}>
                <>
                    <img src={purple_glitter}
                         style={{position: 'absolute', zIndex: -10, width: 15, top: '19%', left: '12%'}}/>
                    <img src={purple_glitter}
                         style={{position: 'absolute', zIndex: -10, width: 10, top: '33%', left: '82%'}}/>

                    <img src={yellow_glitter}
                         style={{position: 'absolute', zIndex: -10, width: 15, top: '10%', left: '90%'}}/>
                    <img src={yellow_glitter}
                         style={{position: 'absolute', zIndex: -10, width: 8, top: '8%', left: '86%'}}/>

                    <img src={green_glitter}
                         style={{position: 'absolute', zIndex: -10, width: 17, top: '60%', left: '12%'}}/>

                    <img src={purple_glitter}
                         style={{position: 'absolute', zIndex: -10, width: 8, top: '88%', left: '7%'}}/>
                    <img src={green_glitter}
                         style={{position: 'absolute', zIndex: -10, width: 15, top: '95%', left: '90%'}}/>
                </>
                <div style={{width: '100%', display: 'flex'}}>

                    <IoIosCloseCircle style={{marginLeft: 'auto', fontSize: 15, cursor: 'pointer'}}
                                      onClick={handleCloseModal}
                    />
                </div>
                {value < 2 &&
                    <div style={{width: '100%', display: 'flex', padding: '0 15px 15px 15px'}}>
                        <div style={{margin: "0 auto"}}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                classes={{
                                    indicator: classes.indicator
                                }}
                                centered
                            >
                                <Tab label="Login" {...a11yProps(0)} />
                                <Tab label="Register"
                                     style={{}} {...a11yProps(1)} />
                            </Tabs>
                        </div>
                    </div>
                }
                {value === 0 &&
                    <LoginModal value={value} setValue={setValue} isLogin={true} handleCloseModal={handleCloseModal}/>
                }
                {value === 1 &&
                    <RegisterModal value={value} setValue={setValue} isLogin={false} handleCloseModal={handleCloseModal}/>
                }
                {value === 2 &&
                    <>
                        <Swiper
                            pagination={{
                                clickable: false,
                            }}
                            onClick={() => {
                            }}
                            modules={[Pagination]}
                            allowTouchMove={false}
                            slidesPerView={1}
                            onInit={(ev) => {
                                set_my_swiper(ev)
                            }}>

                            {showP1 &&
                                <SwiperSlide style={{width: '100%', height: '100%'}}>
                                    <div style={{width: '100%', height: '100%'}}>
                                        <ConsumerProcessOne my_swiper={my_swiper} value={value} setValue={setValue}/>
                                    </div>
                                </SwiperSlide>
                            }
                            {/*<Preferences my_swiper={my_swiper}/>*/}

                            {Object.keys(preferences).map((key, index) => {
                                return (
                                    <SwiperSlide style={{width: '100%', height: '100%'}}>
                                        <div style={{width: '100%', height: '100%'}}>
                                            <div className={'preferences-inner-container'}>
                                                <div className={'modal-top-nav'}
                                                     onClick={() => {
                                                         my_swiper.slidePrev()
                                                     }}
                                                >
                                                    {index !== 0 &&
                                                        <AiOutlineLeft
                                                            style={{
                                                                margin: 'auto 15px auto 0',
                                                                fontSize: 14,
                                                                fontWeight: 700, color: '#424B5A'
                                                            }}/>
                                                    }
                                                    <p style={{
                                                        margin: 'auto auto auto 0',
                                                        fontSize: 14,
                                                        fontWeight: 700, color: '#424B5A'
                                                    }}>Select
                                                        Preferences</p>
                                                </div>
                                                <hr className={'hr-line'}/>
                                                <p className={'consumer-process-modal-text'}
                                                >{preferences[key]['display_name']}</p>
                                                <p className={'preference-meta-text'}
                                                >{preferences[key]['display_text']}</p>
                                                <div
                                                    className="row consumer-process-modal-options">
                                                    {preferences[key]['options'].map((item) => {
                                                        return (
                                                            <div
                                                                className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 my-2 preference-option-container">
                                                                <div
                                                                    onClick={() => enable_item(key, item)}
                                                                    className={clsx('shadow-sm options-container', item.selected && 'active-option')}>
                                                                    <p className={'options-text'}>{item.display_text}</p>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                <button onClick={() => {
                                                    if (index === Object.keys(preferences).length - 1) {
                                                        save_preferences()
                                                    }
                                                    my_swiper.slideNext()
                                                }}
                                                        className={'p-2 btn form-control consumer-process-btn'}>Next
                                                </button>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}

                            <SwiperSlide style={{width: '100%', height: '100%'}}>
                                <div style={{width: '100%', height: '100%'}}>
                                    <LocationChoice my_swiper={my_swiper} setDefaultScreen={setDefaultScreen}
                                                    handleCloseModal={handleCloseModal}/>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide style={{width: '100%', height: '100%'}}>
                                <div style={{width: '100%', height: '100%'}}>
                                    <SetLocation my_swiper={my_swiper} handleCloseModal={handleCloseModal}
                                                 defaultScreen={defaultScreen} setDefaultScreen={setDefaultScreen}
                                                 updateId={updateId}
                                                 setUpdateId={setUpdateId}
                                                 location={location}
                                                 homeAddress={homeAddress}
                                                 setHomeAddress={setHomeAddress}

                                                 serviceAddress={serviceAddress}
                                                 setServiceAddress={setServiceAddress}
                                                 center={center}
                                                 save_address={save_address}

                                                 set_edit_data={set_edit_data}
                                                 setAddress={setAddress}

                                                 regModal={regModal}
                                    />
                                </div>
                            </SwiperSlide>

                        </Swiper>
                    </>
                }
            </div>
        </div>
    );
}

export default RegisterBody;