import ApplyStore from '../assets/images/apply-stone-btn.png'
import GoogleStore from '../assets/images/google-play-btn.png'
import StoreBtn from "./Hero/StoreBtn";
import {AiOutlineSearch} from "react-icons/ai";
import pin from '../assets/images/map-location pin.png'
import {BiPencil} from "react-icons/bi";
import {useState, useEffect, useContext} from "react";
import {useDispatch, useSelector} from "react-redux";
import {update_user_address_locally} from "../actions/accounts";
import {Context} from "../Contexts/Context";
import axios from "axios";
import Config from "../Config";

const get_address = (address) => {
    if (address.substring().length < 80) {
        return address
    } else {
        return address.substring(0, 80) + '...'
    }
}

const Hero = ({handleOpenModal, handleLocationModalOpen, heroData, customer}) => {

    const isAuthenticated = useSelector(state => state.accounts.isAuthenticated)

    let location = useSelector(state => state.accounts.location)

    const userType = useSelector(state => state.accounts.userType)

    const access_token = useSelector(state => state.accounts.access_token)

    const savedLocations = useSelector(state => state.accounts.address)

    const [saved_locations, setSavedLocations] = useState(savedLocations ? savedLocations : [])

    useEffect(() => {
        setSavedLocations(savedLocations ? savedLocations : [])
    }, [savedLocations])

    const dispatch = useDispatch()

    const update_location = (e) => {
        const selected_location = saved_locations.filter(item => item.id == e.target.value)
        if (selected_location) {
            dispatch(update_user_address_locally(selected_location[0]))
        }
    }

    const [cities, setCities] = useState([])

    useEffect(() => {
        const config = Config()
        axios.get(`https://cgapp.clykk.com/location/api/v2/country/city`, config)
            .then(response => {
                setCities(response.data.filter(city => city.enabled))
            }).catch(e => console.log(e.message))
    }, [access_token])


    const update_not_logout_location = (e) => {
        const location = {
            "id": 1,
            "addressType": "",
            "line1": "Bengaluru",
            "line2": "Bengaluru",
            "line3": "Bengaluru",
            "pincode": "560076",
            "city": `${e.target.value}`,
            "state": "Karnataka",
            "country": "India",
            "latitude": 12.9716,
            "longitude": 77.5946,
            "landmark": "Bengaluru",
            "locationText": "Bengaluru",
            "defaultServiceAddress": false,
            "defaultBillingAddress": false
        }

        dispatch(update_user_address_locally(location))
    }

    const service_provider_btn = (img, text, store, url) => {
        return (
            <a className={'service-store-btn'} target={"_blank"} href={url}>
                <img src={img} alt={'img'} className={'service-store-img'}/>
                <div style={{color: 'white'}}>
                    <p className={'service-store-sm-text'}>{text}</p>
                    <p className={'service-store-lg-text'}>{store}</p>
                </div>
            </a>
        )
    }

    const {
        categories, setFilterCategories
    } = useContext(Context);

    const handleSearch = (e) => {
        const searched = e.target.value
        if (searched === '') {
            setFilterCategories(categories)
        } else {
            setFilterCategories(categories.filter(item => item.category_name.toLowerCase().includes(e.target.value.toLowerCase())))
        }
    }

    return (
        <div className={'hero'}>
            <div className="row hero-container"
            >
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                     style={{display: 'flex', flexDirection: "column"}}>
                    <div className={'hero-information-container'}>
                        {heroData &&
                            <div>
                                <p className={'hero-text'}>{heroData.imageText}</p>
                                <p className={'hero-meta'}>{heroData.imageHelp}</p>
                            </div>
                        }
                        <div className={'store-btn-container'}>
                            <div style={{marginRight: 20}}>
                                <StoreBtn img={ApplyStore} text={userType} store={'App Store'}
                                          url={userType === 'Customer' ? 'https://apps.apple.com/us/app/clykk/id1542592627?platform=iphone' : 'https://apps.apple.com/us/app/clykk-service-provider/id1550083789'}/>
                            </div>
                            <div>
                                <StoreBtn img={GoogleStore} text={userType} store={'Google Play'}
                                          url={userType === 'Customer' ? 'https://play.google.com/store/apps/details?id=com.clykk' : 'https://play.google.com/store/apps/details?id=com.clykksp'}/>
                            </div>
                        </div>
                        <div className={'join-us-btns-container'}>
                            <div style={{marginRight: 10}}>
                                {/*<StoreBtn img={ApplyStore} text={userType} store={'App Store'}/>*/}
                                {service_provider_btn(ApplyStore, userType, 'App Store', userType === 'Customer' ? 'https://apps.apple.com/us/app/clykk/id1542592627?platform=iphone' : 'https://apps.apple.com/us/app/clykk-service-provider/id1550083789')}
                            </div>
                            <div>
                                {/*<StoreBtn img={GoogleStore} text={userType} store={'Google Play'}/>*/}
                                {service_provider_btn(GoogleStore, userType, 'Google Play', userType === 'Customer' ? 'https://play.google.com/store/apps/details?id=com.clykk' : 'https://play.google.com/store/apps/details?id=com.clykksp')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'search-address-container'}
                 style={{margin: "0 auto"}}>
                {!isAuthenticated ?
                    <div className={'location-container'}>
                        <img src={pin} className={'hero-pin'}/>
                        <BiPencil className={'edit-pencil'}
                                  onClick={handleOpenModal}/>
                        <select onChange={update_not_logout_location}
                                value={location.city}
                                className="form-control form-select select-cities">
                            {cities.map((city) => {
                                return (
                                    <option value={city.name}>{city.name}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                    :
                    <>
                        <div className={'location-container'}>
                            <img src={pin} className={'hero-pin'}/>
                            <BiPencil className={'edit-pencil'}
                                      onClick={handleLocationModalOpen}/>
                            <select className="form-control form-select select-cities"
                                    onChange={update_location}
                            >
                                {saved_locations.map((item) => {
                                    return (
                                        <option
                                            style={{fontSize: 10}}
                                            selected={location.id == item.id}
                                            value={item.id}>
                                            {get_address(item.line2)}
                                        </option>)
                                })}
                            </select>
                        </div>
                    </>
                }
                {customer &&
                    <div className={'search-container shadow-sm'}>
                        <AiOutlineSearch className={'hero-search-icon'}/>
                        <input onChange={handleSearch} className={'hero-search-bar'}
                               placeholder={'Search for your needs ...'}/>
                    </div>
                }

            </div>
        </div>
    );
}

export default Hero;