import React from 'react';
import {useHistory} from "react-router-dom";
import {AiFillQuestionCircle} from "react-icons/ai";
import {
    EmailIcon,
    EmailShareButton, FacebookIcon, FacebookShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";
import gmail from "../../assets/images/Gmail_Logo_512px.png";

function AccountsSocialsBanner() {
    const history = useHistory();

    const title = "Check out these amazing offers, deals, and coupons at CLYKK\n\n" +
        "Download CLYKK From Play Store\n" +
        "CLYKK Customer\n"+
        "https://play.google.com/store/apps/details?id=com.clykk" + "\n" +
        "CLYKK Service Provider\n"+
        "https://play.google.com/store/apps/details?id=com.clykksp" + "\n\n" +
        "Download CLYKK From Apple Store\n" +
        "CLYKK Customer\n"+
        "https://apps.apple.com/us/app/clykk/id1542592627" + "\n" +
        "CLYKK Service Provider\n"+
        "https://apps.apple.com/us/app/clykk-service-provider/id1550083789" + "\n" +
        "Visit us on \n"

    const email = "https://mail.google.com/mail/?view=cm&fs=1&tf=1&&su=Checkout%20this%20amazing%20deal&body=Checkout this amazing deal%0A%0ADownload CLYKK From Play Store%0ACLYKK Customer%0Ahttps://play.google.com/store/apps/details?id=com.clykk%0ACLYKK Service Provider%0Ahttps://play.google.com/store/apps/details?id=com.clykksp%0A%0ADownload CLYKK From Apple Store%0ACLYKK Customer%0Ahttps://apps.apple.com/us/app/clykk/id1542592627%0ACLYKK Service Provider%0Ahttps://apps.apple.com/us/app/clykk-service-provider/id1550083789%0A%0AVisit us on%0Ahttps://clykk.com/"

    return (
        <div>
            <div className="container p-3">
                <div className="row socials-row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className={'socials-detail-container'}
                             style={{display: 'flex', width: "100%", height: "100%"}}>
                            <div style={{margin: 'auto', textAlign: "center"}}>
                                <div style={{display: 'flex', width: '100%'}}>
                                    <div style={{margin: "0 auto", display: 'flex'}}>
                                        <p style={{fontSize: 30, fontWeight: 700}}>Share & Earn</p>
                                        <AiFillQuestionCircle
                                            onClick={() => {
                                                history.push('/contact-us/30')
                                            }}
                                            className={'share-earn-question-icon'}/>
                                    </div>
                                </div>

                                <p style={{fontSize: 15, lineHeight: 2}}>
                                    CLYKK Lifestyle Home Services, Offering that is Customized by You.
                                </p>
                                <div className={'my-2'}>
                                    <p style={{fontSize: 14}}>Share with your Friends and Family</p>
                                    <div className={'social-icons my-2'}>
                                        <div className={'social-icons-container'}>
                                            <div className={'share-btn-icons'}>
                                                <WhatsappShareButton
                                                    url={`https://clykk.com/`}
                                                    title={title}
                                                    windowWidth={800}
                                                    windowHeight={550}
                                                    separator=""
                                                    className="Demo__some-network__share-button"
                                                >
                                                    <WhatsappIcon size={35} round/>
                                                </WhatsappShareButton>
                                            </div>
                                            <div className={'share-btn-icons'}>
                                                <EmailShareButton
                                                    url={`https://clykk.com/`}
                                                    subject={'This is an amazing offer !!'}
                                                    body={title}
                                                    className="Demo__some-network__share-button"
                                                >
                                                    <EmailIcon size={35} round/>
                                                </EmailShareButton>
                                            </div>
                                            <div className={'share-btn-icons'}>
                                                <TwitterShareButton
                                                    url={`https://clykk.com/`}
                                                    title={title}
                                                    className="Demo__some-network__share-button"
                                                >
                                                    <TwitterIcon size={35} round/>
                                                </TwitterShareButton>
                                            </div>
                                            <div className={'share-btn-icons'}>
                                                <FacebookShareButton
                                                    url={`https://clykk.com/`}
                                                    quote={title}
                                                    className="Demo__some-network__share-button"
                                                >
                                                    <FacebookIcon size={35} round/>
                                                </FacebookShareButton>
                                            </div>
                                            <div style={{
                                                backgroundColor: '#CBEDE3',
                                                borderRadius: '50%',
                                                display: 'flex',
                                                padding: 5,
                                                width: 35
                                            }}>
                                                <a href={email} style={{
                                                    padding: 0,
                                                    textDecoration: "none",
                                                    margin: "auto"
                                                }}
                                                   target={'_blank'}>
                                                    <img src={gmail} style={{width: 22, margin: 'auto 0'}}/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AccountsSocialsBanner;