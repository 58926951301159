import Footer from "../component/LandingPage/Footer";
import Offers from "../component/LandingPage/Offers/offers";
import TopRateServices from "../component/SubCatDetail/TopRateServices";
import {useLocation} from "react-router-dom";
import {AiOutlineSearch} from "react-icons/ai";
import React, {useContext, useEffect, useState} from "react";
import Config from "../Config";
import axios from "axios";
import {GoSettings} from "react-icons/go";
import yellow_glitter from "../assets/images/yellow star.png";
import pink_glitter from "../assets/images/star.svg";
import purple_glitter from "../assets/images/glitter.png";
import green_glitter from "../assets/images/greenstar.png";
import {useSelector} from "react-redux";
import {Context} from "../Contexts/Context";
import {return_city_id} from "../component/Utils";

const SubCategoryDetail = () => {
    const location = useLocation()
    const isAuthenticated = useSelector(state => state.accounts.isAuthenticated)
    const data = useSelector(state => state.accounts.data)
    const {setCatOpenModal, handleCategoryClick} = useContext(Context);

    const user_location = useSelector(state => state.accounts.location)

    const [filter, setFilter] = useState({
        home_type: '',
        search: ''
    })
    const reset_filter = () => {
        setFilter({
            home_type: '',
            search: ''
        })
    }
    const [services, setServices] = useState([])
    const [filterServices, setFilterServices] = useState([])
    const [serviceID, setServiceID] = useState(null)

    const catName = location.pathname.split('/')[2]
    const catID = location.pathname.split('/')[3]
    const subCatID = location.pathname.split('/')[4]

    const [homeTypes, setHomeTypes] = useState([])

    const change_home_type = (e) => {
        setFilter({...filter, home_type: e.target.value})
    }

    const change_search = (e) => {
        setFilter({...filter, search: e.target.value})
    }


    useEffect(() => {
        let filtered = services
        if (filter.search !== '') {
            filtered = filtered.filter(item => item.packageName.toLowerCase().includes(filter.search.toLowerCase()))
        }
        if (filter.home_type !== '') {
            filtered = filtered.filter(item => item.packageName.toLowerCase().includes(filter.home_type.toLowerCase()))
        }
        setFilterServices(filtered)
    }, [filter])

    useEffect(() => {
        const config = Config()
        const cityId = return_city_id(user_location.city)

        axios.get(`https://cgapp.clykk.com/catalog/api/v2/catalog-manager/sub-categories/${subCatID}/package?city_id=${cityId}`, config)
            .then((response) => {
                setServices(response.data ? response.data : [])
                if (response.data.length > 0) {
                    setServiceID(response.data[0].subCategoryServiceRuleID)
                }
                setFilterServices(response.data ? response.data : [])
                let apts = []
                response.data.map((item) => {
                    if (!apts.includes(item.tags)) {
                        apts.push(item.tags)
                    }
                })

                setHomeTypes(apts)
            })
    }, [])

    const [offers, setOffers] = useState([])


    useEffect(() => {
        const config = Config()
        axios.get(`https://cgapp.clykk.com/discount/api/v2/discount-management/city/27/category/${catID}?default=true`, config)
            .then((response) => {
                setOffers(response.data ? response.data : [])
            })
    }, [])

    const small = true
    const get_date_format = () => {
        const date = new Date()
        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()
        if (`${month}`.length > 2) {
            return `${year}-${month}-${day}`
        } else {
            return `${year}-0${month}-${day}`
        }
    }
    const get_offers_slide = (slide, type) => {
        if (!isAuthenticated) {
            return (slide.discount === type && slide.status === 'approved' && Date.parse(slide.expireAt) >= Date.parse(get_date_format())) && (slide.userID === 0)
        } else {
            return (slide.discount === type && slide.status === 'approved' && Date.parse(slide.expireAt) >= Date.parse(get_date_format())) && (slide.userID === 0 || slide.userID === data.userId)
        }
    }

    return (
        <div style={{position: 'relative'}}>
            <>
                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '5.5%', left: '90%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '8%', left: '10%'}}/>
                <img src={yellow_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 17, top: '15%', left: '60%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '25%', left: '90%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 10, top: '30%', left: '5%'}}/>

                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 10, top: '40%', left: '55%'}}/>
                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 10, top: '50%', left: '70%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: -10, width: 15, top: '60%', left: '82%'}}/>
                <img src={pink_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '70%', left: '12%'}}/>

                <img src={green_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '85%', left: '87%'}}/>
                <img src={purple_glitter}
                     style={{position: 'absolute', zIndex: 10, width: 10, top: '97%', left: '10%'}}/>
            </>
            <div className="container sub-category-filter-container">
                <div className={'sub-category-filter-block-lg'}>
                    <p className={'sub-cat-heading'}>{catName}</p>
                    <div className={'category-search-container'}>
                        <AiOutlineSearch className={'hero-search-icon'}/>
                        <input value={filter.search} onChange={change_search} className={'hero-search-bar'}
                               placeholder={'Search for your needs ...'}/>
                    </div>

                    <div className={'filter-drop-down-container'}>
                        <div style={{
                            margin: '0 0 auto 0',
                            transform: 'rotate(90deg)',
                            border: '1px solid #5a6167',
                            padding: 3,
                            borderRadius: 3
                        }}>
                            <GoSettings
                                onClick={reset_filter}
                                style={{color: '#5a6167', fontSize: 20}}/>
                        </div>
                        {serviceID === 1 &&
                            <div className={'service-container'}>
                                <select className="form-select service-select service-at-home"
                                        aria-label="Default select example">
                                    <option selected>Service at Home</option>
                                </select>
                            </div>
                        }
                        {serviceID === 2 &&
                            <div className={'service-container'}>
                                <select className="form-select service-select service-at-home"
                                        aria-label="Default select example">
                                    <option selected>Service at Provider</option>
                                </select>
                            </div>
                        }
                        {serviceID === 3 &&
                            <div className={'service-container'}>
                                <select className="form-select service-select service-at-home"
                                        aria-label="Default select example">
                                    <option selected>Online Service</option>
                                </select>
                            </div>
                        }
                        {serviceID === 4 &&
                            <div className={'service-container'}>
                                <select className="form-select service-select service-at-home"
                                        aria-label="Default select example">
                                    <option selected>Customer Location</option>
                                </select>
                            </div>
                        }

                        <div className={'service-container-right'}>
                            <select value={filter.home_type} className="form-select service-select home-type"
                                    onChange={change_home_type}
                                    aria-label="Default select example">
                                <option value={''}>All</option>
                                {homeTypes.map((item) => {
                                    return (
                                        <option value={item}>{item}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                </div>

            </div>

            <Offers offers={offers} get_slide={get_offers_slide} small={small} offers_text={'Offers for You'}
                    type={'offer'} setCatOpenModal={setCatOpenModal} handleCategoryClick={handleCategoryClick}
                    allowHandleClick={false}/>

            <TopRateServices services={filterServices} subCatID={subCatID}/>
            <Footer/>
        </div>
    );
}

export default SubCategoryDetail;