import {useContext, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {GoSettings} from "react-icons/go";
import Footer from "../component/LandingPage/Footer";
import {useState} from "react";
import {AiFillQuestionCircle} from "react-icons/ai";
import {Context} from "../Contexts/Context";
import axios from "axios";
import Config from "../Config";
import clsx from "clsx";
import {BsChevronRight, BsThreeDotsVertical} from "react-icons/bs";
import {load_user_alert} from "../actions/accounts";
import {Modal} from "@material-ui/core";
import ReminderModal from "../component/Alert/ReminderModal";
import {atcb_action} from 'add-to-calendar-button';


const Alert = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    const {setFaqNum, handleOpenFaqModal} = useContext(Context);
    let isAuthenticated = useSelector(state => state.accounts.isAuthenticated)
    let user = useSelector(state => state.accounts.data)

    const [filterAlertType, setFilterAlertType] = useState('all')
    const [filterAlertTime, setFilterAlertTime] = useState('all')
    const [searchFilter, setSearchFilter] = useState('')

    const [alerts, setAlerts] = useState([])
    const [filterAlert, setFilterAlert] = useState([])

    const reset = () => {
        setFilterAlertType('all')
        setFilterAlertTime('all')
        setSearchFilter('')
    }
    //1796
    const alertReadToggle = (alert, status) => {
        const config = Config()
        let formData = new FormData();

        formData.append('user_id', user.userId);
        formData.append('id', alert.id)
        formData.append('status', status)


        axios.post('https://clapp.clykk.com/customer_alerts/public/index.php/api/updatealert', formData, config)
            .then(response => {
                const filtered_alerts = filterAlert.map(item => {
                    if (response.data.data.id === item.id) {
                        return {...response.data.data, status: parseInt(response.data.data.status)}
                    } else {
                        return item
                    }
                })
                setFilterAlert(filtered_alerts)

                const _alerts = alerts.map(item => {
                    if (response.data.data.id === item.id) {
                        return {...response.data.data, status: parseInt(response.data.data.status)}
                    } else {
                        return item
                    }
                })
                setAlerts(_alerts)

                dispatch(load_user_alert(user.userId))
            })
    }

    const onAlertClick = (alert, status, redirect = true) => {
        alertReadToggle(alert, status)
        if (redirect) {
            if (alert.alert_type === 'Cart' || alert.alert_type === 'ToDo') {
                history.push(`/booking/${alert.refrence_id}/`)
            } else if (alert.alert_type === 'Order') {
                history.push(`/service-execution/${alert.refrence_id}/`)
            }
        }
    }

    const deleteAlert = (alert) => {
        const config = Config()

        const formData = new FormData();

        formData.append('user_id', user.userId);
        formData.append('alert_id', alert.id)

        axios.post('https://clapp.clykk.com/customer_alerts/public/index.php/api/deletealert', formData, config)
            .then((response) => {

                const filtered_alerts = filterAlert.filter(item => item.id !== alert.id)
                setFilterAlert(filtered_alerts)

                const _alerts = alerts.filter(item => item.id !== alert.id)
                setAlerts(_alerts)

                dispatch(load_user_alert(user.userId))
            })
    }

    useEffect(() => {
        let filter_alerts = []

        if (filterAlertTime === 'all') {
            filter_alerts = alerts
        } else {
            if (filterAlertTime === '12') {
                const past_time = new Date().setFullYear(new Date().getFullYear() - parseInt(filterAlertTime))
                filter_alerts = alerts.filter(alert => new Date(alert.created_at) > past_time)
            } else {
                const past_time = new Date().setMonth(new Date().getMonth() - parseInt(filterAlertTime))
                filter_alerts = alerts.filter(alert => new Date(alert.created_at) > past_time)
            }
        }

        if (searchFilter !== '') {
            filter_alerts = alerts.filter(alert => alert.title.toLowerCase().includes(searchFilter.toLowerCase()) || alert.refrence_id.toString().toLowerCase().includes(searchFilter.toLowerCase()))
        }

        // filter by search
        setFilterAlert(filter_alerts)

    }, [searchFilter, filterAlertTime])

    useEffect(() => {
        const config = Config()
        axios.get(`https://clapp.clykk.com/customer_alerts/public/index.php/api/getalerts?user_id=${user.userId}`, config)
            .then(response => {
                setAlerts(response.data.data)
                setFilterAlert(response.data.data)
            })
    }, [])

    if (!isAuthenticated) {
        history.push('')
    }

    const [reminderModal, setReminderModal] = useState(false)
    const handleOpenReminderModal = () => {
        setReminderModal(true)
    }
    const handleCloseReminderModal = () => {
        setReminderModal(false)
    }

    return (
        <>
            <div className={'container alert-page-container'}>
                <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 col-12">
                        <>
                            <div style={{display: 'flex'}}>
                                <div onClick={reset} className={'reset-btn-lg-container'} style={{
                                    transform: 'rotate(90deg)',
                                    border: '1px solid #5a6167',
                                    padding: 3,
                                    width: 25,
                                    height: 30,
                                    borderRadius: 3,
                                    margin: "auto 10px auto 0"
                                }}>
                                    <GoSettings style={{color: '#5a6167'}}/>
                                </div>

                                <p style={{fontSize: 17, fontWeight: 600, margin: 'auto 0'}}>Filter Alerts</p>

                                <AiFillQuestionCircle
                                    className={'alert-question-icon'}
                                    onClick={() => {
                                        setFaqNum(37)
                                        handleOpenFaqModal()
                                    }}/>
                            </div>

                            <input
                                value={searchFilter}
                                onChange={(e) => setSearchFilter(e.target.value)}
                                className={'form-control alert-search'}
                                placeholder={'Search for your needs'}
                            />

                            <div className={'row'}>
                                <div className="col-sm-2 col-xs-2 col-2 reset-btn-sm-container">
                                    <div style={{marginBottom: 26}}></div>
                                    <div onClick={reset} style={{
                                        transform: 'rotate(90deg)',
                                        border: '1px solid #5a6167',
                                        padding: 3,
                                        width: 25,
                                        height: 30,
                                        borderRadius: 3,
                                        margin: "0 0 0 auto"
                                    }}>
                                        <GoSettings style={{color: '#5a6167'}}/>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-5 col-xs-5 col-5">
                                    <div className={'alert-options-container'}>
                                        <p className={'filter-alert-text'}>Alert Type</p>
                                        <select className="form-select alert-select service-at-home"
                                                value={filterAlertType}
                                                onChange={(event) => {
                                                    setFilterAlertType(event.target.value)
                                                }}>
                                            aria-label="Default select example">
                                            <option value={'all'}>All</option>
                                            <option value={'Cart'}>Cart</option>
                                            <option value={'ToDo'}>To-Do</option>
                                            <option value={'Order'}>Orders</option>

                                            <option value={'Offer'}>Offers</option>
                                            <option value={'Deal'}>Deals</option>
                                            <option value={'Coupons'}>Coupons</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-5 col-xs-5 col-5">
                                    <div className={'alert-options-container'}>
                                        <p className={'filter-alert-text'}>Alert Time</p>
                                        <select className="form-select alert-select home-type"
                                                value={filterAlertTime}
                                                onChange={(event) => {
                                                    setFilterAlertTime(event.target.value)
                                                }}>
                                            <option value={'all'}>All</option>
                                            <option value={'1'}>1 Month</option>
                                            <option value={'2'}>2 Months</option>
                                            <option value={'3'}>3 Months</option>
                                            <option value={'6'}>6 Months</option>
                                            <option value={'12'}>1 Year</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </>
                    </div>

                    <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12 col-12">
                        {filterAlert.map((alert) => {
                            return ((alert.alert_type === filterAlertType || filterAlertType === 'all') && <div
                                className={clsx("alert-container", alert.status !== 2 ? 'alert-container-unread' : 'alert-container-read')}
                                //
                            >
                                <div className={'alert-info-container'}
                                     onClick={() => {
                                         onAlertClick(alert, 2)
                                     }}>
                                    <div className={'alert-image-container'}
                                         style={{backgroundImage: `url(${alert.alert_image})`}}>
                                    </div>
                                    <div style={{marginLeft: 15, width: '100%'}}>
                                        <div style={{display: 'flex', width: '100%'}}>
                                            <p className={'alert-text'}
                                               style={{fontWeight: 600, margin: "auto 0"}}>{alert.alert_type}
                                            </p>
                                            <p className={'alert-text'}
                                               style={{margin: 'auto 20px', fontSize: 12}}>{alert.refrence_id}</p>
                                            <p className={'alert-date'}
                                               style={{margin: 'auto 0 auto auto'}}>{alert.created_at.substring(0, 10)}</p>
                                        </div>
                                        <p className={'alert-description'}>{alert.sub_type}</p>
                                        <p className={'alert-description'}>{alert.title}</p>
                                    </div>
                                </div>
                                <div style={{marginLeft: 'auto', padding: '0 5px 0 10px'}}>
                                    <div className="alert-dots">
                                        <BsThreeDotsVertical style={{fontSize: 15}}
                                                             role="button"
                                                             id="dropdownMenuLink"
                                                             data-bs-toggle="dropdown"
                                                             aria-expanded="false"/>
                                        <ul className="dropdown-menu"
                                            aria-labelledby="dropdownMenuLink">
                                            <li><a
                                                onClick={() => {
                                                    onAlertClick(alert, alert.status !== 2 ? 2 : 0, false)
                                                }} className="dropdown-item"
                                                style={{fontSize: 12}}>{alert.status === 2 ? 'Mark as UnRead' : "Mark as Read"}</a>
                                            </li>
                                            <li><a className="dropdown-item"
                                                   style={{fontSize: 12}}
                                                   onClick={() => {
                                                       deleteAlert(alert)
                                                   }}
                                            >Delete</a>
                                            </li>
                                            <li><a className="dropdown-item dropend"
                                                   style={{fontSize: 12}}
                                                   onClick={() => {
                                                       atcb_action({
                                                           name: `CLYKK: ${alert.title} - ${new Date()}`,
                                                           startDate: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`,
                                                           endDate: "",
                                                           options: ['Google', 'Apple', 'Outlook.com'],
                                                           description: `Setting a reminder for ${alert.title} from CLYKK.`,
                                                           timeZone: "Asia/Kolkata",
                                                           iCalFileName: "Reminder-Event",
                                                       });
                                                   }}
                                            >Reminder</a>
                                            </li>

                                        </ul>
                                    </div>
                                    <BsChevronRight
                                        className={'alert-right-arrow'}
                                        onClick={() => {
                                            onAlertClick(alert, 2)
                                        }}
                                    />
                                </div>
                            </div>)
                        })}
                    </div>
                </div>
            </div>

            <Modal
                open={reminderModal}
                onClose={handleCloseReminderModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >

                <ReminderModal handleCloseReminderModal={handleCloseReminderModal}/>

            </Modal>

            <Footer/>
        </>);
}

export default Alert;
